import _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import moment from 'moment';

import { withDefaultProps } from './utils';
import { PATIENT_ENROLLMENT_LIST_QUERY_NAME } from './PatientEnrollment';
import authorizationInfo from './fragments/authorizationInfo';
import detailedAuthorizationInfo from './fragments/detailedAuthorizationInfo';
import patientInfo from './fragments/patientInfo';
import authorizationPage from './fragments/authorizationPage';

export const GET_AUTHORIZATION_QUERY_NAME = 'GetAuthorization';
export const withAuthorizationQuery = gql`
  query ${GET_AUTHORIZATION_QUERY_NAME}($id: Int!) {
    authorizationById(id: $id) {
      ...detailedAuthorizationInfo
      isSupported
    }
  }
  ${detailedAuthorizationInfo}
`;

export const withFaxAssociatedAuthorizations = graphql(
  gql`
    query getAuthorizationsByFormNumber($id: Int!) {
      getAuthorizationsByFormNumber(id: $id) {
        ...authorizationInfo
      }
    }
    ${authorizationInfo}
  `,
  {
    options: { fetchPolicy: 'network-only' },
    props: withDefaultProps(({ data }) => ({ authorizations: _.get(data, 'getAuthorizationsByFormNumber') })),
  }
);

export const withFormAssociatedAuthorizations = graphql(
  gql`
    query getAuthorizationsByForm($id: Int!) {
      getAuthorizationsByForm(id: $id) {
        ...authorizationInfo
      }
    }
    ${authorizationInfo}
  `,
  {
    options: { fetchPolicy: 'network-only' },
    props: withDefaultProps(({ data }) => ({ authorizations: _.get(data, 'getAuthorizationsByForm') })),
  }
);

/* eslint-disable max-len */
export const authorizationsSearchQuery = gql`
  query authorizationSearch($submittedDaysAgo: Int, $InsuranceCompanyId: String, $InstitutionId: String, $patientFirstName: String, $patientLastName: String, $authorizationStatuses: [String], $limit: Int, $AuthorizationId: Int, $patientMemberId: String) {
    authorizationSearch(submittedDaysAgo: $submittedDaysAgo, InsuranceCompanyId: $InsuranceCompanyId, InstitutionId: $InstitutionId, patientFirstName: $patientFirstName, patientLastName: $patientLastName, authorizationStatuses: $authorizationStatuses, limit: $limit, AuthorizationId: $AuthorizationId, patientMemberId: $patientMemberId) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;
/* eslint-enable */

export const withAuthorizationSearch = graphql(
  authorizationsSearchQuery,
  {
    name: 'authorizationSearch',
    options: props => ({
      variables: {
        InstitutionId: props.InstitutionId,
        authorizationStatuses:
          props.authorizationStatuses
          || [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING, CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENT],
        patientFirstName: props.patientFirstName,
        patientLastName: props.patientLastName,
        InsuranceCompanyId: props.InsuranceCompanyId,
        submittedDaysAgo: props.submittedDaysAgo,
        patientMemberId: props.patientMemberId,
      },
      fetchPolicy: 'network-only',
    }),
  }
);

export const AUTHORIZATION_PAGINATION_QUERY_NAME = 'authorizationsPaginated';
export const withAuthorizationsPaginatedQuery = gql`
  query ${AUTHORIZATION_PAGINATION_QUERY_NAME}($filterByAccount: Boolean, $filterByExpired: Boolean, $isArchived: Boolean, $sortBy: String,
      $statuses: [AuthorizationStatusEnum], $firstName: String,$lastName: String, $limit: Int, $patientId: Int,
      $locationId: String, $expirationDate: DateTime, $filterByUpcomingDateOfServiceDays: String, $insuranceCompanyId: Int) {
    ${AUTHORIZATION_PAGINATION_QUERY_NAME}(filterByAccount: $filterByAccount, filterByExpired:$filterByExpired, isArchived:$isArchived,
      statuses: $statuses, firstName: $firstName, lastName: $lastName, limit: $limit, sortBy: $sortBy,
      expirationDate: $expirationDate,
      locationId: $locationId,
      patientId: $patientId,
      filterByUpcomingDateOfServiceDays: $filterByUpcomingDateOfServiceDays,
      insuranceCompanyId: $insuranceCompanyId) {
      ...authorizationPage
    }
  }
  ${authorizationPage}
`;

export const withAuthorizationsPaginated = graphql(
  withAuthorizationsPaginatedQuery,
  {
    options: (props) => {
      const base = { fetchPolicy: 'network-only' };
      const list = props.authorizationList;

      if (list) {
        return {
          ...base,
          variables: {
            limit: list.limit,
            expirationDate: _.get(list.filters, 'expirationDate')
              ? moment().add(list.filters.expirationDate.offset, list.filters.expirationDate.unit).toDate()
              : null,
            filterByAccount: list.selected.onlyMe,
            filterByExpired: list.selected.onlyExpired,
            isArchived: list.selected.isArchived,
            insuranceCompanyId: _.get(list.filters, 'insuranceCompanyId'),
            locationId: _.get(list.filters, 'locationId'),
            firstName: list.firstName,
            lastName: list.lastName,
            sortBy: list.sortBy,
            statuses: _.get(list.filters, 'status') ? [list.filters.status] : list.selected.statuses,
            filterByUpcomingDateOfServiceDays: _.get(list.filters, 'filterByUpcomingDateOfServiceDays'),
          },
        };
      }

      return {
        ...base,
        variables: {
          limit: 10,
          patientId: props.otherAuthorizationsList.patientId,
        },
      };
    },
    props: withDefaultProps(({ data }) => ({
      authorizationPage: _.get(data, 'authorizationsPaginated'),
      authorizationsPaginatedRefetch: _.get(data, 'refetch'),
    })),
  }
);

const withCreateAuthorizationMutation = gql`
  mutation createAuthorization($defaultFields: JSON) {
    createAuthorization(defaultFields: $defaultFields) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;

export const withCreateAuthorization = graphql(
  withCreateAuthorizationMutation,
  {
    name: 'createAuthorization',
    options: () => ({
      refetchQueries: () => [AUTHORIZATION_PAGINATION_QUERY_NAME],
    }),
  },
);

const withUpdateAuthorizationMutation = gql`
  mutation updateAuthorization($id: Int!, $patch: JSON) {
    updateAuthorizationById(id: $id, patch: $patch) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withUpdateAuthorization = graphql(withUpdateAuthorizationMutation, { name: 'updateAuthorization' });

export const withUpdateAuthorizationAndRefetch = graphql(
  withUpdateAuthorizationMutation,
  {
    name: 'updateAuthorizationAndRefetch',
    options: props => ({
      refetchQueries: () => [props.authorizationList ? AUTHORIZATION_PAGINATION_QUERY_NAME : PATIENT_ENROLLMENT_LIST_QUERY_NAME],
    }),
  },
);

const withUpdateAuthorizationProgressMutation = gql`
  mutation updateAuthorizationProgress($id: Int!, $config: JSON, $attachmentIds: [Int], $patientId: Int, $details: JSON) {
    setConfig: setOnAuthorizationJSONField(id: $id, field: "config", patch: $config) {
      ...detailedAuthorizationInfo
    }
    setDetails: setOnAuthorizationJSONField(id: $id, field: "formDetails", patch: $details) {
      ...detailedAuthorizationInfo
    }
    setAuthorizationAttachments(authorizationId: $id, attachmentIds: $attachmentIds) {
      ...detailedAuthorizationInfo
    }
    writeAuthorizationResultsToPatient(patientId: $patientId, authorizationId: $id) {
      ...patientInfo
    }
    setConfigDetailsOnAuthorization(id: $id, authorizationConfig: $config) {
      ...detailedAuthorizationInfo
    }
    writeIntegratedDetailsToAuthorization(id: $id) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
  ${patientInfo}
`;
export const withUpdateAuthorizationProgress = graphql(withUpdateAuthorizationProgressMutation, { name: 'updateAuthorizationProgress' });

export const withUpdateFormDetails = graphql(
  gql`
    mutation updateFormDetails($id: Int!, $details: JSON) {
      setOnAuthorizationJSONField(id: $id, field: "formDetails", patch: $details) {
        ...authorizationInfo
      }
    }
    ${authorizationInfo}
  `,
  { name: 'updateFormDetails' }
);

const withUpdateAuthorizationStatusMutation = gql`
  mutation updateAuthorizationStatus($id: Int!, $status: String!, $nonApprovalInfo: String, $nonApprovalType: String) {
    updateAuthorizationStatus(id: $id, status: $status, nonApprovalInfo: $nonApprovalInfo, nonApprovalType: $nonApprovalType) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withUpdateAuthorizationStatus = graphql(withUpdateAuthorizationStatusMutation, { name: 'updateAuthorizationStatus' });

const withSubmitAuthorizationMutation = gql`
  mutation submitAuthorization($id: Int!, $send: Boolean!, $signatureId: Int) {
    authorizationSubmit(id: $id, send: $send, signatureId: $signatureId) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withSubmitAuthorization = graphql(withSubmitAuthorizationMutation, { name: 'submitAuthorization' });

const setPrescriberOnAuthMutation = gql`
  mutation setPrescriberOnAuth($authorizationId: Int!, $prescriberId: Int) {
    setPrescriberOnAuth(prescriberId: $prescriberId, authorizationId: $authorizationId) {
      ...authorizationInfo
    }
  }
  ${authorizationInfo}
`;
export const withSetPrescriberOnAuth = graphql(setPrescriberOnAuthMutation, { name: 'setPrescriberOnAuth' });

const withSetPatientMutation = gql`
  mutation setPatientOnAuth($patientId: Int!, $authorizationId: Int!) {
    setPatientOnAuth(patientId: $patientId, authorizationId: $authorizationId) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withSetPatient = graphql(withSetPatientMutation, { name: 'setPatient' });

const withDeleteAuthorizationMutation = gql`
  mutation deleteAuthorization($id: Int!) {
    authorizationDelete(id: $id)
  }
`;
export const withDeleteAuthorization = graphql(
  withDeleteAuthorizationMutation,
  {
    name: 'deleteAuthorization',
    options: props => ({
      refetchQueries: () => [props.isReferralOrPatientEnrollment ? PATIENT_ENROLLMENT_LIST_QUERY_NAME : AUTHORIZATION_PAGINATION_QUERY_NAME],
    }),
  },
);

const withSendAuthorizationMutation = gql`
  mutation sendAuthorization($id: Int!, $phone: String!, $resendReasonKey: Int, $resendReasonAdditionalInfo: String) {
    sendAuthorization(id: $id, phone: $phone, resendReasonKey: $resendReasonKey, resendReasonAdditionalInfo: $resendReasonAdditionalInfo) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withSendAuthorization = graphql(
  withSendAuthorizationMutation,
  { name: 'sendAuthorization' },
);

export const withDuplicateAuthorization = graphql(
  gql`
    mutation duplicateAuthorization($authorizationId: Int!, $setNewForm: Boolean, $portalKey: String, $insuranceName: String) {
      duplicateAuthorization(authorizationId: $authorizationId, setNewForm: $setNewForm, portalKey: $portalKey, insuranceName: $insuranceName) {
        id
        portalKey
        isReferral
      }
    }
  `,
  { name: 'duplicateAuthorization', options: () => ({ refetchQueries: () => [AUTHORIZATION_PAGINATION_QUERY_NAME] }) }
);

const withCancelAuthorizationSendMutation = gql`
  mutation cancelAuthorizationSend($id: Int!) {
    cancelAuthorizationSend(id: $id) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withCancelAuthorizationSend = graphql(withCancelAuthorizationSendMutation, { name: 'cancelAuthorizationSend' });

const withSetAuthorizationReminderMutation = gql`
  mutation setAuthorizationReminder($id: Int!, $emailReminderDescription: String, $emailReminderAt: String!) {
    setAuthorizationReminder(id: $id, emailReminderAt: $emailReminderAt, emailReminderDescription: $emailReminderDescription) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;
export const withSetAuthorizationReminder = graphql(withSetAuthorizationReminderMutation, { name: 'setAuthorizationReminder' });

const withUndoAutoAssociatedResponseMutation = gql`
  mutation undoAutoAssociatedResponse($authorizationId: Int!, $isAutoAssociation: Boolean!) {
    undoAutoAssociatedResponse(authorizationId: $authorizationId, isAutoAssociation: $isAutoAssociation) {
      ...detailedAuthorizationInfo
    }
  }
  ${detailedAuthorizationInfo}
`;

export const withUndoAutoAssociatedResponse = graphql(
  withUndoAutoAssociatedResponseMutation,
  {
    name: 'undoAutoAssociatedResponse',
    options: () => ({
      refetchQueries: () => [AUTHORIZATION_PAGINATION_QUERY_NAME, GET_AUTHORIZATION_QUERY_NAME],
    }),
  }
);
