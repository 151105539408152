import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import Upload from '../../Upload';
import BaseButton from '../../BaseButton';
import AlertModal from '../../AlertModal';
import ListFilter from '../../MainList/ListFilter';
import CorrespondenceViewer from '../../CorrespondenceViewer';
import AutoAssociatedModal from '../../AutoAssociatedModal';
import FollowUpModal from '../../FollowUpModal';
import { withCreateAuthorizationCorrespondence, withDeleteCorrespondence } from '../../../graphql/AuthorizationCorrespondence';
import { withUndoAutoAssociatedResponse } from '../../../graphql/Authorization';
import { withCurrentAccount } from '../../../graphql/Account';
import { withCreateReviewNote } from '../../../graphql/Note';
import hasActionableFollowUp from '../../../util/hasActionableFollowUp';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const SectionTitle = styled.div`
  color: ${props => props.theme.purple};
  border-bottom: 1px solid #CCCCCC;
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
`;

const SectionDescription = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 500;
`;

const EditResponses = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CautionText = styled.div`
  color: #ff5555;
`;

const IncorrectAssociationHelper = styled.div`
  background-color: ${props => props.theme.lightPurple};
  padding: 20px;
`;

const TriggerIncorrectAssociationText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.purple};
  font-weight: 500;
`;

const {
  DATE_FORMAT,
  FOLLOW_UP_TYPES,
  TERMINAL_AUTHORIZATION_STATUSES,
  CORRESPONDENCE_LABEL_TYPES,
  AUTHORIZATION_CORRESPONDENCE_TYPES,
  AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES,
} = CONFIG.CONSTANTS;

const labelStrings = {
  [CORRESPONDENCE_LABEL_TYPES.ACCESS_SOLUTIONS_BI]: 'Benefit Investigation Response',
  [CORRESPONDENCE_LABEL_TYPES.ACCESS_SOLUTIONS_COPAY]: 'Copay Assistence Response',
  [CORRESPONDENCE_LABEL_TYPES.GOODDAYS_ENROLLMENT]: 'GoodDays Response',
  [CORRESPONDENCE_LABEL_TYPES.FAX_CONFIRMATION]: 'Sent Fax Confirmation',
  [CORRESPONDENCE_LABEL_TYPES.GOODDAYS_BALANCE_CHECK]: 'Balance Check',
  [CORRESPONDENCE_LABEL_TYPES.PENDING_RESPONSE]: 'Pending',
};

export class ResponseSection extends PureComponent {
  constructor(props) {
    super(props);

    const showReviewAssociationModal = props.authorization.requiresAssociationReview;
    const showPayerQuestionsModal = hasActionableFollowUp(props.authorization)
      && _.get(props.authorization, 'followUp.type') === FOLLOW_UP_TYPES.GENERAL_QUESTIONS;
    const displayReviewWarning = _.includes(TERMINAL_AUTHORIZATION_STATUSES, props.authorization.status)
      && props.authorization.displayReviewWarning
      && !showReviewAssociationModal
      && !showPayerQuestionsModal;
    this.state = {
      selectedFormId: _.get(_.find(props.authorization.correspondences, { isLatest: true }), 'id'),
      showModal: false,
      showIncorrectAssociationModal: false,
      showReviewAssociationModal,
      showPayerQuestionsModal,
      displayReviewWarning,
    };
  }

  componentDidMount = async () => {
    const { authorization, createReviewNote } = this.props;
    if (authorization.displayReviewWarning) {
      await createReviewNote({ variables: { authorizationId: authorization.id } });
    }
  }

  deleteCurrentCorrespondence = async () => {
    const { selectedFormId } = this.state;
    const { deleteCorrespondence, alert, authorization } = this.props;
    try {
      await deleteCorrespondence({ variables: { id: selectedFormId } });
      alert.info('Successfully deleted correspondence');
      this.setState({ showModal: false, selectedFormId: _.get(_.find(authorization.correspondences, { isLatest: true }), 'id') });
    } catch (e) {
      alert.error('There was an error in deleting the correspondence');
    }
  }

  onDropSuccess = async (__, res) => {
    const { createAuthorizationCorrespondence, authorization, alert } = this.props;
    try {
      await createAuthorizationCorrespondence({
        variables: {
          authorizationId: authorization.id,
          fileId: res.data.id,
          type: AUTHORIZATION_CORRESPONDENCE_TYPES.RESPONSE,
        },
      });
      this.setState({ selectedFormId: _.get(_.find(authorization.correspondences, { isLatest: true }), 'id') });
      alert.info('Successfully uploaded new correspondence');
    } catch (e) {
      alert.error('There was an error uploading the document.');
    }
  }

  onDropError = () => {
    const { alert } = this.props;
    alert.error('There was an error uploading the document.');
  }

  getCorrespondences = () => {
    const { authorization } = this.props;
    return _.reject(_.sortBy(authorization.correspondences, ['isLatest', 'createdAt']), { isLatest: false, fileURL: null });
  }

  getCurrentCorrespondence = (correspondences) => {
    const { selectedFormId } = this.state;
    return _.find(correspondences, { id: selectedFormId }) || _.find(correspondences, { isLatest: true });
  }

  undoAutoAssociatedResponse = async () => {
    const { undoAutoAssociatedResponse, authorization, alert } = this.props;

    try {
      await undoAutoAssociatedResponse({ variables: { authorizationId: authorization.id, isAutoAssociation: false } });
      alert.info('The SamaCare team has been notified.');
    } catch {
      alert.error('There was an error reporting this error, please reach out directly');
    } finally {
      this.setState({ showIncorrectAssociationModal: false });
    }
  }

  render() {
    const {
      selectedFormId,
      showModal,
      showIncorrectAssociationModal,
      showReviewAssociationModal,
      showPayerQuestionsModal,
      displayReviewWarning,
    } = this.state;
    const { authorization, back } = this.props;
    const documentString = authorization.isPatientEnrollment ? 'enrollment' : 'authorization';
    const correspondences = this.getCorrespondences();
    const currentCorrespondence = this.getCurrentCorrespondence(correspondences);
    const tabs = correspondences.map(({ createdAt, id, label }, i) => {
      const formattedLabel = `${labelStrings[label] || 'Correspondence '}(${i + 1}) - ${moment(createdAt).format('MM/DD')}`;
      return {
        title: formattedLabel || `${moment(createdAt).format(DATE_FORMAT)} (${i + 1} of ${correspondences.length})`,
        tabTitle: formattedLabel || `${moment(createdAt).format(DATE_FORMAT)} (${i + 1} of ${correspondences.length})`,
        id,
      };
    });

    return (
      <FormContainer>
        <ListFilter
          onSelect={(selection) => {
            this.setState({ selectedFormId: selection.id });
          }}
          selected={_.find(tabs, { id: selectedFormId })}
          tabs={tabs}
          hideFilters
        />
        { _.get(currentCorrespondence, 'fileURL') ? (
          <CorrespondenceViewer correspondence={currentCorrespondence} height="800px" width="100%" />
        ) : (
          <div>
            {_.isEmpty(correspondences)
              ? `There are no responses associated with this ${documentString}`
              : `This response was created without an associated file.
                  This happens when a response is received over the phone or if the user hasn't uploaded the associated form for this response.
                  You can view the notes in the main list to find more details on the response or add a form below.
                `}
          </div>
        )}
        {_.includes(AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES, authorization.status)
        && (
          <div>
            {
              _.get(currentCorrespondence, 'isLatest') && _.get(currentCorrespondence, 'fileURL') && (
                <IncorrectAssociationHelper>
                  { 'Is this response attached to the wrong place? ' }
                  <TriggerIncorrectAssociationText onClick={() => { this.setState({ showIncorrectAssociationModal: true }); }}>
                    Click here
                  </TriggerIncorrectAssociationText>
                  { ' to have SamaCare resolve this issue.' }
                </IncorrectAssociationHelper>
              )
            }
            <div>
              <SectionTitle>Upload Payer Responses</SectionTitle>
              <SectionDescription>
                Files uploaded here are not transmitted to the payer. Uploaded files are for your record keeping only.
              </SectionDescription>
            </div>
            <EditResponses>
              <Upload onDropSuccess={this.onDropSuccess} onDropError={this.onDropError} />
              { !_.get(currentCorrespondence, 'isLatest')
              && (
                <BaseButton
                  style={{ width: '300px', height: '50px' }}
                  color="red"
                  key="deleteCorrespondenceModal"
                  onClick={() => { this.setState({ showModal: true }); }}
                >
                  Delete
                </BaseButton>
              )}
            </EditResponses>
          </div>
        )}
        <FormSubmitButtons back={back} />
        {
          <AlertModal
            header="Confirmation Delete"
            open={showModal}
            closeModal={() => { this.setState({ showModal: false }); }}
            content={(
              <CautionText>
                {`This will permanently delete this message from this ${documentString}. This cannot be undone.`}
              </CautionText>
            )}
            buttons={(
              <BaseButton
                style={{ width: '100%' }}
                key="deleteCorrespondenceButton"
                onClick={this.deleteCurrentCorrespondence}
              >
                I am sure
              </BaseButton>
              )}
          />
        }
        {
          <AlertModal
            header="Confirm Incorrect Response"
            open={showIncorrectAssociationModal}
            closeModal={() => { this.setState({ showIncorrectAssociationModal: false }); }}
            content={(
              <CautionText>
                {`By clicking below, you will send this response back to SamaCare and set this ${documentString} back to the pending status`}
              </CautionText>
            )}
            buttons={(
              <BaseButton
                style={{ width: '100%' }}
                key="undoAutoAssociatedResponse"
                onClick={this.undoAutoAssociatedResponse}
              >
                I am sure
              </BaseButton>
              )}
          />
        }
        { showReviewAssociationModal && (
          <AutoAssociatedModal authorization={authorization} closeModal={() => this.setState({ showReviewAssociationModal: false })} />
        ) }
        { showPayerQuestionsModal && (
          <FollowUpModal
            redirect={() => { this.setState({ showPayerQuestionsModal: false }); }}
            followUpId={authorization.followUp.id}
            correspondence={authorization.latestCorrespondence}
            onClose={() => { this.setState({ showPayerQuestionsModal: false }); }}
          />
        ) }
        { displayReviewWarning && (
          <AlertModal
            open
            closeModal={() => { this.setState({ displayReviewWarning: false }); }}
            buttons={(
              <BaseButton
                style={{ width: '100%' }}
                key="deleteCorrespondenceButton"
                onClick={() => { this.setState({ displayReviewWarning: false }); }}
              >
                Continue
              </BaseButton>
            )}
            header="Review Response"
            content={(
              <div style={{ margin: '5px 0' }}>
                {'Please ensure the response correctly matches the status '}
                <b>{_.upperFirst(authorization.status.replace(/_/g, ' '))}</b>
              </div>
            )}
          />
        ) }
      </FormContainer>
    );
  }
}

export default compose(
  withCreateAuthorizationCorrespondence,
  withDeleteCorrespondence,
  withCurrentAccount,
  withCreateReviewNote,
  withUndoAutoAssociatedResponse,
)(withAlert(ResponseSection));
