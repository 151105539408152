import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

import SegmentNumber from './SegmentNumber';
import SegmentText from './SegmentText';
import SegmentOptions from './SegmentOptions';
import SegmentCheckbox from './SegmentCheckbox';
import SegmentDate from './SegmentDate';
import SegmentPhone from './SegmentPhone';
import SegmentSocial from './SegmentSocial';
import SegmentExtension from './SegmentExtension';
import SegmentState from './SegmentState';
import SegmentUpload from './SegmentUpload';
import SegmentButton from './SegmentButton';
import SegmentICD from './SegmentICD';
import SegmentZip from './SegmentZip';
import SegmentMultiline from './SegmentMultiline';
import RequiredTag from '../RequiredTag';
import { unsetRequiredFields, setRequiredFields } from '../../reducers/formReducer';

const { FORM_TYPES } = CONFIG.CONSTANTS;
export const Container = styled.div`
  display: flex;
  flex-direction: ${props => (props.isinline ? 'row' : 'column')};
  align-items: ${props => (props.isinline ? 'center' : 'flex-start')};
  margin: 0 10px 0 0;
  width: ${props => props.width};
  padding-left: ${props => (props.isConditional ? '15px' : 0)};
  border-radius: 3px;
`;

export const SegmentTitleContainer = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: ${props => `9px 4px ${props.paddingleft} 4px`};
  cursor: ${props => (props.isCheckbox ? 'pointer' : 'default')};
  flex: ${props => (props.isCheckbox ? 2 : '')};
  ${Container}.isInline & {
    margin: 10px 0 10px 10px;
  }
`;

const sizeToPx = {
  large: '600px',
  med: '350px',
  small: '200px',
};

const INLINE_TYPES = [FORM_TYPES.CHECKBOX];
class Segment extends PureComponent {
  componentDidMount() {
    const { item, setRequired } = this.props;
    if (item.required) {
      if (item.type === FORM_TYPES.OPTION) {
        setRequired(_.map(item.options, 'key'));
      } else {
        setRequired(item.key);
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { item, display } = this.props;

    if (prevProps.display && display === false) {
      this.recursivelyUnsetItem(item);
    }
  }

  componentWillUnmount() {
    const { item, unsetRequired } = this.props;

    if (item.required) {
      if (item.type === FORM_TYPES.OPTION) {
        unsetRequired(_.map(item.options, 'key'));
      } else {
        unsetRequired(item.key);
      }
    }
  }

  recursivelyUnsetItem = (item) => {
    const { set } = this.props;
    if (item.key) { set({ [item.key]: null }); }
    if (item.items) {
      _.each(item.items, this.recursivelyUnsetItem);
    }
    if (item.options) {
      _.each(item.options, this.recursivelyUnsetItem);
    }
  }

  render() {
    const { item, set, display, leftPadding, size, disabled, highlightRequiredFields } = this.props;
    const isInline = _.includes(INLINE_TYPES, item.type);
    if (display) {
      return (
        <Container
          className={classNames({ isInline })}
          isinline={isInline ? 1 : 0}
          paddingleft={leftPadding}
          width={sizeToPx[size]}
          isConditional={item.displayIf}
        >
          { item.type === FORM_TYPES.CHECKBOX && (<SegmentCheckbox disabled={disabled} handleChange={set} item={item} name={item.key} />) }
          <SegmentTitleContainer isCheckbox={item.type === FORM_TYPES.CHECKBOX} isinline={isInline ? 1 : 0} paddingleft={leftPadding}>
            <label htmlFor={item.key}>{(item.title || item.key)}</label>
            { item.required && (<RequiredTag />) }
          </SegmentTitleContainer>
          {
            item.type === FORM_TYPES.ICD
            && (<SegmentICD disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.STATE
            && (
              <SegmentState
                disabled={disabled}
                handleChange={set}
                item={item}
                width={sizeToPx[size]}
                highlightIfMissing={highlightRequiredFields && item.required}
              />
            )
          }
          {
            item.type === FORM_TYPES.OPTION
            && (<SegmentOptions disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.MULTILINE
            && (<SegmentMultiline disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            (item.type === FORM_TYPES.TEXT || !_.includes(_.values(FORM_TYPES), item.type))
            && (<SegmentText disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.NUMBER
            && (<SegmentNumber disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.PHONE
            && (<SegmentPhone disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.SOCIAL
            && (<SegmentSocial disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.EXTENSION
            && (<SegmentExtension disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.UPLOAD
            && (<SegmentUpload disabled={disabled} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.ACTION
            && (
              <SegmentButton
                disabled={item.enable ? false : disabled}
                onClick={item.action}
                text={item.text}
                highlightIfMissing={highlightRequiredFields && item.required}
              />
            )
          }
          {
            item.type === FORM_TYPES.ZIP
            && (
              <SegmentZip
                disabled={disabled}
                handleChange={set}
                item={item}
                highlightIfMissing={highlightRequiredFields && item.required}
              />
            )
          }
          {
            _.includes([FORM_TYPES.DATE, FORM_TYPES.COMPOSED_DATE], item.type)
            && (<SegmentDate disabled={disabled} handleChange={set} item={item} highlightIfMissing={highlightRequiredFields && item.required} />)
          }
          {
            item.type === FORM_TYPES.SUBFIELDS
            && (_.map(
              item.items,
              subItem => (
                <ConnectedSegment
                  key={subItem.key}
                  disabled={disabled}
                  item={subItem}
                  highlightIfMissing={highlightRequiredFields && item.required}
                />
              )
            ))
          }
        </Container>
      );
    }
    return (<div />);
  }
}

function mapStateToProps(state, props) {
  const display = props.item.displayIf ? !!state.form.results[props.item.displayIf] : true;
  return { display, highlightRequiredFields: state.form.highlightRequiredFields };
}

const mapDispatchToProps = dispatch => ({
  setRequired(toSet) { dispatch(setRequiredFields(toSet)); },
  unsetRequired(toUnset) { dispatch(unsetRequiredFields(toUnset)); },
});

const ConnectedSegment = connect(mapStateToProps, mapDispatchToProps)(Segment);
export default ConnectedSegment;
