import React from 'react';
import styled from 'styled-components';
import CloseIcon from 'react-icons/lib/md/close';
import { BaseText } from 'Segment/StyledComponents';
import strings from 'Resources/strings';

import IconButton, { IconButtonStyled } from './IconButton';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
`;

const PatientSearchText = styled.div`
  width: 75px;
  text-align: right;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  margin-left: 10px;
  align-items: center;
  ${IconButtonStyled} {
    background-color: white;
    border: 1px solid ${props => props.theme.purple};
    color: ${props => props.theme.purple};
    &:hover {
      background-color: ${props => props.theme.purple};
      color: white;
    }
  }
`;

const PatientSearchFields = ({ firstName, lastName, clearName, setName }) => (
  <Wrapper>
    {firstName || lastName ? (
      <IconButtonWrapper clearState>
        <IconButton
          icon={CloseIcon} onClick={clearName}
        >
          Clear
        </IconButton>
      </IconButtonWrapper>
    ) : <PatientSearchText>Patient Search</PatientSearchText>}
    <BaseText
      style={{ width: '150px', margin: '0 10px' }}
      value={firstName}
      onChange={(e) => { setName(true, e); }}
      placeholder={strings.AUTHORIZATIONS.FIRST_NAME_SEARCH_PLACEHOLDER}
    />
    <BaseText
      style={{ width: '150px' }}
      value={lastName}
      onChange={(e) => { setName(false, e); }}
      placeholder={strings.AUTHORIZATIONS.LAST_NAME_SEARCH_PLACEHOLDER}
    />
  </Wrapper>
);

export default PatientSearchFields;
