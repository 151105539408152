import React, { useState } from 'react';
import styled from 'styled-components';

import Modal from './Modal';
import BaseButton from './BaseButton';
import { ModalFooter, ModalContent } from './ModalStyledComponents';

const CustomModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: flex-end;
  ${BaseButton} {
    width: 150px;
    height: 40px;
    margin-left: 10px;
  }
`;

const DecriptionText = styled.div`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 300;
  margin: 10px 20px;
`;

const BackButton = styled(BaseButton)`
  color: white;
  border-color: ${props => props.theme.lightGray};
  background-color: ${props => props.theme.lightGray};
  &:hover {
    color: white;
    background-color: ${props => (props.disabled ? props.theme.lightGray : props.theme.gray)};
    border-color: ${props => (props.disabled ? props.theme.lightGray : props.theme.gray)};
  }
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0;
`;

const ImageContainer = styled.div`
  margin: 0 10px;
  width: 100%;
`;

const Image = styled.img`
  width: ${props => props.logo ? '20%' : '700px'};
  border: ${props => props.logo ? '' : `1px solid ${props.theme.lightGray}`};
`;

const tutorialLinks = [
  {
    description: 'SamaCare integrates with various payer websites to create a seamless web experience for your practice. '
      + 'Please use the Live Viewer window just like any other browser window.',
    link: '../../assets/logo.svg',
  },
  {
    description: 'Note that in LiveViewer, you are interacting directly with an external website through our platform. '
      + 'We will autofill any relevant information as you complete the authorization. You can even download attachments from their site, and '
      + 'they will be automatically uploaded to SamaCare.',
    link: '../../assets/portal-tutorial/01_liveViewer.png',
  },
  {
    description: 'As a reminder, when using LiveViewer you are interacting directly with the payer website. '
      + 'Once you have submitted the authorization through the integrated portal, you will have to '
      + 'mark the authorization as submitted in SamaCare using the right hand sidebar. '
      + 'This let\'s SamaCare know to keep an eye on your authorizations for you.',
    link: '../../assets/portal-tutorial/02_manualSubmit.png',
  },
  {
    description: 'We will periodically check the status of your authorization.  '
      + 'You can trigger a check of any supported portal authorization by clicking Check Authorization from the main list.',
    link: '../../assets/portal-tutorial/03_checkStatus.png',
  },
];

const PortalTutorialModal = ({ open, back, onComplete }) => {
  const [step, setStep] = useState(0);
  const finalStep = step === tutorialLinks.length - 1;
  const showBackButton = step > 0 && step < tutorialLinks.length - 1;
  const { description, link } = tutorialLinks[step];
  return (
    <Modal header="SamaCare Live Integration Tutorial" open={open} onClick={back}>
      <CustomModalContent>
        <ImageContainer>
          <Image logo={step === 0} src={link} />
        </ImageContainer>
      </CustomModalContent>
      <DecriptionText>{description}</DecriptionText>
      <CustomModalFooter>
        {showBackButton && <BackButton onClick={() => { setStep(step - 1); }}>Back</BackButton>}
        {finalStep
          ? <BaseButton onClick={() => { onComplete(); }}>Finish</BaseButton>
          : <BaseButton onClick={() => { setStep(step + 1); }}>Next</BaseButton>}
      </CustomModalFooter>
    </Modal>
  );
};

export default PortalTutorialModal;
