import React, { PureComponent } from 'react';
import _ from 'lodash';
import { withApollo } from 'react-apollo';
import Select from 'react-select';
import { withAlert } from 'react-alert';

import { HCPCSCodeSearchQuery } from '../graphql/HCPCSCode';

export class HcpcsCodeSelector extends PureComponent {
  requeryResults = (searchString) => {
    const { client } = this.props;

    return client.query({
      query: HCPCSCodeSearchQuery,
      variables: { searchString },
    }).then(({ data }) => (data.searchHCPCS));
  }

  selectResult = (result) => {
    const { alert, onChange } = this.props;

    if (result) {
      const { value, label } = result;
      if (value.length !== 5 || _.isNaN(parseInt(value.slice(1), 10))) {
        alert.error('The value you provide must be a 5 digit HCPCS code');
      } else {
        onChange({ value, label });
      }
    } else {
      onChange(null);
    }
  }

  render() {
    const { value, highlight } = this.props;

    return (
      <Select.AsyncCreatable
        style={{ maxWidth: 400, height: 40, minWidth: 300, border: (highlight ? '2px solid red' : '') }}
        loadOptions={input => (
          this.requeryResults(input)
            .then(res => ({ options: _.map(res, hcpcs => ({ label: `${hcpcs.code}: ${hcpcs.shortDescription}`, value: hcpcs.code })) }))
        )}
        onChange={this.selectResult}
        value={value}
      />
    );
  }
}

export default withApollo(withAlert(HcpcsCodeSelector));
