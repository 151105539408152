import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import Section from 'AuthorizationSharedComponents/Section';

import BaseButton from '../../BaseButton';
import Modal from '../../Modal';
import FormSubmitButtons from '../../AuthorizationSharedComponents/FormSubmitButtons';
import { withUpsertAccount } from '../../../graphql/Account';
import { withUpsertLocation } from '../../../graphql/Location';
import { setFormFields } from '../../../reducers/formReducer';

const CreateButton = styled(BaseButton)`
  height: 30px;
  width: 250px;
  margin: 35px 0 0 35px;
`;

const dFields = CONFIG.DEFAULT_FIELDS;
export class CreatePrescriberOrLocation extends PureComponent {
  state = { shouldRenderCreateModal: false };

  handleModal = (shouldOpen = false) => {
    const { set, isPrescriber } = this.props;

    this.setState({ shouldRenderCreateModal: shouldOpen });
    if (isPrescriber) {
      set({
        [dFields.PRESCRIBER_FIRST_NAME.key]: '',
        [dFields.PRESCRIBER_LAST_NAME.key]: '',
        [dFields.PRESCRIBER_SPECIALTY.key]: '',
        [dFields.PRESCRIBER_NPI.key]: '',
        [dFields.PRESCRIBER_TIN.key]: '',
      });
    } else {
      set({
        [dFields.LOCATION_NAME.key]: '',
        [dFields.PRESCRIBER_ADDRESS.key]: '',
        [dFields.PRESCRIBER_CITY.key]: '',
        [dFields.PRESCRIBER_STATE.key]: '',
        [dFields.PRESCRIBER_ZIP.key]: '',
        [dFields.PRESCRIBER_OFFICE_TIN.key]: '',
        [dFields.PRESCRIBER_OFFICE_NPI.key]: '',
      });
    }
  }

  handleCreate = async () => {
    const { results, upsertAccount, alert, requiredFields, isPrescriber, upsertLocation } = this.props;

    const hasAllRequiredFields = _.every(_.map(_.flatten(requiredFields), 'key'), field => results[field]);
    const errMsgText = isPrescriber ? 'prescriber' : 'location';

    if (hasAllRequiredFields) {
      try {
        if (isPrescriber) {
          await upsertAccount({ variables: { patch: {
            firstName: results[dFields.PRESCRIBER_FIRST_NAME.key],
            lastName: results[dFields.PRESCRIBER_LAST_NAME.key],
            email: `newPrescriber@${Math.floor((Math.random() * 10000000))}.com`,
            type: 'prescriber',
            specialty: results[dFields.PRESCRIBER_SPECIALTY.key],
            NPI: results[dFields.PRESCRIBER_NPI.key],
            TIN: results[dFields.PRESCRIBER_TIN.key],
          } } });
        } else {
          await upsertLocation({ variables: { patch: {
            name: results[dFields.LOCATION_NAME.key],
            address: results[dFields.PRESCRIBER_ADDRESS.key],
            city: results[dFields.PRESCRIBER_CITY.key],
            state: results[dFields.PRESCRIBER_STATE.key],
            zip: results[dFields.PRESCRIBER_ZIP.key],
            taxId: results[dFields.PRESCRIBER_OFFICE_TIN.key],
            NPI: results[dFields.PRESCRIBER_OFFICE_NPI.key],
            facilityName: results[dFields.LOCATION_NAME.key],
          } } });
        }

        this.setState({ shouldRenderCreateModal: false });
        alert.success('Success');
      } catch (e) {
        alert.error(`There was an error creating the new ${errMsgText}: ${e.message}`);
      }
    } else {
      alert.error(`All ${errMsgText} details fields are required to create a new ${errMsgText}`);
    }
  }

  render() {
    const { isPrescriber, requiredFields } = this.props;
    const { shouldRenderCreateModal } = this.state;

    const prescriberOrLocationText = isPrescriber ? 'Prescriber' : 'Location';
    return (
      <>
        <CreateButton onClick={() => { this.handleModal(true); }}>{`Create + Set New ${prescriberOrLocationText}`}</CreateButton>
        { shouldRenderCreateModal && (
          <Modal header={`Create New ${prescriberOrLocationText}`} onClick={() => { this.handleModal(); }} open={shouldRenderCreateModal}>
            <Section
              section={{
                items: requiredFields,
                title: `${prescriberOrLocationText} Details`,
              }}
            />
            <br />
            <FormSubmitButtons
              back={() => { this.handleModal(); }}
              submitText="Create and Set"
              submit={this.handleCreate}
            />
          </Modal>
        ) }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    set: toSet => dispatch(setFormFields(toSet)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(compose(withUpsertAccount, withUpsertLocation)(withAlert(CreatePrescriberOrLocation)));
