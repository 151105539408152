import React, { PureComponent } from 'react';
import _ from 'lodash';
import mouseTrap from 'react-mousetrap';

import { TransparentOverlay } from './Overlay';
import ScrollBar from './ScrollBar';

function getMouseActionRelativeToRect(e, zoom) {
  const rect = e.target.getBoundingClientRect();
  return { x: (e.clientX - rect.left) / zoom, y: (e.clientY - rect.top) / zoom };
}

const { INTEGRATION_MESSAGE_TYPES, LIVE_CONNECTION_ACTIONS } = CONFIG.CONSTANTS;
const { LIVE_CONNECTION_MESSAGE } = INTEGRATION_MESSAGE_TYPES;
class ActionOverlay extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isScrolling: false, x: null, y: null, isVertical: null };

    this._throttledDrag = _.throttle(this._onDrag.bind(this), 100);
    this.wheelEvents = [];
    this.stopScrollTimeout = null;
  }

  onMouseDown = (e) => {
    const { sendIntegrationMessage, currentZoom } = this.props;
    const { isScrolling } = this.state;

    if (isScrolling) {
      this.setState({ isScrolling: false, x: null, y: null, isVertical: null });
    }

    sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.MOUSE_DOWN, data: getMouseActionRelativeToRect(e, currentZoom) });
  }

  onMouseLeave = () => {
    const { isScrolling } = this.state;

    if (isScrolling) {
      this.stopScrollTimeout = setTimeout(() => {
        this.stopScroll();
        this.stopScrollTimeout = null;
      }, 500);
    }
  }

  onMouseEnter = () => {
    if (this.stopScrollTimeout) {
      clearTimeout(this.stopScrollTimeout);
    }
  }

  stopScroll = () => {
    this.setState({ isScrolling: false, x: null, y: null, isVertical: null });
  }

  onMouseUp = (e) => {
    const { sendIntegrationMessage, currentZoom } = this.props;
    const { isScrolling } = this.state;

    if (isScrolling) {
      e.stopPropagation();
      this.stopScroll();
    } else {
      sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.MOUSE_UP, data: getMouseActionRelativeToRect(e, currentZoom) });
    }
  }

  onWheel = (e) => {
    const { currentZoom } = this.props;
    this.wheelEvents.push({ deltaX: e.deltaX, deltaY: e.deltaY, ...getMouseActionRelativeToRect(e, currentZoom) });
    this.dispatchOnWheelEvents();
  }

  _dispatchOnWheelEvents = () => {
    const { sendIntegrationMessage } = this.props;

    const events = this.wheelEvents;
    this.wheelEvents = [];

    const toUpdate = _.reduce(
      events,
      (agg, event) => ({ deltaX: agg.deltaX + event.deltaX, deltaY: agg.deltaY + event.deltaY, x: event.x, y: event.y }),
      { deltaX: 0, deltaY: 0 },
    );

    sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.WHEEL, data: toUpdate });
  }

  dispatchOnWheelEvents = _.throttle(this._dispatchOnWheelEvents, 250, { leading: true, trailing: true });

  onKeyDown = (e) => {
    const { sendIntegrationMessage } = this.props;
    const mappings = {
      8: 'Backspace',
      9: 'Tab',
      13: 'Enter',
      27: 'Escape',
      33: 'PageUp',
      34: 'PageDown',
      37: 'ArrowLeft',
      38: 'ArrowUp',
      39: 'ArrowRight',
      40: 'ArrowDown',
      46: 'Backspace',
    };

    if (mappings[e.which]) {
      // The event prevent default and propagation are important for Tab and Escape
      e.preventDefault();
      e.stopPropagation();
      sendIntegrationMessage({
        message: LIVE_CONNECTION_MESSAGE,
        type: LIVE_CONNECTION_ACTIONS.KEY_PRESS,
        data: { key: mappings[e.which] },
      });
    }
  }

  onKeyPress = (e) => {
    const { sendIntegrationMessage } = this.props;
    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.KEY_PRESS,
      data: { key: String.fromCharCode(e.which) },
    });
  }

  paste = (e) => {
    const { sendIntegrationMessage } = this.props;
    sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.TYPE, data: { string: e.clipboardData.getData('text/plain') } });
  }

  _onDrag = (clientX, clientY) => {
    const { sendIntegrationMessage, scrollbarDimensions, liveViewerWidth, liveViewerHeight } = this.props;
    const { isScrolling, y, x, isVertical } = this.state;

    if (isScrolling) {
      let xDiff = 0;
      let yDiff = 0;

      if (isVertical) {
        yDiff = ((clientY - y) / liveViewerHeight) * scrollbarDimensions.client.height;
      } else {
        xDiff = ((clientX - x) / liveViewerWidth) * scrollbarDimensions.client.width;
      }

      sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.SCROLL, data: { x: xDiff, y: yDiff } });

      this.setState({ x: clientX, y: clientY });
    }
  }

  onDrag = (e) => {
    const y = e.clientY;
    const x = e.clientX;
    this._throttledDrag(x, y);
  }

  clickScroll = (direction) => {
    const { liveViewerWidth, liveViewerHeight, sendIntegrationMessage } = this.props;
    const multiplier = 0.2;
    let xDiff = 0;
    let yDiff = 0;

    if (direction === 'top') {
      yDiff = Math.floor(liveViewerHeight * multiplier);
    } else if (direction === 'bottom') {
      yDiff = -Math.floor(liveViewerHeight * multiplier);
    } else if (direction === 'left') {
      xDiff = -Math.floor(liveViewerWidth * multiplier);
    } else if (direction === 'right') {
      xDiff = Math.floor(liveViewerWidth * multiplier);
    }

    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.SCROLL,
      data: { x: xDiff, y: yDiff },
    });
  }

  render() {
    const { isActive, liveViewerWidth, liveViewerHeight, scrollbarDimensions, currentZoom, children, width } = this.props;

    const childImage = (
      <div
        style={{
          width: `${liveViewerWidth}px`,
          height: `${liveViewerHeight}px`,
          overflowX: isActive ? 'scroll' : 'hidden',
          overflowY: 'hidden',
          position: 'relative',
        }}
      >
        { /* The following two elemenets cannot be nested, otherwise, our onpaste event will be swallowed by the child */ }
        <TransparentOverlay
          tabIndex="0"
          onMouseDown={isActive ? this.onMouseDown : () => {}}
          onMouseUp={isActive ? this.onMouseUp : () => {}}
          zIndexOverride={isActive ? 3 : 1}
          width={`${width}px`}
        />
        { children }
      </div>
    );

    if (isActive) {
      return (
        <div
          style={{ position: 'relative' }}
          onWheel={this.onWheel}
          onMouseMove={this.onDrag}
          onMouseLeave={this.onMouseLeave}
          onMouseEnter={this.onMouseEnter}
          onKeyPress={this.onKeyPress}
          onKeyDown={this.onKeyDown}
          onPaste={this.paste}
        >
          { childImage }
          { scrollbarDimensions && (
            <ScrollBar
              onScrollStart={(x, y, isVertical) => { this.setState({ isScrolling: true, x, y, isVertical }); }}
              height={scrollbarDimensions.scrollbar.yLength}
              position={scrollbarDimensions.location.y}
              onClickScroll={this.clickScroll}
              stopScroll={this.stopScroll}
              isZoomed={currentZoom !== 1}
            />
          ) }
        </div>
      );
    }

    return childImage;
  }
}

export default mouseTrap(ActionOverlay);
