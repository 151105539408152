import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _ from 'lodash';
import drugOptionInfo from './fragments/drugOptionInfo';

import { withDefaultProps } from './utils';

const DRUG_OPTIONS_QUERY_NAME = 'getDrugOptions';

export const withGetDrugOptionsQuery = gql`
  query ${DRUG_OPTIONS_QUERY_NAME}($specialty: String) {
    getDrugOptions(specialty: $specialty) {
      ...drugOptionInfo
    }
  }
  ${drugOptionInfo}
`;

export const withDrugOptions = graphql(withGetDrugOptionsQuery, {
  props: withDefaultProps(({ data }) => ({ drugOptions: _.get(data, 'getDrugOptions') })),
});

export const withUpsertDrugOption = graphql(
  gql`
    mutation upsertDrugOption($id: Int, $code: String!, $drugName: String!, $dose: String, $specialties: [String!]) {
      upsertDrugOption(id: $id, code: $code, drugName: $drugName, dose: $dose, specialties: $specialties) {
        ...drugOptionInfo
      }
    }
    ${drugOptionInfo}
  `,
  {
    name: 'upsertDrugOption',
    options: () => ({ refetchQueries: () => [DRUG_OPTIONS_QUERY_NAME] }),
  }
);

export const withDeleteDrugOption = graphql(
  gql`
    mutation deleteDrugOption($id: Int!) {
      deleteDrugOption(id: $id)
    }
  `,
  {
    name: 'deleteDrugOption',
    options: () => ({ refetchQueries: () => [DRUG_OPTIONS_QUERY_NAME] }),
  }
);
