import gql from 'graphql-tag';

export default gql`
  fragment accountInfo on Account {
    id
    phone
    extension
    fax
    firstName
    lastName
    isSamaUser
    isAdmin
    email
    isReadOnly
    isDeactivated
    defaultToPatientEnrollment
    hidePortalTutorial

    institution {
      name
      id
      featureFlags  {
        EMRPullEnabled
        PatientEnrollmentEnabled
        OptionalSignature
        UploadToOncoEMR
      }
      integratedPortals
      userFacingPortalCheckerKeys
      ReferralForms {
        id
        title
        pdfURL
        description
      }
      practicingSpecialty
    }
  }
`;
