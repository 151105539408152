import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { WITH_FORMS_QUERY } from './AuthorizationForm';
import { POSSIBLE_NUMBERS_QUERY } from './FormNumber';

export const withSetFavorite = graphql(
  gql`
    mutation setFavorite($authorizationFormId: Int, $formNumberId: Int) {
      setFavorite(authorizationFormId: $authorizationFormId, formNumberId: $formNumberId)
    }
  `,
  {
    name: 'setFavorite',
    options: () => ({ refetchQueries: () => [WITH_FORMS_QUERY, POSSIBLE_NUMBERS_QUERY] }),
  }
);

export const withDeleteFavorite = graphql(
  gql`
    mutation deleteFavorite($authorizationFormId: Int, $formNumberId: Int) {
      deleteFavorite(authorizationFormId: $authorizationFormId, formNumberId: $formNumberId)
    }
  `,
  {
    name: 'deleteFavorite',
    options: () => ({ refetchQueries: () => [WITH_FORMS_QUERY, POSSIBLE_NUMBERS_QUERY] }),
  }
);
