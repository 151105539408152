import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';
import Creatable from 'react-select/lib/Creatable';

import { withUpsertInsuranceCompany, withInsuranceCompanies } from '../../../../graphql/InsuranceCompany';
import BaseButton from '../../../../components/BaseButton';
import Modal from '../../../../components/Modal';
import { ModalBodyLarge } from '../../../../components/ModalStyledComponents';
import CustomCheckbox from '../../../../components/CustomCheckbox';
import filterInsuranceCompanyOptions from '../../../../util/filterInsuranceCompanyOptions';

const Container = styled.div`
  padding: 20px 0;
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const FormSubmitButton = styled(BaseButton)`
  margin-left: 10px;
`;

class SelectPhoneList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      name: props.name || '',
      isPlan: false,
      parentCompany: {},
    };
  }

  create = () => {
    const { upsertInsuranceCompany, onClose, alert } = this.props;
    const { name, parentCompany } = this.state;

    if (!name) {
      alert.error('Missing required field');
      return;
    }
    upsertInsuranceCompany({
      variables: { name, searchTags: [], ...(parentCompany.id ? { parentId: parentCompany.id } : { parentCompanyName: parentCompany.name }) },
    })
      .then(() => { alert.success('Created, please associate the newly created company to this form / fax'); onClose(); })
      .catch((e) => { alert.error(`Error: ${e.message}`); });
  }

  render() {
    const { onClose, insuranceCompanies } = this.props;
    const { name, isPlan, parentCompany } = this.state;

    return (
      <Modal header="Add Insurance Company" onClick={onClose} open>
        <ModalBodyLarge>
          <Container>
            <SelectorTitle>Payer / Plan Name</SelectorTitle>
            <BaseText onChange={(e) => { this.setState({ name: e.target.value }); }} value={name} />
            <div style={{ display: 'flex', margin: '8px', alignItems: 'center' }}>
              <CustomCheckbox checked={isPlan} onChange={() => { this.setState({ isPlan: !isPlan }); }} />
              <div style={{ margin: 'flex', marginLeft: '10px' }}>
                Check here if the above is an insurance plan that needs to be associated with a parent company
              </div>
            </div>
            { isPlan && (
              <>
                <SelectorTitle>Parent Company</SelectorTitle>
                <Creatable
                  labelKey="name"
                  onChange={(company) => { this.setState({ parentCompany: company }); }}
                  options={_.sortBy(insuranceCompanies, 'name')}
                  sorted
                  placeholder="Choose or create an insurance company"
                  filterOption={filterInsuranceCompanyOptions}
                  value={parentCompany}
                />
              </>
            ) }
          </Container>
          <ButtonContainer>
            <FormSubmitButton onClick={this.create}>Create</FormSubmitButton>
          </ButtonContainer>
        </ModalBodyLarge>
      </Modal>
    );
  }
}

export default compose(withUpsertInsuranceCompany, withInsuranceCompanies)(withAlert(SelectPhoneList));
