import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import colors from 'Resources/colors';
import ErrorIcon from 'react-icons/lib/md/error-outline';

import { BaseCleave } from './StyledComponents';

const DateInput = styled(BaseCleave)`
  min-width: 130px;
  width: 130px;
`;

const DatePastWarning = styled.span`
  margin-left: 10px;
  color: ${props => props.theme.red};
`;

export class SegmentDate extends PureComponent {
  state = { showError: false, dateCleave: null }

  componentDidMount() {
    const { result, setToToday } = this.props;
    if (setToToday && !result) {
      this.setDate(moment().format(CONFIG.CONSTANTS.DATE_FORMAT));
    }
  }

  setDate = (date) => {
    const { handleChange, item } = this.props;

    handleChange({ [item.key]: date });
  };

  isValidTwoDigitYear = () => {
    const { result } = this.props;
    return moment(result, 'MM/DD/YY', true).isValid();
  }

  isValid = (dateOverride) => {
    const { result, item, alert } = this.props;
    const OLDEST_HUMAN_LIFESPAN = 117;

    const isValidDate = moment((dateOverride || result), CONFIG.CONSTANTS.DATE_FORMAT, true).isValid()
      && moment((dateOverride || result), CONFIG.CONSTANTS.DATE_FORMAT).isAfter(moment().subtract(OLDEST_HUMAN_LIFESPAN, 'years'))
      && moment((dateOverride || result)).isBefore(moment().add(3, 'years'));

    if (item.futureOnly && isValidDate) {
      const isFutureDate = moment(dateOverride || result, CONFIG.CONSTANTS.DATE_FORMAT).startOf('day').isSameOrAfter(
        moment().add(1, 'day').startOf('day')
      );

      if (isFutureDate || !result) {
        return true;
      }

      alert.error('Selected date must be in the future');
      return false;
    }
    // Don't show error tag if user clicks in and clicks out OR if is valid date
    return isValidDate || !result;
  };

  onDateInit = (cleave) => {
    this.setState({ dateCleave: cleave });
  };

  onBlur = () => {
    const { item, result } = this.props;
    const { dateCleave } = this.state;

    let convertedDate = result;
    if (this.isValidTwoDigitYear()) {
      convertedDate = moment(result, 'MM/DD/YY', true).format(CONFIG.CONSTANTS.DATE_FORMAT);
    }

    if (this.isValid(convertedDate)) {
      this.setDate(convertedDate);
      _.get(item, 'onBlur', () => {})();
    } else {
      this.setDate('');
      dateCleave.setRawValue('');
      this.setState({ showError: true });
    }
  };

  handlePaste = (e) => {
    e.preventDefault();
    // parse date from multiple formats and set
    this.setDate(moment(e.clipboardData.getData('text')).format(CONFIG.CONSTANTS.DATE_FORMAT));
  };

  showDatePastWarning = () => {
    const { item, result } = this.props;

    return item.warnIfPast && moment(result, CONFIG.CONSTANTS.DATE_FORMAT).isBefore(moment().startOf('day'));
  }

  render() {
    const { result, disabled, highlightIfMissing } = this.props;
    const { showError } = this.state;

    const errorStyle = {};
    if (showError) {
      errorStyle.borderColor = colors.red;
    }

    return (
      <div>
        <DateInput
          disabled={disabled}
          style={errorStyle}
          onChange={(e) => { this.setDate(e.target.value); }}
          onInit={this.onDateInit}
          onFocus={() => { this.setState({ showError: false }); }}
          onBlur={this.onBlur}
          onPaste={this.handlePaste}
          options={{ date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' }}
          placeholder={CONFIG.CONSTANTS.DATE_FORMAT}
          value={result || ''}
          highlight={highlightIfMissing && !result}
        />
        { showError && (
          <span
            data-for="dateValidationError"
            data-tip=""
          >
            <ErrorIcon style={{ height: '100%', width: 25, marginLeft: 2, color: colors.red }} />
            <ReactTooltip id="dateValidationError">Invalid Date</ReactTooltip>
          </span>
        ) }
        { this.showDatePastWarning() && (
          <DatePastWarning>
            Warning - date is in the past
          </DatePastWarning>
        ) }
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  return { result: state.form.results[props.item.key] };
}

export default connect(mapStateToProps)(withAlert(SegmentDate));
