import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';

import RequiredTag from '../../../components/RequiredTag';
import { withReportMissingInformation } from '../../../graphql/AuthorizationForm';
import BaseButton from '../../../components/BaseButton';
import { ModalBodyLarge } from '../../../components/ModalStyledComponents';

const Title = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const InfoText = styled.div`
  text-align: center;
  width: 100%;
  margin: 10px;
`;

const HelperText = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: ${props => props.theme.purple};
  margin-top: 8px;
`;

const FooterButton = styled(BaseButton)`
  margin-left: 10px;
`;

const WideButton = styled(BaseButton)`
  width: 100%;
`;

class MissingInformationModalContent extends PureComponent {
  state = { hcpcsCode: '', name: '', submitted: false };

  getError = () => {
    const { hcpcsCode, name } = this.state;

    if (!hcpcsCode || hcpcsCode.length !== 5) {
      return 'HCPCS Code must be exactly 5 characters in length';
    }

    if (!name) {
      return 'Drug name must be provided';
    }

    return null;
  }

  setAndClose = () => {
    const { alert, close, set } = this.props;
    const { hcpcsCode, name } = this.state;

    const error = this.getError();
    if (error) {
      alert.error(error);
      return;
    }

    set(name, hcpcsCode);
    close();
  }

  setAndSubmit = async () => {
    const { reportMissingInformation, set, alert, authorizationId } = this.props;
    const { hcpcsCode, name } = this.state;

    const error = this.getError();
    if (error) {
      alert.error(error);
      return;
    }

    try {
      await reportMissingInformation({
        variables: {
          type: 'Drug Option',
          details: `Please add drug option with HCPCSCode[${hcpcsCode}] and name[${name}]`,
          authorizationId,
        },
      });
    } catch (e) {
      // silence any errors
    } finally {
      set(name, hcpcsCode);
      this.setState({ submitted: true });
    }
  }

  render() {
    const { close } = this.props;
    const { hcpcsCode, name, submitted } = this.state;

    if (submitted) {
      return (
        <div>
          <ModalBodyLarge>
            <InfoText>
              Your drug has been set to this authorization and a request has been sent to SamaCare.
            </InfoText>
            <InfoText>
              SamaCare will verify your drug name and details and perminantly add it to the system within 72 business hours.
            </InfoText>
          </ModalBodyLarge>
          <ButtonContainer>
            <WideButton onClick={close}>Confirm</WideButton>
          </ButtonContainer>
        </div>
      );
    }

    return (
      <div>
        <ModalBodyLarge>
          <Title>
            HCPCS Code
            <RequiredTag />
          </Title>
          <BaseText value={hcpcsCode} onChange={(e) => { this.setState({ hcpcsCode: e.target.value }); }} />
          <Title>
            Drug Name
            <RequiredTag />
          </Title>
          <BaseText value={name} onChange={(e) => { this.setState({ name: e.target.value }); }} />
        </ModalBodyLarge>
        <HelperText>
          SamaCare is focused on Medical Benefit authorizations, some requests for procedural HCPCS codes may not be implemented.
        </HelperText>
        <ButtonContainer>
          <FooterButton onClick={this.setAndSubmit}>Use this drug and add to SamaCare</FooterButton>
          <FooterButton onClick={this.setAndClose}>Use this drug</FooterButton>
        </ButtonContainer>
      </div>
    );
  }
}

export default compose(withReportMissingInformation)(withAlert(MissingInformationModalContent));
