import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const withRunStatusChecker = graphql(
  gql`
    mutation runStatusChecker($accountId: Int!, $authId: Int $key: String!, $responses: JSON!, $isNewRunner: Boolean!) {
      runStatusChecker(key: $key, accountId: $accountId, authId: $authId, responses: $responses, isNewRunner: $isNewRunner) {
        status
        text
        userInputs {
          label
          type
          key
          options
          required
        }
      }
    }
  `,
  {
    name: 'runStatusChecker',
  }
);
