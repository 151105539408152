import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import { withResolveSupportedFollowUp } from '../../../graphql/SupportedAuthorizationFollowUp';
import BaseButton from '../../../components/BaseButton';
import Modal from '../../../components/Modal';
import { ModalFooter } from '../../../components/ModalStyledComponents';
import { BaseText } from '../../../components/Segment/StyledComponents';

const ModalBody = styled.div`
  width: 650px;
  height: 450px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    width: 100%;
    height: 40px;
    margin: 0 50px;
  }
`;

const DecisionButton = styled(BaseButton)`
  color: ${props => props.isSelected ? 'white' : props.theme.purple};
  background-color: ${props => props.isSelected ? props.theme.purple : 'white'};
  text-align: center;
  margin: 3px 0;
  flex: 1;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

class SupportedFollowUpsDashboard extends PureComponent {
  state = { resolvedStatus: '', resolveDetails: '' }

  resolve = async () => {
    const { resolveSupportedFollowUp, alert, followUpId, onClose } = this.props;
    const { resolvedStatus, resolveDetails } = this.state;

    if (!resolvedStatus) {
      alert.error('Expected a resolution status');
    }

    try {
      await resolveSupportedFollowUp({
        variables: { id: followUpId, status: resolvedStatus, details: resolveDetails },
      });
      this.setState({ resolvedStatus: '', resolveDetails: '' }, onClose);
      alert.info('Success');
    } catch {
      alert.error('There was an error');
    }
  }

  render() {
    const { onClose } = this.props;
    const { resolvedStatus, resolveDetails } = this.state;

    return (
      <Modal
        header="Select Resolution Status"
        onClick={() => { this.setState({ resolvedStatus: '' }, onClose); }}
        open
      >
        <ModalBody>
          <ModalButtonContainer>
            { _.map(
              _.without(
                _.values(CONFIG.CONSTANTS.SUPPORT_AUTHORIZATION_FOLLOWUP_STATUS),
                CONFIG.CONSTANTS.SUPPORT_AUTHORIZATION_FOLLOWUP_STATUS.UNRESOLVED
              ),
              status => (
                <DecisionButton
                  onClick={() => { this.setState({ resolvedStatus: status }); }}
                  isSelected={status === resolvedStatus}
                >
                  { status }
                </DecisionButton>
              )
            ) }
          </ModalButtonContainer>
          <div style={{ margin: '10px 0', textAlign: 'center' }}>
            User facing details (leaving this blank is okay)
          </div>
          <BaseText
            value={resolveDetails || ''}
            onChange={(e) => { this.setState({ resolveDetails: e.target.value }); }}
            placeholder="User facing note"
            style={{ marginLeft: 0 }}
          />
        </ModalBody>
        <CustomModalFooter>
          <BaseButton disabled={!resolvedStatus} onClick={this.resolve}>Resolve</BaseButton>
        </CustomModalFooter>
      </Modal>
    );
  }
}

export default compose(withResolveSupportedFollowUp)(withAlert(SupportedFollowUpsDashboard));
