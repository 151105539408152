import { withAlert } from 'react-alert';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import FilterBox from './FilterBox';

const EmptyFilter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  width: 100%;
  margin: 0 8px 16px 0;
  height: 200px;
  border-radius: 3px;
  background-color: ${props => props.theme.lightPurple};
  text-align: center;
  &:hover {
    border: 1px solid ${props => props.theme.purple};
    cursor: pointer;
    background-color: white;
  }
`;

class FiltersContainer extends PureComponent {
  addFilter = async () => {
    const { upsertFunction, alert, associationKey, associationId } = this.props;
    try {
      await upsertFunction({ variables: { [associationKey]: associationId } });
      alert.info('Successfully created filter');
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  }

  render() {
    const { filter, upsertFunction, associationId, associationKey } = this.props;

    return (
      <div style={{ display: 'flex' }}>
        {filter ? (
          <FilterBox
            states={filter.states}
            jCodes={filter.jCodes}
            insuranceType={filter.insuranceType}
            type={filter.type}
            key={`filter-box-${filter.id}`}
            id={filter.id}
            upsertFunction={upsertFunction}
            associationKey={associationKey}
            associationId={associationId}
          />
        ) : <EmptyFilter onClick={() => { this.addFilter(); }}>Click to create a filter</EmptyFilter>}
      </div>
    );
  }
}

export default withAlert(FiltersContainer);
