import gql from 'graphql-tag';

import authorizationCorrespondenceInfo from './authorizationCorrespondenceInfo';
import noteInfo from './noteInfo';

export default gql`
  fragment authorizationInfo on Authorization {
    id
    config
    status
    formId
    formDetails
    updatedAt
    submittedAt
    createdById
    createdByUser
    checkRequested
    createdAt
    version
    InstitutionId
    InsuranceCompanyId
    institutionAuthorizationId
    isArchived
    lastCheckedAt
    displayReviewWarning
    hideFromExpiring
    portalKey
    ICDs
    HCPCSCodes
    isSupported
    isReferral
    isPatientEnrollment
    portalTitle
    portalAuthorizationId
    requiresAssociationReview
    emailReminderAt
    emailReminderDescription
    availablePortalHooks
    hasResumeBehavior
    integratedEMRDocumentsDownloaded

    customPortalSteps {
      key
      id
      display
      value
      isTerminal
      message
      type
      subType
      title
      requiresHardReset
      imageURL
      isDraft
      errorGroup
    }

    patient {
      id
      firstName
      lastName
      InsuranceCompanyId
      institutionPatientId
      dob
    }

    insuranceCompany {
      id
      name
      responseRangeMax
      responseRangeMin
      notReceivedNonApprovals
    }

    latestCorrespondence {
      ...authorizationCorrespondenceInfo
    }

    correspondences {
      ...authorizationCorrespondenceInfo
    }

    latestNote {
      ...noteInfo
    }

    formFieldConfig {
      requiredFields
      hiddenFields
    }

    followUp {
      id
      createdAt
      isReviewed
      type
    }
  }

  ${authorizationCorrespondenceInfo}
  ${noteInfo}
`;
