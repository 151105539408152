import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import Select from 'react-select';

import colors from 'Resources/colors';
import { BaseText } from 'Segment/StyledComponents';
import BaseButton from '../../../components/BaseButton';

import { withUpsertDrugOption, withDeleteDrugOption } from '../../../graphql/DrugOptions';
import { Pill, CreateNew, SupportEditContainer } from '../SharedStyles';

const StyledInput = styled(BaseText)`
  width: 100%;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const FieldLabel = styled.div`
  width: 30%;
  font-weight: 500;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const { PRACTICING_SPECIALTY_TYPES } = CONFIG.CONSTANTS;
const baseState = {
  createNewDrug: false,
  updateCode: '',
  updateDrugName: '',
  updateDose: '',
  updateSpecialties: [],
};

export class DrugEdit extends PureComponent {
  state = baseState;

  componentDidMount() {
    const { selectedDrug } = this.props;
    this.updateSelectedDrug(selectedDrug);
  }

  componentDidUpdate(prevProps) {
    const { selectedDrug } = this.props;
    if (selectedDrug.id !== prevProps.selectedDrug.id) {
      this.updateSelectedDrug(selectedDrug);
    }
  }

  updateSelectedDrug = (selectedDrug) => {
    if (!_.isEmpty(selectedDrug)) {
      this.setState({
        createNewDrug: false,
        updateCode: selectedDrug.code,
        updateDrugName: selectedDrug.drugName,
        updateDose: selectedDrug.dose,
        updateSpecialties: [...selectedDrug.specialties],
      });
    }
  }

  checkForUnsavedChanges = () => {
    const { selectedDrug } = this.props;
    const { updateCode, updateDrugName, updateSpecialties, updateDose, createNewDrug } = this.state;
    if (createNewDrug) {
      return updateCode || updateDrugName || updateDose || updateSpecialties.length;
    }

    return (
      updateCode !== selectedDrug.code
      || updateDrugName !== selectedDrug.drugName
      || updateDose !== selectedDrug.dose
      || !_.isEqual(_.sortBy(updateSpecialties), _.sortBy(selectedDrug.specialties))
    );
  }

  deleteDrug = async () => {
    const { deleteDrugOption, selectedDrug, clearSelectedDrug } = this.props;
    try {
      await deleteDrugOption({ variables: { id: selectedDrug.id } });
      clearSelectedDrug();
      alert.info('Successfully deleted drug option');
    } catch (e) {
      alert.error(`Failed to delete ${e}`);
    }
  }

  saveChanges = async () => {
    const { upsertDrugOption, selectedDrug, resetSelectedDrug, alert } = this.props;
    const { updateCode, updateDrugName, updateDose, updateSpecialties, createNewDrug } = this.state;
    if (!updateCode || !updateDrugName || !updateSpecialties.length) {
      alert.error('Please enter a correct JCode, Name and at least one specialty');
    } else {
      try {
        const args = {
          ...(createNewDrug ? {} : { id: selectedDrug.id }),
          code: updateCode,
          drugName: updateDrugName,
          specialties: updateSpecialties,
          dose: updateDose,
        };
        await upsertDrugOption({ variables: args });
        if (!createNewDrug) {
          resetSelectedDrug();
          alert.info('Successfully updated drug option');
        } else {
          this.setState(baseState);
          alert.info('Successfully created drug option');
        }
      } catch (e) {
        alert.error(`Failed to upsert ${e}`);
      }
    }
  }

  render() {
    const { updateCode, updateDrugName, updateDose, updateSpecialties, createNewDrug } = this.state;
    const { selectedDrug, clearSelectedDrug } = this.props;

    const hasDrug = !_.isEmpty(selectedDrug) || createNewDrug;

    return !hasDrug ? (
      <CreateNew onClick={() => { this.setState({ ...baseState, createNewDrug: true }); }}>
        Click to Create a New Drug
      </CreateNew>
    ) : (
      <SupportEditContainer>
        {createNewDrug && (
          <RowWrapper>
            <div style={{ marginLeft: '10px', fontStyle: 'italic' }}>Edit New Drug Details and Click Save To Create New Record</div>
          </RowWrapper>
        )}
        <RowWrapper>
          <FieldLabel>Code</FieldLabel>
          <StyledInput
            onChange={(e) => { this.setState({ updateCode: e.target.value }); }}
            value={updateCode}
            placeholder="Update JCode"
          />
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>Name</FieldLabel>
          <StyledInput
            onChange={(e) => { this.setState({ updateDrugName: e.target.value }); }}
            value={updateDrugName}
            placeholder="Update Drug Name"
          />
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>Dose</FieldLabel>
          <StyledInput
            onChange={(e) => { this.setState({ updateDose: e.target.value }); }}
            value={updateDose}
            placeholder="Update Dosage"
          />
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>Specialities</FieldLabel>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <StyledSelect
              onChange={({ value }) => { this.setState(prevState => ({ updateSpecialties: _.uniq([...prevState.updateSpecialties, value]) })); }}
              options={_.map(_.values(PRACTICING_SPECIALTY_TYPES), specialty => ({ value: specialty, label: specialty }))}
              clearable={false}
              searchable={false}
            />
            <div style={{ display: 'flex', marginTop: '10px' }}>
              {_.map(updateSpecialties, specialty => (
                <Pill
                  onClick={() => { this.setState(prevState => ({ updateSpecialties: _.without(prevState.updateSpecialties, specialty) })); }}
                  key={`pill-${specialty}`}
                >
                  {`${specialty} X`}
                </Pill>
              ))}
            </div>
          </div>
        </RowWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BaseButton
            color={colors.red}
            style={{ width: '25%', marginLeft: '10px' }}
            onClick={this.deleteDrug}
            disabled={createNewDrug}
          >
            Delete Drug
          </BaseButton>
          <div style={{ width: '35%', marginLeft: '10px', color: colors.red, textAlign: 'center' }}>
            {this.checkForUnsavedChanges() ? 'Unsaved Changes' : ''}
          </div>
          <BaseButton
            style={{ width: '20%', marginLeft: '10px' }}
            onClick={() => { this.setState({ createNewDrug: false }, clearSelectedDrug); }}
          >
            Cancel
          </BaseButton>
          <BaseButton
            style={{ width: '20%', marginLeft: '10px' }}
            onClick={this.saveChanges}
          >
            Save
          </BaseButton>
        </div>
      </SupportEditContainer>
    );
  }
}

export default compose(withUpsertDrugOption, withDeleteDrugOption)(withAlert(DrugEdit));
