import _ from 'lodash';
import React, { PureComponent } from 'react';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import styled from 'styled-components';

import PDFViewer from '../../../components/PDFViewer';

const withFormById = graphql(
  gql`
    query formById($id: Int!) {
      formById(id: $id) {
        pdfURL
      }
    }
  `,
  {
    options: props => ({ variables: { id: props.formId } }),
    props: ({ data }) => ({ form: _.get(data, 'formById') }),
  }
);

const PDFContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px;
  background-color: gray;
  margin: 10px 0;
`;

export class FormConfirmation extends PureComponent {
  render() {
    const { form } = this.props;

    if (form) {
      return (
        <div>
          <PDFContainer>
            <PDFViewer
              fileURL={form.pdfURL}
              height="400px"
              width="650px"
            />
          </PDFContainer>
        </div>
      );
    }

    return (<div />);
  }
}

export default compose(withFormById)(FormConfirmation);
