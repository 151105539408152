import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { graphql, compose, Query } from 'react-apollo';
import styled from 'styled-components';
import Select from 'react-select';
import gql from 'graphql-tag';
import { BaseText } from 'Segment/StyledComponents';

import { withAuthorizationQuery, withUpdateAuthorizationStatus } from '../../../graphql/Authorization';
import { withUpdateAuthorizationCorrespondence } from '../../../graphql/AuthorizationCorrespondence';
import Modal from '../../../components/Modal';
import CorrespondenceViewer from '../../../components/CorrespondenceViewer';
import BaseButton from '../../../components/BaseButton';
import StatusRecommendation from './StatusRecommendation';
import DetailsSection from './DetailsSection';
import SetAutomatedCorrespondence from './SetAutomatedCorrespondence';
import DetailsContainer from '../../../components/DetailsContainer';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 0 7px 0;
`;

const TwoPaneContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const AuthorizationDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;

const withCorrespondenceFullText = gql`
  query withCorrespondenceFullText($id: Int!) {
    correspondenceById(id: $id) {
      fullText {
        text
      }
    }
  }
`;

const DetailTitle = styled.div`
  font-weight: bold;
  color: ${props => props.theme.purple};
`;

const WarningText = styled.div`
  margin: 10px 0;
  color: ${props => props.theme.red};
  font-weight: 500;
`;


class UpdateAuthorizationDetailsButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      suggestedStatus: props.correspondence.suggestedStatus,
      hideShowIsAutomatedCorrespondence: false,
      nonApprovalInfo: '',
      nonApprovalType: '',
    };
  }

  submit = async () => {
    const { setCorrespondenceOnAuthorization } = this.props;

    await setCorrespondenceOnAuthorization();
  }

  acceptSuggestedStatus = async () => {
    const { setCorrespondenceOnAuthorization, updateAuthorizationStatus, authorizationId, alert } = this.props;
    const { suggestedStatus, nonApprovalInfo, nonApprovalType } = this.state;

    try {
      await updateAuthorizationStatus({
        variables: {
          id: authorizationId,
          status: suggestedStatus,
          nonApprovalInfo,
          nonApprovalType,
        },
      });

      await setCorrespondenceOnAuthorization();
    } catch (err) {
      alert.error(`There was an error updating the Authorization ${err.message}`);
    }
  }

  rejectSuggestion = async () => {
    const { correspondence, updateAuthorizationCorrespondence } = this.props;
    await updateAuthorizationCorrespondence({
      variables: { id: correspondence.id, patch: { code: null, startDate: null, endDate: null, authorizedProcedures: [] } },
    });
    this.setState({ hideShowIsAutomatedCorrespondence: true });
  }


  getActionContent = (fullText) => {
    const { authorization, correspondence } = this.props;
    const { hideShowIsAutomatedCorrespondence, suggestedStatus, nonApprovalType } = this.state;
    const deniedSuggestion = suggestedStatus === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.DENIED;
    const pendingSuggestion = suggestedStatus === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING;

    if (deniedSuggestion) {
      return (
        <div>
          <DetailTitle>Automated Denial Detected</DetailTitle>
          <div style={{ margin: ' 16px 0' }}>
            Please add reason and details when setting the authorization to denied. Otherwise, mark this as an incorrect suggestion.
          </div>
          <SelectorTitle>Denial Reason</SelectorTitle>
          <StyledSelect
            value={{ value: nonApprovalType, label: nonApprovalType }}
            onChange={(selected) => { this.setState({ nonApprovalType: selected.value }); }}
            options={_.map(_.values(CONFIG.CONSTANTS.USER_NONAPPROVAL_TYPES), selection => ({ label: selection, value: selection }))}
          />
          <SelectorTitle>Additional Info</SelectorTitle>
          <BaseText onChange={(event) => { this.setState({ nonApprovalInfo: event.target.value }); }} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BaseButton
              style={{ width: '48%', marginTop: 'auto' }}
              onClick={this.acceptSuggestedStatus}
            >
              Associate and close
            </BaseButton>
            <BaseButton
              style={{ width: '48%', marginTop: 'auto' }}
              onClick={() => { this.setState({ suggestedStatus: null }); }}
            >
              Incorrect suggestion - Take me back
            </BaseButton>
          </div>
        </div>
      );
    }

    if (pendingSuggestion) {
      return (
        <div>
          <DetailTitle>Automated Authorization Status Detected</DetailTitle>
          <div style={{ margin: ' 16px 0' }}>
            Would you like to set this authorization to
            <strong>
              {` ${suggestedStatus}`}
            </strong>
            ?
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <BaseButton
              style={{ width: '48%', marginTop: 'auto' }}
              onClick={this.acceptSuggestedStatus}
            >
              Associate and close
            </BaseButton>
            <BaseButton
              style={{ width: '48%', marginTop: 'auto' }}
              onClick={() => { this.setState({ suggestedStatus: null }); }}
            >
              Incorrect
            </BaseButton>
          </div>
        </div>
      );
    }

    const showIsAutomatedCorrespondence = correspondence.code
      && correspondence.endDate
      && correspondence.startDate
      && !_.isEmpty(correspondence.authorizedProcedures)
      && !hideShowIsAutomatedCorrespondence;

    return (
      <div>
        <StatusRecommendation
          suggestedStatus={suggestedStatus}
          fullText={fullText}
          authorization={authorization}
        />
        {
          (showIsAutomatedCorrespondence || suggestedStatus) && (
            <SetAutomatedCorrespondence
              correspondence={correspondence}
              set={this.submit}
              rejectSuggestion={this.rejectSuggestion}
              disabled={!_.includes(CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ARCHIVABLE, authorization.status)}
            />
          )
        }
        {
          !showIsAutomatedCorrespondence && (
            <>
              <DetailsSection
                fullText={fullText}
                key={`AuthorizationDetailsFor_${authorization.id}`}
                authorization={authorization}
                correspondenceId={correspondence.id}
              />
              {authorization.followUp && moment().subtract(10, 'minutes').isAfter(moment(authorization.followUp.createdAt)) && (
                <WarningText>Setting a new correspondence will remove the existing Follow Up.</WarningText>
              )}
              <BaseButton
                disabled={!_.includes(CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ARCHIVABLE, authorization.status)}
                style={{ width: '100%', marginTop: 'auto' }}
                onClick={this.submit}
              >
                Associate and close
              </BaseButton>
            </>
          )
        }
      </div>
    );
  }

  render() {
    const { onClose, authorization, correspondence } = this.props;

    if (!authorization) { return (<div />); }

    return (
      <Modal onClick={onClose} open header="Associate Response - Don't Mess Up!">
        <Query
          query={withCorrespondenceFullText}
          variables={{ id: correspondence.id }}
        >
          {({ data, loading }) => {
            if (loading) return (<div />);
            const fullText = _.get(data.correspondenceById, 'fullText.text');

            return (
              <TwoPaneContainer>
                <CorrespondenceViewer correspondence={correspondence} width="700px" height="550px" />
                <AuthorizationDetailsContainer>
                  <DetailsContainer authorization={authorization} />
                  { this.getActionContent(fullText) }
                </AuthorizationDetailsContainer>
              </TwoPaneContainer>
            );
          }}
        </Query>
      </Modal>
    );
  }
}

const withAuthorization = graphql(withAuthorizationQuery, {
  options: props => ({ variables: { id: props.authorizationId } }),
  props({ data }) { return { authorization: data.authorizationById }; },
});

export default compose(withAuthorization, withUpdateAuthorizationCorrespondence, withUpdateAuthorizationStatus)(withAlert(UpdateAuthorizationDetailsButton));
