import styled from 'styled-components';

export const AuthorizationDetails = styled.div`
  background-color: white;
  padding: 7px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
`;

export const TitleContainer = styled.div`
  padding: 5px 0;
  font-size: 18px;
  color: ${({ theme }) => theme.purple};
  font-weight: 400;
  text-align: center;
`;

export const DescriptionContainer = styled(TitleContainer)`
  font-size: 14px;
  color: ${({ theme }) => theme.darkGray};
`;
