import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { BaseText } from 'Segment/StyledComponents';

import FaxEditContainer from '../components/FaxEditContainer';
import { withDetailedForms } from '../../../../graphql/AuthorizationForm';
import { withFormNumbers } from '../../../../graphql/FormNumber';
import { Pill } from '../../SharedStyles';


const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: ${props => props.selected && props.theme.lightPurple};

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.lightPurple};
  }
`;

const DarkGray = styled.div`
  color: ${props => props.theme.darkGray};
`;

const InputLabel = styled.div`
  width: 100%;
  font-weight: 500;
  margin: 10px 0;
`;

const Scrollable = styled.div`
  margin: 8px 0;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const MainContainer = styled.div`
  display: flex;
  font-weight: 300;
  margin: 10px 0px;
`;

export class FaxEdit extends PureComponent {
  state = {
    searchNumber: '',
    searchDescription: '',
    selectedNumberId: null,
  }

  getFilteredNumbers = () => {
    const { formNumbers } = this.props;
    const { searchNumber, searchDescription } = this.state;
    return _.filter(formNumbers, ({ description, number }) => (
      number.replace(/-/g, '').includes(searchNumber.replace(/-/g, ''))
      && description.toUpperCase().includes(searchDescription.toUpperCase())
    ));
  }

  render() {
    const { formNumbers, forms } = this.props;
    const { searchNumber, searchDescription, selectedNumberId } = this.state;

    return (
      <MainContainer>
        <div style={{ width: '35%', marginRight: '16px' }}>
          <InputLabel>Type to Search Numbers - Click on a number to edit</InputLabel>
          <BaseText
            style={{ width: '250px' }}
            onChange={(e) => { this.setState({ searchDescription: e.target.value }); }}
            value={searchDescription}
            placeholder="Search Description"
          />
          <BaseText
            style={{ width: '200px', margin: '0 8px' }}
            onChange={(e) => { this.setState({ searchNumber: e.target.value }); }}
            value={searchNumber}
            placeholder="Search Number"
          />
          <Scrollable style={{ height: '450px' }}>
            {_.map(this.getFilteredNumbers(), ({ id, description, number, forbiddenReason, associatedForms, associatedCompanies, InstitutionId }) => (
              <ItemContainer selected={selectedNumberId === id} onClick={() => { this.setState({ selectedNumberId: id }); }} key={`fax-${id}`}>
                <div style={{ maxWidth: '80%', display: 'flex', flexDirection: 'column' }}>
                  <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{description}</div>
                  <DarkGray>{number}</DarkGray>
                </div>
                <div style={{ maxWidth: '30%', display: 'flex', flexWrap: 'wrap', marginLeft: '10px' }}>
                  <Pill>{`${_.size(associatedForms)} Form${_.size(associatedForms) === 1 ? '' : 's'}`}</Pill>
                  <Pill>{`${_.size(associatedCompanies)} Payer${_.size(associatedCompanies) === 1 ? '' : 's'}`}</Pill>
                  {forbiddenReason && <Pill disabled>Blacklisted</Pill>}
                  {InstitutionId && <Pill>{`Institution ${InstitutionId}`}</Pill>}
                </div>
              </ItemContainer>
            ))}
          </Scrollable>
        </div>
        <div style={{ width: '65%' }}>
          {selectedNumberId && (
            <FaxEditContainer
              selectedNumber={_.find(formNumbers, { id: selectedNumberId })}
              clearSelectedNumber={() => { this.setState({ selectedNumberId: null }); }}
              forms={forms}
            />
          )}
        </div>
      </MainContainer>
    );
  }
}

export default compose(withFormNumbers, withDetailedForms)(FaxEdit);
