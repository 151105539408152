import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';

import Favorite from '../../../components/Favorite';
import BaseButton from '../../../components/BaseButton';

const themeColor = '#003d88';
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${({ margin }) => margin}px;
  border-left: 4px solid ${themeColor};
  padding-left: 10px;
`;

const FormDetails = styled.div`
  flex: 1;
`;

const FormDescription = styled.div`
  font-size: 16px;
  color: ${props => props.theme.gray};
`;

const FormTitle = styled.div`
  font-weight: 500;
`;

const FormPreview = styled.a`
  font-weight: 500;
  pointer: cursor;
`;

const SelectFormButton = styled(BaseButton)`
  padding: 10px;
  font-size: 14px;
  margin: 0 5px 0 20px;
`;

const FormContent = ({ index, form, onSelect, setFavorite, deleteFavorite, isFavorite }) => (
  <FormContainer margin={index === 0 ? '0' : '10'} key={`formSelectContainer-formId-${form.id}`}>
    <FormDetails>
      <FormTitle>
        <Favorite
          isFavorite={isFavorite}
          setFavorite={setFavorite}
          deleteFavorite={deleteFavorite}
          formId={_.parseInt(form.id)}
        />
        {form.title}
        {' '}
        <FormPreview href={form.pdfURL} target="_blank">Preview Form</FormPreview>
      </FormTitle>
      <FormDescription>{form.description}</FormDescription>
    </FormDetails>
    <SelectFormButton color={themeColor} onClick={() => { onSelect(form.id); }}>Select</SelectFormButton>
  </FormContainer>
);

export default FormContent;
