import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { withAlert } from 'react-alert';

import { PhoneCleave } from './BaseComponents';

export class SegmentPhone extends PureComponent {
  setNumber = (number) => {
    const { handleChange, item } = this.props;
    handleChange({ [item.key]: number });
  }

  onBlur = () => {
    const { result, alert } = this.props;
    const rawNumber = _.replace(result, /\D/g, '');

    if (rawNumber.length !== 10) {
      this.setNumber('');
      alert.error('Phone numbers must contain 10 characters');
    }
  }

  render() {
    const { result, disabled, highlightIfMissing } = this.props;

    return (
      <PhoneCleave
        highlight={highlightIfMissing && !result}
        disabled={disabled}
        onChange={(e) => { this.setNumber(e.target.rawValue || ''); }}
        number={result || ''}
        onBlur={this.onBlur}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return { result: props.override || state.form.results[props.item.key] };
}

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withAlert(SegmentPhone));
