import gql from 'graphql-tag';

export default gql`
  fragment formFilterInfo on FormFilter {
    id
    states
    jCodes
    insuranceType
  }
`;
