import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { graphql, compose } from 'react-apollo';
import colors from 'Resources/colors';
import { BaseText } from 'Segment/StyledComponents';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import BaseButton from '../../../../components/BaseButton';
import { withAuthorizationQuery } from '../../../../graphql/Authorization';

const ActionButton = styled(BaseButton)`
  text-align: center;
  margin: 5px;
`;

const FittedText = styled(BaseText)`
  width: calc(100% - 10px);
  margin: 5px;
`;

class VerifyPortalAuthorizationId extends PureComponent {
  state = { portalAuthorizationId: '' };

  componentDidUpdate(prevProps) {
    const { authorization } = this.props;

    if (!prevProps.authorization && authorization) {
      // eslint-disable-next-line
      this.setState({ portalAuthorizationId: authorization.portalAuthorizationId });
    }
  }

  render() {
    const { alert, onBack, onVerified, authorization } = this.props;
    const { portalAuthorizationId } = this.state;

    if (authorization) {
      return (
        <>
          <FittedText
            value={portalAuthorizationId || ''}
            onChange={(e) => { this.setState({ portalAuthorizationId: e.target.value }); }}
            placeholder="Portal Authorization ID"
          />
          <ActionButton
            onClick={() => {
              if (!portalAuthorizationId || portalAuthorizationId.length < 4) {
                alert.error('Please input a valid transaction ID');
              } else {
                onVerified(portalAuthorizationId);
              }
            }}
          >
            Verify
          </ActionButton>
          <ActionButton color={colors.gray} onClick={onBack}>Back</ActionButton>
        </>
      );
    }

    return (<LoadingSpinner />);
  }
}

export const withAuthorization = graphql(
  withAuthorizationQuery,
  {
    options: props => ({ variables: { id: props.authorizationId }, fetchPolicy: 'network-only' }),
    props: ({ data }) => ({
      authorization: data.authorizationById,
    }),
  }
);

export default compose(withAuthorization)(withAlert(VerifyPortalAuthorizationId));
