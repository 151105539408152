import _ from 'lodash';
import React, { PureComponent } from 'react';
import { Radio, RadioGroup } from 'react-radio-group';

class CustomRadio extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedValue: '' };
  }

  setRadio = (radioId) => {
    const { onChange } = this.props;

    this.setState({ selectedValue: radioId }, () => { onChange(radioId); });
  }

  render() {
    const { radioButtons } = this.props;
    const { selectedValue } = this.state;

    return (
      <RadioGroup
        onChange={this.setRadio}
        selectedValue={selectedValue}
      >
        {
          _.map(radioButtons, item => (
            <div
              style={{ marginBottom: '5px' }}
              onClick={({ target }) => {
                if (target.value === selectedValue) {
                  // deselect a radio if it is the currently
                  // selected value and is clicked again
                  this.setRadio(null);
                }
              }}
              value={item.value}
            >
              <Radio
                disabled={item.disabled}
                style={{ marginRight: '5px' }}
                id={item.value}
                value={item.value}
              />
              <label htmlFor={item.value} style={{ cursor: 'pointer' }}>{item.label}</label>
            </div>
          ))
        }
      </RadioGroup>
    );
  }
}

export default CustomRadio;
