import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import strings from 'Resources/strings';
import { BaseMultiline } from 'Segment/StyledComponents';
import colors from 'Resources/colors';

import { withReportMissingInformation } from '../../graphql/AuthorizationForm';
import LoadingButton from '../../components/LoadingButton';
import BaseButton from '../../components/BaseButton';
import Upload from '../../components/Upload';
import { ModalBodyLarge } from '../../components/ModalStyledComponents';

const MAX_FILE_SIZE_B = 10000000;

const Title = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const ResponseContainer = styled.div`
  text-align: center;
  margin: 20px 0;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const FileUploadedText = styled.div`
  text-align: center;
  padding: 10px;
`;

class MissingInformationModalContent extends PureComponent {
  state = { serverResponse: '', missingInformationDetails: '', uploadedFileKey: '' };

  reportMissingInformation = async () => {
    const { state, reportMissingInformation, authorizationId, insuranceCompanyId, alert, type } = this.props;
    const { missingInformationDetails, uploadedFileKey } = this.state;

    try {
      this.setState({ isLoading: true });
      const response = await reportMissingInformation({
        variables: {
          type: type || 'Form',
          authorizationId,
          insuranceCompanyId,
          details: missingInformationDetails,
          state,
          fileKey: uploadedFileKey,
        },
      });
      this.setState({ serverResponse: response.data.reportMissingInformation.message });
    } catch (e) {
      alert.error(strings.MISSING_INFORMATION.ERROR);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  onDropError = () => {
    const { alert } = this.props;
    alert.error('There was an error uploading your file');
  }

  onDropSuccess = (file, res) => {
    const { data } = res;
    this.setState({ uploadedFileKey: data.fields.key });
  }

  render() {
    const { onCancel } = this.props;
    const { serverResponse, uploadedFileKey, isLoading } = this.state;

    let modalBody = (
      <div>
        <Title>Please provide relevant details</Title>
        <BaseMultiline onChange={(e) => { this.setState({ missingInformationDetails: e.target.value }); }} />
        {
          !uploadedFileKey && (
            <div>
              <Title>Please upload a copy of the requested form</Title>
              <Upload
                dropzoneStyle={{ width: '100%' }}
                onDropSuccess={this.onDropSuccess}
                onDropError={this.onDropError}
                customMaxSize={MAX_FILE_SIZE_B}
              />
            </div>
          )
        }
        {
          uploadedFileKey && (<FileUploadedText>File Uploaded!</FileUploadedText>)
        }
      </div>
    );
    if (serverResponse) {
      modalBody = (<ResponseContainer>{ serverResponse }</ResponseContainer>);
    }

    return (
      <div>
        <ModalBodyLarge>
          { modalBody }
        </ModalBodyLarge>
        <ButtonContainer>
          <BaseButton
            style={{ marginRight: 'auto' }}
            onClick={onCancel}
            color={colors.gray}
          >
            Back
          </BaseButton>
          { !serverResponse && (
            <LoadingButton loading={isLoading} onClick={this.reportMissingInformation}>Submit Request</LoadingButton>
          ) }
        </ButtonContainer>
      </div>
    );
  }
}

export default compose(withReportMissingInformation)(withAlert(MissingInformationModalContent));
