import React, { PureComponent } from 'react';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import SegmentSelector from 'Segment/SegmentSelector';

import { withUpdateFormDetails, withSetPrescriberOnAuth } from '../../../../graphql/Authorization';
import { withPrescribers } from '../../../../graphql/Account';

function formatPrescriber(p) {
  const defaultValue = `${_.get(p, 'firstName')} ${_.get(p, 'lastName')}`;
  return _.get(p, 'label') || defaultValue;
}

class PrescriberSelector extends PureComponent {
  async submit(prescriberId) {
    const {
      setPrescriberOnAuth, updateFormDetails, authorizationId, alert, afterSelection,
    } = this.props;

    try {
      await setPrescriberOnAuth({ variables: { authorizationId, prescriberId } });
      await updateFormDetails({ variables: { id: authorizationId, details: { prescriberId } } });
      afterSelection();
    } catch (e) {
      alert.error('There was an error setting prescriber on this authorization');
    }
  }

  render() {
    const { prescribers, prescriberId, disabled } = this.props;
    const selected = _.find(prescribers, { id: prescriberId });

    return (
      <div>
        <SegmentSelector
          disabled={disabled}
          onChange={(e) => {
            this.submit(e ? e.value : null);
          }}
          options={_.map(prescribers, prescriber => ({ value: prescriber.id, label: formatPrescriber(prescriber) }))}
          title="Saved Prescribers"
          value={selected ? { value: selected.id, label: formatPrescriber(selected) } : ''}
          sorted
        />
      </div>
    );
  }
}

export default connect((state, ownProps) => ({ disabled: state.form.disabled || ownProps.disabled }))(
  compose(withUpdateFormDetails, withPrescribers, withSetPrescriberOnAuth)(withAlert(PrescriberSelector))
);
