import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import _ from 'lodash';
import Patient from 'AuthorizationSharedComponents/Patient';
import Section from 'AuthorizationSharedComponents/Section';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';
import PrimaryDrug from 'AuthorizationSharedComponents/PrimaryDrug';

import { withCurrentAccount } from '../../graphql/Account';
import { withCreatePatientOnAuthorization, withUpdatePatient } from '../../graphql/Patient';
import { setFormFields } from '../../reducers/formReducer';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const defaultFields = CONFIG.DEFAULT_FIELDS;
export class InitializeSection extends PureComponent {
  submit = () => {
    const { authorization, isInvalid, results, nextStep, firstName, lastName, dob, createPatientOnAuthorization, set } = this.props;
    const requiredFieldKeys = [defaultFields.INSURANCE_STATE.key, defaultFields.PRIMARY_DRUG_NAME.key];

    set({ [defaultFields.ALL_HCPCS.key]: results[defaultFields.HCPCS_0.key] });
    if (isInvalid(_.some(requiredFieldKeys, key => (!results[key]))) || !results[defaultFields.PRIMARY_DRUG_NAME.key]) { return; }

    if (!results[defaultFields.REQUEST_DESCRIPTION.key]) {
      set({ [defaultFields.REQUEST_DESCRIPTION.key]: results[defaultFields.PRIMARY_DRUG_NAME.key].toUpperCase() });
    }

    if (!authorization.patient) {
      createPatientOnAuthorization({
        variables: {
          authorizationId: authorization.id,
          patientProps: {
            firstName: firstName.trim(), lastName: lastName.trim(), dob,
          },
        },
      }).then(nextStep);
    } else {
      nextStep();
    }
  }

  render() {
    const { authorization, lastName, firstName, disabled, account } = this.props;

    if (!account) { return (<div />); }

    let insuranceSection = [defaultFields.INSURANCE_STATE];
    if (account.institution.practicingSpecialty === CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES.RETINA) {
      insuranceSection = [defaultFields.INSURANCE_TYPE, ...insuranceSection];
    }

    return (
      <FormContainer>
        <Patient
          authorizationId={authorization.id}
          firstName={firstName}
          lastName={lastName}
          patientId={_.get(authorization, 'patient.id')}
          currentStep={authorization.formDetails.currentStep}
        />
        <PrimaryDrug authorization={authorization} />
        <Section
          section={{
            items: [defaultFields.EMAIL_NOTIFICATION_TYPE],
            title: 'Send notification for major status updates?',
          }}
        />
        <Section
          childrenAfterSection
          section={{
            items: insuranceSection,
            title: 'Patient Insurance Information',
          }}
          disabled={disabled}
        />
        <FormSubmitButtons submit={this.submit} />
      </FormContainer>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    firstName: state.form.results[defaultFields.PATIENT_FIRST_NAME.key],
    lastName: state.form.results[defaultFields.PATIENT_LAST_NAME.key],
    dob: state.form.results[defaultFields.PATIENT_DOB.key],
    state: state.form.results[defaultFields.INSURANCE_STATE.key],
    disabled: state.form.disabled || ownProps.disabled,
    results: state.form.results,
  };
}

const mapDispatchToProps = dispatch => ({
  set(fields) { dispatch(setFormFields(fields)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(withCreatePatientOnAuthorization, withUpdatePatient, withCurrentAccount)(InitializeSection)
);
