import React, { PureComponent } from 'react';
import _ from 'lodash';

import { getInputValue } from '../util/inputConfigurationManager';
import ROUTE_PATHS from '../routes/ROUTE_PATHS';
import trimAuthorizationConfiguration from '../util/trimAuthorizationConfiguration';

export const NewAuthorizationHOC = WrappedComponent => (
  class IntegrationWebSocketProvider extends PureComponent {
    constructor(props) {
      _.each([
        'location', 'setStep', 'step', 'goToLocation', 'reset', 'updateAuthorizationProgress', 'alert', 'updateFormDetails', 'toggleHighlightRequiredFields',
      ], (requiredProp) => {
        if (_.isNil(props[requiredProp])) throw new Error(`Missing required prop ${requiredProp} in new authorization component`);
      });

      super(props);
    }

    async componentWillUnmount() {
      const { reset } = this.props;
      reset();
    }

    saveAuthorizationChanges = async (overrides = {}) => {
      const { updateAuthorizationProgress, authorization, results, attachments, account, alert } = this.props;
      if (!account.isReadOnly) {
        try {
          await updateAuthorizationProgress({
            variables: {
              id: authorization.id,
              config: trimAuthorizationConfiguration(results),
              patientId: authorization.patient.id,
              attachmentIds: _.map((attachments || []), 'id'),
              // note: if updating details in this method, you will run into bugs being overridden
              ...overrides,
            },
          });
          alert.info('Successfully saved changes');
        } catch (e) {
          alert.error('Failed to save authorization changes, if this persists, please contact SamaCare');
          throw e;
        }
      }
    }

    setStepAndUpdateURL = (nextStep, pathnameOverride) => {
      const { location, setStep, goToLocation } = this.props;
      const params = new URLSearchParams(location.search);
      params.set('step', nextStep);

      goToLocation(`${pathnameOverride || location.pathname}?${params.toString()}`);
      setStep(nextStep);
      window.scrollTo(0, 0);
    };

    getStepFromURL = () => {
      const { authorization, location } = this.props;

      let lastPossibleStep = _.last(CONFIG.CONSTANTS.AUTHORIZATION_FORM_STEPS.POST_SUBMIT).step;
      if (_.get(authorization, 'portalKey')) {
        lastPossibleStep = _.last(CONFIG.CONSTANTS.PORTAL_AUTHORIZATION_STEPS.POST_SUBMIT).step;
      }

      const params = new URLSearchParams(location.search);
      const urlStep = _.toNumber(params.get('step'));

      return _.min([lastPossibleStep, urlStep]) || 1;
    }

    syncStepToUrl = () => {
      const { step, setStep } = this.props;
      const urlStep = this.getStepFromURL();

      if (step !== urlStep) {
        setStep(urlStep);
      }
    }

    resultsAreValid = (forceResultsInvalid) => {
      const { results, requiredFieldKeys, authorization } = this.props;

      if (authorization.submittedAt) return true;
      if (forceResultsInvalid) return false;

      return _.every(requiredFieldKeys, (requiredField) => {
        if (_.isString(requiredField)) {
          if (_.isString(results[requiredField])) return !!results[requiredField].trim();
          return !!results[requiredField];
        }
        if (_.isArray(requiredField)) {
          return _.some(requiredField, (key) => {
            if (_.isString(results[key])) return !!results[key].trim();
            return !!results[key];
          });
        }
        if (_.isObject(requiredField)) {
          const val = getInputValue(requiredField, results);
          if (_.isString(val)) return !!val.trim();
          return !!val;
        }

        console.error('Expected array or string from required field keys');
        return false;
      });
    }

    takeStep = _.debounce(async (isForward) => {
      const { step, updateFormDetails, authorization, account } = this.props;

      const stepIncrement = isForward ? 1 : -1;
      const nextStep = step + stepIncrement;

      if ((!authorization.formDetails.currentStep || authorization.formDetails.currentStep < nextStep) && !account.isReadOnly) {
        await updateFormDetails({ variables: { details: { currentStep: nextStep }, id: authorization.id } });
      }

      this.setStepAndUpdateURL(nextStep);
    }, 1500, { leading: true, trailing: false });

    hasInvalidResults = (forceResultsInvalid) => {
      const { toggleHighlightRequiredFields } = this.props;

      if (!this.resultsAreValid(forceResultsInvalid)) {
        toggleHighlightRequiredFields(true);
        return true;
      }

      toggleHighlightRequiredFields(false);
      return false;
    }

    onComponentUpdate = (prevProps, authorization, results, syncLocalStateMethod) => {
      const { location } = this.props;

      this.syncStepToUrl();

      if (!authorization) { return; }

      if (!prevProps.authorization && authorization) {
        const stepToSet = this.getStepFromURL();

        let properPath;
        if (authorization.portalKey) {
          properPath = ROUTE_PATHS.PORTAL_AUTHORIZATION;
        } else if (authorization.isReferral) {
          properPath = ROUTE_PATHS.REFERRAL;
        } else if (authorization.isPatientEnrollment) {
          properPath = ROUTE_PATHS.PATIENT_ENROLLMENT;
        } else {
          properPath = ROUTE_PATHS.FORM_AUTHORIZATION;
        }

        if (
          (authorization.portalKey && !_.includes(location.pathname, ROUTE_PATHS.PORTAL_AUTHORIZATION))
          || (authorization.isReferral && !_.includes(location.pathname, ROUTE_PATHS.REFERRAL))
          || (authorization.isPatientEnrollment && !_.includes(location.pathname, ROUTE_PATHS.PATIENT_ENROLLMENT))
          || (!authorization.portalKey && !_.includes(location.pathname, ROUTE_PATHS.FORM_AUTHORIZATION))
        ) {
          this.setStepAndUpdateURL(stepToSet, properPath);
        }
      }

      if (
        authorization
        && authorization.config
        && !_.isEqual(authorization.config, _.get(prevProps, 'authorization.config'))
      ) {
        syncLocalStateMethod();
      }
    }

    render() {
      return (
        <WrappedComponent
          syncStepToUrl={this.syncStepToUrl}
          hasInvalidResults={this.hasInvalidResults}
          setStepAndUpdateURL={this.setStepAndUpdateURL}
          saveAuthorizationChanges={this.saveAuthorizationChanges}
          onComponentUpdate={this.onComponentUpdate}
          takeStep={this.takeStep}
          {...this.props}
        />
      );
    }
  }
);

export default NewAuthorizationHOC;
