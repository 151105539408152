import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import { withSupportedAuthorizationFollowUps, withResolveSupportedFollowUp } from '../../../graphql/SupportedAuthorizationFollowUp';
import AuthorizationListManager from '../../Authorizations/AuthorizationListManager';
import GenericResolveModal from './GenericResolveModal';
import CaremetxModal from './CaremetxModal';
import FailedCheckerModal from './FailedCheckerModal';

const Container = styled.div`
  width: 1500px;
  margin-left: 25px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
  font-weight: bold;
`;

const ScrollContainer = styled.div`
  position: relative;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
  max-height: ${props => props.height || '450px'};
  min-width: 100px;
  overflow: scroll;
`;

const getButtonText = (followUp) => {
  if (_.get(followUp, 'isFailedStatusCheck')) return 'Failed Check';
  if (_.get(followUp, 'authorization.status') === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.CAREMETX_FORMS_RESEARCH) return 'Find CareMetx Form';

  return null;
};

const baseState = { followUpToResolveId: null, isFormsResearch: null, isFailedStatusCheck: null, selectedAuthorization: {} };
class SupportedFollowUpsDashboard extends PureComponent {
  state = baseState;

  clickHandler = (authorization) => {
    const { supportedAuthorizationFollowUps } = this.props;
    const { id, isFailedStatusCheck } = _.find(supportedAuthorizationFollowUps, { AuthorizationId: authorization.id });
    this.setState({
      followUpToResolveId: id,
      isFormsResearch: authorization.status === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.CAREMETX_FORMS_RESEARCH,
      isFailedStatusCheck,
      selectedAuthorization: authorization,
    });
  }

  generateSupportedAuthList = (authorizations) => {
    const { insuranceCompanies, supportedAuthorizationFollowUps } = this.props;
    if (_.isEmpty(authorizations)) {
      return <div />;
    }

    return (
      <ScrollContainer height="500px">
        <AuthorizationListManager
          minWidth="600px"
          authorizations={authorizations}
          insuranceCompanies={insuranceCompanies}
          alwaysOpenInBlank
          emptyStateText="None Found"
          tableConfig={{
            headers: ['Patient', 'Status', 'Details'],
            columns: [
              'stackedPatient',
              'status',
              'authorizationDetails',
              {
                type: 'customButton',
                text: 'Resolve',
                getSecondaryText: id => getButtonText(_.find(supportedAuthorizationFollowUps, { AuthorizationId: id })),
                onClick: this.clickHandler,
              },
            ],
          }}
        />
      </ScrollContainer>
    );
  }

  render() {
    const { followUpToResolveId, isFormsResearch, isFailedStatusCheck, selectedAuthorization } = this.state;
    const { supportedAuthorizationFollowUps } = this.props;
    const sortKeys = ['InsuranceCompanyId', 'InstitutionId'];
    const supportedDrugAuths = _.sortBy(_.map(_.filter(supportedAuthorizationFollowUps, 'isSupportedDrug'), 'authorization'), sortKeys);
    const spotlightPracticeAuths = _.sortBy(_.map(_.filter(supportedAuthorizationFollowUps, { isSupportedDrug: false }), 'authorization'), sortKeys);

    return (
      <Container>
        <TitleContainer>Support Drug Follow Ups</TitleContainer>
        {this.generateSupportedAuthList(supportedDrugAuths)}
        <TitleContainer>Other Follow Ups</TitleContainer>
        {this.generateSupportedAuthList(spotlightPracticeAuths)}
        { followUpToResolveId && !isFailedStatusCheck && !isFormsResearch && (
          <GenericResolveModal
            followUpId={followUpToResolveId}
            onClose={() => { this.setState(baseState); }}
          />
        ) }
        { followUpToResolveId && !isFailedStatusCheck && isFormsResearch && (
          <CaremetxModal
            followUpId={followUpToResolveId}
            authorization={selectedAuthorization}
            onClose={() => { this.setState(baseState); }}
          />
        ) }
        { followUpToResolveId && isFailedStatusCheck && (
          <FailedCheckerModal
            authorization={selectedAuthorization}
            followUpId={followUpToResolveId}
            onClose={() => { this.setState(baseState); }}
          />
        ) }
      </Container>
    );
  }
}

export default compose(withSupportedAuthorizationFollowUps, withResolveSupportedFollowUp)(withAlert(SupportedFollowUpsDashboard));
