import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Prompt } from 'react-router-dom';

import LiveViewer from './LiveViewer';
import FrozenBackdropHOC from '../../../components/FrozenBackdropHOC';
import Sidebar from './Sidebar';

const WholeContainer = styled.div`
  z-index: ${props => props.theme.zTop};
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;

  @media (max-width: 1350px) {
    left: ${({ isFullScreen }) => isFullScreen ? '50%' : '52%'};
  }
`;

const LiveViewContainer = styled.div`
  background-color: white;
  padding: 3px;
  border-radius: 3px;
  margin-right: 20px;
  border: 1px solid ${({ theme }) => theme.lightGray};

  @media (max-width: 1350px) {
    margin-right: 10px;
  }
`;

const SidebarContainer = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 1350px) {
    width: 225px;
  }

  @media (max-width: 1100px) {
    width: 175px;
  }
`;

class LivePortalConnection extends PureComponent {
  state = { sidebarHidden: false };

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidUpdate() {
    window.onbeforeunload = () => ('Warning: refreshing the page will restart this authorization');
  }

  render() {
    const { sendIntegrationMessage, close, ioClient, authorization, backToAuthorizations, restartIntegration, hasLostConnection } = this.props;
    const { sidebarHidden } = this.state;

    return (
      <WholeContainer isFullScreen={sidebarHidden}>
        <LiveViewContainer>
          <LiveViewer
            sendIntegrationMessage={sendIntegrationMessage}
            ioClient={ioClient}
            authorization={authorization}
            sidebarHidden={sidebarHidden}
            toggleSidebar={() => { this.setState({ sidebarHidden: !sidebarHidden }); }}
            restartIntegration={restartIntegration}
            hasLostConnection={hasLostConnection}
          />
        </LiveViewContainer>
        { !sidebarHidden && (
          <SidebarContainer>
            <Sidebar
              authorization={authorization}
              sendIntegrationMessage={sendIntegrationMessage}
              backToAuthorizations={backToAuthorizations}
              close={close}
            />
          </SidebarContainer>
        ) }
        <Prompt
          message={(location, action) => {
            if (action === 'POP') {
              return 'Going back will leave this authorization in a draft status. '
              + 'You can use the buttons on the right to update this authorization if it is completed / submitted';
            }
            return true;
          }}
        />
      </WholeContainer>
    );
  }
}

export default FrozenBackdropHOC(LivePortalConnection);
