import React, { PureComponent } from 'react';
import styled from 'styled-components';
import colors from 'Resources/colors';
import { compose, withApollo } from 'react-apollo';
import { withAlert } from 'react-alert';
import _ from 'lodash';

import VerifyPortalAuthorizationId from './VerifyPortalAuthorizationId';
import BaseButton from '../../../../components/BaseButton';
import DeleteAuthorizationButton from '../../../../components/DeleteAuthorizationButton';
import { AuthorizationDetails, TitleContainer, DescriptionContainer } from './ContainerComponents';
import { withUpdateAuthorization, withAuthorizationQuery } from '../../../../graphql/Authorization';

const ActionButton = styled(BaseButton)`
  display: inline;
  text-align: center;
  margin: 5px;
`;

const { INTEGRATION_MESSAGE_TYPES, AUTHORIZATION_STATUSES, LIVE_CONNECTION_ACTIONS, TERMINAL_AUTHORIZATION_STATUSES } = CONFIG.CONSTANTS;
const { SHOULD_RESUME_SAVED_DRAFT } = CONFIG.DEFAULT_FIELDS;
const { LIVE_CONNECTION_MESSAGE } = INTEGRATION_MESSAGE_TYPES;
const initialFlags = { isComplete: false, isDelete: false, isError: false, onCompleteAction: null, isSaveDraft: false };
export const onCompleteActions = {
  VERIFY_STATUS: 'VERIFY_STATUS',
  APPROVED_HELPER: 'APPROVED_HELPER',
  OTHER_SELECTION: 'OTHER_SELECTION',
};
export class AuthorizationActions extends PureComponent {
  state = { ...initialFlags, autoDetectedStatus: null };

  back = () => {
    const { setIsClosing } = this.props;
    this.setState(initialFlags, () => { setIsClosing(false); });
  }

  submit = (status, portalAuthorizationId) => {
    const { sendIntegrationMessage } = this.props;

    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.COMPLETE,
      data: { status, portalAuthorizationId },
    });
  }

  triggerComplete = (completeAction) => {
    const { authorization } = this.props;

    if (
      completeAction === onCompleteActions.VERIFY_STATUS
      && !authorization.availablePortalHooks.verifyPortalAuthorizationIdOnSubmit
    ) {
      this.submit(AUTHORIZATION_STATUSES.PENDING);
    } else {
      this.setState({ onCompleteAction: completeAction });
    }
  }

  setSaveDraft = async () => {
    const { updateAuthorization, authorization, alert, backToAuthorizations } = this.props;

    try {
      await updateAuthorization({ variables: { id: authorization.id, patch: { config: { ...authorization.config, [SHOULD_RESUME_SAVED_DRAFT.key]: true } } } });
      this.setState({ isSaveDraft: false }, backToAuthorizations);
      alert.success('Your draft has been saved!');
    } catch {
      alert.error('There was an error saving your draft');
    }
  }

  refetchAuthorizationStatus = async () => {
    const { client, authorization } = this.props;

    const { data } = await client.query({ query: withAuthorizationQuery, variables: { id: authorization.id } });
    const auth = data.authorizationById;
    return auth.status;
  }

  handleIsComplete = async (shouldSetIsClosing = false) => {
    const { setIsClosing } = this.props;

    try {
      const autoDetectedStatus = await this.refetchAuthorizationStatus();
      if (_.includes(TERMINAL_AUTHORIZATION_STATUSES, autoDetectedStatus)) {
        this.setState({ autoDetectedStatus, isComplete: false });
      } else {
        this.setState({ isComplete: true });
      }
    } catch {
      this.setState({ isComplete: true });
    }

    if (shouldSetIsClosing) {
      setIsClosing(true);
    }
  };

  render() {
    const { authorization, backToAuthorizations, closeLiveView, isClosing } = this.props;
    const { isComplete, isDelete, isError, onCompleteAction, isSaveDraft, autoDetectedStatus } = this.state;

    let title = '';
    let description = '';
    let content = (
      <>
        <ActionButton onClick={async () => this.handleIsComplete(true)}>
          Authorization is Submitted/Complete
        </ActionButton>
        { authorization.hasResumeBehavior && (
          <ActionButton onClick={() => { this.setState({ isSaveDraft: true }); }}>Save Progress and Close</ActionButton>
        ) }
        <ActionButton onClick={() => { this.setState({ isDelete: true }); }}>Delete Authorization</ActionButton>
        <ActionButton onClick={() => { this.setState({ isError: true }); }}>Report an Error</ActionButton>
      </>
    );

    if (onCompleteAction === onCompleteActions.APPROVED_HELPER) {
      title = 'Approval documents';
      description = (
        <>
          <p>
            If there are approval documents to download you can download them through the live view on the left
            . Navigate to the documents you want to to download and click to download them.
          </p>
          <p>Afterwards, your downloaded documents will be added to this authorization.</p>
        </>
      );

      content = (
        <>
          <ActionButton onClick={() => { this.submit(AUTHORIZATION_STATUSES.APPROVED); }}>
            I am done downloading my documents
          </ActionButton>
          <ActionButton color={colors.gray} onClick={() => { this.setState({ onCompleteAction: null }); }}>Back</ActionButton>
        </>
      );
    } else if (onCompleteAction === onCompleteActions.OTHER_SELECTION) {
      title = 'Completed outcome';
      content = (
        <>
          <ActionButton onClick={() => { this.submit(AUTHORIZATION_STATUSES.VOIDED); }}>
            The authorization cannot be submitted or is not needed - I would like to
            <b> void </b>
            it
          </ActionButton>
          <ActionButton onClick={() => { this.submit(AUTHORIZATION_STATUSES.DUPLICATE); }}>
            This authorization
            <b> duplicates </b>
            a previous authorization
          </ActionButton>
          <ActionButton onClick={() => { this.submit(AUTHORIZATION_STATUSES.NO_AUTH_REQUIRED); }}>
            This drug or patient does
            <b> not require </b>
            an authorization
          </ActionButton>
          <ActionButton onClick={() => { this.submit(AUTHORIZATION_STATUSES.SENT); }}>Other</ActionButton>
          <ActionButton color={colors.gray} onClick={() => { this.setState({ onCompleteAction: null }); }}>Back</ActionButton>
        </>
      );
    } else if (onCompleteAction === onCompleteActions.VERIFY_STATUS) {
      title = 'Verify transaction Id';
      description = `Verifying the transaction ID set by ${authorization.portalTitle} helps us automatically maintain the status of your authorization`;

      content = (
        <VerifyPortalAuthorizationId
          authorizationId={authorization.id}
          onBack={() => { this.setState({ onCompleteAction: null }); }}
          onVerified={(portalAuthorizationId) => { this.submit(AUTHORIZATION_STATUSES.PENDING, portalAuthorizationId); }}
        />
      );
    } else if (isComplete || isDelete || isError || isSaveDraft || autoDetectedStatus) {
      if (isComplete) {
        title = 'Completed outcome';
        content = (
          <>
            <ActionButton onClick={() => { this.triggerComplete(onCompleteActions.APPROVED_HELPER); }}>Authorization is Approved</ActionButton>
            <ActionButton onClick={() => { this.triggerComplete(onCompleteActions.VERIFY_STATUS); }}>Authorization is Pending Decision</ActionButton>
            <ActionButton onClick={() => { this.triggerComplete(onCompleteActions.OTHER_SELECTION); }}>Other</ActionButton>
            <ActionButton color={colors.gray} onClick={this.back}>Back</ActionButton>
          </>
        );
      }

      if (isDelete) {
        title = 'Confirm delete';
        content = (
          <>
            <DeleteAuthorizationButton
              hideTooltip
              isReferralOrPatientEnrollment={authorization.isReferral || authorization.isPatientEnrollment}
              id={authorization.id}
              step={authorization.formDetails.currentStep}
              portalKey={authorization.portalKey}
              onDelete={backToAuthorizations}
              skipConfirmationAndDataCollection
            >
              <div style={{ width: '100%' }}><ActionButton>Delete authorization</ActionButton></div>
            </DeleteAuthorizationButton>
            <ActionButton color={colors.gray} onClick={this.back}>Back</ActionButton>
          </>
        );
      }

      if (isError) {
        title = 'Error details';
        content = (
          <>
            <div>Please report errors by chatting with us through the chat button on the bottom right of the screen</div>
            <ActionButton color={colors.gray} onClick={this.back}>Back</ActionButton>
          </>
        );
      }

      if (isSaveDraft) {
        title = 'Save Draft';
        content = (
          <>
            <div style={{ textAlign: 'center', padding: '5px' }}>
            WARNING: please save your draft in the live viewer screen to the left prior to pressing the confirm button below.
            Your draft will be saved on SamaCare and you will be navigated to the in progress authorization the next time you return.
            </div>
            <ActionButton onClick={this.setSaveDraft}>Confirm</ActionButton>
            <ActionButton color={colors.gray} onClick={this.back}>Back</ActionButton>
          </>
        );
      }

      if (autoDetectedStatus) {
        title = 'Confirm Status';
        content = (
          <>
            <div style={{ textAlign: 'center', padding: '5px' }}>{`Is this authorization in status: ${autoDetectedStatus}?`}</div>
            <ActionButton onClick={() => { this.submit(); }}>Yes</ActionButton>
            <ActionButton onClick={() => { this.setState({ isComplete: true, autoDetectedStatus: null }); }}>No</ActionButton>
          </>
        );
      }
    } else if (isClosing) {
      content = (
        <>
          <ActionButton onClick={this.handleIsComplete}>
            Authorization is Submitted/Complete
          </ActionButton>
          { authorization.hasResumeBehavior && (
            <ActionButton onClick={() => { this.setState({ isSaveDraft: true }); }}>Save Progress and Close</ActionButton>
          ) }
          <ActionButton onClick={closeLiveView}>Exit - Authorization is not submitted</ActionButton>
          <ActionButton color={colors.gray} onClick={this.back}>Back</ActionButton>
        </>
      );
    }

    return (
      <AuthorizationDetails style={{ marginTop: '15px' }}>
        { title && (<TitleContainer>{ title }</TitleContainer>) }
        { description && (<DescriptionContainer>{ description }</DescriptionContainer>) }
        { content }
      </AuthorizationDetails>
    );
  }
}

export default compose(withUpdateAuthorization)(withApollo(withAlert(AuthorizationActions)));
