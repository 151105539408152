import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import mouseTrap from 'react-mousetrap';
import { withAlert } from 'react-alert';

import ActionOverlay from './ActionOverlay';
import DialogOverlay from './DialogOverlay';
import SelectOverlay from './SelectOverlay';
import NavigationActions from './NavigationActions';
import UploadOverlay from './UploadOverlay';
import { getViewerWidth, getViewerHeight } from './utils';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';
import ErrorOverlay from './ErrorOverlay';
import SubmissionOverlay from './SubmissionOverlay';
import PageLoadMessageOverlay from './PageLoadMessageOverlay';

const {
  INTEGRATION_MESSAGE_TYPES,
  LIVE_CONNECTION_ACTIONS,
} = CONFIG.CONSTANTS;

const { LIVE_CONNECTION_MESSAGE } = INTEGRATION_MESSAGE_TYPES;
const LiveView = styled.img`
  max-width: ${({ width }) => `${width}px`};
  max-height: ${({ height }) => `${height}px`};

  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const LiveViewContainer = styled.div`
  width: ${({ width }) => `${width}px`};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

class LivePortalConnection extends PureComponent {
  constructor(props) {
    super(props);

    props.ioClient.on(LIVE_CONNECTION_MESSAGE, ({ data, type, automatedSubmissionStatus }) => {
      if (type === LIVE_CONNECTION_ACTIONS.IMAGE) {
        this.setState({ isLoading: false });
        this.setImage(data);
      } else if (type === LIVE_CONNECTION_ACTIONS.SELECT) {
        this.setState({ currentSelect: data });
      } else if (type === LIVE_CONNECTION_ACTIONS.UPLOAD) {
        this.setState({ currentUpload: data });
      } else if (type === LIVE_CONNECTION_ACTIONS.DIALOG) {
        this.setState({ currentDialog: data });
      } else if (type === LIVE_CONNECTION_ACTIONS.SCROLL_BAR) {
        // Sometimes on reload, a new image is not sent because the frame is the same as the prior frame
        // (duplicate images are deduplicated by puppeteer... I think)
        // Either way, need to remove the loading indicator in those cases, which is why we need this here
        this.setState({ scrollbarDimensions: data, isLoading: false, liveWidth: data.client.width });
      } else if (type === LIVE_CONNECTION_ACTIONS.FILE_DOWNLOADED) {
        props.alert.info('File downloaded to authorization');
      } else if (type === LIVE_CONNECTION_ACTIONS.POPUP_LOADING) {
        this.setState({ isLoading: true });
      } else if (type === LIVE_CONNECTION_ACTIONS.FIT_TO_SCREEN) {
        this.fitToScreen();
      } else if (type === LIVE_CONNECTION_ACTIONS.PAGE_DISCONNECTED) {
        this.setState({ renderErrorOverlay: true });
      } else if (type === LIVE_CONNECTION_ACTIONS.SUBMISSION_PAGE_DETECTED) {
        this.setState({ automatedSubmissionStatus });
      } else if (type === LIVE_CONNECTION_ACTIONS.SAMA_PAGE_LOAD) {
        this.setState({ samaPageLoadMessage: data.isLoading ? (data.userMessage || 'Page is loading') : null });
      } else if (type === LIVE_CONNECTION_ACTIONS.SET_WIDTH_DIMENSIONS) {
        this.setState({ liveWidth: data.width });
      }
    });

    this.state = {
      currentSelect: null,
      currentUpload: false,
      currentDialog: null,
      scrollbarDimensions: null,
      liveViewerWidth: getViewerWidth(props.sidebarHidden),
      liveViewerHeight: getViewerHeight(),
      isLoading: false,
      currentZoom: 1,
      renderErrorOverlay: false,
      samaPageLoadMessage: null,
      automatedSubmissionStatus: null,
      liveWidth: null,
      latestImage: null,
    };
  }

  componentDidMount() {
    const { sendIntegrationMessage } = this.props;
    const { liveViewerWidth, liveViewerHeight, currentZoom } = this.state;

    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.START,
      data: { initialWidth: liveViewerWidth, initialHeight: liveViewerHeight, scaleFactor: currentZoom },
    });
  }

  componentWillUnmount() {
    const { sendIntegrationMessage } = this.props;
    sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.STOP });
  }

  componentDidUpdate(prevProps) {
    const { sidebarHidden } = this.props;
    if (sidebarHidden !== prevProps.sidebarHidden) {
      this.fitToScreen();
    }
  }

  setImage(data) {
    this.setState({ latestImage: `data:image/jpeg;base64,${data}` });
  }

  respondToDialog = (accept) => {
    const { sendIntegrationMessage } = this.props;

    sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.DIALOG, data: { accept } });
    this.setState({ currentDialog: null });
  }

  selectOption = (value, label) => {
    const { sendIntegrationMessage } = this.props;
    const { currentSelect } = this.state;
    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.SELECT,
      data: { value, path: currentSelect.path, label, frameIndex: currentSelect.frameIndex },
    });
    this.setState({ currentSelect: null });
  }

  sendUploads = (files) => {
    const { sendIntegrationMessage } = this.props;
    const { currentUpload } = this.state;
    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.UPLOAD,
      data: { path: currentUpload.path, files, frameIndex: currentUpload.frameIndex },
    });
    this.setState({ currentUpload: null });
  }

  fitToScreen = () => {
    const { sendIntegrationMessage, sidebarHidden } = this.props;
    const newWidth = getViewerWidth(sidebarHidden);
    const newHeight = getViewerHeight();
    this.setState({ liveViewerWidth: newWidth, liveViewerHeight: newHeight });
    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.RESIZE,
      data: { width: newWidth, height: newHeight },
    });
  }

  zoom = (zoomIn) => {
    const { sendIntegrationMessage, sidebarHidden } = this.props;
    const { currentZoom } = this.state;

    const width = getViewerWidth(sidebarHidden);
    const height = getViewerHeight();

    let factor;
    if (zoomIn) {
      factor = _.min([currentZoom + 0.125, 1.25]);
      sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.ZOOM, data: { factor, width, height } });
    } else {
      factor = _.max([currentZoom - 0.125, 1]);
      sendIntegrationMessage({ message: LIVE_CONNECTION_MESSAGE, type: LIVE_CONNECTION_ACTIONS.ZOOM, data: { factor, width, height } });
    }

    this.setState({ currentZoom: factor });
  }

  render() {
    const { sendIntegrationMessage, authorization, toggleSidebar, sidebarHidden, restartIntegration, hasLostConnection } = this.props;
    const {
      currentSelect,
      currentUpload,
      currentDialog,
      scrollbarDimensions,
      liveViewerWidth,
      liveViewerHeight,
      currentZoom,
      isLoading,
      renderErrorOverlay,
      samaPageLoadMessage,
      automatedSubmissionStatus,
      liveWidth,
      latestImage,
    } = this.state;

    return (
      <LiveViewContainer width={liveViewerWidth}>
        <NavigationActions
          sendIntegrationMessage={sendIntegrationMessage}
          toggleSidebar={toggleSidebar}
          fitToScreen={this.fitToScreen}
          currentWidth={liveViewerWidth}
          currentHeight={liveViewerHeight}
          triggerLoading={() => { this.setState({ isLoading: true }); }}
          currentZoom={currentZoom}
          zoom={this.zoom}
          isFullScreen={sidebarHidden}
        />
        { samaPageLoadMessage && (<PageLoadMessageOverlay message={samaPageLoadMessage} />) }
        { currentDialog && (
          <DialogOverlay respondToDialog={this.respondToDialog} type={currentDialog.type} message={currentDialog.message} />
        ) }
        { currentSelect && (
          <SelectOverlay
            clear={() => { this.setState({ currentSelect: null }); }}
            onSelect={this.selectOption}
            options={currentSelect.options}
          />
        ) }
        { currentUpload && (
          <UploadOverlay
            authorizationId={authorization.id}
            sendUploads={this.sendUploads}
            maxFiles={currentUpload.maxFiles}
            maxMB={currentUpload.maxMB}
          />
        ) }
        { (renderErrorOverlay || hasLostConnection) && (
          <ErrorOverlay restartIntegration={restartIntegration} authorization={authorization} />
        ) }
        { automatedSubmissionStatus && (
          <SubmissionOverlay
            authorization={authorization}
            back={() => { this.setState({ automatedSubmissionStatus: null }); }}
            sendIntegrationMessage={sendIntegrationMessage}
            automatedSubmissionStatus={automatedSubmissionStatus}
          />
        ) }

        <ActionOverlay
          sendIntegrationMessage={sendIntegrationMessage}
          scrollbarDimensions={scrollbarDimensions}
          liveViewerWidth={liveViewerWidth}
          liveViewerHeight={liveViewerHeight}
          width={liveWidth}
          currentZoom={currentZoom}
          isActive={(!automatedSubmissionStatus && !samaPageLoadMessage && !currentSelect && !currentUpload && !currentDialog && !renderErrorOverlay)}
        >
          <LiveView
            src={latestImage}
            draggable={false}
            width={liveWidth || liveViewerWidth}
            height={liveViewerHeight}
          />
        </ActionOverlay>

        <LoadingSpinner open={isLoading} />
      </LiveViewContainer>
    );
  }
}

export default withAlert(mouseTrap(LivePortalConnection));
