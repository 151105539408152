import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import Segment from 'Segment/Segment';

import { setFormFields } from '../../reducers/formReducer';

const leftPadding = '7px';
const SegmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

const MultiSegment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const SegmentTitle = styled.div`
  color: ${props => props.theme.purple};
  border-bottom: 1px solid #CCCCCC;
  padding: ${leftPadding};
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
`;

const SegmentFieldContainer = styled.div`
  padding-top: 5px;
  border: ${props => props.highlight ? '1px solid red' : ''};
`;

const countToSize = {
  1: 'large',
  2: 'med',
  3: 'small',
};

class Section extends PureComponent {
  render() {
    const { section, set, childrenAfterSection, display, disabled, children, highlightAsMissing } = this.props;

    if (display) {
      return (
        <SegmentContainer>
          <SegmentTitle>{section.title}</SegmentTitle>
          { !childrenAfterSection && children }
          <SegmentFieldContainer highlight={highlightAsMissing}>
            { _.map(section.items, (item, i) => {
              if (_.isArray(item)) {
                return (
                  <MultiSegment key={`MultiSegment${item.title}${i}`}>
                    { _.map(item, (subItem, index) => (
                      <Segment
                        key={`${subItem.title}${index}`}
                        disabled={disabled}
                        item={subItem}
                        leftPadding={leftPadding}
                        set={set}
                        size={countToSize[item.length]}
                      />
                    )) }
                  </MultiSegment>
                );
              }
              return (
                <Segment
                  key={`${section.title}${i}`} disabled={disabled} item={item} leftPadding={leftPadding} set={set} size="large"
                />
              );
            }) }
          </SegmentFieldContainer>
          { childrenAfterSection && children }
        </SegmentContainer>
      );
    }
    return (<div />);
  }
}

function mapStateToProps(state, props) {
  return {
    display: !props.section.displayIf || _.get(state, `form.results.${props.section.displayIf}`),
    disabled: state.form.disabled || props.disabled,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    set: toSet => dispatch(setFormFields(toSet)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Section);
