import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import strings from 'Resources/strings';
import styled from 'styled-components';
import { BaseText } from 'Segment/StyledComponents';

import { withResetPassword } from '../graphql/Account';
import AlertModal from './AlertModal';
import BaseButton from './BaseButton';

const PurpleButton = styled(BaseButton)`
  background-color: ${({ theme, disabled }) => (disabled ? theme.disabledGray : theme.purple)};
  border-color: ${({ theme, disabled }) => (disabled ? theme.disabledGray : theme.purple)};
  color: white;
  margin-left: auto;

  &:hover {
    color: ${({ theme, disabled }) => (disabled ? 'white' : theme.purple)};
    background: ${({ theme, disabled }) => (disabled ? theme.disabledGray : 'white')};
  }
`;

const SectionTitle = styled.div`
  color: ${props => props.theme.gray};
  font-weight: 500;
  margin-top: 10px;
  margin-right: auto;
`;

const Message = styled.div`
  color: ${props => props.warn ? props.theme.red : props.theme.green};
  text-align: center;
  margin-top: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${BaseButton} {
    flex: 1;
  }
`;

const { RETURN_CODES } = CONFIG.CONSTANTS;

class ForgotPasswordButton extends PureComponent {
  state = { openModal: false, email: '' };

  onOpenModal = (e) => {
    const { email } = this.props;
    e.stopPropagation();
    this.setState({ openModal: true, email, isRequesting: false, message: '', shouldShowButton: true });
  };

  onCloseModal = () => {
    this.setState({ openModal: false });
  };

  sendResetPasswordLink = () => {
    const { resetPassword, alert } = this.props;
    const { email } = this.state;
    this.setState({ isRequesting: true });
    resetPassword({
      variables: {
        email,
      },
    }).then((response) => {
      switch (response.data.resetPassword.code) {
        case RETURN_CODES.OK:
          this.setState({ message: strings.RESET_PASSWORD.SUCCESS_SENDING_LINK, shouldShowButton: false });
          break;
        case RETURN_CODES.ACCOUNT_NOT_FOUND:
          this.setState({
            message: 'SamaCare does not have an account on file for this email. '
            + 'Please reach out to help@samacare.com or your practice administrator if you need to create a new account.',
            isRequesting: false });
          break;
        default:
          alert.error('Unexpected error. Please try again.');
          break;
      }
    }).catch(() => {
      alert.error(strings.RESET_PASSWORD.ERROR_SENDING_LINK);
      this.setState({ isRequesting: false });
    });
  };

  render() {
    const { openModal, email, message, isRequesting, shouldShowButton } = this.state;

    return (
      <div>
        <AlertModal
          buttons={shouldShowButton ? (
            <PurpleButton onClick={this.sendResetPasswordLink} disabled={isRequesting || !email}>{strings.RESET_PASSWORD.SEND_RESET_LINK}</PurpleButton>
          ) : null}
          content={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SectionTitle>{strings.RESET_PASSWORD.CONFIRM_EMAIL}</SectionTitle>
              <BaseText
                type="email"
                placeholder={strings.COMMON.EMAIL_PLACEHOLDER}
                onChange={(e) => { this.setState({ email: e.target.value }); }}
                value={email}
                onKeyPress={({ key }) => (key === 'Enter' && this.sendResetPasswordLink())}
              />
              { message && (<Message warn={message !== strings.RESET_PASSWORD.SUCCESS_SENDING_LINK}>{ message }</Message>) }
            </div>
          )}
          header={strings.RESET_PASSWORD.HEADER}
          open={openModal}
          closeModal={this.onCloseModal}
        />
        <ButtonContainer>
          <PurpleButton onClick={this.onOpenModal}>
            {strings.RESET_PASSWORD.FORGOT_PASSWORD}
          </PurpleButton>
        </ButtonContainer>
      </div>
    );
  }
}

export default compose(withResetPassword)(withAlert(ForgotPasswordButton));
