import React from 'react';

import Section from 'AuthorizationSharedComponents/Section';
import PrescriberSelector from './PrescriberSelector';
import { transformSections } from '../../../../util/sectionConfigurationManager';
import CreatePrescriberOrLocation from '../CreatePrescriberOrLocation';

const dFields = CONFIG.DEFAULT_FIELDS;
const createPrescriberRequiredFields = [
  [dFields.PRESCRIBER_FIRST_NAME, dFields.PRESCRIBER_LAST_NAME],
  [dFields.PRESCRIBER_SPECIALTY, { ...dFields.PRESCRIBER_NPI, required: false }],
  dFields.PRESCRIBER_TIN,
];

const PrescriberDetails = ({ prescriberId, disabled, authorization, results, set }) => (
  <div>
    <Section
      section={transformSections([{
        items: [
          [dFields.PRESCRIBER_FIRST_NAME, dFields.PRESCRIBER_LAST_NAME],
          [dFields.PRESCRIBER_SPECIALTY, dFields.PRESCRIBER_NPI],
          dFields.PRESCRIBER_TIN,
        ],
        title: 'Prescriber Details',
      }], authorization.formFieldConfig)[0]}
      disabled={disabled}
    >
      <div style={{ display: 'flex' }}>
        <PrescriberSelector
          afterSelection={() => {
            // REALLY JANKY FIX
            // We save the authorization because there are some cases when the user doesn't have a phone saved
            // And setPrescriberOnAuth will clear out the number they input (because it refetches the auth + removes front end cached data)
            set({ [dFields.REQUESTED_BY_PHONE.key]: results[dFields.REQUESTED_BY_PHONE.key] });
          }}
          authorizationId={authorization.id}
          disabled={disabled}
          prescriberId={prescriberId}
        />
        <CreatePrescriberOrLocation
          isPrescriber
          requiredFields={createPrescriberRequiredFields}
        />
      </div>
    </Section>
  </div>
);

export default PrescriberDetails;
