import _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { withDefaultProps } from './utils';

import noteInfo from './fragments/noteInfo';
import authorizationCorrespondenceInfo from './fragments/authorizationCorrespondenceInfo';

export const PATIENT_ENROLLMENT_LIST_QUERY_NAME = 'patientEnrollmentsByInstitutionId';

export const patientEnrollmentsByInstitutionIdQuery = gql`
  query ${PATIENT_ENROLLMENT_LIST_QUERY_NAME}($filterByAccount: Boolean, $isArchived: Boolean, $sortBy: String) {
    ${PATIENT_ENROLLMENT_LIST_QUERY_NAME}(filterByAccount: $filterByAccount, isArchived:$isArchived, sortBy:$sortBy) {
      id
      config
      status
      formId
      formDetails
      updatedAt
      submittedAt
      createdByUser
      checkRequested
      createdAt
      version
      InstitutionId
      institutionAuthorizationId
      isArchived
      displayReviewWarning
      hideFromExpiring
      portalKey
      ICDs
      HCPCSCodes
      isSupported
      sortByTimestamp
      isPatientEnrollment

      patient {
        id
        firstName
        lastName
        InsuranceCompanyId
        institutionPatientId
        dob
      }

      latestCorrespondence {
        ...authorizationCorrespondenceInfo
      }

      correspondences {
        ...authorizationCorrespondenceInfo
      }

      latestNote {
        ...noteInfo
      }

      formFieldConfig {
        requiredFields
        hiddenFields
      }
    }

  }

  ${noteInfo}
  ${authorizationCorrespondenceInfo}
`;

export const withPatientEnrollments = graphql(
  patientEnrollmentsByInstitutionIdQuery,
  {
    options: { fetchPolicy: 'network-only' },
    props: withDefaultProps(({ data }) => ({
      patientEnrollments: _.get(data, 'patientEnrollmentsByInstitutionId'),
      patientEnrollmentsRefetch: _.get(data, 'refetch'),
    })),
  }
);

export const withSubmitPatientEnrollment = graphql(
  gql`
    mutation submitPatientEnrollment($id: Int!, $isBalanceCheck: Boolean) {
      submitPatientEnrollment(id: $id, isBalanceCheck: $isBalanceCheck)
    }
  `,
  {
    name: 'submitPatientEnrollment',
  }
);
