const { LIVE_VIEWER_WIDTH } = CONFIG.CONSTANTS;

export const getViewerHeight = () => (window.innerHeight - 60);

export const getViewerWidth = (isSidebarHidden = false) => {
  if (isSidebarHidden) {
    return window.innerWidth - 115;
  }

  if (window.innerWidth > 1350) {
    return window.innerWidth - 375;
  }

  return Math.max(LIVE_VIEWER_WIDTH, window.innerWidth - 275);
};
