import _ from 'lodash';
import axios from 'axios';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import moment from 'moment';
import { push } from 'connected-react-router';

import { setLoginRedirect } from './reducers/loginRedirectReducer';
import store from './reduxStore';
import ROUTE_PATHS from './routes/ROUTE_PATHS';

const link = createHttpLink({ uri: '/graphql', credentials: 'same-origin' });

let now = moment();
let queries = [];

const isWhitelisted = (locationHash) => {
  const whitelistedPartialMatchOkay = ['/new-authorization?step=3', '/portal-authorization?step=3', '/support'];
  const whitelistedExactMatch = ['#/'];
  return !_.isEmpty(_.filter(whitelistedPartialMatchOkay, hash => locationHash.includes(hash))) || _.includes(whitelistedExactMatch, locationHash);
};

const errorOnOverQuerying = new ApolloLink((operation, forward) => {
  queries.push(operation.operationName);
  // Over 5 queries in the same .5 milliseconds
  if (moment().diff(now, 'milliseconds') > 500) {
    // This should currently pass on all our routes.
    // If we want to allow an increased amount of authorizations, we should explicitly whitelist
    // operationName / location hash combinations and add a Jira Ticket
    const locationHash = window.location.hash;
    if (isWhitelisted(locationHash)) {
      now = moment();
      queries = [];
    }

    if (queries.length >= 5) {
      const errorMessage = `Too many queries on page: ${locationHash}, query list: ${JSON.stringify(_.countBy(queries))}.`
      + ' If we cannot lower the number of queries right now, create a jira ticket & whitelist this location hash';
      console.error(errorMessage);
      // alert(errorMessage);
      // // Aggressively erroring or else it'll be too easy to ignore
      // throw new Error(errorMessage);
    }

    now = moment();
    queries = [];
  }

  return forward(operation);
});


export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => console.error(`[GraphQL error]: Message: ${message}`));
  }
  if (networkError) {
    if (networkError.statusCode === 401 && !window.location.href.includes(ROUTE_PATHS.RESET_PASSWORD_BASE)) {
      if (!window.location.href.includes(ROUTE_PATHS.RESET_PASSWORD_BASE)) {
        // We can't redirect back to 'login'
        if (!window.location.href.includes(ROUTE_PATHS.LOGIN)) {
          store.dispatch(setLoginRedirect(window.location.href));
        }
        axios.post('/logout');
        store.dispatch(push(ROUTE_PATHS.LOGIN));
      }
    }
    console.error(`[Network error]: ${networkError}`);
  }
});

let links = [errorLink, link];

if (CONFIG.NODE_ENV === 'dev') {
  links = [errorOnOverQuerying].concat(links);
}

const client = new ApolloClient({
  link: ApolloLink.from(links),
  cache: new InMemoryCache(),
});

export default client;
