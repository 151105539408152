import React from 'react';
import _ from 'lodash';
import Section from 'AuthorizationSharedComponents/Section';

import LocationSelector from './LocationSelector';
import { transformSections } from '../../../../util/sectionConfigurationManager';
import CreatePrescriberOrLocation from '../CreatePrescriberOrLocation';

const createLocationRequiredFields = [
  CONFIG.DEFAULT_FIELDS.LOCATION_NAME,
  CONFIG.DEFAULT_FIELDS.PRESCRIBER_ADDRESS,
  [CONFIG.DEFAULT_FIELDS.PRESCRIBER_CITY, CONFIG.DEFAULT_FIELDS.PRESCRIBER_STATE, CONFIG.DEFAULT_FIELDS.PRESCRIBER_ZIP],
  [CONFIG.DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN, { ...CONFIG.DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI, required: false }],
];

const LocationDetails = ({
  set, authorization, disabled,
}) => (
  <div>
    <Section
      section={_.first(transformSections([{
        items: [
          CONFIG.DEFAULT_FIELDS.LOCATION_NAME,
          CONFIG.DEFAULT_FIELDS.PRESCRIBER_ADDRESS,
          [CONFIG.DEFAULT_FIELDS.PRESCRIBER_CITY, CONFIG.DEFAULT_FIELDS.PRESCRIBER_STATE, CONFIG.DEFAULT_FIELDS.PRESCRIBER_ZIP],
          [CONFIG.DEFAULT_FIELDS.PRESCRIBER_OFFICE_TIN, CONFIG.DEFAULT_FIELDS.PRESCRIBER_OFFICE_NPI],
        ],
        title: 'Location Details',
      }], authorization.formFieldConfig))}
      disabled={disabled}
    >
      <div style={{ display: 'flex' }}>
        <LocationSelector authorizationId={authorization.id} disabled={disabled} locationId={authorization.formDetails.locationId} set={set} />
        <CreatePrescriberOrLocation
          isPrescriber={false}
          requiredFields={createLocationRequiredFields}
        />
      </div>
    </Section>
  </div>
);
export default LocationDetails;
