import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { graphql, compose, Query } from 'react-apollo';
import styled from 'styled-components';
import _ from 'lodash';
import CheckIcon from 'react-icons/lib/md/check';
import strings from 'Resources/strings';
import mixpanel from 'mixpanel-browser';

import AuthorizationReminderButton from '../../AuthorizationReminderButton';
import BaseButton from '../../BaseButton';
import DeleteAuthorizationButton from '../../DeleteAuthorizationButton';
import DownloadFormsButton from '../../DownloadFormsButton';
import { withAuthorizationQuery } from '../../../graphql/Authorization';
import DownloadPortalAttachmentsButton from './DownloadPortalAttachmentsButton';
import UploadToOncoEMR from './UploadToOncoEMR';

import institutionInfo from '../../../graphql/fragments/institutionInfo';
import { SUPPORT_INSTITUTION_QUERY } from '../../../graphql/Institution';

const withInstitutionsQuery = gql`
  query ${SUPPORT_INSTITUTION_QUERY} {
    institutionsFindAll {
      ...institutionInfo
    }
  }
  ${institutionInfo}
`;

const Container = styled.div`
  flex: 1;
  padding: 50px 10px;
`;

const Tracker = styled.div`
  width: 300px;
  margin: 0 auto;
  display: block;
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 3px;
  padding: 10px 20px;
`;

const Section = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
`;

const Title = styled.div`
  font-size: 18px;
  margin-left: 10px;
  font-weight: 500;
`;

const InstitutionName = styled.div`
  color: ${props => props.theme.darkGray};
  font-style: italic;
  font-weight: 700;
  marign: 0 5px;
  font-size: 14px;
`;

const SectionItems = styled.div`
  font-size: 14px;
`;

const Item = styled.div`
  color: ${props => (props.current ? 'white' : (props.active ? props.theme.darkGray : props.theme.lightGray))};
  padding: 6px 0 6px 28px;
  font-weight: ${props => (props.current ? 500 : 200)};
  background: ${props => (props.current ? props.theme.purple : 'transparent')};

  &:hover {
    cursor: ${props => !props.current && props.active && 'pointer'};
    background: ${props => (props.current ? props.theme.purple : (props.active ? props.theme.purple : 'transparent'))};
    color: ${props => (props.current ? 'white' : (props.active ? 'white' : props.theme.lightGray))};
    font-weight: ${props => props.active && 500};
  }
`;

const TrackerTitle = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding-top: 10px;
  font-size: 20px;
  color: ${props => props.theme.purple};
  font-weight: 500;
`;

const checkmarkSize = 20;
const Checkmark = styled(CheckIcon)`
  height: ${checkmarkSize}px;
  width: ${checkmarkSize}px;
  color: ${props => props.theme.purple};
`;

const CheckmarkPlaceholder = styled.div`
  height: ${checkmarkSize}px;
  width: ${checkmarkSize}px;
`;

const HeaderContainer = styled.div`
  width: 300px;
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 3px;
  padding: 10px 20px;
  margin: 0 auto 10px auto;
`;

const OverviewRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
`;

const ContextPaneButton = styled(BaseButton)`
  margin-bottom: 10px;
  width: 100%;
`;

const { AUTHORIZATION_FORM_STEPS, TERMINAL_AUTHORIZATION_STATUSES, AUTHORIZATION_STATUSES, FEATURE_FLAGS, PORTAL_AUTHORIZATION_STEPS } = CONFIG.CONSTANTS;

const getSections = sections => (
  [
    {
      title: 'Pre Submission',
      items: sections.PRE_SUBMIT,
      sectionActive() { return true; },
    },
    {
      title: 'Authorization Submitted',
      sectionActive(status) {
        return !_.includes([AUTHORIZATION_STATUSES.PRESUBMISSION], status);
      },
    },
    {
      title: 'Response Received',
      sectionActive(status) {
        return _.includes(TERMINAL_AUTHORIZATION_STATUSES, status);
      },
      items: sections.POST_SUBMIT,
    },
  ]
);

class ProgressTracker extends PureComponent {
  render() {
    const {
      authorization,
      setStep,
      currentStep,
      backToAuthorizations,
      saveChanges,
      isPortalAuth,
      triggerReinputLogin,
      children,
      sections,
      isPatientEnrollment,
      isSamaUser,
      toggleFormModal,
      account,
    } = this.props;

    if (authorization) {
      const isFormAuth = authorization.formId && !authorization.isReferral;
      const isPresubmit = !authorization.submittedAt;

      return (
        <Container>
          <HeaderContainer>
            <Title>Overview</Title>
            {isSamaUser && (
              <Query query={withInstitutionsQuery}>
                {(resp) => {
                  if (resp.loading) { return <InstitutionName />; }
                  const institutions = resp.data.institutionsFindAll;
                  return (
                    <InstitutionName>{`Request for ${_.find(institutions, { id: authorization.InstitutionId }).name}`}</InstitutionName>
                  );
                }}
              </Query>
            )}
            <OverviewRow>
              <div style={{ flex: 1 }}>Patient</div>
              <div>
                { authorization.patient
                  ? `${authorization.patient.lastName}, ${authorization.patient.firstName}`
                  : strings.NEW_AUTHORIZATIONS.NO_PATIENT }
              </div>
            </OverviewRow>
            { children }
          </HeaderContainer>
          <Tracker>
            <TrackerTitle>Progress</TrackerTitle>
            { _.map(getSections(sections), section => (
              <Section key={`Section-${section.title}`}>
                <TitleContainer key={`ProgressTracker${section.title}`}>
                  { section.sectionActive(authorization.status) ? (<Checkmark />) : (<CheckmarkPlaceholder />) }
                  <Title>{ section.title }</Title>
                </TitleContainer>
                <SectionItems>
                  { _.map(section.items, item => (
                    <Item
                      key={`ProgressTrackerItem${item.title}`}
                      active={authorization.formDetails.currentStep >= item.step}
                      current={currentStep === item.step}
                      onClick={() => {
                        if (authorization.formDetails.currentStep >= item.step) {
                          mixpanel.track('Context pane navigation used');
                          setStep(item.step);
                        }
                      }}
                    >
                      { item.title }
                    </Item>
                  ))}
                </SectionItems>
              </Section>
            )) }
            { (isPresubmit && !isPortalAuth) && (
              // We want to avoid passing the event object from onClick to save changes
              <ContextPaneButton onClick={() => { saveChanges(); }}>
                {strings.NEW_AUTHORIZATIONS.SAVE}
              </ContextPaneButton>
            ) }
            {!authorization.isPatientEnrollment && (
              <AuthorizationReminderButton authorization={authorization}>
                <ContextPaneButton>
                  Schedule Reminder Email
                </ContextPaneButton>
              </AuthorizationReminderButton>
            )}
            { isPresubmit && (
              <DeleteAuthorizationButton
                hideTooltip
                isReferralOrPatientEnrollment={authorization.isReferral || authorization.isPatientEnrollment}
                id={authorization.id}
                step={authorization.formDetails.currentStep}
                portalKey={authorization.portalKey}
                onDelete={() => {
                  mixpanel.track('Authorization deleted from context pane', { step: currentStep });
                  backToAuthorizations();
                }}
              >
                <ContextPaneButton>
                  {!isPatientEnrollment ? strings.NEW_AUTHORIZATIONS.DELETE_AUTH : 'Delete Enrollment'}
                </ContextPaneButton>
              </DeleteAuthorizationButton>
            )}
            {currentStep === AUTHORIZATION_FORM_STEPS.ALL.FORM_DETAILS.step && isFormAuth && (
              <ContextPaneButton onClick={toggleFormModal}>
                Change Form
              </ContextPaneButton>
            )}
            { triggerReinputLogin && (
              <ContextPaneButton onClick={triggerReinputLogin}>
                Reinput Login Credentials
              </ContextPaneButton>
            ) }
            { !isPresubmit && (
              <DownloadFormsButton authorization={authorization}>
                <ContextPaneButton>Download Forms</ContextPaneButton>
              </DownloadFormsButton>
            ) }
            { !isPresubmit && isPortalAuth && (
              <DownloadPortalAttachmentsButton authorization={authorization}>
                <ContextPaneButton>
                  Download Integration Uploads
                </ContextPaneButton>
              </DownloadPortalAttachmentsButton>
            ) }
            { (
              (isPortalAuth && currentStep === PORTAL_AUTHORIZATION_STEPS.ALL.RESPONSE.step)
              || (isFormAuth && currentStep === AUTHORIZATION_FORM_STEPS.ALL.RESPONSE.step)
            )
            && _.get(account, 'institution.featureFlags', {})[FEATURE_FLAGS.UploadToOncoEMR] && (
              <UploadToOncoEMR authorization={authorization}>
                <ContextPaneButton>Upload Documents to OncoEMR</ContextPaneButton>
              </UploadToOncoEMR>
            ) }
          </Tracker>
        </Container>
      );
    }
    return (<div />);
  }
}

const mapStateToProps = state => ({
  currentStep: state.form.currentStep,
});

const withAuthorization = graphql(
  withAuthorizationQuery,
  {
    options: props => ({ variables: { id: parseInt(props.authorizationId, 10) } }),
    props({ data }) { return { authorization: data.authorizationById }; },
  },
);

export default connect(mapStateToProps)(compose(withAuthorization)(ProgressTracker));
