import _ from 'lodash';

const SET_FORM_FIELDS = 'SET_FORM_FIELD';
const SYNC_AUTHORIZATION_DETAILS = 'SYNC_AUTHORIZATION_DETAILS';
const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
const RESET_FORM = 'RESET_FORM';
const SET_ATTACHMENT = 'SET_ATTACHMENT';
const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
const ADJUST_REQUIRED_FIELDS = 'ADJUST_REQUIRED_FIELDS';
const TOGGLE_LOADING_SEGMENT = 'TOGGLE_LOADING_SEGMENT';
const SET_SHOULD_INCLUDE_COVER_LETTER = 'SET_SHOULD_INCLUDE_COVER_LETTER';
const TOGGLE_HIGHLIGHT_REQUIRED_FIELDS = 'TOGGLE_HIGHLIGHT_REQUIRED_FIELDS';
const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS';

export function syncAuthorizationDetails(config, status, attachments, portalTitle) {
  return {
    type: SYNC_AUTHORIZATION_DETAILS, config, status, attachments, portalTitle,
  };
}

export function resetForm() {
  return { type: RESET_FORM };
}

export function setFormFields(toSet) {
  return { type: SET_FORM_FIELDS, toSet };
}

export function setCurrentStep(step) {
  return { type: SET_CURRENT_STEP, step };
}

export function setAttachment(file) {
  return { type: SET_ATTACHMENT, attachment: file };
}

export function removeAttachment(file) {
  return { type: REMOVE_ATTACHMENT, attachment: file };
}

export function setRequiredFields(toSet) {
  return { type: ADJUST_REQUIRED_FIELDS, toSet };
}

export function unsetRequiredFields(toUnset) {
  return { type: ADJUST_REQUIRED_FIELDS, toUnset };
}

export function toggleLoadingSegment(force) {
  return { type: TOGGLE_LOADING_SEGMENT, forcedValue: force };
}

export function setShouldIncludeCoverLetter(shouldIncludeCoverLetter) {
  return { type: SET_SHOULD_INCLUDE_COVER_LETTER, shouldIncludeCoverLetter };
}

export function toggleHighlightRequiredFields(highlightRequiredFields) {
  return { type: TOGGLE_HIGHLIGHT_REQUIRED_FIELDS, highlightRequiredFields };
}

export function clearAttachments() {
  return { type: CLEAR_ATTACHMENTS };
}

export const defaultState = {
  results: {},
  currentStep: 1,
  disabled: false,
  warnUnsent: false,
  attachments: [],
  requiredFieldKeys: [],
  segmentLoading: false,
  shouldIncludeCoverLetter: false,
  portalTitle: '',
  highlightRequiredFields: false,
};

export default function form(state = defaultState, action) {
  switch (action.type) {
    case TOGGLE_HIGHLIGHT_REQUIRED_FIELDS: {
      return { ...state, highlightRequiredFields: action.highlightRequiredFields };
    }
    case TOGGLE_LOADING_SEGMENT: {
      const segmentLoading = action.forcedValue === undefined ? !state.segmentLoading : action.forcedValue;
      return { ...state, segmentLoading };
    }
    case SET_FORM_FIELDS: {
      return { ...state, results: { ...state.results, ...action.toSet } };
    }
    case SYNC_AUTHORIZATION_DETAILS: {
      const statuses = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
      const disabled = !_.includes([statuses.PRESUBMISSION, statuses.EDIT_AND_RESUBMIT], action.status);
      const warnUnsent = statuses.EDIT_AND_RESUBMIT === action.status;
      return {
        ...state,
        results: { ...state.results, ...action.config },
        disabled,
        warnUnsent,
        attachments: action.attachments,
        portalTitle: (action.portalTitle || ''),
      };
    }
    case SET_CURRENT_STEP: {
      return { ...state, currentStep: action.step };
    }
    case SET_ATTACHMENT: {
      return { ...state, attachments: state.attachments.concat(action.attachment) };
    }
    case REMOVE_ATTACHMENT: {
      return { ...state, attachments: _.reject(state.attachments, { awsKey: action.attachment.awsKey }) };
    }
    case ADJUST_REQUIRED_FIELDS: {
      const { toSet, toUnset } = action;
      let newRequiredFields = state.requiredFieldKeys;

      if (toSet) {
        newRequiredFields = newRequiredFields.concat([toSet]);
      }

      if (toUnset) {
        newRequiredFields = _.filter(newRequiredFields, field => (!_.isEqual(field, toUnset)));
      }

      return { ...state, requiredFieldKeys: newRequiredFields };
    }
    case SET_SHOULD_INCLUDE_COVER_LETTER: {
      return { ...state, shouldIncludeCoverLetter: action.shouldIncludeCoverLetter };
    }
    case RESET_FORM: {
      return defaultState;
    }
    case CLEAR_ATTACHMENTS: {
      return { ...state, attachments: [] };
    }
    default:
      return state;
  }
}
