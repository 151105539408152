import React, { PureComponent } from 'react';
import styled from 'styled-components';
import RefreshIcon from 'react-icons/lib/md/refresh';
import FitIcon from 'react-icons/lib/md/aspect-ratio';
import ZoomInIcon from 'react-icons/lib/md/zoom-in';
import ZoomOutIcon from 'react-icons/lib/md/zoom-out';
import CameraIcon from 'react-icons/lib/md/photo-camera';
import screenfull from 'screenfull';
import { withAlert } from 'react-alert';

const NavigationActions = styled.div`
  display: flex;
  padding: 3px 0;
  width: 100%;
`;

const ZoomContainer = styled.div`
  font-weight: bold;
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const NavigationAction = styled.div`
  cursor: ${({ disabled }) => disabled ? 'cursor' : 'pointer'};
  font-weight: bold;
  padding: 3px 10px;
  border-radius: 5px;
  border: 1px solid;
  color: ${({ theme, disabled }) => disabled ? theme.lightGray : 'default'};
  border-color: ${({ theme, disabled }) => disabled ? theme.lightGray : 'default'};

  &:hover {
    color: ${({ theme, disabled }) => disabled ? 'default' : theme.purple};
    border-color: ${({ theme, disabled }) => disabled ? 'default' : theme.purple};
  }

  margin-left: ${props => (props.right ? 'auto' : '10px')};
  margin-right 10px;
`;

const { INTEGRATION_MESSAGE_TYPES, LIVE_CONNECTION_ACTIONS } = CONFIG.CONSTANTS;
const { LIVE_CONNECTION_MESSAGE } = INTEGRATION_MESSAGE_TYPES;

export class NavActions extends PureComponent {
  componentDidMount() {
    const { fitToScreen } = this.props;
    // if in testing environment or strange browsers, may not exist
    if (screenfull.on) {
      screenfull.on('change', fitToScreen);
    }
  }

  componentWillUnmount() {
    const { fitToScreen } = this.props;
    // if in testing environment or strange browsers, may not exist
    if (screenfull.off) {
      screenfull.off('change', fitToScreen);
    }
  }

  screenshot = () => {
    const { sendIntegrationMessage, alert } = this.props;

    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.SCREENSHOT,
    });

    alert.info('A screenshot has been saved to this authorization');
  }

  onRefresh = () => {
    const { sendIntegrationMessage, currentWidth, currentHeight, triggerLoading } = this.props;
    triggerLoading();
    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.REFRESH,
      data: { currentWidth, currentHeight },
    });
  }

  // Deprecated for now... until we find a good use case
  onBack = () => {
    const { sendIntegrationMessage, currentWidth, currentHeight, triggerLoading } = this.props;
    triggerLoading();
    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.BACK,
      data: { currentWidth, currentHeight },
    });
  }

  render() {
    const { fitToScreen, toggleSidebar, currentZoom, zoom, isFullScreen } = this.props;

    return (
      <NavigationActions>
        <NavigationAction onClick={this.onRefresh}>
          <RefreshIcon />
          { ' Refresh' }
        </NavigationAction>
        <ZoomContainer>
          <NavigationAction disabled={currentZoom === 1} onClick={() => { zoom(false); }}><ZoomOutIcon /></NavigationAction>
          <div>{`${currentZoom * 100}%`}</div>
          <NavigationAction disabled={currentZoom === 1.25} onClick={() => { zoom(true); }}><ZoomInIcon /></NavigationAction>
        </ZoomContainer>
        <div style={{ marginLeft: 'auto', display: 'flex' }}>
          <NavigationAction onClick={this.screenshot}>
            <CameraIcon />
            { ' Save Page Screenshot' }
          </NavigationAction>
          <NavigationAction
            onClick={async () => {
              toggleSidebar();

              let fullScreenTimeout = 10;

              try {
                if (screenfull.isEnabled) {
                  if (isFullScreen) {
                    await screenfull.exit();
                  } else {
                    await screenfull.request();
                  }
                  fullScreenTimeout = 1000;
                }
              } catch (e) {
                console.error(`Error closing or opening fullscreen ${e.stack}`);
              } finally {
                setTimeout(fitToScreen, fullScreenTimeout);
              }
            }}
          >
            <FitIcon />
            { isFullScreen ? ' Exit Full Screen' : ' Full Screen' }
          </NavigationAction>
        </div>
      </NavigationActions>
    );
  }
}

export default withAlert(NavActions);
