import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { compose, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withAlert } from 'react-alert';
import PrescriberAndLocationDetails from 'AuthorizationSharedSteps//PrescriberSection';
import ResponseSection from 'AuthorizationSharedSteps/ResponseSection';
import ContextPane from 'AuthorizationSharedComponents/ContextPane';
import RequiredFieldModal from 'AuthorizationSharedComponents/RequiredFieldModal';
import * as FullStory from '@fullstory/browser';

import ROUTE_PATHS from '../ROUTE_PATHS';
import IntegrationWebsocketHOC from './IntegrationWebsocketHOC';
import {
  resetForm,
  setCurrentStep,
  setFormFields,
  syncAuthorizationDetails,
  toggleHighlightRequiredFields,
} from '../../reducers/formReducer';
import InitializeSection from './InitializeSection';
import RequestDetails from './RequestDetails';
import PortalSpecificSteps from './PortalSpecificSteps';
import { Container, FormContainer, FormTitle, FormSubTitle, withAuthorization } from '../NewAuthorization/index';
import { withSubmitAuthorization, withUpdateFormDetails, withAuthorizationQuery, withUpdateAuthorizationProgress } from '../../graphql/Authorization';
import { withCurrentAccount } from '../../graphql/Account';
import NewAuthorizationHOC from '../../components/NewAuthorizationHOC';
import {
  resultsPropType,
  latestIntegrationMsgAndCustomPortalStepsPropTypes,
  locationPropTypes,
  accountPropTypes,
  authorizationPropTypes,
} from './portalAuthorizationPropTypes';

const setupIntegrationRunnerMutation = gql`
  mutation setupIntegrationRunner($authorizationId: Int!) {
    setupIntegrationRunner(authorizationId: $authorizationId)
  }
`;

const OverviewRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
`;

const MESSAGE_TYPES = CONFIG.CONSTANTS.INTEGRATION_MESSAGE_TYPES;
const STEPS = CONFIG.CONSTANTS.PORTAL_AUTHORIZATION_STEPS.ALL;
const RESET_ON_RESTART = { hasStartedLiveConnection: false, hasStartedIntegration: false };
export class PortalForm extends Component {
  state = {
    requiredFieldModalOpen: false,
    triggerReinputLogin: null,
    isMissingPortalCredentials: false,
    ...RESET_ON_RESTART,
  }

  componentDidMount() {
    const { account, authorization, latestIntegrationMessage } = this.props;
    const { hasStartedIntegration, hasStartedLiveConnection } = this.state;

    this.syncLocalState();
    if (CONFIG.NODE_ENV === 'production' && !_.get(authorization, 'submittedAt') && account) {
      FullStory.setUserVars({ displayName: `${account.firstName} ${account.lastName}`, userId: account.id });
    }

    if (!hasStartedIntegration) {
      try {
        this.kickoffFreshIntegration();
      } catch (e) {
        if (_.includes(e.message, CONFIG.CONSTANTS.ERROR_TYPES.PORTAL_MISSING_CREDENTIALS)) {
          // eslint-disable-next-line
          this.setState({ isMissingPortalCredentials: true });
        }
      }
    }

    const currentMessageType = _.get(latestIntegrationMessage, 'type');
    if (!hasStartedLiveConnection && currentMessageType === MESSAGE_TYPES.TRIGGER_LIVE_CONNECTION) {
      // eslint-disable-next-line
      this.setState({ hasStartedLiveConnection: true });
    }
  }

  syncLocalState() {
    const { authorization, syncLocalState } = this.props;

    if (authorization) {
      syncLocalState(authorization.config, authorization.status, authorization.attachments, authorization.portalTitle);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      onComponentUpdate,
      authorization,
      results,
      unsetRefetchAuthorization,
      shouldRefetchAuthorization,
      authorizationRefetch,
      latestIntegrationMessage,
    } = this.props;
    const { hasStartedLiveConnection } = this.state;
    if (prevProps.shouldRefetchAuthorization !== shouldRefetchAuthorization) {
      authorizationRefetch();
      unsetRefetchAuthorization();
    }

    onComponentUpdate(prevProps, authorization, results, this.syncLocalState.bind(this));

    const currentMessageType = _.get(latestIntegrationMessage, 'type');
    if (!hasStartedLiveConnection && currentMessageType === MESSAGE_TYPES.TRIGGER_LIVE_CONNECTION) {
      // eslint-disable-next-line
      this.setState({ hasStartedLiveConnection: true });
    }
  }

  componentWillUnmount() {
    const { stopIntegration } = this.props;

    stopIntegration();
  }

  kickoffFreshIntegration = async (isRestart = false) => {
    const { client, kickoffIntegration, location, pingIntegration, sendRestart } = this.props;
    const { hasStartedIntegration, isMissingPortalCredentials } = this.state;

    // Need to wait to make sure we're still where we expect
    await new Promise(resolve => (setTimeout(resolve, 1000)));
    const params = new URLSearchParams(location.search);

    if (
      _.includes(window.location.href, ROUTE_PATHS.PORTAL_AUTHORIZATION)
      && window.location.hash.match(/step=[234]/) && (!hasStartedIntegration || isMissingPortalCredentials || isRestart)
    ) {
      this.setState(RESET_ON_RESTART);
      const authorizationId = _.toNumber(params.get('id'));
      try {
        await pingIntegration();
        await sendRestart();
      } catch (e) {
        const { data } = await client.query({ query: withAuthorizationQuery, variables: { id: authorizationId } });
        const authorization = data.authorizationById;
        const isTerminated = _.get(_.last(authorization.customPortalSteps), 'isTerminal');

        if (!authorization.submittedAt && !isTerminated) {
          const setupIntegrationRunnerRes = await client.mutate({
            mutation: setupIntegrationRunnerMutation,
            variables: { authorizationId: authorization.id },
          });

          const resId = setupIntegrationRunnerRes.data.setupIntegrationRunner;

          if (_.includes(resId, CONFIG.CONSTANTS.ERROR_TYPES.PORTAL_MISSING_CREDENTIALS)) {
            this.setState({ isMissingPortalCredentials: true });
            throw new Error(CONFIG.CONSTANTS.ERROR_TYPES.PORTAL_MISSING_CREDENTIALS);
          } else if (resId) {
            kickoffIntegration(resId);
          }
        }
      } finally {
        this.setState({ hasStartedIntegration: true });
      }
    }
  }

  nextStep = async (forcedNextStep) => {
    const { takeStep, saveAuthorizationChanges, hasInvalidResults, setStepAndUpdateURL, account, onNextStep, step } = this.props;

    if (hasInvalidResults() && !forcedNextStep) {
      this.setState({ requiredFieldModalOpen: true });
    } else {
      if (!account.isReadOnly) {
        await saveAuthorizationChanges();
      }

      if (!_.isNumber(forcedNextStep)) {
        await takeStep(true);
      } else {
        setStepAndUpdateURL(forcedNextStep);
      }

      onNextStep(step, this.kickoffFreshIntegration, () => {
        // This is to unset state flags on timeout
        this.setState(RESET_ON_RESTART);
      });
    }
  };

  back = () => {
    const { step, setStepAndUpdateURL } = this.props;

    setStepAndUpdateURL(step - 1);
  }

  takeStepsBack = (stepsBack) => {
    const { step, setStepAndUpdateURL } = this.props;

    setStepAndUpdateURL(step - stepsBack);
  }

  initializeSectionHasInvalidHelper = (forceResultsInvalid) => {
    const { hasInvalidResults } = this.props;
    if (hasInvalidResults(forceResultsInvalid)) {
      this.setState({ requiredFieldModalOpen: true });
      return true;
    }

    return false;
  }

  render() {
    const {
      authorization,
      step,
      setStepAndUpdateURL,
      sendIntegrationMessage,
      latestIntegrationMessage,
      goToLocation,
      stopIntegration,
      disabled,
      runnerId,
      account,
      triggerLiveConnection,
      ioClient,
    } = this.props;
    const { requiredFieldModalOpen, triggerReinputLogin, isMissingPortalCredentials, hasStartedIntegration, hasStartedLiveConnection } = this.state;

    let content;

    if (authorization) {
      const { currentStep } = authorization.formDetails;

      const hasResponse = currentStep > STEPS.PORTAL_SPECIFIC_DETAILS.step;

      switch (step) {
        case STEPS.INITIATE.step:
          content = (
            <InitializeSection
              authorization={authorization}
              isInvalid={this.initializeSectionHasInvalidHelper}
              account={account}
              nextStep={this.nextStep}
              disabled={disabled}
            />
          );
          break;
        case STEPS.REQUEST_DETAILS.step:
          content = (
            <RequestDetails
              authorization={authorization}
              back={this.back}
              nextStep={this.nextStep}
              disabled={disabled}
            />
          );
          break;
        case STEPS.PRESCRIBER_AND_LOCATION_DETAILS.step:
          content = (
            <PrescriberAndLocationDetails
              authorization={authorization}
              back={this.back}
              nextStep={this.nextStep}
              disabled={disabled}
            />
          );
          break;
        case STEPS.PORTAL_SPECIFIC_DETAILS.step:
          content = (
            <PortalSpecificSteps
              authorization={authorization}
              hidePortalTutorial={_.get(account, 'hidePortalTutorial')}
              back={this.back}
              latestIntegrationMessage={latestIntegrationMessage}
              triggerLiveConnection={triggerLiveConnection}
              sendIntegrationMessage={sendIntegrationMessage}
              _restartIntegration={this.kickoffFreshIntegration}
              goToInitialStep={() => { setStepAndUpdateURL(STEPS.INITIATE.step); }}
              disabled={disabled}
              navigateToMainList={() => { goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST); }}
              // This is the hackiest thing in North America. TODO: Remove the location reload when we have some time
              navigateToResponse={() => {
                setStepAndUpdateURL(STEPS.RESPONSE.step);
                setTimeout(() => { window.location.reload(); }, 2500);
              }}
              nextStep={hasResponse ? () => { setStepAndUpdateURL(STEPS.RESPONSE.step); } : null}
              stopIntegration={stopIntegration}
              takeStepsBack={this.takeStepsBack}
              runnerId={runnerId}
              setTriggerReinputLogin={(triggerReinputLoginMethod) => { this.setState({ triggerReinputLogin: triggerReinputLoginMethod }); }}
              ioClient={ioClient}
              isMissingPortalCredentials={isMissingPortalCredentials}
              hasStartedIntegration={hasStartedIntegration}
              unsetMissingPortalCredentials={() => { this.setState({ isMissingPortalCredentials: false }); }}
              hasStartedLiveConnection={hasStartedLiveConnection}
            />
          );
          break;
        case STEPS.RESPONSE.step:
          content = (
            <ResponseSection
              back={this.back}
              authorization={authorization}
            />
          );
          break;
        default:
          content = (<div />);
      }

      return (
        <Container>
          <FormContainer>
            <FormTitle>Prior Authorization</FormTitle>
            <FormSubTitle>{_.get(_.find(STEPS, { step }), 'title')}</FormSubTitle>
            { content }
          </FormContainer>
          <ContextPane
            isSamaUser={_.get(account, 'isSamaUser')}
            sections={CONFIG.CONSTANTS.PORTAL_AUTHORIZATION_STEPS}
            authorizationId={authorization.id}
            isPortalAuth
            backToAuthorizations={() => { goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST); }}
            setStep={(stepTo) => {
              if (stepTo === STEPS.RESPONSE.step || disabled) {
                setStepAndUpdateURL(stepTo);
              } else {
                this.nextStep(stepTo);
              }
            }}
            triggerReinputLogin={triggerReinputLogin}
            account={account}
          >
            <OverviewRow>
              <div style={{ flex: 1 }}>Selected Portal</div>
              <div style={{ maxWidth: '175px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{ authorization.portalKey }</div>
            </OverviewRow>
          </ContextPane>
          <RequiredFieldModal
            closeModal={() => { this.setState({ requiredFieldModalOpen: false }); }}
            open={requiredFieldModalOpen}
            content={_.get(_.find(STEPS, ['step', step]), 'requiredFieldModalContent')}
          />
        </Container>
      );
    }
    return (<div />);
  }
}

PortalForm.propTypes = {
  account: accountPropTypes,
  authorization: authorizationPropTypes,
  authorizationRefetch: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  goToLocation: PropTypes.func.isRequired,
  hasInvalidResults: PropTypes.func.isRequired,
  kickoffIntegration: PropTypes.func.isRequired,
  latestIntegrationMessage: latestIntegrationMsgAndCustomPortalStepsPropTypes,
  location: locationPropTypes,
  onComponentUpdate: PropTypes.func.isRequired,
  pingIntegration: PropTypes.func.isRequired,
  results: resultsPropType,
  runnerId: PropTypes.string,
  saveAuthorizationChanges: PropTypes.func.isRequired,
  sendIntegrationMessage: PropTypes.func.isRequired,
  sendRestart: PropTypes.func.isRequired,
  setStepAndUpdateURL: PropTypes.func.isRequired,
  shouldRefetchAuthorization: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
  stopIntegration: PropTypes.func.isRequired,
  syncLocalState: PropTypes.func.isRequired,
  takeStep: PropTypes.func.isRequired,
  unsetRefetchAuthorization: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    results: state.form.results,
    attachments: state.form.attachments,
    step: state.form.currentStep,
    disabled: state.form.disabled,
    requiredFieldKeys: state.form.requiredFieldKeys,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    syncLocalState: (config, status, attachments, portalTitle) => { dispatch(syncAuthorizationDetails(config, status, attachments, portalTitle)); },
    goToLocation: (location) => { dispatch(push(location)); },
    setToForm: toSet => dispatch(setFormFields(toSet)),
    setStep(step) { dispatch(setCurrentStep(step)); },
    reset() { dispatch(resetForm()); },
    toggleHighlightRequiredFields(shouldBeHighlighted) { dispatch(toggleHighlightRequiredFields(shouldBeHighlighted)); },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(withUpdateFormDetails, withSubmitAuthorization, withAuthorization, withCurrentAccount, withUpdateAuthorizationProgress)(
    IntegrationWebsocketHOC(withApollo(withAlert(NewAuthorizationHOC(PortalForm))))
  )
);
