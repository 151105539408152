import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import Patient from 'AuthorizationSharedComponents/Patient';
import Section from 'AuthorizationSharedComponents/Section';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';
import SegmentSelector from 'Segment/SegmentSelector';
import PrimaryDrug from 'AuthorizationSharedComponents/PrimaryDrug';

import { withCurrentAccount } from '../../../graphql/Account';
import { withInsuranceCompanies } from '../../../graphql/InsuranceCompany';

import { withCreatePatientOnAuthorization, withUpdatePatient, findDuplicateAuthorizationsForPatientQuery } from '../../../graphql/Patient';
import { setFormFields } from '../../../reducers/formReducer';
import RequiredTag from '../../../components/RequiredTag';
import BaseButton from '../../../components/BaseButton';
import AuthorizationModal from '../../../components/AuthorizationModal';
import MissingInformationModal from '../MissingInformationModalContent';
import Modal from '../../../components/Modal';
import filterInsuranceCompanyOptions from '../../../util/filterInsuranceCompanyOptions';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextButton = styled.div`
  padding: 5px 10px;
  margin: 0 0 3px 10px;
  text-decoration: underline;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.darkPurple};
    font-weight: 500;
  }
`;
const SingleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const getPlansFromParent = (companies, selectedCompanyName) => {
  const selectedCompany = _.find(companies, { name: selectedCompanyName });
  return selectedCompany ? _.filter(companies, { planParentCompanyId: _.parseInt(selectedCompany.id) }) : [];
};

const fields = CONFIG.DEFAULT_FIELDS;
export class InitializeSection extends PureComponent {
  state = { showMissingInfoModal: false, foundDuplicateAuthorizations: [] }

  companySelect = null

  checkForDuplicateAuthorizations = () => {
    const { authorization, client, toggleFormModal } = this.props;

    client.query({
      query: findDuplicateAuthorizationsForPatientQuery,
      variables: { id: authorization.id },
      fetchPolicy: 'no-cache',
    }).then(({ data }) => {
      if (!_.isEmpty(data.findDuplicateAuthorizationsForPatient)) {
        this.setState({ foundDuplicateAuthorizations: data.findDuplicateAuthorizationsForPatient });
      } else {
        toggleFormModal();
      }
    });
  }

  submit = async () => {
    const {
      insuranceCompanies,
      authorization,
      isInvalid,
      results,
      set,
      saveChanges,
      createPatientOnAuthorization,
      dob,
      firstName,
      lastName,
    } = this.props;

    let requiredFieldKeys = [fields.INSURANCE_STATE.key, fields.INSURANCE_COMPANY.key];
    if ((getPlansFromParent(insuranceCompanies, results[fields.INSURANCE_COMPANY.key])).length > 0) {
      requiredFieldKeys = requiredFieldKeys.concat(fields.INSURANCE_PLAN.key);
    }

    set({ [fields.ALL_HCPCS.key]: results[fields.HCPCS_0.key] });

    if (isInvalid(_.some(requiredFieldKeys, key => (!results[key])) || !results[fields.PRIMARY_DRUG_NAME.key])) { return; }

    if (!results[fields.REQUEST_DESCRIPTION.key]) {
      set({ [fields.REQUEST_DESCRIPTION.key]: results[fields.PRIMARY_DRUG_NAME.key].toUpperCase() });
    }

    if (authorization.formId && authorization.submissionPhoneNumber) {
      await this.success();
    } else {
      if (!authorization.patient) {
        await createPatientOnAuthorization({
          variables: {
            authorizationId: authorization.id,
            patientProps: {
              firstName: firstName.trim(), lastName: lastName.trim(), dob,
            } },
        });
      }
      await saveChanges();
      await this.checkForDuplicateAuthorizations();
    }
  }

  success = () => {
    const {
      nextStep, authorization, dob, firstName, lastName, createPatientOnAuthorization,
    } = this.props;

    if (!authorization.patient) {
      createPatientOnAuthorization({
        variables: {
          authorizationId: authorization.id,
          patientProps: {
            firstName: firstName.trim(), lastName: lastName.trim(), dob,
          },
        },
      }).then(nextStep);
    } else {
      nextStep();
    }
  }

  render() {
    const {
      authorization, insuranceCompanies, lastName, firstName, dob, disabled, set, state, results, account, toggleFormModal,
    } = this.props;
    const { showMissingInfoModal, foundDuplicateAuthorizations } = this.state;
    const selectedCompany = _.find(insuranceCompanies, { name: results[fields.INSURANCE_COMPANY.key] });

    if (!account) { return (<div />); }

    let insuranceSection = [fields.PATIENT_MEMBER_ID, fields.INSURANCE_STATE];
    if (account.institution.practicingSpecialty === CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES.ONCOLOGY) {
      insuranceSection = [fields.PATIENT_GROUP_NUMBER, ...insuranceSection];
    // only need this for Novartis data collection right now
    } else if (account.institution.practicingSpecialty === CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES.RETINA) {
      insuranceSection = [fields.INSURANCE_TYPE, ...insuranceSection];
    }

    let insurancePlans = getPlansFromParent(insuranceCompanies, results[fields.INSURANCE_COMPANY.key]);
    if (insurancePlans.length > 0) {
      insurancePlans = _.concat(insurancePlans, selectedCompany);
    }
    const selectedPlan = _.find(insurancePlans, { name: results[fields.INSURANCE_PLAN.key] });
    return (
      <FormContainer>
        <Patient
          authorizationId={authorization.id}
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          patientId={_.get(authorization, 'patient.id')}
          currentStep={authorization.formDetails.currentStep}
        />
        <Section
          section={{
            items: [
              fields.PATIENT_ADDRESS_LINE,
              [fields.PATIENT_CITY, fields.PATIENT_STATE, fields.PATIENT_ZIP],
            ],
            title: 'Patient Address',
          }}
        />
        <PrimaryDrug authorization={authorization} />
        <Section
          section={{
            items: [fields.EMAIL_NOTIFICATION_TYPE],
            title: 'Send notification for major status updates?',
          }}
        />
        <Section
          childrenAfterSection
          section={{
            items: insuranceSection,
            title: 'Patient Insurance Information',
          }}
        >
          <SingleLine>
            <SegmentSelector
              disabled={disabled}
              labelKey="name"
              onChange={(company) => {
                if (company) {
                  set({ [fields.INSURANCE_COMPANY.key]: company.name, [fields.INSURANCE_PLAN.key]: null });
                } else {
                  set({ [fields.INSURANCE_COMPANY.key]: null });
                }
              }}
              options={_.sortBy(insuranceCompanies, 'name')}
              setRef={ref => this.companySelect = ref}
              title={(
                <TitleContainer>
                  Insurance Organization
                  <RequiredTag />
                </TitleContainer>
              )}
              value={selectedCompany}
              valueKey="id"
              sorted
              filterOption={filterInsuranceCompanyOptions}
            />
            <TextButton onClick={() => { this.setState({ showMissingInfoModal: true }); }}>Request Support for Additional Insurers</TextButton>
          </SingleLine>
          { insurancePlans.length > 0 && (
            <SingleLine>
              <SegmentSelector
                labelKey="name"
                onChange={(company) => {
                  if (company) {
                    set({ [fields.INSURANCE_PLAN.key]: company.name });
                  } else {
                    set({ [fields.INSURANCE_PLAN.key]: null });
                  }
                }}
                options={_.sortBy(insurancePlans, 'name')}
                title={(
                  <TitleContainer>
                    Insurance Plan
                    <RequiredTag />
                  </TitleContainer>
                )}
                value={selectedPlan}
                valueKey="id"
                sorted
                filterOption={filterInsuranceCompanyOptions}
              />
            </SingleLine>
          ) }
        </Section>
        <FormSubmitButtons submit={this.submit} />
        { !_.isEmpty(foundDuplicateAuthorizations) && (
          <AuthorizationModal
            open
            authorizations={foundDuplicateAuthorizations}
            iteratePage={() => {}}
            headerText="Possible duplicate authorizations found"
            bodyText="Someone in your office recently submitted authorization(s) for this patient. To save time and effort, check
to ensure you're not duplicate an authorization."
            customButtons={[
              (<BaseButton key="duplicateGoBack" onClick={() => { this.setState({ foundDuplicateAuthorizations: [] }); }}>Go Back</BaseButton>),
              (
                <BaseButton
                  style={{ marginLeft: 10 }}
                  key="duplicateContinue"
                  onClick={() => { toggleFormModal(); this.setState({ foundDuplicateAuthorizations: [] }); }}
                >
                  Continue with Authorization
                </BaseButton>),
            ]}
          />
        )}
        { showMissingInfoModal && (
          <Modal header="Request Increased Support" onClick={() => { this.setState({ showMissingInfoModal: false }); }} open={showMissingInfoModal}>
            <MissingInformationModal
              type="Insurance Company"
              authorizationId={authorization.id}
              onCancel={() => { this.setState({ showMissingInfoModal: false }); }}
              insuranceCompanyId={_.get(selectedCompany, 'id')}
              state={state}
            />
          </Modal>
        ) }
      </FormContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.form.results[fields.PATIENT_FIRST_NAME.key],
    lastName: state.form.results[fields.PATIENT_LAST_NAME.key],
    dob: state.form.results[fields.PATIENT_DOB.key],
    state: state.form.results[fields.INSURANCE_STATE.key],
    disabled: state.form.disabled,
    results: state.form.results,
  };
}

const mapDispatchToProps = dispatch => ({
  set(toSet) { dispatch(setFormFields(toSet)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(
    withCreatePatientOnAuthorization,
    withUpdatePatient,
    withInsuranceCompanies,
    withCurrentAccount
  )(withApollo(InitializeSection))
);
