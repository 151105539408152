
const statuses = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;

export default [
  {
    title: 'Patient Enrollments',
    statuses,
    tabTitle: 'Mine',
    onlyMe: true,
    isArchived: false,
    emptyState: 'You have not created any Patient Enrollments yet. To create a new enrollment use the "New Patient Enrollment" button at the top.',
  },
  {
    title: 'Patient Enrollments',
    statuses,
    tabTitle: 'All',
    onlyMe: false,
    isArchived: false,
    emptyState: 'There are no Patient Enrollments yet. To create a new enrollment use the "New Patient Enrollment" button at the top.',
  },
  {
    title: 'Patient Enrollments',
    statuses,
    tabTitle: 'Archived',
    onlyMe: false,
    onlyExpired: false,
    isArchived: true,
    emptyState: 'No Patient Enrollments have been archived yet.',
  },
];
