import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { BaseText } from 'Segment/StyledComponents';

import Modal from '../../Modal';
import BaseButton from '../../BaseButton';
import { ModalBodyLarge, ModalFooter, ModalContent } from '../../ModalStyledComponents';

const CustomModalContent = styled(ModalContent)`
  margin: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
`;

const GrayButton = styled(BaseButton)`
  background-color: ${props => props.theme.lightGray};
  border-color: ${props => props.theme.lightGray};
  color: white;
  width: 120px;
  margin-left: 10px;
  padding: 5px 10px;
`;

const SectionTitle = styled.div`
  color: ${props => props.theme.gray};
  font-weight: 500;
  margin-top: 10px;
  margin-right: auto;
  text-align: left;
`;

class LoginEMR extends PureComponent {
  state = { username: '', password: '', loginURL: '' };

  submit = () => {
    const { handleSubmit, documentsToUpload, closeModal, alert, patientMRN } = this.props;
    const { username, password, loginURL } = this.state;

    if (!username || !password || !loginURL) {
      alert.error('Please input a valid username, password + login URL!');
    } else {
      handleSubmit(patientMRN, documentsToUpload, { username, password, loginURL: `https://secure${loginURL}.oncoemr.com/OncoEMR` });
      closeModal();
    }
  }

  render() {
    const { closeModal, open } = this.props;
    const { username, password, loginURL } = this.state;

    return (
      <Modal
        header="OncoEMR Credentials"
        onClick={this.toggleModal}
        open={open}
      >
        <ModalBodyLarge>
          <CustomModalContent>
            <div>
              Add your login credentials for
              <b>
                &nbsp;OncoEMR
              </b>
              . We will use your credentials to upload documents on your behalf.
            </div>
            <SectionTitle>
              Username
            </SectionTitle>
            <BaseText
              onChange={(e) => { this.setState({ username: e.target.value }); }}
              value={username || ''}
            />
            <SectionTitle>
              Password
            </SectionTitle>
            <BaseText
              type="password"
              onChange={(e) => { this.setState({ password: e.target.value }); }}
              value={password || ''}
            />
            <SectionTitle>
              Login URL
            </SectionTitle>
            <div style={{ display: 'flex', margin: '5px' }}>
              <div>
                { 'https://secure' }
              </div>
              <BaseText
                onChange={(e) => { this.setState({ loginURL: e.target.value }); }}
                value={loginURL || ''}
                style={{ width: '40px', height: '10px' }}
              />
              <div>
                { '.oncoemr.com/OncoEMR' }
              </div>
            </div>
            All credentials are protected with 256-bit encryption
          </CustomModalContent>
          <CustomModalFooter>
            <GrayButton onClick={closeModal}>Close</GrayButton>
            <BaseButton style={{ marginLeft: 10 }} onClick={this.submit}>
              Submit
            </BaseButton>
          </CustomModalFooter>
        </ModalBodyLarge>
      </Modal>
    );
  }
}

export default withAlert(LoginEMR);
