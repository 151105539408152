import _ from 'lodash';
import moment from 'moment';
import faker from 'faker';

const { DATE_FORMAT, PRACTICING_SPECIALTY_TYPES } = CONFIG.CONSTANTS;
const defaultFields = CONFIG.DEFAULT_FIELDS;
const { drugName, code, dose } = _.sample([
  { dose: '', code: 'J3301', drugName: 'Kenalog' },
  { code: 'J9042', drugName: 'Adcetris', dose: '1 mg' },
  { code: 'J9217', drugName: 'Leuprolide (Eligard)', dose: '7.5 mg' },
]);

const genderBool = Math.random() >= 0.5;
const urgencyBool = Math.random() >= 0.5;
const continuationBool = Math.random() >= 0.5;
const age = Math.floor(Math.random() * Math.floor(50) + 20);
const generateEightDigitString = () => (Math.floor(Math.random() * (99999999 - 10000000)) + 10000000).toString();

const generateFakePatientConfig = () => (
  {
    [defaultFields.REQUESTED_BY.key]: faker.name.findName(),
    [defaultFields.REQUESTED_BY_PHONE.key]: faker.phone.phoneNumberFormat(0),
    [defaultFields.REQUESTED_BY_EXTENSION.key]: '1234',
    [defaultFields.PATIENT_FIRST_NAME.key]: faker.name.firstName(),
    [defaultFields.PATIENT_LAST_NAME.key]: faker.name.lastName(),
    [defaultFields.PATIENT_DOB.key]: moment().subtract(age, 'days').format(DATE_FORMAT),
    [defaultFields.PATIENT_MEMBER_ID.key]: generateEightDigitString(),
    [defaultFields.PATIENT_GROUP_NUMBER.key]: faker.phone.phoneNumberFormat(0),
    [defaultFields.INSURANCE_STATE.key]: faker.address.stateAbbr(),
    [defaultFields.PATIENT_ZIP.key]: faker.address.zipCode().split('-')[0],
    [defaultFields.PATIENT_CITY.key]: faker.address.city(),
    [defaultFields.PATIENT_STATE.key]: faker.address.stateAbbr(),
    [defaultFields.PATIENT_ADDRESS_LINE.key]: faker.address.streetAddress(),
    [defaultFields.PATIENT_PHONE.key]: faker.phone.phoneNumberFormat(0),
    [defaultFields.PATIENT_GENDER_MALE.key]: genderBool && 'Male',
    [defaultFields.PATIENT_GENDER_FEMALE.key]: !genderBool && 'Female',
    [defaultFields.PATIENT_GENDER_TEXT.key]: genderBool ? 'Male' : 'Female',
    [defaultFields.PATIENT_AGE.key]: age,
    [defaultFields.PRIMARY_DRUG_NAME.key]: drugName,
    [defaultFields.HCPCS_0.key]: code,
    [defaultFields.ALL_HCPCS.key]: code,
    [defaultFields.PRIMARY_DOSE.key]: dose,
    [defaultFields.INSURANCE_COMPANY.key]: faker.company.companyName(),
    [defaultFields.REQUEST_DESCRIPTION.key]: drugName,
    [defaultFields.DATE_OF_SERVICE.key]: moment().add('10', 'days').format(DATE_FORMAT),
    [defaultFields.URGENT_REQUEST.key]: urgencyBool && 'On',
    [defaultFields.NOT_URGENT_REQUEST.key]: !urgencyBool && 'On',
    [defaultFields.INITIATION_REQUEST.key]: continuationBool && 'On',
    [defaultFields.CONTINUATION_REQUEST.key]: !continuationBool && 'On',
    [defaultFields.PRESCRIBER_SPECIALTY.key]: _.sample(_.values(PRACTICING_SPECIALTY_TYPES)),
    [defaultFields.PRESCRIBER_NPI.key]: generateEightDigitString(),
    [defaultFields.PRESCRIBER_TIN.key]: generateEightDigitString(),
    [defaultFields.PRESCRIBER_FIRST_NAME.key]: faker.name.firstName(),
    [defaultFields.PRESCRIBER_LAST_NAME.key]: faker.name.lastName(),
    [defaultFields.PRESCRIBER_LICENSE_NUMBER.key]: generateEightDigitString(),
    [defaultFields.PRESCRIBER_DEA_NUMBER.key]: generateEightDigitString(),
    [defaultFields.LOCATION_NAME.key]: faker.company.companyName(),
    [defaultFields.PRESCRIBER_ADDRESS.key]: faker.address.streetAddress(),
    [defaultFields.PRESCRIBER_CITY.key]: faker.address.city(),
    [defaultFields.PRESCRIBER_STATE.key]: faker.address.stateAbbr(),
    [defaultFields.PRESCRIBER_ZIP.key]: faker.address.zipCode().split('-')[0],
    [defaultFields.PRESCRIBER_OFFICE_TIN.key]: generateEightDigitString(),
    [defaultFields.PRESCRIBER_OFFICE_NPI.key]: generateEightDigitString(),
  }
);

export default generateFakePatientConfig;
