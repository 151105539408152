import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import moment from 'moment';
import Section from 'AuthorizationSharedComponents/Section';
import { SegmentTitleContainer, Container as SegmentContainer } from 'Segment/Segment';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import { withFormById } from '../../graphql/AuthorizationForm';
import ICDSelector from '../../components/ICDSelector';
import { setFormFields } from '../../reducers/formReducer';
import RequiredTag from '../../components/RequiredTag';
import { icdChangeHandler } from '../../util/requestDetailsChangeHandlers';
import BaseButton from '../../components/BaseButton';
import { DROPZONE_WIDTH } from '../../components/SamaDropzone';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const fields = CONFIG.DEFAULT_FIELDS;
const isFutureExpirationDate = config => (
  config[fields.PATIENT_CONSENT_EXPIRATION.key] && moment(config[fields.PATIENT_CONSENT_EXPIRATION.key]) > moment()
);
export class RequestDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { uploadNow: !isFutureExpirationDate(props.authorization.config) };
  }

  validateFields = () => {
    const { results, attachments, authorization } = this.props;
    const { uploadNow } = this.state;

    if (results[fields.GENENTECH_ENROLLMENT.key]) {
      return (results[fields.BENEFIT_INVESTIGATION.key] || results[fields.COPAY_ASSISTANCE.key])
        && !!results[fields.ALL_ICDS.key]
        // Doesn't need attachment or already has
        && (!_.isEmpty(attachments) || !uploadNow || isFutureExpirationDate(authorization.config));
    }

    return true;
  }

  submit = () => {
    const { triggerRequiredModalOnInvalid, nextStep } = this.props;

    if (this.validateFields()) {
      nextStep();
    } else {
      triggerRequiredModalOnInvalid();
    }
  }

  getPatientConsentSection = () => {
    const { uploadNow } = this.state;
    const { attachments, highlightRequiredFields, authorization, results } = this.props;

    const futureExpirationDate = isFutureExpirationDate(authorization.config);

    if (futureExpirationDate && !uploadNow) {
      return (
        <Section
          section={{ title: 'Patient Consent' }}
        >
          <div style={{ padding: '10px' }}>
            A valid patient consent form is on file. The patient consent will expire on
            { ` ${moment(results[fields.PATIENT_CONSENT_EXPIRATION.key]).format('MM/DD/YYYY')}` }
            <BaseButton
              style={{ width: `${DROPZONE_WIDTH}px` }}
              onClick={() => this.setState({ uploadNow: true })}
            >
              I will upload a patient consent form anyway
            </BaseButton>
          </div>
        </Section>
      );
    }

    if (!uploadNow) {
      return (
        <Section
          section={{ title: 'Patient Consent' }}
        >
          <div style={{ padding: '10px' }}>
            After submitting, you will need to log in to Access Solutions to upload the consent form.
          </div>
          <BaseButton style={{ width: `${DROPZONE_WIDTH}px` }} onClick={() => this.setState({ uploadNow: true })}>
            I want to upload my patient consent form now
          </BaseButton>
        </Section>
      );
    }

    return (
      <Section
        section={{
          items: [{
            key: 'Attachments',
            title: 'Please attach the patient consent form',
            type: CONFIG.CONSTANTS.FORM_TYPES.UPLOAD,
            notMultiple: true,
          }],
          title: 'Patient Consent',
        }}
        highlightAsMissing={highlightRequiredFields && _.isEmpty(attachments)}
        childrenAfterSection
      >
        { _.isEmpty(attachments) && (
          <BaseButton
            style={{ width: `${DROPZONE_WIDTH}px` }}
            onClick={() => this.setState({ uploadNow: false })}
          >
            I will upload a patient consent form later
          </BaseButton>
        ) }
      </Section>
    );
  }

  render() {
    const { set, back, authorization, disabled, highlightRequiredFields, results } = this.props;
    const noEnrollmentSelection = !results[fields.BENEFIT_INVESTIGATION.key] && !results[fields.COPAY_ASSISTANCE.key];

    return (
      <FormContainer>
        <Section
          section={{
            items: [[
              fields.REQUEST_DESCRIPTION,
              { ...fields.DATE_OF_SERVICE, required: false, futureOnly: true, title: 'Date of service (future dates only)' },
              fields.TREATMENT_HAS_STARTED,
            ]],
            title: 'Request Description (Internal Use Only, to help identify requests)',
          }}
        />
        {results[fields.GENENTECH_ENROLLMENT.key] && (
          <Section
            highlightAsMissing={highlightRequiredFields && noEnrollmentSelection}
            section={{
              items: [[fields.BENEFIT_INVESTIGATION, fields.COPAY_ASSISTANCE]],
              title: 'Requested Service Types (At least one is required)',
            }}
          />
        )}
        {results[fields.GENENTECH_ENROLLMENT.key] && (
          <Section section={{ title: 'Patient Diagnosis' }}>
            <SegmentContainer width="600px">
              <SegmentTitleContainer paddingleft="7px">
                ICD-10 Codes (Max: 3)
                <RequiredTag />
              </SegmentTitleContainer>
              <ICDSelector
                disabled={disabled}
                max={3}
                initializedICDs={authorization.ICDs}
                onChange={(newIcds) => { icdChangeHandler(newIcds, set); }}
                highlightRequiredFields={highlightRequiredFields}
              />
            </SegmentContainer>
          </Section>
        )}
        {results[fields.GOODDAYS_ENROLLMENT.key] && (
          <Section
            section={{
              items: [[fields.SOCIAL_SECURITY_NUMBER, fields.HOUSEHOLD_INCOME, fields.NUMBER_OF_DEPENDENTS]],
              title: 'Patient Financial Information',
            }}
          />
        )}
        { results[fields.GENENTECH_ENROLLMENT.key] && this.getPatientConsentSection() }
        <FormSubmitButtons back={back} submit={this.submit} />
      </FormContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    disabled: state.form.disabled,
    highlightRequiredFields: state.form.highlightRequiredFields,
    attachments: state.form.attachments,
  };
}

const mapDispatchToProps = dispatch => ({
  set(config) { dispatch(setFormFields(config)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(compose(withFormById)(RequestDetails));
