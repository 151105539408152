import styled from 'styled-components';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import { BaseText } from 'Segment/StyledComponents';
import { withUpsertForm } from '../../../../graphql/AuthorizationForm';
import BaseButton from '../../../../components/BaseButton';
import Modal from '../../../../components/Modal';
import { ModalContent } from '../../../../components/ModalStyledComponents';

const CustomModalContent = styled(ModalContent)`
  max-height: 500px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

class EditFormTitle extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { title: props.title, description: props.description };
  }

  upsertForm = async () => {
    const { id, upsertForm, clear, alert } = this.props;
    const { title, description } = this.state;
    try {
      await upsertForm({ variables: { formId: id, description, title } });
      alert.info('Successfully updated form name and description');
      clear();
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  }

  render() {
    const { clear } = this.props;
    const { title, description } = this.state;
    return (
      <Modal header="Edit Authorization Form Title and Description" onClick={() => { clear(); }} open>
        <CustomModalContent>
          <BaseText
            style={{ width: '500px', marginTop: '16px 0' }}
            onChange={(e) => { this.setState({ title: e.target.value }); }}
            value={title}
            placeholder="Title"
          />
          <BaseText
            style={{ width: '500px', margin: '8px 0' }}
            onChange={(e) => { this.setState({ description: e.target.value }); }}
            value={description}
            placeholder="Description"
          />
          <BaseButton style={{ width: '500px' }} onClick={this.upsertForm}>
            Save Changes
          </BaseButton>
        </CustomModalContent>
      </Modal>
    );
  }
}

export default compose(withUpsertForm)(withAlert(EditFormTitle));
