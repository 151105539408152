import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import Section from 'AuthorizationSharedComponents/Section';
import Creatable from 'react-select/lib/Creatable';
import { compose, withApollo } from 'react-apollo';
import { connect } from 'react-redux';

import RequiredTag from '../RequiredTag';
import AddDrugModal from '../../routes/NewAuthorization/InitializeSection/AddDrugModal';
import Modal from '../Modal';
import { withGetDrugOptionsQuery } from '../../graphql/DrugOptions';
import { setFormFields } from '../../reducers/formReducer';
import { dispatchSetDrugOptions } from '../../reducers/drugOptions';
import { withCurrentAccount } from '../../graphql/Account';

const Selector = styled(Creatable)`
  width: 600px;
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const defaultFields = CONFIG.DEFAULT_FIELDS;
export class PrimaryDrug extends PureComponent {
  state = { showJCodeModal: false }

  componentDidMount = async () => {
    const { setDrugOptions, drugOptionsLoaded, client, account } = this.props;

    if (!drugOptionsLoaded) {
      const options = await client.query({
        query: withGetDrugOptionsQuery,
        variables: { specialty: account.institution.practicingSpecialty },
        fetchPolicy: 'no-cache',
      });
      setDrugOptions(options.data.getDrugOptions);
    }
  }

  setDrug = (name = '', jcode = '', dose = '') => {
    const { set } = this.props;

    set({
      [defaultFields.PRIMARY_DRUG_NAME.key]: name,
      [defaultFields.HCPCS_0.key]: jcode,
      [defaultFields.ALL_HCPCS.key]: jcode,
      [defaultFields.PRIMARY_DOSE.key]: dose,
    });
  }

  render() {
    const { authorization, drugOptions, results } = this.props;
    const { showJCodeModal } = this.state;

    return (
      <>
        <Section
          section={{
            items: (
              results[defaultFields.PRIMARY_DRUG_NAME.key]
                ? [defaultFields.PRIMARY_DRUG_NAME, defaultFields.PRIMARY_DOSE, defaultFields.HCPCS_0]
                : []
            ),
            title: 'Primary drug',
          }}
        >
          { !results[defaultFields.PRIMARY_DRUG_NAME.key] && (
            <div>
              <SelectorTitle>
                Drug name (search by J-Code or Name)
                <RequiredTag />
              </SelectorTitle>
              <Selector
                options={drugOptions}
                onChange={(selected) => {
                  this.setDrug(_.get(selected, 'drugName'), _.get(selected, 'code'), _.get(selected, 'dose'));
                }}
                promptTextCreator={() => 'The drug I am looking for is missing'}
                onNewOptionClick={() => {
                  this.setState({ showJCodeModal: true });
                }}
              />
            </div>
          ) }
        </Section>
        { showJCodeModal && (
          <Modal header="Add a Drug to SamaCare" onClick={() => { this.setState({ showJCodeModal: false }); }} open>
            <AddDrugModal
              set={this.setDrug}
              authorizationId={authorization.id}
              close={() => { this.setState({ showJCodeModal: false }); }}
            />
          </Modal>
        ) }
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    drugOptions: state.drugOptions.options,
    drugOptionsLoaded: state.drugOptions.loaded,
  };
}

const mapDispatchToProps = dispatch => ({
  set(fields) { dispatch(setFormFields(fields)); },
  setDrugOptions(options) { dispatch(dispatchSetDrugOptions(options)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(withCurrentAccount)(withApollo(PrimaryDrug))
);
