import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import _ from 'lodash';
import colors from 'Resources/colors';

import { withForms, withReportMissingInformation } from '../../../graphql/AuthorizationForm';
import { withSetFavorite, withDeleteFavorite } from '../../../graphql/Favorite';
import MissingInformationModalContent from '../MissingInformationModalContent';
import FormContent from './FormContent';
import MissingFormButton from './MissingFormButton';
import LoadingSpinner from '../../../components/LoadingSpinner';
import BaseButton from '../../../components/BaseButton';

const TitleContainer = styled.div`
  font-weight: 500;
  padding: 10px 0;
  margin: 0px 5px 2px 5px;
  font-size: 16px;
  border-bottom: 2px solid ${props => props.theme.darkGray};
`;

const ScrollContainer = styled.div`
  max-height: 200px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const EmptyState = styled.div`
  font-size: 16px;
  margin: 20px;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  ${LoadingSpinner} {
    border-color: ${props => props.theme.purple};
    border-top-color: white;
    height: 50px;
    width: 50px;
  }
`;

export const VIEWS = {
  NO_FORMS: 'NO_FORMS',
  DEFAULT_FORMS: 'DEFAULT_FORMS',
  REFERRAL_FORMS: 'REFERRAL_FORMS',
  SECONDARY_FORMS: 'SECONDARY_FORMS',
  REQUEST_NEW_FORMS: 'REQUEST_NEW_FORMS',
  LOADING: 'LOADING',
};

export class FormList extends PureComponent {
  state = { requestNewForm: false, showSecondaryForms: false };

  getFormView = () => {
    const { forms, referralForms } = this.props;
    const { requestNewForm, showSecondaryForms } = this.state;

    if (requestNewForm) {
      return VIEWS.REQUEST_NEW_FORMS;
    }

    if (showSecondaryForms) {
      const { otherStateForms, suggestedParentForms, otherPayerForms } = forms;
      // This can happen if there are few secondary forms and they all get favorited
      if (_.isEmpty(otherStateForms) && _.isEmpty(otherPayerForms) && _.isEmpty(suggestedParentForms)) {
        return VIEWS.DEFAULT_FORMS;
      }
      return VIEWS.SECONDARY_FORMS;
    }

    if (!_.isEmpty(referralForms)) {
      return VIEWS.REFERRAL_FORMS;
    }

    if (_.isUndefined(forms)) {
      return VIEWS.LOADING;
    }

    if (_.get(forms, 'hasForms')) {
      const { stateForms, payerForms, suggestedForms, favoriteForms } = forms;
      if (!_.isEmpty([...stateForms, ...suggestedForms, ...payerForms, ...favoriteForms])) {
        return VIEWS.DEFAULT_FORMS;
      }
    }

    return VIEWS.NO_FORMS;
  }

  render() {
    const {
      onSelect,
      forms,
      insuranceName,
      authorizationId,
      state,
      insuranceCompanyId,
      referralForms,
      setFavorite,
      deleteFavorite,
    } = this.props;

    const view = this.getFormView();
    const FAVORITE_FORMS_TITLE = 'Favorited Forms';
    if (view === VIEWS.DEFAULT_FORMS || view === VIEWS.SECONDARY_FORMS) {
      const { stateForms, payerForms, suggestedForms, favoriteForms, otherPayerForms, otherStateForms, suggestedParentForms } = forms;
      let toShow = [
        { title: FAVORITE_FORMS_TITLE, forms: favoriteForms },
        { title: 'Suggested Forms', forms: suggestedForms },
        { title: `${insuranceName} Mandated Forms`, forms: payerForms },
        { title: 'State Mandated Forms', forms: stateForms },
      ];

      if (view === VIEWS.SECONDARY_FORMS) {
        toShow = [
          { title: 'Forms from different insurance types', forms: otherPayerForms },
          { title: 'Forms from different states', forms: otherStateForms },
          { title: 'Forms from parent insurance company', forms: suggestedParentForms },
        ];
      }
      return (
        <div>
          { view === VIEWS.SECONDARY_FORMS && (
            <EmptyState>
              Here are some other forms that may match your criteria
            </EmptyState>
          ) }
          { _.map(_.filter(toShow, 'forms.length'), config => (
            <div key={`form_selection_modal_${config.title}`}>
              <TitleContainer>
                { config.title }
                { ` (${config.forms.length} Forms)` }
              </TitleContainer>
              <ScrollContainer>
                { _.map(config.forms, (form, index) => (
                  <FormContent
                    index={index}
                    form={form}
                    onSelect={onSelect}
                    setFavorite={async (args) => {
                      await setFavorite(args);

                      if (view === VIEWS.SECONDARY_FORMS) {
                        this.setState({ showSecondaryForms: false });
                      }
                    }}
                    deleteFavorite={deleteFavorite}
                    isFavorite={config.title === FAVORITE_FORMS_TITLE}
                  />
                )) }
              </ScrollContainer>
            </div>
          )) }
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            { view === VIEWS.SECONDARY_FORMS && (
              <BaseButton
                onClick={() => { this.setState({ showSecondaryForms: false }); }}
                color={colors.gray}
                style={{ marginRight: 'auto' }}
              >
                Back
              </BaseButton>
            ) }
            {
              (view === VIEWS.SECONDARY_FORMS || (_.isEmpty(otherStateForms) && _.isEmpty(otherPayerForms) && _.isEmpty(suggestedParentForms)))
                ? (<MissingFormButton onClick={() => { this.setState({ requestNewForm: true }); }} />)
                : (
                  <BaseButton
                    style={{ marginLeft: 'auto' }}
                    onClick={() => { this.setState({ showSecondaryForms: true }); }}
                  >
                    See More Forms
                  </BaseButton>
                )
            }
          </div>
        </div>
      );
    }
    if (view === VIEWS.REFERRAL_FORMS) {
      return (
        <div>
          <TitleContainer>
            Referral Forms
            { ` (${referralForms.length} Forms)` }
          </TitleContainer>
          <ScrollContainer>
            { _.map(referralForms, (form, index) => (
              <FormContent
                index={index}
                form={form}
                onSelect={onSelect}
              />
            )) }
          </ScrollContainer>
          <MissingFormButton onClick={() => this.setState({ requestNewForm: true })} />
        </div>
      );
    }
    if (view === VIEWS.REQUEST_NEW_FORMS) {
      return (
        <MissingInformationModalContent
          authorizationId={authorizationId}
          onCancel={() => { this.setState({ requestNewForm: false }); }}
          insuranceCompanyId={insuranceCompanyId}
          state={state}
        />
      );
    }
    if (view === VIEWS.LOADING) {
      return (<LoadingContainer><LoadingSpinner /></LoadingContainer>);
    }

    return (
      <>
        <EmptyState>
          No forms were found matching the specified insurance and plan information. Please report the missing form using the button below.
        </EmptyState>
        <MissingFormButton onClick={() => this.setState({ requestNewForm: true })} />
      </>
    );
  }
}

export default compose(withForms, withReportMissingInformation, withSetFavorite, withDeleteFavorite)(FormList);
