import _ from 'lodash';

const SET_DRUG_OPTIONS = 'SET_DRUG_OPTIONS';

const initialState = { drugOptions: [], loaded: false };

export function dispatchSetDrugOptions(options) {
  const formattedOptions = _.map(options, (opt) => {
    const label = `[${opt.code}] ${opt.drugName} ${opt.dose}`;
    return {
      ...opt,
      value: label,
      label,
    };
  });

  return { type: SET_DRUG_OPTIONS, options: formattedOptions };
}

export default function drugOptions(state = initialState, action) {
  switch (action.type) {
    case SET_DRUG_OPTIONS:
      return { ...state, options: action.options, loaded: true };
    default:
      return state;
  }
}
