import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose, withApollo } from 'react-apollo';
import Select from 'react-select';
import styled from 'styled-components';
import moment from 'moment';
import { withAlert } from 'react-alert';

import CorrespondenceViewer from '../../../components/CorrespondenceViewer';
import UpdateAuthorizationStatusButton from '../../../components/UpdateAuthorizationStatusButton';
import BaseButton from '../../../components/BaseButton';
import Modal from '../../../components/Modal';
import { ModalFooter } from '../../../components/ModalStyledComponents';
import { reportError } from '../../../graphql/RequestHelp';
import { withUserCredentialByFollowUp } from '../../../graphql/Account';
import { withResolveSupportedFollowUp } from '../../../graphql/SupportedAuthorizationFollowUp';

const TwoPaneContainer = styled.div`
  width: 1250px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
`;

const Warning = styled.div`
  font-weight: 500;
  text-align: center;
  height: 400px;
  width: 100%;
`;

const HelperText = styled.div`
  margin: 10px 0;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.purple};
`;

const UpdateStatusButton = styled.div`
  width: 100%;
  padding: 10px;
  font-size: 16px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    width: 100%;
    height: 40px;
    margin: 0 50px;
  }
`;

const CredentialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
  ${BaseButton} {
    width: 150px;
  }
`;

const CredentialLabel = styled.div`
  width: 100px;
  font-size: 16px;
  font-weight: 500;
`;

const CredentialValue = styled.div`
  width: 100px;
  cursor: text;
  font-size: 16px;
  font-weight: 400;
`;

const { DATE_FORMAT } = CONFIG.CONSTANTS;

class FailedCheckerModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { selectedCorrespondenceId: _.get(props.authorization, 'latestCorrespondence.id', null) };
  }

  sendInternalEmail = async () => {
    const { authorization, followUpId, alert, client, onClose } = this.props;
    try {
      await client.mutate({
        mutation: reportError,
        variables: {
          title: 'Failed Status Check: Eng Requested',
          internal: true,
          message: `Authorization ID: ${authorization.id}<br /><br />SupportedFollowUp ID: ${followUpId}<br /><br />Portal Key: ${authorization.portalKey}` },
      });
      alert.info('Success');
      onClose();
    } catch {
      alert.error('There was an error');
    }
  };

  resolve = async () => {
    const { resolveSupportedFollowUp, alert, followUpId, onClose } = this.props;

    const status = CONFIG.CONSTANTS.SUPPORT_AUTHORIZATION_FOLLOWUP_STATUS.MUTE;
    try {
      await resolveSupportedFollowUp({ variables: { id: followUpId, status, details: '' } });
      alert.info('Success');
      onClose();
    } catch {
      alert.error('There was an error');
    }
  }

  render() {
    const { onClose, authorization, userCredential } = this.props;
    const { selectedCorrespondenceId, showUsername, showPassword } = this.state;
    const onlyImages = _.reject(_.sortBy(authorization.correspondences, ['isLatest', 'createdAt']), { fileURL: null });
    const augmentedCorrespondences = _.map(
      onlyImages,
      (correspondence, i) => ({ ...correspondence, value: correspondence.id, label: `${i + 1} - ${moment(correspondence.createdAt).format(DATE_FORMAT)}` })
    );
    const selectedCorrespondence = _.find(augmentedCorrespondences, { id: selectedCorrespondenceId });

    return (
      <Modal onClick={onClose} open header="Failed Status Check">
        <TwoPaneContainer>
          {_.isEmpty(onlyImages)
            ? <Warning>This Authorization Does Not Have Viewable Correspondences</Warning>
            : <CorrespondenceViewer correspondence={selectedCorrespondence} width="700px" height="450px" />}
          <Column>
            <HelperText>
              {`This authorization was not found in the ${authorization.portalKey} system.\n`
              + 'Use the dropdown menu to view all correspondences associated with this authorization.\n'
              + 'You can update the status of this auth or forward this to engineering for review below.'}
            </HelperText>
            <StyledSelect
              value={selectedCorrespondence}
              valueKey="id"
              onChange={(correspondence) => { this.setState({ selectedCorrespondenceId: correspondence.id }); }}
              options={augmentedCorrespondences}
              clearable={false}
              searchable={false}
              placeholder="View Correspondences for this Authorization"
            />
            <UpdateAuthorizationStatusButton
              authorization={authorization}
              styleOverrides={{ width: '100%', margin: '10px 0' }}
              onUpdateSuccess={this.resolve}
            >
              <UpdateStatusButton>
                Current status
                <b>
                  { ` : ${authorization.status}` }
                </b>
                . Click to update
              </UpdateStatusButton>
            </UpdateAuthorizationStatusButton>
            {userCredential && (
              <div>
                <HelperText>SENSITIVE DATA BELOW - Hover over credentials to reveal, or click the copy button to copy the field to your clipboard.</HelperText>
                <CredentialContainer>
                  <div style={{ fontSize: '16px' }}>
                    {`Login Information for ${authorization.portalKey} ${userCredential.hub ? ` - Hub: (${userCredential.hub})` : ''}`}
                  </div>
                </CredentialContainer>
                <CredentialContainer>
                  <CredentialLabel>Username:</CredentialLabel>
                  <CredentialValue // eslint-disable-line jsx-a11y/mouse-events-have-key-events
                    onMouseOver={() => this.setState({ showUsername: true })}
                    onMouseLeave={() => this.setState({ showUsername: false })}
                  >
                    {showUsername ? userCredential.username : '*******'}
                  </CredentialValue>
                  <BaseButton onClick={() => { navigator.clipboard.writeText(userCredential.username); }}>Copy Username</BaseButton>
                </CredentialContainer>
                <CredentialContainer>
                  <CredentialLabel>Password:</CredentialLabel>
                  <CredentialValue // eslint-disable-line jsx-a11y/mouse-events-have-key-events
                    onMouseOver={() => this.setState({ showPassword: true })}
                    onMouseLeave={() => this.setState({ showPassword: false })}
                  >
                    {showPassword ? userCredential.password : '*******'}
                  </CredentialValue>
                  <BaseButton onClick={() => { navigator.clipboard.writeText(userCredential.password); }}>Copy Password</BaseButton>
                </CredentialContainer>
              </div>
            )}
            <CustomModalFooter>
              <BaseButton onClick={this.resolve}>Resolve Follow Up</BaseButton>
              <BaseButton onClick={this.sendInternalEmail}>Forward To Eng</BaseButton>
            </CustomModalFooter>
          </Column>
        </TwoPaneContainer>
      </Modal>
    );
  }
}

export default compose(withResolveSupportedFollowUp, withUserCredentialByFollowUp)(withApollo(withAlert(FailedCheckerModal)));
