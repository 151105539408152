import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import Section from 'AuthorizationSharedComponents/Section';
import { withAlert } from 'react-alert';

import { transformSections } from '../../../../util/sectionConfigurationManager';
import BaseButton from '../../../BaseButton';
import { withUpsertAccount } from '../../../../graphql/Account';

const CreateButton = styled(BaseButton)`
  height: 30px;
  width: 250px;
  margin: 100px 0 0 -3px;
`;

const dFields = CONFIG.DEFAULT_FIELDS;
const segmentItems = [
  dFields.REQUESTED_BY,
  dFields.REQUESTED_BY_PHONE,
  dFields.REQUESTED_BY_EXTENSION,
];

const segmentItemsEnrollments = [
  dFields.REQUESTED_BY_FIRST_NAME,
  dFields.REQUESTED_BY_LAST_NAME,
  dFields.REQUESTED_BY_PHONE,
  dFields.REQUESTED_BY_EXTENSION,
];

class DetailsForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { phone: props.results[dFields.REQUESTED_BY_PHONE.key], extension: props.results[dFields.REQUESTED_BY_EXTENSION.key] };
  }

  componentDidUpdate() {
    /* eslint-disable react/no-did-update-set-state */
    const { results } = this.props;
    const { phone, extension } = this.state;

    if (_.isNil(phone)) {
      this.setState({ phone: results[dFields.REQUESTED_BY_PHONE.key] });
    }

    if (_.isNil(extension)) {
      this.setState({ extension: results[dFields.REQUESTED_BY_EXTENSION.key] });
    }
    /* eslint-enable react/no-did-update-set-state */
  }

  handleUpdateRequestedByDetails = async () => {
    const { alert, upsertAccount, account, results, set } = this.props;
    const { phone, extension } = this.state;

    try {
      const hasUpdatedPhone = phone !== results[dFields.REQUESTED_BY_PHONE.key];
      const hasUpdatedExtension = extension !== results[dFields.REQUESTED_BY_EXTENSION.key];

      let formattedPhone;
      if (hasUpdatedPhone) {
        const number = results[dFields.REQUESTED_BY_PHONE.key];
        formattedPhone = `${number.slice(0, 3)}-${number.slice(3, 6)}-${number.slice(6)}`;
        set({ [dFields.REQUESTED_BY_PHONE.key]: formattedPhone });
      }

      if (hasUpdatedExtension) {
        set({ [dFields.REQUESTED_BY_EXTENSION.key]: results[dFields.REQUESTED_BY_EXTENSION.key] });
      }

      await upsertAccount({ variables: {
        id: account.id,
        patch: {
          firstName: account.firstName,
          lastName: account.lastName,
          email: account.email,
          ...(hasUpdatedPhone ? { phone: formattedPhone } : {}),
          ...(hasUpdatedExtension ? { extension: results[dFields.REQUESTED_BY_EXTENSION.key] } : {}),
        },
      } });
      alert.success('Successfully saved your phone number updates!');
    } catch {
      alert.error('There was an error saving your phone number updates');
    }
  }

  render() {
    const { disabled, authorization, results } = this.props;
    const { phone, extension } = this.state;

    return (
      <div style={{ display: 'flex' }}>
        <Section
          section={
            transformSections([{
              items: [!authorization.isPatientEnrollment ? segmentItems : segmentItemsEnrollments],
              title: 'Requested By',
            }], authorization.formFieldConfig)[0]
          }
          disabled={disabled}
        />
        { ((phone !== results[dFields.REQUESTED_BY_PHONE.key]) || (extension !== results[dFields.REQUESTED_BY_EXTENSION.key])) && (
          <CreateButton onClick={this.handleUpdateRequestedByDetails}>Save Phone Number Updates</CreateButton>
        ) }
      </div>
    );
  }
}

export default (compose(withUpsertAccount))(withAlert(DetailsForm));
