import _ from 'lodash';
import styled from 'styled-components';
import React, { PureComponent } from 'react';
import AddCircle from 'react-icons/lib/md/add-circle-outline';
import RemoveCircle from 'react-icons/lib/md/remove-circle-outline';
import Delete from 'react-icons/lib/md/delete';
import { push } from 'connected-react-router';
import moment from 'moment';
import { connect } from 'react-redux';

import RequestStatus from '../../components/MainList/RequestStatus';
import { getDescription } from '../Authorizations/getTableCells';
import DeleteAuthorizationButton from '../../components/DeleteAuthorizationButton';
import AuthorizationDetails from '../../components/MainList/AuthorizationDetails';
import CheckBalanceButton from './CheckBalanceButton';
import ROUTE_PATHS from '../ROUTE_PATHS';

const { AUTHORIZATION_STATUSES, CORRESPONDENCE_LABEL_TYPES } = CONFIG.CONSTANTS;
const columns = ['description', 'status', 'enrollmentDetails'];

const Row = styled.tr`
  td {
    padding: 5px;
  }
`;

const ShortContainer = styled.div`
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const LongContainer = styled.div`
  width: 500px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Cell = styled.div`
  padding: 5px;
`;

const IconContainer = styled(Cell)`
  color: ${props => props.theme.purple};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;

  :hover {
    cursor: pointer;
    color: ${props => props.theme.lightPurple};
  }
`;

const BIStatusContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: ${props => props.isWarning ? props.theme.warningRed : props.theme.darkGray};
  max-width: 300px;
`;

const BIStatusDate = styled.div`
  font-size: 14px;
  color: ${props => props.theme.darkGray};
`;

const EnrollmentRow = styled.td`
  td {
    padding: 5px;
  }

  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.lightGray};
  }
`;

const DeleteButton = styled(IconContainer)`
  color: ${props => props.theme.presubmissionPink};

  :hover {
    color: ${props => props.theme.lightGray};
  }
`;

const getTableCells = enrollment => (
  _.map(columns, (column) => {
    switch (column) {
      case 'description':
        return (
          <td>
            <ShortContainer key={`description-id-${enrollment.id}`}>
              {
                getDescription(
                  enrollment.config[CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key],
                  enrollment.config[CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key]
                ) || 'No description provided'
              }
            </ShortContainer>
          </td>
        );
      case 'status':
        return (
          <td>
            <ShortContainer>
              <RequestStatus key={`enrollmentStatusIndicator-id-${enrollment.id}`} authorization={enrollment} />
            </ShortContainer>
          </td>
        );
      case 'enrollmentDetails':
        return (
          <td>
            <LongContainer>
              <AuthorizationDetails key={`EnrollmentDetailsFor_${enrollment.id}`} authorization={enrollment} />
            </LongContainer>
          </td>
        );
      default:
        throw new Error(`Unexpected column type ${column}`);
    }
  })
);

const getLatestEnrollmentByConfigKey = (enrollments, key) => (
  _.last(_.sortBy(_.filter(
    enrollments,
    ({ config }) => (config[key])
  ), 'sortByTimestamp'))
);

export const getBalanceContent = (enrollments) => {
  const balanceChecks = _.filter(_.flatten(_.map(enrollments, 'correspondences')), { label: CORRESPONDENCE_LABEL_TYPES.GOODDAYS_BALANCE_CHECK });
  const latestBalanceCheck = _.last(_.sortBy(balanceChecks, 'createdAt'));

  if (latestBalanceCheck) {
    return (
      <BIStatusContainer>
        <div>{ latestBalanceCheck.authorizedProcedures[0].value }</div>
        <BIStatusDate>{ moment(latestBalanceCheck).format('MM/DD/YY') }</BIStatusDate>
      </BIStatusContainer>
    );
  }

  return null;
};

export const getStatusContent = (
  enrollment,
  correspondenceLabel,
  { noEnrollmentMessage, defaultMessage, draftMessage, completeMessage, actionRequired },
) => {
  const { status } = enrollment;

  let statusText = '';
  let dateText = '';

  if (!status) {
    statusText = noEnrollmentMessage;
    dateText = null;
  } else {
    const latestSuccessfulCorr = _.find(
      enrollment.correspondences,
      { label: correspondenceLabel },
    );
    dateText = moment(_.get(latestSuccessfulCorr, 'createdAt') || enrollment.updatedAt).format('MM/DD/YY');

    if (latestSuccessfulCorr) {
      statusText = completeMessage;
    } else if (status === AUTHORIZATION_STATUSES.ACTION_REQUIRED) {
      statusText = actionRequired;
    } else if (AUTHORIZATION_STATUSES.PRESUBMISSION === status) {
      statusText = draftMessage;
    } else {
      statusText = defaultMessage;
    }
  }

  return (
    <BIStatusContainer isWarning={statusText === actionRequired}>
      <div>{ statusText }</div>
      { dateText && (<BIStatusDate>{ dateText }</BIStatusDate>) }
    </BIStatusContainer>
  );
};

export class PatientEnrollmentListManager extends PureComponent {
  constructor(props) {
    super(props);
    const { enrollments } = this.props;

    this.state = {
      expanded: false,
      latestAccessSolutionsEnrollment: getLatestEnrollmentByConfigKey(enrollments, CONFIG.DEFAULT_FIELDS.GENENTECH_ENROLLMENT.key) || {},
      latestGooddaysEnrollment: getLatestEnrollmentByConfigKey(enrollments, CONFIG.DEFAULT_FIELDS.GOODDAYS_ENROLLMENT.key) || {},
    };
  }

  handleRowClick = (enrollment, shouldOpenInBlank) => {
    const { toEnrollment, alwaysOpenInBlank } = this.props;

    toEnrollment(
      enrollment.id,
      enrollment.formDetails.currentStep,
      ROUTE_PATHS.PATIENT_ENROLLMENT,
      alwaysOpenInBlank || shouldOpenInBlank
    );
  };

  getStatusContainer = (statusText, dateText) => (
    <BIStatusContainer>
      <div>{ statusText }</div>
      { dateText && (<BIStatusDate>{ dateText }</BIStatusDate>) }
    </BIStatusContainer>
  )

  getGooddaysContent = () => {
    const { enrollments } = this.props;
    const { latestGooddaysEnrollment } = this.state;

    return getBalanceContent(enrollments) || getStatusContent(
      latestGooddaysEnrollment,
      CORRESPONDENCE_LABEL_TYPES.GOODDAYS_ENROLLMENT,
      {
        defaultMessage: 'Pending',
        draftMessage: 'Drafted, not submitted',
        completeMessage: 'Copay Enrollment Success',
        actionRequired: 'Action Required',
        noEnrollmentMessage: 'N / A',
      },
    );
  }

  getBIContent = () => {
    const { latestAccessSolutionsEnrollment } = this.state;
    return getStatusContent(
      latestAccessSolutionsEnrollment,
      CORRESPONDENCE_LABEL_TYPES.ACCESS_SOLUTIONS_BI,
      {
        defaultMessage: 'Pending',
        draftMessage: 'Drafted, not submitted',
        completeMessage: 'BI Received',
        actionRequired: 'Action Required',
        noEnrollmentMessage: 'N / A',
      },
    );
  }

  getCells = () => {
    const { enrollments, patient } = this.props;
    const { expanded } = this.state;

    let patientName = 'No Patient Assigned';
    if (patient) {
      patientName = `${patient.lastName}, ${patient.firstName}`;
    }

    const latestEnrollment = _.last(_.sortBy(_.reject(enrollments, { submittedAt: null }), 'submittedAt'));

    return [
      (
        <IconContainer key={`${_.get(patient, 'id', 'undefPatient')}_expandIcon`} onClick={() => { this.setState({ expanded: !expanded }); }}>
          { expanded ? (<RemoveCircle />) : (<AddCircle />) }
        </IconContainer>
      ),
      patientName,
      this.getGooddaysContent(),
      this.getBIContent(),
      (latestEnrollment && (<CheckBalanceButton key={`${_.get(patient, 'id', 'undefPatient')}_checkBalance`} authorization={latestEnrollment} />)),
    ];
  }

  render() {
    const { enrollments, patient } = this.props;
    const { expanded } = this.state;

    const cells = this.getCells();
    return (
      <>
        <Row>
          { _.map(cells, (cellContent, i) => (<td key={`${_.get(patient, 'id', 'undefPatient')}_${i}`}><Cell>{ cellContent }</Cell></td>)) }
        </Row>
        { expanded && (
          _.map(
            enrollments,
            enrollment => (
              <tr>
                <td>
                  {
                    enrollment.status === AUTHORIZATION_STATUSES.PRESUBMISSION
                    && (
                      <DeleteAuthorizationButton id={enrollment.id} isReferralOrPatientEnrollment>
                        <DeleteButton>
                          <Delete />
                        </DeleteButton>
                      </DeleteAuthorizationButton>
                    )
                  }
                </td>
                <EnrollmentRow colSpan={cells.length - 1} onClick={() => { this.handleRowClick(enrollment); }}>
                  <table style={{ width: '100%' }}>
                    <tbody>
                      <tr>{ getTableCells(enrollment) }</tr>
                    </tbody>
                  </table>
                </EnrollmentRow>
              </tr>
            ),
          )
        ) }
      </>
    );
  }
}

const mapDispatchProps = dispatch => ({
  toEnrollment(id, step, routePrefix, shouldOpenInBlank) {
    const route = `${routePrefix}?step=${step || 1}&id=${id}`;
    if (shouldOpenInBlank) {
      window.open(`#${route}`, '_blank');
    } else {
      dispatch(push(route));
    }
  },
});

export default connect(() => ({}), mapDispatchProps)(PatientEnrollmentListManager);
