import React, { PureComponent } from 'react';
import styled from 'styled-components';
import * as FullStory from '@fullstory/browser';

import { WhiteOpaqueOverlay, SelectionOverOverlay } from './Overlay';
import BaseButton from '../../../../components/BaseButton';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectionOverlay = styled(SelectionOverOverlay)`
  width: 600px;
  padding: 20px 10px;
  margin-top: 100px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectionHeader = styled.div`
  text-align: center;
  font-size: 20px;
  color: ${props => props.theme.purple};
  margin-bottom: 20px;
`;

const ActionButton = styled(BaseButton)`
  width: 200px;
  margin: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const { INTEGRATION_MESSAGE_TYPES, LIVE_CONNECTION_ACTIONS } = CONFIG.CONSTANTS;
const { LIVE_CONNECTION_MESSAGE } = INTEGRATION_MESSAGE_TYPES;

class SubmissionOverlay extends PureComponent {
  state = {};

  componentDidMount = async () => {
    const { authorization } = this.props;

    if (CONFIG.NODE_ENV === 'production' && authorization) {
      FullStory.event('Portal Submission Page Detected', {
        authorizationId: authorization.id,
        userId: authorization.createdById,
        portalKey: authorization.portalKey,
      });
    }
  }

  handleAuthorizationSubmitted = () => {
    const { sendIntegrationMessage, back, automatedSubmissionStatus } = this.props;

    sendIntegrationMessage({
      message: LIVE_CONNECTION_MESSAGE,
      type: LIVE_CONNECTION_ACTIONS.COMPLETE,
      data: { status: automatedSubmissionStatus, isAutomatedSubmissionCheck: true },
    });
    back();
  };

  handleFalseDetection = () => {
    const { authorization, back } = this.props;

    if (CONFIG.NODE_ENV === 'production' && authorization) {
      FullStory.event('False Portal Submission Page Detection', {
        authorizationId: authorization.id,
        userId: authorization.createdById,
        portalKey: authorization.portalKey,
      });
    }
    back();
  }

  render() {
    const { authorization } = this.props;

    return (
      <Container>
        <WhiteOpaqueOverlay />
        <SelectionOverlay>
          <SelectionHeader>Authorization Submission Detected</SelectionHeader>
          <TextContainer>
            { `It seems like you've just submitted the authorization to ${authorization.portalKey} - is this correct?` }
          </TextContainer>
          <ButtonContainer>
            <ActionButton onClick={this.handleAuthorizationSubmitted}>Yes</ActionButton>
            <ActionButton onClick={this.handleFalseDetection}>No</ActionButton>
          </ButtonContainer>
        </SelectionOverlay>
      </Container>
    );
  }
}

export default SubmissionOverlay;
