import React from 'react';
import styled from 'styled-components';
import FavoriteIcon from 'react-icons/lib/md/favorite';

const FavoriteShared = styled(FavoriteIcon)`
  margin-right: 3px;
  margin-bottom: 2px;
  height: 20px;
  width: 20px;
  cursor: pointer;
`;

const SetFavoriteStyled = styled(FavoriteShared)`
  color: ${props => props.theme.darkGray};
  &:hover {
    color: ${props => props.theme.purple};
  }
`;

const DeleteFavoriteStyled = styled(FavoriteShared)`
  color: ${props => props.theme.purple};

  &:hover {
    color: ${props => props.theme.darkGray};
  }
`;

const Favorite = ({
  isFavorite, setFavorite, deleteFavorite, formId, numberId,
}) => (
  <>
    {
      isFavorite
        ? (
          <DeleteFavoriteStyled
            onClick={() => { deleteFavorite({ variables: { authorizationFormId: formId, formNumberId: numberId } }); }}
          />
        )
        : (
          <SetFavoriteStyled
            onClick={() => { setFavorite({ variables: { authorizationFormId: formId, formNumberId: numberId } }); }}
          />
        )
    }
  </>
);

export default Favorite;
