import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import CheckIcon from 'react-icons/lib/md/check';
import ErrorIcon from 'react-icons/lib/md/error-outline';
import { Pill } from '../SharedStyles';

const Cell = styled.td`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const SoftText = styled.div`
  font-size: 14px;
  color: ${props => props.theme.darkGray};
  margin-bottom: 4px;
`;

const PatientCredentialInstitutionCell = ({ name, errorLogs }) => {
  const showErrors = !_.isEmpty(errorLogs) && _.every(errorLogs, ({ success }) => success === false);
  return (
    <Cell>
      <div style={{ display: 'flex', marginBottom: '8px' }}>
        <div style={{ margin: '0 8px 2px 0' }}>{name}</div>
        {!!_.size(errorLogs) && (!showErrors ? <CheckIcon color="green" /> : <ErrorIcon color="red" />)}
      </div>
      <SoftText>
        {!_.isEmpty(errorLogs)
          ? `Last ran on ${moment(_.last(errorLogs).createdAt).format('LL')}`
          : 'Credential has not been run'}
      </SoftText>
      {showErrors && (
        <div>
          <SoftText>Error Dates:</SoftText>
          {_.map(errorLogs, log => <Pill key={log.id}>{moment(log.createdAt).format('M/D')}</Pill>)}
        </div>
      )}
    </Cell>
  );
};

export default PatientCredentialInstitutionCell;
