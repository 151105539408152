import React, { PureComponent } from 'react';
import styled from 'styled-components';
import strings from 'Resources/strings';
import Segment from 'Segment/Segment';
import { withAlert } from 'react-alert';

import Modal from '../Modal';
import BaseButton from '../BaseButton';
import CustomCheckbox from '../CustomCheckbox';
import { ModalBodyLarge, ModalContent } from '../ModalStyledComponents';
import { BaseText } from '../Segment/StyledComponents';

const GrayButton = styled(BaseButton)`
  background-color: ${props => props.theme.lightGray};
  border-color: ${props => props.theme.lightGray};
  color: white;
  width: 120px;
  margin-left: 10px;
  padding: 5px 10px;
`;

const CustomModalContent = styled(ModalContent)`
  margin: 20px 0;
  font-size: 16px;
`;

const ModalButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 300px;
  margin-left: auto;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin-left: 5px;
    padding: 10px;
  }
`;

const QuestionSection = styled.div`
  margin: 5px 0 2px 0;
  font-weight: bold;
  font-size: 14px;
  text-align: left;

  ${BaseText} {
    font-size: 16px;
  }
`;

const CheckboxSection = styled(QuestionSection)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SHOULD_INCLUDE_CL = 'SHOULD_INCLUDE_CL';
const SET_ADDITIONAL_NOTES = 'SET_ADDITIONAL_NOTES';
const SUPPORTED_CL = 'SUPPORTED_CL';
const getFirstDisplay = authorization => (authorization.isSupported ? SUPPORTED_CL : SHOULD_INCLUDE_CL);
export class CoverLetterModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      display: getFirstDisplay(props.authorization),
      directionsForUse: '',
      quantity: props.authorization.config[CONFIG.DEFAULT_FIELDS.HCPCS_QUANTITY_0.key],
      refills: '',
      cancelPriorRequests: false,
      priorRequestNumber: '',
    };
  }

  supportedCLSubmit = () => {
    const { set, alert } = this.props;
    const { directionsForUse, quantity, refills, cancelPriorRequests, priorRequestNumber } = this.state;

    if (directionsForUse && quantity && refills) {
      let notesString = `Directions: ${directionsForUse}\nQuantity Requested: ${quantity}\nNumber of Refills: ${refills}`;
      if (cancelPriorRequests) {
        if (priorRequestNumber) {
          notesString = `If there is a conflict, please void Authorization ${priorRequestNumber} for this patient\n${notesString}`;
        } else {
          notesString = `If there is a conflict, please void prior Authorizations for this patient\n${notesString}`;
        }
      }

      set({ [CONFIG.DEFAULT_FIELDS.COVER_LETTER_NOTE.key]: notesString });
      this.setState({ display: SET_ADDITIONAL_NOTES });
    } else {
      alert.info('Please fill out all of the above fields');
    }
  }

  getContent = () => {
    const { set, close, submit, authorization } = this.props;
    const { display, directionsForUse, quantity, refills, cancelPriorRequests, priorRequestNumber } = this.state;

    if (display === SET_ADDITIONAL_NOTES) {
      return (
        <>
          <CustomModalContent>
            <Segment
              item={CONFIG.DEFAULT_FIELDS.COVER_LETTER_NOTE}
              set={set}
            />
          </CustomModalContent>
          <ModalButtonContainer>
            <GrayButton
              onClick={() => { this.setState({ display: getFirstDisplay(authorization) }); }}
            >
              Cancel
            </GrayButton>
            <BaseButton
              onClick={() => { submit(true); }}
            >
              Submit
            </BaseButton>
          </ModalButtonContainer>
        </>
      );
    }

    if (display === SUPPORTED_CL) {
      return (
        <>
          <CustomModalContent>
            <div style={{ marginBottom: '10px' }}>
              The following information will help ensure a timely approval for this authorization.
            </div>
            <CheckboxSection>
              <CustomCheckbox
                onChange={() => { this.setState({ cancelPriorRequests: !cancelPriorRequests }); }}
                checked={cancelPriorRequests}
              />
              <div style={{ marginLeft: 10 }}>I would like this request to cancel prior authorizations for this patient</div>
            </CheckboxSection>
            { cancelPriorRequests && (
              <QuestionSection>
                Authorization # of authorization to cancel
                <BaseText
                  onChange={(e) => { this.setState({ priorRequestNumber: e.target.value }); }}
                  placeholder="Prior"
                  style={{ marginLeft: 0 }}
                  value={priorRequestNumber || ''}
                />
              </QuestionSection>
            )}
            <QuestionSection>
              Directions For Use
              <BaseText
                onChange={(e) => { this.setState({ directionsForUse: e.target.value }); }}
                placeholder="Directions for use"
                style={{ marginLeft: 0 }}
                value={directionsForUse || ''}
              />
            </QuestionSection>
            <QuestionSection>
              Quantity
              <BaseText
                onChange={(e) => { this.setState({ quantity: e.target.value }); }}
                placeholder="Quantity"
                style={{ marginLeft: 0 }}
                value={quantity || ''}
              />
            </QuestionSection>
            <QuestionSection>
              Refills Requested
              <BaseText
                onChange={(e) => { this.setState({ refills: e.target.value }); }}
                placeholder="Refills"
                style={{ marginLeft: 0 }}
                value={refills || ''}
              />
            </QuestionSection>
          </CustomModalContent>
          <ModalButtonContainer>
            <GrayButton
              onClick={close}
            >
              Close
            </GrayButton>
            <BaseButton
              onClick={this.supportedCLSubmit}
            >
              Next
            </BaseButton>
          </ModalButtonContainer>
        </>
      );
    }

    return (
      <>
        <CustomModalContent>{strings.NEW_AUTHORIZATIONS.COVER_SHEET_MODAL_DESCRIPTION}</CustomModalContent>
        <ModalButtonContainer>
          <GrayButton
            onClick={() => { submit(false); }}
          >
            {strings.COMMON.NO}
          </GrayButton>
          <BaseButton
            onClick={() => { this.setState({ display: SET_ADDITIONAL_NOTES }); }}
          >
            {strings.COMMON.YES}
          </BaseButton>
        </ModalButtonContainer>
      </>
    );
  }

  render() {
    return (
      <Modal
        header="Attach Cover Letter"
        onClick={this.toggleModal}
        open
      >
        <ModalBodyLarge>
          { this.getContent() }
        </ModalBodyLarge>
      </Modal>
    );
  }
}

export default withAlert(CoverLetterModal);
