import _ from 'lodash';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import authorizationInfo from './fragments/authorizationInfo';
import { withDefaultProps } from './utils';

const SUPPORTED_FOLLOW_UPS_QUERY_NAME = 'SUPPORTED_FOLLOW_UPS_QUERY_NAME';

export const withSupportedAuthorizationFollowUps = graphql(
  gql`
    query ${SUPPORTED_FOLLOW_UPS_QUERY_NAME} {
      getUnresolvedFollowUps {
        id
        AuthorizationId
        isSupportedDrug
        isFailedStatusCheck
        authorization {
          ...authorizationInfo
        }
      }
    }
    ${authorizationInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({ supportedAuthorizationFollowUps: _.get(data, 'getUnresolvedFollowUps') })),
    options: { fetchPolicy: 'network-only' },
  }
);

export const withResolveSupportedFollowUp = graphql(
  gql`
    mutation resolveSupportedFollowUp($id: Int!, $details: String!, $status: String!) {
      resolveSupportedFollowUp(id: $id, status: $status, details: $details) {
        id
      }
    }
  `,
  {
    name: 'resolveSupportedFollowUp',
    options: () => ({ refetchQueries: () => [SUPPORTED_FOLLOW_UPS_QUERY_NAME] }),
  },
);

export const withResolveCareMetxFollowUp = graphql(
  gql`
    mutation resolveCareMetxFollowUp($id: Int!, $status: String!, $formId: ID, $InsuranceCompanyId: ID) {
      resolveCareMetxFollowUp(id: $id, status: $status, formId: $formId, InsuranceCompanyId: $InsuranceCompanyId) {
        id
      }
    }
  `,
  {
    name: 'resolveCareMetxFollowUp',
    options: () => ({ refetchQueries: () => [SUPPORTED_FOLLOW_UPS_QUERY_NAME] }),
  },
);

export const withCreateSupportedFollowUp = graphql(
  gql`
    mutation createSupportedFollowUp($id: Int!) {
      createSupportedFollowUp(id: $id) {
        id
      }
    }
  `,
  { name: 'createSupportedFollowUp' },
);
