import React, { PureComponent } from 'react';
import styled from 'styled-components';
import AuthIcon from 'react-icons/lib/md/assignment';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import { Checkbox } from 'Segment/SegmentCheckbox';

import IconButton, { IconButtonStyled } from '../../components/IconButton';
import MenuButton from '../../components/MenuButton';
import { withCurrentAccount } from '../../graphql/Account';
import { withCreateAuthorization } from '../../graphql/Authorization';
import BaseButton from '../../components/BaseButton';
import Modal from '../../components/Modal';
import { ModalBody, ModalFooter, ModalContent } from '../../components/ModalStyledComponents';

const CustomModalContent = styled(ModalContent)`
  padding: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  ${BaseButton} {
    width: 200px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  font-size: 16px;
  margin-left: 10px;
  display: flex;
  align-items: center;

  ${IconButtonStyled} {
    background-color: ${props => props.theme.green};
    border: 1px solid ${props => props.theme.green};

    &:hover {
      background-color: white;
      color: ${props => props.theme.green};
    }
  }
`;

const MenuItems = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 300;

  :hover {
    cursor: pointer;
    background-color: ${props => props.theme.lightGray};
  }
`;

const MenuText = styled.p`
  text-align: center;
  width 100px;
  margin: 2px 5px;
`;

const Logo = styled.img`
  height: 20px;
  width: 100px;
`;

const OptionContainer = styled.div`
  margin: auto;
  width: 80%;
  cursor: pointer;
  display: flex;
  padding: 5px 0;
`;

const CheckLabel = styled.label`
  cursor: pointer;
  text-align: left;
  margin-top: 3px;
  margin-left: 10px;
`;

export class PatientEnrollmentsList extends PureComponent {
  state = { showAccessSolutionsModal: false, goodDays: false, BI: false, copay: false }

  createNewEnrollment = async (jcode, drugName) => {
    const { navigateMethod, account, createAuthorization, alert } = this.props;
    const { goodDays, BI, copay } = this.state;

    const defaultFields = {
      status: CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION,
      config: {
        [CONFIG.DEFAULT_FIELDS.REQUESTED_BY.key]: `${account.firstName} ${account.lastName}`,
        [CONFIG.DEFAULT_FIELDS.REQUESTED_BY_FIRST_NAME.key]: account.firstName,
        [CONFIG.DEFAULT_FIELDS.REQUESTED_BY_LAST_NAME.key]: account.lastName,
        [CONFIG.DEFAULT_FIELDS.REQUESTED_BY_PHONE.key]: account.phone,
        [CONFIG.DEFAULT_FIELDS.REQUESTED_BY_EXTENSION.key]: account.extension,
        [CONFIG.DEFAULT_FIELDS.EMAIL_NOTIFICATION_TYPE.key]: 'On',
        [CONFIG.DEFAULT_FIELDS.DEFAULT_CHECKMARK_TRUE.key]: 'On',
        [CONFIG.DEFAULT_FIELDS.HCPCS_0.key]: jcode,
        [CONFIG.DEFAULT_FIELDS.ALL_HCPCS.key]: jcode,
        [CONFIG.DEFAULT_FIELDS.PRIMARY_DRUG_NAME.key]: drugName,
        [CONFIG.DEFAULT_FIELDS.GOODDAYS_ENROLLMENT.key]: goodDays ? 'On' : null,
        [CONFIG.DEFAULT_FIELDS.GENENTECH_ENROLLMENT.key]: (BI || copay) ? 'On' : null,
        [CONFIG.DEFAULT_FIELDS.BENEFIT_INVESTIGATION.key]: BI ? 'On' : null,
        [CONFIG.DEFAULT_FIELDS.COPAY_ASSISTANCE.key]: copay ? 'On' : null,
      },
      isPatientEnrollment: true,
    };

    try {
      const res = await createAuthorization({ variables: { defaultFields } });
      navigateMethod(res.data.createAuthorization.id);
    } catch {
      alert.error('There was an error creating a new Patient Enrollment');
    }
  };

  render() {
    const { showAccessSolutionsModal, goodDays, BI, copay } = this.state;
    return (
      <Container>
        <ButtonWrapper>
          <MenuButton
            menuButtons={[
              (
                <MenuItems key="newLucentisEnrollment" onClick={() => this.setState({ showAccessSolutionsModal: true })}>
                  <MenuText>Lucentis by</MenuText>
                  <Logo src="../../assets/genentech.png" />
                </MenuItems>
              ),
            ]}
          >
            <IconButton icon={AuthIcon}>New Patient Enrollment</IconButton>
          </MenuButton>
        </ButtonWrapper>
        <Modal
          header="Select Services"
          onClick={() => this.setState({ showAccessSolutionsModal: false })}
          open={showAccessSolutionsModal}
        >
          <ModalBody>
            <CustomModalContent>
              <OptionContainer>
                <Checkbox id="gooddaysCheck" type="checkbox" onChange={() => { this.setState({ goodDays: !goodDays }); }} checked={goodDays} />
                <CheckLabel for="gooddaysCheck">GoodDays Enrollment</CheckLabel>
              </OptionContainer>
              <OptionContainer>
                <Checkbox id="BICheck" type="checkbox" onChange={() => { this.setState({ BI: !BI }); }} checked={BI} />
                <CheckLabel for="BICheck">Access Solutions - Benefit Investigation</CheckLabel>
              </OptionContainer>
              <OptionContainer>
                <Checkbox id="copayCheck" type="checkbox" onChange={() => { this.setState({ copay: !copay }); }} checked={copay} />
                <CheckLabel for="copayCheck">Access Solutions - Co-Pay Assistance</CheckLabel>
              </OptionContainer>
            </CustomModalContent>
            <CustomModalFooter>
              <BaseButton
                style={{ width: '100%' }}
                disabled={!(goodDays || BI || copay)}
                onClick={() => { this.createNewEnrollment('J2778', 'Lucentis'); }}
              >
                Continue
              </BaseButton>
            </CustomModalFooter>
          </ModalBody>
        </Modal>
      </Container>
    );
  }
}

export default compose(withCreateAuthorization, withCurrentAccount)(withAlert(PatientEnrollmentsList));
