import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { WhiteOpaqueOverlay, SelectionOverOverlay } from './Overlay';

import BaseButton from '../../../../components/BaseButton';

const MessageContainer = styled(SelectionOverOverlay)`
  width: 400px;
  padding: 10px;
  margin-top: 100px;
  overflow: scroll;
  background: white;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  ${BaseButton} {
    margin-left: 10px;
  }
`;

const SelectionHeader = styled.div`
  text-align: center;
  font-size: 20px;
  color: ${props => props.theme.purple};
  margin-bottom: 20px;
`;

const MessageContent = styled.div`
  margin-bottom: 10px;
`;

const { LIVE_CONNECTION_ACTIONS } = CONFIG.CONSTANTS;
class DialogOverlay extends PureComponent {
  getButtons = () => {
    const { respondToDialog, type } = this.props;

    if (type === LIVE_CONNECTION_ACTIONS.ALERT) {
      return (
        <BaseButton onClick={() => { respondToDialog(false); }}>Accept</BaseButton>
      );
    }

    return (
      <>
        <BaseButton onClick={() => { respondToDialog(false); }}>Dismiss</BaseButton>
        <BaseButton onClick={() => { respondToDialog(true); }}>Accept</BaseButton>
      </>
    );
  }

  render() {
    const { message } = this.props;

    return (
      <Container>
        <WhiteOpaqueOverlay />
        <MessageContainer>
          <SelectionHeader>Please Select an Option</SelectionHeader>
          <MessageContent>{ message }</MessageContent>
          <ButtonContainer>{ this.getButtons() }</ButtonContainer>
        </MessageContainer>
      </Container>
    );
  }
}

export default DialogOverlay;
