import _ from 'lodash';
import React from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import { withAuthorizationEvents } from '../graphql/AuthorizationEvent';
import Modal from './Modal';
import BaseButton from './BaseButton';
import { ModalBodyXL, ModalFooter } from './ModalStyledComponents';
import AuthorizationTimeline from './AuthorizationTimeline';

const ModalContent = styled.div`
  padding: 10px 0;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  justify-content: center;
  ${BaseButton} {
    width: 30%;
    padding: 5px 10px;
  }
`;

const ScrollContainer = styled.div`
  max-height: 500px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const { STATUS_CHANGE, FOLLOWUP_REQUESTED, FOLLOWUP_COMPLETED } = CONFIG.CONSTANTS.AUTHORIZATION_EVENT_TYPES;
const { TERMINAL_AUTHORIZATION_STATUSES, AUTHORIZATION_STATUSES } = CONFIG.CONSTANTS;

const completedStatusStrings = payerName => ({
  PENDING_CONFIRMED: `SamaCare has confirmed a pending status with ${payerName}`,
  PAYER_RECEIPT_CONFIRMED: `SamaCare has confirmed the authorization has been received by ${payerName}`,
  TERMINAL_STATUS_REACHED: `SamaCare has confirmed with ${payerName} there is a terminal status to this authorization`,
  USER_ACTION_REQUIRED: `SamaCare has reached out to ${payerName} and found that this authorization requires user action`,
});

const getEventText = (event, { name }) => {
  switch (event.type) {
    case STATUS_CHANGE: {
      return `Status changed to ${event.appliedStatus.replace(/_/g, ' ')}`;
    }
    case FOLLOWUP_REQUESTED: {
      return `SamaCare follow up with ${name} was requested`;
    }
    case FOLLOWUP_COMPLETED: {
      // future iterations should probably flatten this logic
      return completedStatusStrings(name)[event.supportedAuthorizationFollowUp.status];
    }
    default: {
      return null;
    }
  }
};

const getDetails = (event) => {
  if (event.type === FOLLOWUP_COMPLETED) {
    return event.supportedAuthorizationFollowUp.details;
  }
  return '';
};

export const AuthorizationTimelineModal = ({ authorizationId, authorizationEvents, currentStatus, authorizationCreatedAt, payer, open, close }) => {
  const createEvent = { createdAt: authorizationCreatedAt, text: 'Authorization created' };
  const followUpEvent = {
    text: `SamaCare to follow up with ${payer.name}`,
    details: 'Samacare has a scheduled follow up for this authorization.'
    + ' Follow up times depend on the date of service and payer average response times',
  };
  const willFollowUpEvent = {
    text: 'Pending status to be confirmed',
    details: `Samacare will follow up with ${payer.name} to ensure the authorization is flowing smoothly.`
    + ' Follow up times depend on the date of service and payer average response times',
  };
  const terminalEvent = {
    text: `Authorization decision to be rendered by ${payer.name}`,
    details: 'Samacare works to ensure supported authorizations reach a decision as soon as possible.'
    + ' On average supported authorizations are resolved 54% faster.',
  };

  const sortedEvents = _.sortBy(authorizationEvents, 'createdAt');

  const processedEvents = _.filter(
    _.map(
      sortedEvents,
      event => ({
        type: event.type,
        id: event.id,
        createdAt: event.createdAt,
        text: getEventText(event, payer),
        details: getDetails(event),
      }),
    ),
    'text',
  );

  let syntheticEvents = [];
  const latestFollowUpEvent = _.last(_.filter(sortedEvents, 'supportedAuthorizationFollowUp'));
  const pendingFollowUp = _.get(latestFollowUpEvent, 'type') === FOLLOWUP_REQUESTED;
  const hasTerminalStatus = _.includes(TERMINAL_AUTHORIZATION_STATUSES, currentStatus);
  if (!hasTerminalStatus) {
    syntheticEvents = [terminalEvent];
    if (pendingFollowUp) {
      syntheticEvents = [followUpEvent, terminalEvent];
    } else if (currentStatus === AUTHORIZATION_STATUSES.SENT) {
      syntheticEvents = [willFollowUpEvent, terminalEvent];
    }
  }

  return (
    <Modal header="Supported Authorization Timeline" onClick={close} open={open}>
      <ModalBodyXL>
        <ModalContent>
          <ScrollContainer>
            <AuthorizationTimeline
              authorizationId={authorizationId}
              compiledEvents={[createEvent, ...processedEvents, ...syntheticEvents]}
            />
          </ScrollContainer>
        </ModalContent>
        <CustomModalFooter>
          <BaseButton onClick={close}>Back</BaseButton>
        </CustomModalFooter>
      </ModalBodyXL>
    </Modal>
  );
};

export default compose(withAuthorizationEvents)(withAlert(AuthorizationTimelineModal));
