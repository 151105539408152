import React from 'react';
import styled from 'styled-components';

import BaseButton from '../../components/BaseButton';

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${({ theme }) => theme.purple};
  border-radius: 3px;
  white-space: pre-wrap;
`;

const ReopenLivePortalConnection = ({ onClick }) => (
  <div>
    <LoadingContainer>
      <BaseButton onClick={onClick}>Reopen</BaseButton>
      { ' the live portal connection to complete this authorization.' }
    </LoadingContainer>
  </div>
);

export default ReopenLivePortalConnection;
