import React, { PureComponent } from 'react';
import styled from 'styled-components';

import AuthorizationActions from './AuthorizationActions';
import AuthorizationDetails from './AuthorizationDetails';

const CloseButton = styled.div`
  cursor: pointer;
  border-radius: 3px;
  margin-left: auto;
  background-color: white;
  border: 1px solid ${({ theme }) => theme.red};
  color: ${({ theme }) => theme.red};
  padding: 10px;
  margin-bottom: 15px;
  &:hover {
    color: white;
    background-color: ${({ theme }) => theme.red};
  }
`;

class SideBar extends PureComponent {
  state = { isClosing: false };

  render() {
    const { authorization, sendIntegrationMessage, backToAuthorizations, close } = this.props;
    const { isClosing } = this.state;

    return (
      <>
        {
          !isClosing && (
            <CloseButton onClick={() => { this.setState({ isClosing: true }); }}>
              X Close Live View
            </CloseButton>
          )
        }
        { !isClosing && (<AuthorizationDetails authorization={authorization} />) }
        <AuthorizationActions
          isClosing={isClosing}
          setIsClosing={(isClosingVal) => { this.setState({ isClosing: isClosingVal }); }}
          sendIntegrationMessage={sendIntegrationMessage}
          authorization={authorization}
          backToAuthorizations={backToAuthorizations}
          closeLiveView={close}
        />
      </>
    );
  }
}

export default SideBar;
