import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Query, compose } from 'react-apollo';
import _ from 'lodash';
import { withAlert } from 'react-alert';
import moment from 'moment';

import Modal from '../../Modal';
import BaseButton from '../../BaseButton';
import { ModalBodyXL, ModalFooter } from '../../ModalStyledComponents';
import { BaseCleave, BaseText } from '../../Segment/StyledComponents';
import { withAuthorizationQuery } from '../../../graphql/Authorization';
import { withUploadDocumentsToOncoEMR } from '../../../graphql/OncoEMR';
import CustomCheckbox from '../../CustomCheckbox';
import { DATE_FORMAT } from '../../../shared-code';
import LoginEMR from './LoginEMR';

const ModalContent = styled.div`
  font-size: 16px;
`;

const ModalText = styled.div`
  text-align: center;
  padding: 20px 0 0 0;
  font-weight: 500;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: flex-end;
  font-size: 16px;
  margin-top: 25px;
  ${BaseButton} {
    width: 175px;
    margin-left: 15px;
    padding: 5px 10px;
  }
`;

const SectionTitle = styled.div`
  color: ${props => props.theme.gray};
  font-weight: 500;
  margin-top: 10px;
  text-align: left;
  margin-right: 10px;
`;

const UploadButton = styled(BaseButton)`
  border-color: ${props => props.theme.purple};
  background: ${props => props.theme.purple};
  color: white;
  display: flex;
  flex-direction: row;

  &:hover {
    cursor: pointer;
    color: ${props => props.theme.purple};
    background: white;
  }
`;

const CancelButton = styled(BaseButton)`
  background-color: ${props => props.theme.lightGray};
  border-color: ${props => props.theme.lightGray};
  color: white;
  width: 120px;
  margin-left: 10px;
  padding: 5px 10px;
`;

class UploadToOncoEMR extends PureComponent {
  constructor(props) {
    super(props);
    const { authorization } = this.props;

    this.state = { isOpen: false, documentsToUpload: [], isMissingCredentials: false, patientMRN: _.get(authorization, 'patient.institutionPatientId') };
  }

  toggleModal = (e) => {
    const { isOpen } = this.state;

    e.stopPropagation();
    this.setState({ isOpen: !isOpen });
  }

  handleSubmit = async (patientMRN, documents, emrCreds = null) => {
    const { authorization, uploadDocumentsToOncoEMR, alert } = this.props;

    if (!patientMRN || !_.every(documents, doc => doc.name)) {
      alert.error('Please verify that you have inputted the Patient OncoEMR MRN and a document name for each upload');
    } else {
      try {
        this.setState({ isOpen: false });
        alert.info('Documents are being submitted to OncoEMR!');
        await uploadDocumentsToOncoEMR({
          variables: {
            authorizationId: authorization.id,
            documents: _.map(documents, doc => doc.date ? doc : { ...doc, date: moment().format(DATE_FORMAT) }),
            patientMRN: patientMRN.trim(),
            ...(emrCreds ? { emrCreds } : {}),
          },
        });
      } catch (e) {
        if (_.includes(e.message, 'User is missing EMR credentials')) {
          this.setState({ isMissingCredentials: true });
        }
      }
    }
  }

  render() {
    const { children, authorization } = this.props;
    const { isOpen, documentsToUpload, isMissingCredentials, patientMRN } = this.state;

    return (
      <div>
        <Modal header="Upload Documents to OncoEMR" onClick={this.toggleModal} open={isOpen}>
          <ModalBodyXL>
            <ModalContent>
              { isOpen
                && (
                  <Query
                    query={withAuthorizationQuery}
                    variables={{ id: authorization.id }}
                  >
                    {({ data, loading }) => {
                      if (loading || !data) {
                        return (<div style={{ textAlign: 'center', paddingTop: '20px', fontWeight: '500', paddingBottom: '15px' }}>Loading...</div>);
                      }

                      const { correspondences } = data.authorizationById;
                      const filteredCorrespondences = _.filter(correspondences, 'fileURL');
                      const correspondenceForms = _.map(_.sortBy(filteredCorrespondences, 'createdAt'), ({ fileAWSKey, createdAt, fileURL, id }) => (
                        { awsKey: fileAWSKey, title: `Authorization Correspondence - ${moment(createdAt).format(DATE_FORMAT)}`, fileURL, id }
                      ));

                      if (_.isEmpty(correspondenceForms)) {
                        return (
                          <div style={{ textAlign: 'center', paddingTop: '20px', fontWeight: '500', paddingBottom: '15px' }}>
                            No responses have been uploaded to this authorization!
                          </div>
                        );
                      }

                      return (
                        <>
                          <ModalText>Please verify the oncoEMR MRN and select the documents you would like to upload.</ModalText>
                          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <SectionTitle>
                              Patient OncoEMR MRN:
                            </SectionTitle>
                            <BaseText
                              onChange={(e) => { this.setState({ patientMRN: e.target.value }); }}
                              value={patientMRN || ''}
                              style={{ width: '250px', marginTop: '10px' }}
                            />
                          </div>
                          {
                            _.map(correspondenceForms, (form) => {
                              const isSelected = _.find(documentsToUpload, { id: form.id });
                              return (
                                <div style={{ margin: '10px 0px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                  <CustomCheckbox
                                    checked={isSelected}
                                    onChange={() => {
                                      if (isSelected) {
                                        const updatedDocs = _.reject(documentsToUpload, { id: form.id });
                                        this.setState({ documentsToUpload: updatedDocs });
                                      } else {
                                        this.setState({ documentsToUpload: documentsToUpload.concat(form) });
                                      }
                                    }}
                                  />
                                  <div style={{ marginLeft: '10px' }}>{ form.title }</div>
                                  { isSelected && (
                                    <>
                                      <BaseText
                                        value={isSelected.name || ''}
                                        onChange={(e) => {
                                          const val = e.target.value;
                                          this.setState(prevState => ({
                                            documentsToUpload: prevState.documentsToUpload.map(
                                              doc => (doc.id === form.id ? { ...doc, name: val } : doc)
                                            ),
                                          }));
                                        }}
                                        placeholder="Document Name"
                                        style={{ width: '200px', marginLeft: '20px' }}
                                      />
                                      <BaseCleave
                                        onChange={(e) => {
                                          const val = e.target.value;
                                          this.setState(prevState => ({
                                            documentsToUpload: prevState.documentsToUpload.map(
                                              doc => (doc.id === form.id ? { ...doc, date: val } : doc)
                                            ),
                                          }));
                                        }}
                                        options={{ date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' }}
                                        value={isSelected.date || moment().format(DATE_FORMAT)}
                                        style={{ width: '175px', marginLeft: '20px' }}
                                      />
                                    </>
                                  ) }
                                </div>
                              );
                            })
                          }
                          <CustomModalFooter>
                            <CancelButton onClick={this.toggleModal}>Cancel</CancelButton>
                            <UploadButton onClick={async () => this.handleSubmit(patientMRN, documentsToUpload)}>Upload</UploadButton>
                          </CustomModalFooter>
                        </>
                      );
                    }}
                  </Query>
                )
              }
            </ModalContent>
          </ModalBodyXL>
        </Modal>
        <div onClick={this.toggleModal}>
          { children }
        </div>
        { isMissingCredentials && (
          <LoginEMR
            open={isMissingCredentials}
            closeModal={() => { this.setState({ isMissingCredentials: false }); }}
            documentsToUpload={documentsToUpload}
            authorization={authorization}
            handleSubmit={this.handleSubmit}
            patientMRN={patientMRN}
          />
        ) }
      </div>
    );
  }
}

export default compose(withUploadDocumentsToOncoEMR)(withAlert(UploadToOncoEMR));
