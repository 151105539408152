import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import _ from 'lodash';

import faxFilterInfo from './fragments/faxFilterInfo';
import { withDefaultProps } from './utils';
import { FORM_NUMBERS_QUERY } from './FormNumber';

const WITH_FAX_FILTERS_QUERY = 'faxFiltersFindAll';
export const withFaxFilters = graphql(
  gql`
    query ${WITH_FAX_FILTERS_QUERY} {
      faxFiltersFindAll {
        ...faxFilterInfo
      }
    }
    ${faxFilterInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({ faxFilters: _.get(data, 'faxFiltersFindAll') })),
  }
);

export const withUpsertFaxFilter = graphql(
  gql`
    mutation upsertFaxFilter($formNumberId: Int!, $states: [String]) {
      upsertFaxFilter(formNumberId: $formNumberId, states: $states) {
        ...faxFilterInfo
      }
    }
    ${faxFilterInfo}
  `,
  {
    name: 'upsertFaxFilter',
    options: () => ({ refetchQueries: () => [FORM_NUMBERS_QUERY] }),
  }
);
