import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import { withUpsertPortalCredentials, withCurrentAccount } from '../graphql/Account';
import UserInput from '../routes/CheckAuthorizationStatus/UserInput';
import BaseButton from './BaseButton';
import Modal from './Modal';

const ModalButtonContainer = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  ${BaseButton} {
    flex: 1;
    text-align: center;
    margin: 5px 0;
  }
`;

export class UpdateCredentialsModal extends PureComponent {
  constructor(props) {
    super(props);
    const { portalKey, account } = this.props;
    const creds = _.find(_.get(account, 'portalCredentials') || {}, { key: portalKey }) || {};
    this.state = { ...creds };
  }

  submit = async () => {
    const { upsertPortalCredentials, requiredFields, onSubmit, portalKey, alert } = this.props;
    const newCreds = _.pickBy(this.state, (value, key) => value && _.includes(_.map(requiredFields, 'key'), key));
    try {
      await upsertPortalCredentials({ variables: { credentials: { key: portalKey, ...newCreds } } });
      await onSubmit();
    } catch (e) {
      alert.error(e.message);
    }
  }

  render() {
    const { portalKey, close, requiredFields } = this.props;

    return (
      <Modal onClick={close} header={`Update Credentials for ${_.upperFirst(_.toLower(portalKey))}`} open>
        <ModalButtonContainer>
          { _.map(requiredFields, input => (
            <UserInput
              input={input}
              onChange={(key, val) => this.setState({ [key]: val })}
              // eslint-disable-next-line
              value={this.state[input.key]}
            />
          )) }
          <BaseButton onClick={this.submit}>
            Update credentials
          </BaseButton>
        </ModalButtonContainer>
      </Modal>
    );
  }
}

export default compose(withUpsertPortalCredentials, withCurrentAccount)(withAlert(UpdateCredentialsModal));
