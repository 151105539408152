import _ from 'lodash';

export const payerList = [
  'AARP',
  'ABARCA HEALTH',
  'ABSENTEE SHAWNEE TRIBAL HEALTH SYSTEM',
  'ABSOLUTE TOTAL CARE',
  'ACARIA HEALTH',
  'ACCESS DUPAGE',
  'Access Health',
  'ACCESS MEDICARE',
  'ACCESS NOW',
  'ACCESS PLUS',
  'ACCESS TO CARE',
  'Access to Healthcare Network (AHN)',
  'ACCG INSURANCE',
  'ACCLAIM BENEFITS',
  'ACCLAIM TPA',
  'ACCOUNTABLE CARE MANAGEMENT',
  'ACE Program for Adults',
  'ACLAIM INC',
  'ACS BENEFIT SERVICES',
  'ACTIVA BENEFIT SERVICES',
  'Acuity Insurance Company',
  'ADMINISTRATION SERVICES INC',
  'ADMINISTRATIVE CONCEPTS, INC.',
  'ADMINISTRATIVE CONSULTING SERVICES OF TN (ACST)',
  'Administrative D.C. No.1 Welfare Fund',
  'ADMINISTRATIVE SERVICES ONLY',
  'ADMINISTRATIVE SOLUTIONS, INC',
  'ADMINSURE INC',
  'ADMIRAL LIFE INSURANCE CO. OF AMERICA',
  'ADROIT HEALTH GROUP',
  'Advanced Benefit Solutions',
  'ADVANCED MEDICAL MANAGEMENT - AMM',
  'ADVANTAGE HEALTH PLAN - AHP',
  'ADVANTAGE HEALTH SOLUTIONS INC',
  'ADVANTEK BENEFIT ADMINISTRATORS',
  'Advantra',
  'Advent Health',
  'ADVENTIST HEALTH',
  'ADVENTIST RISK MANAGEMENT',
  'ADVICARE HEALTH',
  'ADVOCATE HEALTH CARE',
  'AEGIS ADMINISTRATIVE SERVICES',
  'Aetna Advantage',
  'AETNA BETTER HEALTH',
  'Aetna Better Health - Florida',
  'Aetna Better Health - Illinois',
  'Aetna Better Health - Kentucky',
  'Aetna Better Health - Louisiana',
  'Aetna Better Health - Maryland',
  'AETNA BETTER HEALTH - MISSOURI',
  'Aetna Better Health - Nebraska',
  'AETNA BETTER HEALTH - NEW JERSEY',
  'Aetna Better Health - Ohio',
  'Aetna Better Health - Pennsylvania',
  'Aetna Better Health -New York',
  'AETNA BETTER HEALTH OF KANSAS',
  'AETNA BETTER HEALTH OF MARYLAND',
  'AETNA BETTER HEALTH OF TEXAS',
  'AETNA BETTER HEALTH OF WEST VIRGINIA',
  'Aetna Better Health- New Jersey',
  'Aetna Better Health- Virginia',
  'Aetna Better Health-Michigan',
  'AETNA HEALTH - NORTHERN NEW JERSEY',
  'AETNA HEALTH - SOUTHERN NEW JERSEY',
  'AETNA HEALTH INC - ALABAMA',
  'AETNA HEALTH INC - ALASKA',
  'AETNA HEALTH INC - ARIZONA',
  'AETNA HEALTH INC - ARKANSAS',
  'AETNA HEALTH INC - CALIFORNIA',
  'AETNA HEALTH INC - COLORADO',
  'AETNA HEALTH INC - CONNECTICUT',
  'AETNA HEALTH INC - DELAWARE',
  'AETNA HEALTH INC - DISTRICT OF COLUMBIA',
  'AETNA HEALTH INC - FLORIDA',
  'AETNA HEALTH INC - GEORGIA',
  'AETNA HEALTH INC - HAWAII',
  'AETNA HEALTH INC - IDAHO',
  'AETNA HEALTH INC - ILLINOIS',
  'AETNA HEALTH INC - INDIANA',
  'AETNA HEALTH INC - IOWA',
  'AETNA HEALTH INC - KANSAS',
  'AETNA HEALTH INC - KANSAS/MISSOURI',
  'AETNA HEALTH INC - KENTUCKY',
  'AETNA HEALTH INC - LOUISIANA',
  'AETNA HEALTH INC - MAINE',
  'AETNA HEALTH INC - MARYLAND',
  'AETNA HEALTH INC - MASSACHUSETTS',
  'AETNA HEALTH INC - MICHIGAN',
  'AETNA HEALTH INC - MINNESOTA',
  'AETNA HEALTH INC - MISSISSIPPI',
  'AETNA HEALTH INC - MISSOURI',
  'AETNA HEALTH INC - MONTANA',
  'AETNA HEALTH INC - NEBRASKA',
  'AETNA HEALTH INC - NEVADA',
  'AETNA HEALTH INC - NEW HAMPSHIRE',
  'AETNA HEALTH INC - NEW JERSEY',
  'AETNA HEALTH INC - NEW MEXICO',
  'AETNA HEALTH INC - NEW YORK',
  'AETNA HEALTH INC - NORTH CAROLINA',
  'AETNA HEALTH INC - NORTH DAKOTA',
  'AETNA HEALTH INC - OHIO',
  'AETNA HEALTH INC - OKLAHOMA',
  'AETNA HEALTH INC - OREGON',
  'AETNA HEALTH INC - PENNSYLVANIA',
  'AETNA HEALTH INC - RHODE ISLAND',
  'AETNA HEALTH INC - SOUTH CAROLINA',
  'AETNA HEALTH INC - SOUTH DAKOTA',
  'AETNA HEALTH INC - TENNESSEE',
  'AETNA HEALTH INC - TEXAS',
  'AETNA HEALTH INC - THE CAROLINAS',
  'AETNA HEALTH INC - UTAH',
  'AETNA HEALTH INC - VERMONT',
  'AETNA HEALTH INC - VIRGINIA',
  'AETNA HEALTH INC - WASHINGTON',
  'AETNA HEALTH INC - WASHINGTON D.C.',
  'AETNA HEALTH INC - WEST VIRGINIA',
  'AETNA HEALTH INC - WISCONSIN',
  'AETNA HEALTH INC - WYOMING',
  'AETNA INC - (CORPORATE)',
  'Aetna Pharmacy Management',
  'AETNA PHARMACY MANAGEMENT',
  'Aetna South East Region',
  'AFFINITY HEALTH PLAN',
  'AFFORDABLE BENEFIT ADMINISTRATORS, INC',
  'AFLAC',
  'AFTRA HEALTH & RETIREMENT FUNDS',
  'Agelity',
  'AGENTRA HEALTH SOLUTIONS',
  'AGERIGHT ADVANTAGE HEALTH PLAN',
  'AGEWELL NEW YORK',
  'Agile Health Insurance',
  'AHCCCS FEDERAL EMERGENCY SERVICE',
  'AHMC HEALTHCARE',
  'AIM ADMINISTRATORS INC',
  'Aither Health',
  'ALAMEDA ALLIANCE FOR HEALTH',
  'ALAN STURM & ASSOCIATES',
  'ALASKA ELECTRICAL HEALTH & WELFARE FUND',
  'Alaska Teamster-Employer Welfare and Pension Trust Plans',
  'ALICARE',
  'Aliera Healthcare',
  'ALIGN SENIOR CARE - (CORPORATE)',
  'ALIGN SENIOR CARE - MICHIGAN',
  'ALIGN SENIOR CARE - VIRGINIA',
  'ALIGNMENT HEALTH PLAN',
  'All Savers',
  'All Savers Insurance Company',
  'All Ways',
  'all well',
  'ALLCARE HEALTH PLAN',
  'ALLEGEANT',
  'ALLEGIAN HEALTH PLANS',
  'ALLEGIANCE BENEFIT PLAN MANAGEMENT',
  'Allegiant Rx',
  'ALLIANCE COAL HEALTH PLAN',
  'ALLIANCE PARTNERS',
  'ALLIANT HEALTH PLANS INC',
  'ALLIANZ',
  'ALLIED ADMINISTRATORS',
  'ALLIED BENEFIT SYSTEMS',
  'ALLIED INSURANCE',
  'ALLIED NATIONAL',
  'ALLSTATE',
  'Alluma',
  'ALLWAYS HEALTH PARTNERS',
  'ALLWELL',
  'ALOHACARE',
  'ALPHACARE',
  'ALTA MEDICAL MANAGEMENT',
  'ALTERNATIVE BENEFIT PLANS',
  'Alternative Insurance Resources',
  'ALTERNATIVE RISK MANAGEMENT',
  'ALTIUS HEALTH PLANS INC - UTAH',
  'ALTIUS HEALTH PLANS INC - WYOMING',
  'Altrua HealthShare',
  'AMA INSURANCE AGENCY INC.',
  'AMA INSURANCE AGENCY, INC.',
  'AMALGAMATED LIFE',
  'AMALGAMATED TRANSIT UNION - ATU',
  'Amalgamated Welfare Trust Fund Local 371',
  'AMBETTER HEALTH - FLORIDA',
  'AMBETTER HEALTH OF INDIANA',
  'AMBETTER OF ARIZONA',
  'AMBETTER OF ARKANSAS',
  'AMBETTER OF GEORGIA',
  'AMBETTER OF ILLINOIS',
  'AMBETTER OF KANSAS',
  'AMBETTER OF MASSACHUSETTS',
  'AMBETTER OF MISSISSIPPI',
  'AMBETTER OF MISSOURI',
  'AMBETTER OF NEVADA',
  'AMBETTER OF NEW HAMPSHIRE',
  'AMBETTER OF NORTH CAROLINA',
  'AMBETTER OF OHIO',
  'AMBETTER OF SOUTH CAROLINA',
  'AMBETTER OF TENNESSEE',
  'AMBETTER OF TEXAS',
  'AMBETTER OF WASHINGTON',
  'AMERAPLAN, INC',
  'AMERIBEN SOLUTIONS',
  'AmeriBen Solutions',
  'AMERICA REPUBLIC INSURANCE COMPANY',
  'AMERICA\'S 1ST CHOICE INSURANCE COMPANY OF NORTH CAROLINA',
  'AMERICA\'S CHOICE HEALTHPLAN - ACH',
  'American Access Casualty Company',
  'AMERICAN ADMINISTRATIVE GROUP',
  'American Association for Medical Benefits',
  'American Benefit Corporation',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY (CORPORATE)',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - ARIZONA',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - ARKANSAS',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - ILLINOIS',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - INDIANA',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - IOWA',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - MICHIGAN',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - MISSOURI',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - NEBRASKA',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - OHIO',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - OKLAHOMA',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - SOUTH CAROLINA',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - TENNESSEE',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - TEXAS',
  'AMERICAN COMMUNITY MUTUAL INSURANCE COMPANY - WISCONSIN',
  'American Continental Insurance Company',
  'AMERICAN FAMILY INSURANCE COMPANY',
  'AMERICAN FIDELITY ASSURANCE',
  'AMERICAN FOREIGN SERVICE BENEFIT PLAN',
  'AMERICAN FOREIGN SERVICES BENEFIT',
  'AMERICAN GENERAL ASSURANCE COMPANY',
  'American General Life and Accident Insurance Company',
  'AMERICAN GROUP ADMINISTRATORS, INC.',
  'AMERICAN HEALTH ADVANTAGE OF MISSISSIPPI',
  'AMERICAN HEALTH ADVANTAGE OF TENNESSEE',
  'AMERICAN HEALTH CARE (AHC)',
  'AMERICAN HEALTH CHOICE, INC',
  'AMERICAN HEALTH MEDICARE',
  'AMERICAN HEALTHCARE ALLIANCE',
  'AMERICAN HERITAGE LIFE INSURANCE COMPANY',
  'AMERICAN INCOME LIFE',
  'American Indian Health plan',
  'AMERICAN LIFE INS CO - ALICO',
  'AMERICAN MEDICAL & LIFE INSURANCE COMPANY',
  'AMERICAN MEDICAL HEALTHCARE',
  'AMERICAN NATIONAL INSURANCE CO',
  'AMERICAN PIONEER LIFE INSURANCE COMPANY',
  'American Plan Administrators',
  'AMERICAN PROGRESSIVE L&H NY',
  'AMERICAN PUBLIC LIFE INS CO. - APL',
  'AMERICAN REPUBLIC INSURANCE COMPANY',
  'American Retirement Life Insurance Company',
  'AMERICAN SENTINEL INSURANCE CO.',
  'American Workers Insurance Services (AWIS)',
  'AMERICA`S 1ST CHOICE HEALTH PLAN - (CORPORATE)',
  'AMERICA`S 1ST CHOICE HEALTH PLAN - GEORGIA',
  'AMERICA`S 1ST CHOICE HEALTH PLAN - NORTH CAROLINA',
  'AMERICA`S 1ST CHOICE HEALTH PLAN - SOUTH CAROLINA',
  'Americhoice',
  'AMERICO FINANCIAL LIFE AND ANNUITY INSURANCE COMPANY',
  'Amerigroup',
  'AMERIGROUP REALSOLUTIONS',
  'AMERIGROUP REALSOLUTIONS (CORPORATE)',
  'AMERIGROUP REALSOLUTIONS - FLORIDA',
  'AMERIGROUP REALSOLUTIONS - GEORGIA',
  'AMERIGROUP REALSOLUTIONS - IOWA',
  'AMERIGROUP REALSOLUTIONS - KANSAS',
  'AMERIGROUP REALSOLUTIONS - MARYLAND',
  'AMERIGROUP REALSOLUTIONS - NEVADA',
  'AMERIGROUP REALSOLUTIONS - NEW JERSEY',
  'AMERIGROUP REALSOLUTIONS - NEW MEXICO',
  'AMERIGROUP REALSOLUTIONS - OHIO',
  'AMERIGROUP REALSOLUTIONS - SOUTH CAROLINA',
  'AMERIGROUP REALSOLUTIONS - TENNESSEE',
  'AMERIGROUP REALSOLUTIONS - TEXAS',
  'AMERIGROUP REALSOLUTIONS - WASHINGTON',
  'AMERIHEALTH - DELAWARE',
  'AMERIHEALTH - NEW JERSEY',
  'AMERIHEALTH - PENNSYLVANIA',
  'AMERIHEALTH - WEST VIRGINIA',
  'AMERIHEALTH ADMINISTRATORS',
  'Baker Benefits Administrators, Inc.',
  'BAKERY & CONFECTIONERY HEALTH BENEFITS FUND',
  'BANKERS FIDELITY LIFE INSURANCE COMPANY',
  'BANKERS LIFE AND CASUALTY',
  'BANNER HEALTH',
  'BANNER MEDISUN INC',
  'BANNER PLAN ADMINISTRATION',
  'BANNER-UNIVERSITY FAMILY CARE',
  'BAPTIST HEALTH PLAN',
  'BAY AREA DELIVERY DRIVERS',
  'Bay Bridge Administrators',
  'BAYCARE HEALTH PLANS',
  'BAYSTATE HEALTH SYSTEM',
  'BBG, INC.',
  'BCBS Association',
  'BCBS FEP Pharmacy Program',
  'BCBS MA',
  'BCBS MI Medicare Advantage PPO',
  'BCBS OF ALABAMA',
  'BCBS OF ARIZONA',
  'BCBS OF ARIZONA (COMPANY)',
  'BCBS OF FLORIDA (COMPANY)',
  'BCBS OF GEORGIA',
  'BCBS OF ILLINOIS',
  'BCBS OF KANSAS',
  'BCBS OF KANSAS CITY',
  'BCBS OF LOUISIANA',
  'BCBS OF MASSACHUSETTS',
  'BCBS OF MICHIGAN',
  'BCBS OF MICHIGAN (COMPANY)',
  'BCBS OF MINNESOTA',
  'BCBS OF MISSISSIPPI',
  'BCBS OF MONTANA',
  'BCBS OF NEBRASKA',
  'BCBS OF NEW MEXICO',
  'BCBS OF NORTH CAROLINA',
  'BCBS OF NORTH DAKOTA',
  'BCBS OF OKLAHOMA',
  'BCBS OF RHODE ISLAND',
  'BCBS OF SOUTH CAROLINA',
  'BCBS OF TENNESSEE',
  'BCBS OF TEXAS',
  'BCBS OF VERMONT',
  'BCBS of Washington',
  'BCBS OF WESTERN NEW YORK',
  'BCBS OF WYOMING',
  'BCMH OF OH',
  'BEACON HEALTH',
  'BEAUMONT EMPLOYEE HEALTH PLAN',
  'Beazley Insurance Company Inc',
  'BEHEALTHY AMERICA',
  'BELLA VISTA PHARMACARE',
  'BEN-E-LECT',
  'BeneCard',
  'BENECARD SERVICES INC',
  'Benefit & Risk Management Services (BRMS)',
  'BENEFIT ADMINISTRATION SERVICES',
  'BENEFIT ADMINISTRATIVE SYSTEMS, LLC',
  'BENEFIT ADMINISTRATORS',
  'BENEFIT ASSISTANCE CORPORATION (TPA)',
  'Benefit Coordinators',
  'BENEFIT MANAGEMENT',
  'BENEFIT MANAGEMENT GROUP, INC',
  'BENEFIT MANAGEMENT SERVICES (BMS)',
  'BENEFIT MANAGEMENT, INC. (BMI)',
  'BENEFIT PLAN ADMINISTRATION OF WISCONSIN',
  'BENEFIT PLAN ADMINISTRATORS',
  'BENEFIT PLAN ADMINISTRATORS - VA',
  'Benefit Risk Management Services',
  'BENEFIT SERVICES, INC.',
  'BENEFIT SOLUTIONS, INC.',
  'BENEFIT SUPPORT INC.',
  'Benefits Administration Corporation',
  'Benefits Management Group Inc',
  'BENEFLEX',
  'BENELECT EMPLOYER DRIVEN BENEFITS',
  'BENEMAX',
  'BENESCRIPT SERVICES INC',
  'BENESYS',
  'BENICOMP, INC',
  'Bentegrity Solutions',
  'BERKSHIRE HATHAWAY GUARD INSURANCE COMPANIES',
  'Berkshire Health Partners',
  'Best Choice Plus',
  'BEST DOCTORS INSURANCE',
  'Best Life',
  'Better Health',
  'BETTER HEALTH',
  'BEYONDRX',
  'BF&M Insurance Group',
  'BIND ON-DEMAND',
  'BIOSCRIP INC',
  'Blessing Financial Assistance',
  'BLUE ADVANTAGE ADMINISTRATOR OF ARKANSAS',
  'BLUE BENEFIT ADMINISTRATORS',
  'Blue Care Network MI',
  'BLUE CARE NETWORK OF MICHIGAN',
  'Blue Choice',
  'Blue Cross Blue Shield Northern Plains Alliance',
  'BLUE CROSS OF IDAHO',
  'BLUE CROSS OF NORTHEASTERN PENNSYLVANIA',
  'BLUE CROSS OF PUERTO RICO',
  'BLUE JOURNEY',
  'BLUE SHIELD OF CALIFORNIA',
  'Blue Shield PA',
  'Blue Shield Pharmacy Management of California',
  'Bluelink TPA',
  'BLUESHIELD OF NORTHEASTERN NEW YORK',
  'BMI HEALTH PLANS',
  'BOEING',
  'BOILERMAKERS NATIONAL HEALTH & WELFARE FUND',
  'Boon Group',
  'BOON-CHAPMAN BENEFIT ADMINISTRATORS',
  'BOSTON MEDICAL CENTER (CORPORATE)',
  'BOSTON MEDICAL CENTER HEALTHNET PLAN',
  'BOULDER ADMINISTRATION SERVICES',
  'BRAND NEW DAY',
  'BRAVO HEALTH - (CORPORATE)',
  'BRAVO HEALTH - ALABAMA',
  'BRAVO HEALTH - ALASKA',
  'BRAVO HEALTH - ARIZONA',
  'BRAVO HEALTH - ARKANSAS',
  'BRAVO HEALTH - CALIFORNIA',
  'BRAVO HEALTH - COLORADO',
  'BRAVO HEALTH - CONNECTICUT',
  'BRAVO HEALTH - DELAWARE',
  'BRAVO HEALTH - DISTRICT OF COLUMBIA',
  'BRAVO HEALTH - FLORIDA',
  'BRAVO HEALTH - GEORGIA',
  'BRAVO HEALTH - HAWAII',
  'BRAVO HEALTH - IDAHO',
  'BRAVO HEALTH - ILLINOIS',
  'BRAVO HEALTH - INDIANA',
  'BRAVO HEALTH - IOWA',
  'BRAVO HEALTH - KANSAS',
  'BRAVO HEALTH - KENTUCKY',
  'BRAVO HEALTH - LOUISIANA',
  'BRAVO HEALTH - MARYLAND',
  'BRAVO HEALTH - MASSACHUSETTS',
  'BRAVO HEALTH - MICHIGAN',
  'BRAVO HEALTH - MINNESOTA',
  'BRAVO HEALTH - MISSISSIPPI',
  'BRAVO HEALTH - MISSOURI',
  'BRAVO HEALTH - MONTANA',
  'BRAVO HEALTH - NEBRASKA',
  'BRAVO HEALTH - NEVADA',
  'BRAVO HEALTH - NEW JERSEY',
  'BRAVO HEALTH - NEW MEXICO',
  'BRAVO HEALTH - NEW YORK',
  'BRAVO HEALTH - NORTH CAROLINA',
  'BRAVO HEALTH - NORTH DAKOTA',
  'BRAVO HEALTH - OHIO',
  'BRAVO HEALTH - OKLAHOMA',
  'BRAVO HEALTH - PENNSYLVANIA',
  'BRAVO HEALTH - RHODE ISLAND',
  'BRAVO HEALTH - SOUTH CAROLINA',
  'BRAVO HEALTH - SOUTH DAKOTA',
  'BRAVO HEALTH - TENNESSEE',
  'BRAVO HEALTH - TEXAS',
  'BRAVO HEALTH - UTAH',
  'BRAVO HEALTH - VERMONT',
  'BRAVO HEALTH - VIRGINIA',
  'BRAVO HEALTH - WEST VIRGINIA',
  'BRAVO HEALTH - WISCONSIN',
  'BRAVO HEALTH - WYOMING',
  'Brazoria County Health Department',
  'BridgeSpan Health',
  'BRIDGESTONE AMERICAS, INC.',
  'BRIDGESTONE FIRESTONE',
  'BRIDGEWAY HEALTH SOLUTIONS',
  'BRIGHT HEALTH (CORPORATE)',
  'BRIGHT HEALTH - ALABAMA',
  'BRIGHT HEALTH - ARIZONA',
  'BRIGHT HEALTH - COLORADO',
  'BRIGHT HEALTH - FLORIDA',
  'BRIGHT HEALTH - NEBRASKA',
  'BRIGHT HEALTH - NORTH CAROLINA',
  'BRIGHT HEALTH - OKLAHOMA',
  'BRIGHT HEALTH - SOUTH CAROLINA',
  'BRIGHT HEALTH - TENNESSEE',
  'BRIOVA RX',
  'Britton Benefit Services LLC (fka) Markham Gray & Dennis',
  'BROADREACH MEDICAL RESOURCES',
  'Broadspire',
  'BROOKWOOD BAPTIST HEALTH PRINCETON NEUROLOGY',
  'Broward County Public Schools',
  'Broward Health',
  'BUCKEYE HEALTH PLAN',
  'Building Trades Health and Welfare Fund',
  'Bunker Hill/Pintlar Retiree Medical Plan',
  'BUPA INTERNATIONAL',
  'BUSINESS ADMINISTRATORS & CONSULTANTS - BAC',
  'BUTLER BENEFIT SERVICES',
  'C & O EMPLOYEES\' HOSPITAL ASSOCIATION',
  'C&R CONSULTING',
  'C.L. FRATES & COMPANY',
  'CAHABA GBA',
  'Cal Optima',
  'California Children\'s Services',
  'CALIFORNIA DEPARTMENT OF HEALTH SERVICES',
  'CALIFORNIA HEALTH & WELLNESS',
  'California Insurance Guarantee Association',
  'CALIFORNIA PHYSICIANS SERVICE',
  'CALIFORNIA WATER SERVICE',
  'CALOPTIMA',
  'CALVIVA HEALTH',
  'CALVO INSURANCE',
  'CAMBIA HEALTH SOLUTIONS INC (CORPORATE)',
  'CAMBRIDGE INTEGRATED SERVICES - GRAND RAPIDS',
  'Cameron County Indigent Health Care',
  'CanaRx',
  'Cannon Cochran Management Services Ins (CCMSI)',
  'CANON COCHRAN MANAGEMENT SERVICES, INC.',
  'CAPITAL ADMINISTRATORS',
  'CAPITAL BLUECROSS',
  'CAPITAL BLUECROSS (CORPORATE)',
  'CAPITAL DISTRICT PHYSICIANS` HEALTH PLAN INC',
  'CAPITAL HEALTH PLAN',
  'CAPITAL RX',
  'Capitol Administrators Southeast',
  'CAPROCK HEALTHPLANS',
  'CARDAY ASSOCIATES, INC',
  'CARDINAL CHOICE',
  'CARE (SUPPLEMENTAL PLAN)',
  'CARE 1ST HEALTH PLAN (CORPORATE)',
  'CARE 1ST HEALTH PLAN - ARIZONA',
  'CARE 1ST HEALTH PLAN - CALIFORNIA',
  'CARE 1ST HEALTH PLAN - TEXAS',
  'CARE CONNECT',
  'CARE IMPROVEMENT PLUS - (CORPORATE)',
  'CARE IMPROVEMENT PLUS - ALABAMA',
  'CARE IMPROVEMENT PLUS - ARKANSAS',
  'CARE IMPROVEMENT PLUS - GEORGIA',
  'CARE IMPROVEMENT PLUS - ILINOIS',
  'CARE IMPROVEMENT PLUS - INDIANA',
  'CARE IMPROVEMENT PLUS - IOWA',
  'CARE IMPROVEMENT PLUS - MARYLAND',
  'CARE IMPROVEMENT PLUS - MISSOURI',
  'CARE IMPROVEMENT PLUS - NEBRASKA',
  'CARE IMPROVEMENT PLUS - NEW MEXICO',
  'CARE IMPROVEMENT PLUS - NEW YORK',
  'CARE IMPROVEMENT PLUS - NORTH CAROLINA',
  'CARE IMPROVEMENT PLUS - PENNSYLVANIA',
  'CARE IMPROVEMENT PLUS - SOUTH CAROLINA',
  'CARE IMPROVEMENT PLUS - TEXAS',
  'CARE IMPROVEMENT PLUS - WISCONSIN',
  'CARE N` CARE HEALTH PLANS',
  'CARE N` CARE INSURANCE COMPANY OF NORTH CAROLINA',
  'CARE RING MEDICAL ASSISTANCE PROGRAM',
  'CARE WISCONSIN FIRST INC',
  'CARECONNECT INSURANCE COMPANY, INC.',
  'CareFactor',
  'CareFirst Administrators',
  'CAREFIRST BCBS',
  'CAREFIRST BCBS (CORPORATE)',
  'CareFirst Medical Group',
  'CAREFIRST OF MARYLAND INC',
  'CAREFLORIDA',
  'CARELINK HEALTH PLANS INC',
  'Carelink-University Health System',
  'CAREMORE HEALTH PLAN - (CORPORATE)',
  'CAREMORE HEALTH PLAN - ARIZONA',
  'CAREMORE HEALTH PLAN - CALIFORNIA',
  'CAREMORE HEALTH PLAN - NEVADA',
  'CAREOREGON HEALTH PLAN',
  'CarePartners of Connecticutt',
  'CAREPLUS HEALTH PLANS INC',
  'CARESOURCE - GEORGIA',
  'CARESOURCE - MICHIGAN',
  'CARESOURCE - OHIO',
  'CARESOURCE - WEST VIRGINIA',
  'CARESOURCE MANAGEMENT GROUP (CORPORATE)',
  'CareSource, Inc',
  'CARESOURCE-INDIANA',
  'CARESOURCE-KENTUCKY',
  'CARILION CLINIC MEDICARE HEALTH PLAN',
  'CARITEN HEALTHCARE',
  'CAROLINA CARE PLAN INC',
  'CAROLINA CRESCENT HEALTH PLAN',
  'Carpenter Funds Administrative Office of Northern California, Inc.',
  'CARPENTER\'S HEALTH & WELFARE IL',
  'CARPENTER\'S HEALTH & WELFARE MO',
  'CARPENTER\'S HEALTH & WELFARE NJ',
  'CARPENTERS BENEFIT FUNDS OF PHILADELPHIA',
  'CARPENTERS HEALTH & WELFARE TRUST FUND OF ST. LOUIS',
  'CARPENTERS HEALTH AND WELFARE TRUST FUND - CALIFORNIA',
  'Carpenters Health and Welfare-NY',
  'Casa Dorinda',
  'CASCADE HEALTH ALLIANCE LLC',
  'CASTIA RX',
  'CASTIARX',
  'CATALYST RX',
  'CATAMARAN',
  'Catholic Health Initiatives (CHI)',
  'CATHOLIC HEALTHCARE WEST',
  'CATHOLIC UNITED FINANCIAL',
  'CATSKILL AREA SCHOOL BENEFIT PLAN',
  'CBA BLUE',
  'CBCA ADMINISTRATORS, INC',
  'CBP (COORDINATED BENEFIT PLAN)',
  'CCS/ SANTA CLARA COUNTY',
  'CCS/ SONOMA COUNTY',
  'CCS/CONTRA COSTA COUNTY',
  'CCS/FRESNO COUNTY',
  'CCS/HUMBOLDT COUNTY',
  'CCS/LOS ANGELES COUNTY',
  'CCS/MENDOCINO COUNTY',
  'CCS/NAPA COUNTY',
  'CCS/ORANGE COUNTY',
  'CCS/RIVERSIDE COUNTY',
  'CCS/SACRAMENTO COUNTY',
  'CCS/SAN BERNARDINO COUNTY',
  'CCS/SAN FRANCISCO COUNTY',
  'CCS/SANTA BARBARA COUNTY',
  'CCStpa',
  'CDS GROUP HEALTH',
  'CEBT',
  'CEDAR VALLEY COMMUNITY HEALTHCARE',
  'CELTIC INSURANCE COMPANY',
  'CELTICARE HEALTH PLAN OF MASSACHUSETTS',
  'CEMENT AND CONCRETE WORKERS WELFARE FUND - NY',
  'CENCAL HEALTH',
  'CENPATICO INTEGRATED CARE',
  'CENTENE CORPORATION (CORPORATE)',
  'Centennial Pharmacy',
  'CENTERCARE INC',
  'CENTERLIGHT HEALTHCARE',
  'CENTERS PLAN FOR HEALTHY LIVING',
  'CENTIVO',
  'CENTRAL ACCIDENT REPORTING OFFICE',
  'CENTRAL BENEFITS',
  'CENTRAL CALIFORNIA ALLIANCE FOR HEALTH',
  'CENTRAL HEALTH MEDICARE PLAN',
  'CENTRAL LABORERS\'  PENSION WELFARE & ANN',
  'CENTRAL PENNSYLVANIA TEAMSTERS HEALTH & WELFARE',
  'CENTRAL RESERVE LIFE',
  'CENTRAL STATES HEALTH & LIFE',
  'CENTRAL STATES HEALTH & WELFARE FUND',
  'Central States Indemnity (CSI)',
  'Central States Indemnity Co. of Omaha (CSI)',
  'CENTRAL UNITED LIFE INSURANCE COMPANY',
  'Centrix Benefits',
  'CENTURY HEALTHCARE',
  'Cerner Corporation',
  'Cerner Health Plan',
  'CerpassRx',
  'CHAMPVA',
  'CHANGE HEALTHCARE',
  'Charity Care',
  'Chartwell',
  'Cherokee Insurance Co',
  'CHEROKEE NATION HEALTH SERVICE',
  'Chesapeake Life Insurance',
  'CHESTERFIELD RESOURCES',
  'Chicago Laborers Pension and Welfare Funds',
  'CHICAGO PAINTERS HEALTH AND WELFARE FUND',
  'CHICAGO REGIONAL',
  'CHILD HEALTH PLUS',
  'CHILDREN MEDICAL SERVICES NETWORK',
  'CHILDREN\'S MEDICAL SECURITY PLAN',
  'CHILDREN\'S MEDICAL SERVICES NETWOK',
  'Children\'s Special Health Care Services CSHCS',
  'CHILDREN`S COMMUNITY HEALTH PLAN',
  'CHILDREN`S MEDICAL CENTER HEALTH PLAN',
  'CHILDREN`S MERCY FAMILY HEALTH PARTNERS (CORPORATE)',
  'CHILDREN`S MERCY FAMILY HEALTH PARTNERS - KANSAS',
  'CHILDREN`S MERCY FAMILY HEALTH PARTNERS - MISSOURI',
  'CHINESE COMMUNITY HEALTH PLAN',
  'CHOCTAW NATION-TAIHINA OKLAHOMA',
  'Choice Benefits',
  'CHOICE PLUS',
  'Choice Rx Solutions',
  'CHRISTIAN BROTHERS EMPLOYEE BENEFIT TRUST',
  'CHRISTIAN CARE MINISTRY MEDI-SHARE',
  'CHRISTIAN FIDELITY LIFE INSURANCE COMPANY',
  'CHRISTIAN HEALTH AID',
  'Christian Health Ministry',
  'CHRISTUS HEALTH PLAN - (CORPORATE)',
  'CHRISTUS HEALTH PLAN - NEW MEXICO',
  'CHRISTUS HEALTH PLAN - TEXAS',
  'Chronic Disease Fund',
  'CHUBB',
  'Church Health Center',
  'CICP - COLORADO INDIGENT CARE PROGRAM',
  'CIGNA HEALTH AND LIFE',
  'CIGNA HEALTHCARE (CORPORATE)',
  'CIGNA HEALTHCARE OF ALABAMA',
  'CIGNA HEALTHCARE OF ALASKA',
  'CIGNA HEALTHCARE OF ARIZONA',
  'CIGNA HEALTHCARE OF ARKANSAS',
  'CIGNA HEALTHCARE OF CALIFORNIA',
  'CIGNA HEALTHCARE OF COLORADO',
  'CIGNA HEALTHCARE OF CONNECTICUT',
  'CIGNA HEALTHCARE OF DELAWARE',
  'CIGNA HEALTHCARE OF DISTRICT OF COLUMBIA',
  'CIGNA HEALTHCARE OF FLORIDA',
  'CIGNA HEALTHCARE OF GEORGIA',
  'CIGNA HEALTHCARE OF HAWAII',
  'CIGNA HEALTHCARE OF IDAHO',
  'CIGNA HEALTHCARE OF ILLINOIS',
  'CIGNA HEALTHCARE OF INDIANA',
  'CIGNA HEALTHCARE OF IOWA',
  'CIGNA HEALTHCARE OF KANSAS',
  'CIGNA HEALTHCARE OF KANSAS/MISSOURI',
  'CIGNA HEALTHCARE OF KENTUCKY',
  'CIGNA HEALTHCARE OF LOUISIANA',
  'CIGNA HEALTHCARE OF MAINE',
  'CIGNA HEALTHCARE OF MARYLAND',
  'CIGNA HEALTHCARE OF MASSACHUSETTS',
  'CIGNA HEALTHCARE OF MICHIGAN',
  'CIGNA HEALTHCARE OF MINNESOTA',
  'CIGNA HEALTHCARE OF MISSISSIPPI',
  'CIGNA HEALTHCARE OF MISSOURI',
  'CIGNA HEALTHCARE OF MONTANA',
  'CIGNA HEALTHCARE OF NEBRASKA',
  'CIGNA HEALTHCARE OF NEVADA',
  'CIGNA HEALTHCARE OF NEW HAMPSHIRE',
  'CIGNA HEALTHCARE OF NEW JERSEY',
  'CIGNA HEALTHCARE OF NEW MEXICO',
  'CIGNA HEALTHCARE OF NEW YORK',
  'CIGNA HEALTHCARE OF NORTH CAROLINA',
  'CIGNA HEALTHCARE OF NORTH DAKOTA',
  'CIGNA HEALTHCARE OF OHIO',
  'CIGNA HEALTHCARE OF OKLAHOMA',
  'CIGNA HEALTHCARE OF OREGON',
  'CIGNA HEALTHCARE OF PENNSYLVANIA',
  'CIGNA HEALTHCARE OF RHODE ISLAND',
  'CIGNA HEALTHCARE OF SOUTH CAROLINA',
  'CIGNA HEALTHCARE OF SOUTH DAKOTA',
  'CIGNA HEALTHCARE OF TENNESSEE',
  'CIGNA HEALTHCARE OF TEXAS',
  'CIGNA HEALTHCARE OF THE MID-ATLANTIC',
  'CIGNA HEALTHCARE OF UTAH',
  'CIGNA HEALTHCARE OF VERMONT',
  'CIGNA HEALTHCARE OF VIRGINIA',
  'CIGNA HEALTHCARE OF WASHINGTON',
  'CIGNA HEALTHCARE OF WEST VIRGINIA',
  'CIGNA HEALTHCARE OF WISCONSIN',
  'CIGNA HEALTHCARE OF WYOMING',
  'Cigna HealthSpring - Colorado',
  'Cigna ID',
  'CIGNA PHARMACY MANAGEMENT',
  'CIGNA-HEALTHSPRING - (CORPORATE)',
  'CIGNA-HEALTHSPRING - ALABAMA',
  'CIGNA-HEALTHSPRING - ALASKA',
  'CIGNA-HEALTHSPRING - ARIZONA',
  'CIGNA-HEALTHSPRING - ARKANSAS',
  'CIGNA-HEALTHSPRING - CALIFORNIA',
  'CIGNA-HEALTHSPRING - COLORADO',
  'CIGNA-HEALTHSPRING - CONNECTICUT',
  'CIGNA-HEALTHSPRING - DELAWARE',
  'CIGNA-HEALTHSPRING - FLORIDA',
  'CIGNA-HEALTHSPRING - GEORGIA',
  'CIGNA-HEALTHSPRING - HAWAII',
  'CIGNA-HEALTHSPRING - IDAHO',
  'CIGNA-HEALTHSPRING - ILLINOIS',
  'CIGNA-HEALTHSPRING - INDIANA',
  'CIGNA-HEALTHSPRING - IOWA',
  'CIGNA-HEALTHSPRING - KANSAS',
  'CIGNA-HEALTHSPRING - KENTUCKY',
  'CIGNA-HEALTHSPRING - LOUISIANA',
  'CIGNA-HEALTHSPRING - MAINE',
  'CIGNA-HEALTHSPRING - MARYLAND',
  'CIGNA-HEALTHSPRING - MASSACHUSETTS',
  'CIGNA-HEALTHSPRING - MICHIGAN',
  'CIGNA-HEALTHSPRING - MINNESOTA',
  'CIGNA-HEALTHSPRING - MISSISSIPPI',
  'CIGNA-HEALTHSPRING - MISSOURI',
  'CIGNA-HEALTHSPRING - MONTANA',
  'CIGNA-HEALTHSPRING - NEBRASKA',
  'CIGNA-HEALTHSPRING - NEVADA',
  'CIGNA-HEALTHSPRING - NEW HAMPSHIRE',
  'CIGNA-HEALTHSPRING - NEW JERSEY',
  'CIGNA-HEALTHSPRING - NEW MEXICO',
  'CIGNA-HEALTHSPRING - NEW YORK',
  'CIGNA-HEALTHSPRING - NORTH CAROLINA',
  'CIGNA-HEALTHSPRING - NORTH DAKOTA',
  'D. H. EVANS ASSOCIATES, INC.',
  'DAKOTACARE',
  'DART MANAGEMENT CORP - DMC BENEFIT PLAN',
  'DATA-RX MANAGEMENT',
  'DATARX',
  'Davis Vision',
  'DEACONESS ONECARE HEALTH PLAN',
  'DEAN HEALTH PLAN',
  'DELAWARE PHYSICIANS CARE INC',
  'DELL CHILDREN`S HEALTH PLAN',
  'DELTA AIRLINES RETIREMENT COMMITTEE (DALRC)',
  'DELTA HEALTH SYSTEMS',
  'Denton County Indigent Health Care',
  'DENVER HEALTH MEDICAL PLAN INC',
  'DEPARTMENT OF LABOR',
  'DESERET ALLIANCE',
  'Deseret Mutual Benefit Administrators',
  'DESERET MUTUAL HEALTHCARE',
  'DESERT CANYON COMMUNITY CARE',
  'Devon Health Services',
  'Devoted',
  'DEVOTED HEALTH - (CORPORATE)',
  'DICKINSON GROUP',
  'Dignity Health',
  'DIRECT CARE ADMINISTRATORS',
  'DIRECTOR\'S GUILD HEALTH PLAN',
  'DISABILITY MANAGEMENT SERVICES',
  'District 9 I.A.M.A.W Welfare Trust',
  'District Council # 3 Painters and Allied Trades Benefit',
  'District Council #11 - Insurance Programmers',
  'District Council 16 Northern CA. Health and Welfare Trust Fund',
  'Diversified Administration Corporation',
  'Diversified Benefit Administrators',
  'DIVISION 1181 ATU NEW YORK WELFARE FUND',
  'DMC Care',
  'DOCS Health Plan',
  'Doctors HealthCare Plan, Inc',
  'DRISCOLL CHILDREN\'S HEALTH PLAN',
  'DRISCOLL CHILDREN`S HEALTH PLAN',
  'Drug Card Inc.',
  'DST PHARMACY SOLUTIONS',
  'DUNN & ASSOCIATES TPA',
  'East Hartford Town and Board of Education',
  'EASY CHOICE HEALTH PLAN',
  'EASY CHOICE HEALTH PLAN OF NEW YORK',
  'Ebix Health Administrative Exchange',
  'EBPA',
  'EDS ADMINISTRATIVE SERVICES, LLC',
  'EDS HEALTH BENEFITS',
  'EHFA',
  'EHIM',
  'EHO',
  'EIGHT DISTRICT ELECTRICAL BENEFIT FUND',
  'EL PASO FIRST HEALTH PLANS INC',
  'EL PASO HEALTH',
  'Elderly Pharmaceutical Insurance Coverage (EPIC) Program',
  'ELDERPLAN INC',
  'ELECTRICAL CONSTRUCTION INDUSTRY HEALTH AND PLAN WELFARE PLAN',
  'ELIANCE ADMINISTRATORS',
  'Elite Administration',
  'ELITE BENEFITS',
  'Embassy of the State of Qatar',
  'EMBLEMHEALTH INC',
  'EMBLEMHEALTH INC (CORPORATE)',
  'EMI HEALTH - (CORPORATE)',
  'EMI HEALTH - ARIZONA',
  'EMI HEALTH - IDAHO',
  'EMI HEALTH - UTAH',
  'EMPIRE BCBS',
  'Empire BCBS NY',
  'Empire Pharmacy Management',
  'Empire Plan',
  'EMPIRE STATE CARPENTERS ANNUITY FUND',
  'EmpiRx Health',
  'EMPIRX HEALTH',
  'EMPLOYEE BENEFIT ADMINISTRATORS',
  'EMPLOYEE BENEFIT CONCEPTS',
  'EMPLOYEE BENEFIT CONSULTANTS',
  'EMPLOYEE BENEFIT MANAGEMENT CORP - EBMC',
  'EMPLOYEE BENEFIT MANAGEMENT SERVICES EBMS',
  'EMPLOYEE BENEFIT MANAGERS',
  'EMPLOYEE BENEFIT SERVICES',
  'EMPLOYEE BENEFIT SYSTEMS (EBS)',
  'Employee Benefits Administration & Management. EBA&M',
  'Employee Benefits Administration and Management Corporation',
  'EMPLOYEE BENEFITS SOLUTIONS',
  'EMPLOYEE HEALTH INSURANCE MANAGEMENT',
  'Employee Painters\' Trust Health & Welfare Plan',
  'EMPLOYEE PLANS, LLC',
  'EMPLOYER PLAN SERVICES',
  'Employers and Operating Engineers Local 520 Health and Welfare Fund',
  'EMPLOYERS DIRECT HEALTH',
  'EMPOWER HEALTHCARE SOLUTIONS',
  'ENCOMPASS',
  'ENTERPRISE GROUP PLANNING, INC',
  'ENTRUST TPA',
  'Envisacare RX',
  'ENVISACARERX',
  'ENVISION INSURANCE COMPANY (CORPORATE)',
  'ENVISION INSURANCE COMPANY - ALABAMA',
  'ENVISION INSURANCE COMPANY - ALASKA',
  'ENVISION INSURANCE COMPANY - ARIZONA',
  'ENVISION INSURANCE COMPANY - ARKANSAS',
  'ENVISION INSURANCE COMPANY - CALIFORNIA',
  'ENVISION INSURANCE COMPANY - COLORADO',
  'ENVISION INSURANCE COMPANY - CONNECTICUT',
  'ENVISION INSURANCE COMPANY - DELAWARE',
  'ENVISION INSURANCE COMPANY - DISTRICT OF COLUMBIA',
  'ENVISION INSURANCE COMPANY - FLORIDA',
  'ENVISION INSURANCE COMPANY - GEORGIA',
  'ENVISION INSURANCE COMPANY - GUAM',
  'ENVISION INSURANCE COMPANY - HAWAII',
  'ENVISION INSURANCE COMPANY - IDAHO',
  'ENVISION INSURANCE COMPANY - ILLINOIS',
  'ENVISION INSURANCE COMPANY - INDIANA',
  'ENVISION INSURANCE COMPANY - IOWA',
  'ENVISION INSURANCE COMPANY - KANSAS',
  'ENVISION INSURANCE COMPANY - KENTUCKY',
  'ENVISION INSURANCE COMPANY - LOUISIANA',
  'ENVISION INSURANCE COMPANY - MAINE',
  'ENVISION INSURANCE COMPANY - MARYLAND',
  'ENVISION INSURANCE COMPANY - MASSACHUSETTS',
  'ENVISION INSURANCE COMPANY - MICHIGAN',
  'ENVISION INSURANCE COMPANY - MINNESOTA',
  'ENVISION INSURANCE COMPANY - MISSISSIPPI',
  'ENVISION INSURANCE COMPANY - MISSOURI',
  'ENVISION INSURANCE COMPANY - MONTANA',
  'ENVISION INSURANCE COMPANY - NEBRASKA',
  'ENVISION INSURANCE COMPANY - NEVADA',
  'ENVISION INSURANCE COMPANY - NEW HAMPSHIRE',
  'ENVISION INSURANCE COMPANY - NEW JERSEY',
  'ENVISION INSURANCE COMPANY - NEW MEXICO',
  'ENVISION INSURANCE COMPANY - NEW YORK',
  'ENVISION INSURANCE COMPANY - NORTH CAROLINA',
  'ENVISION INSURANCE COMPANY - NORTH DAKOTA',
  'ENVISION INSURANCE COMPANY - OHIO',
  'ENVISION INSURANCE COMPANY - OKLAHOMA',
  'ENVISION INSURANCE COMPANY - OREGON',
  'ENVISION INSURANCE COMPANY - PENNSYLVANIA',
  'ENVISION INSURANCE COMPANY - PUERTO RICO',
  'ENVISION INSURANCE COMPANY - RHODE ISLAND',
  'ENVISION INSURANCE COMPANY - SOUTH CAROLINA',
  'ENVISION INSURANCE COMPANY - SOUTH DAKOTA',
  'ENVISION INSURANCE COMPANY - TENNESSEE',
  'ENVISION INSURANCE COMPANY - TEXAS',
  'ENVISION INSURANCE COMPANY - UTAH',
  'ENVISION INSURANCE COMPANY - VERMONT',
  'ENVISION INSURANCE COMPANY - VIRGINIA',
  'ENVISION INSURANCE COMPANY - WASHINGTON',
  'ENVISION INSURANCE COMPANY - WEST VIRGINIA',
  'ENVISION INSURANCE COMPANY - WISCONSIN',
  'ENVISION INSURANCE COMPANY - WYOMING',
  'Envision Rx',
  'ENVISIONRXOPTIONS',
  'ENVOLVE PHARMACY SOLUTIONS',
  'EOCCO',
  'EON HEALTH, INC - GEORGIA',
  'EON HEALTH, INC - SOUTH CAROLINA',
  'EON HEALTH, INC. (CORPORATE)',
  'EPC Associates',
  'EPIC LIFE INSURANCE COMPANY',
  'EPIC PHARMACY NETWORK INC',
  'EPIPHANYRX',
  'EPOCH',
  'EQUIAN',
  'EQUITABLE FINANCIAL HEALTH INSURANCE',
  'EQUITABLE LIFE & CASUALTY INSURANCE',
  'EQUITABLE PLAN SERVICES INC/EPS',
  'EQUITY NATIONAL LIFE INSURANCE COMPANY',
  'ERICKSON ADVANTAGE- (CORPORATE)',
  'ERICKSON ADVANTAGE- COLORADO',
  'ERICKSON ADVANTAGE- KANSAS',
  'ERICKSON ADVANTAGE- MARYLAND',
  'ERICKSON ADVANTAGE- MASSACHUSETTS',
  'ERICKSON ADVANTAGE- MICHIGAN',
  'ERICKSON ADVANTAGE- NEW JERSEY',
  'ERICKSON ADVANTAGE- PENNSYLVANIA',
  'ERICKSON ADVANTAGE- TEXAS',
  'ERICKSON ADVANTAGE- VIRGINIA',
  'ESIS INC',
  'ESKENAZI HEALTH',
  'ESSENCE HEALTHCARE - (CORPORATE)',
  'ESSENCE HEALTHCARE - ILLINOIS',
  'ESSENCE HEALTHCARE - INDIANA',
  'ESSENCE HEALTHCARE - KENTUCKY',
  'ESSENCE HEALTHCARE - MISSOURI',
  'ESSENCE HEALTHCARE - NEW YORK',
  'ESSENCE HEALTHCARE - WASHINGTON',
  'Essential Benefit Administrators',
  'ESSENTIAL BENEFIT ADMINISTRATORS',
  'EVANGELICAL LUTHERAN CHURCH OF AMERICA/BOARD OF PENSIONS',
  'Evercare Select',
  'Everence Association, Inc.',
  'EVEREST HEALTH PLAN ADMINISTRATORS',
  'EVEREST REINSURANCE COMPANY',
  'EVERGREEN HEALTH PLAN INC',
  'EVOLUTIONS HEALTHCARE  SYSTEMS, INC',
  'Exactus Pharmacy Solutions',
  'EXCEEDENT HEALTH',
  'EXCELLUS BCBS',
  'EXCELLUS HEALTH PLAN INC',
  'EXCLUSIVE CARE',
  'Experience Health HMO',
  'Expert Benefit Solutions',
  'EXPRESS SCRIPTS INC',
  'EXPRESS SCRIPTS MEDICARE- (CORPORATE)',
  'EXPRESS SCRIPTS MEDICARE- ALABAMA',
  'EXPRESS SCRIPTS MEDICARE- ALASKA',
  'EXPRESS SCRIPTS MEDICARE- ARIZONA',
  'EXPRESS SCRIPTS MEDICARE- ARKANSAS',
  'EXPRESS SCRIPTS MEDICARE- CALIFORNIA',
  'EXPRESS SCRIPTS MEDICARE- COLORADO',
  'EXPRESS SCRIPTS MEDICARE- CONNECTICUT',
  'EXPRESS SCRIPTS MEDICARE- DELAWARE',
  'EXPRESS SCRIPTS MEDICARE- DISTRICT OF COLUMBIA',
  'EXPRESS SCRIPTS MEDICARE- FLORIDA',
  'EXPRESS SCRIPTS MEDICARE- GEORGIA',
  'EXPRESS SCRIPTS MEDICARE- HAWAII',
  'EXPRESS SCRIPTS MEDICARE- IDAHO',
  'EXPRESS SCRIPTS MEDICARE- ILLINOIS',
  'EXPRESS SCRIPTS MEDICARE- INDIANA',
  'EXPRESS SCRIPTS MEDICARE- IOWA',
  'EXPRESS SCRIPTS MEDICARE- KANSAS',
  'EXPRESS SCRIPTS MEDICARE- KENTUCKY',
  'EXPRESS SCRIPTS MEDICARE- LOUISIANA',
  'EXPRESS SCRIPTS MEDICARE- MAINE',
  'EXPRESS SCRIPTS MEDICARE- MARYLAND',
  'EXPRESS SCRIPTS MEDICARE- MASSACHUSETTS',
  'EXPRESS SCRIPTS MEDICARE- MICHIGAN',
  'EXPRESS SCRIPTS MEDICARE- MINNESOTA',
  'EXPRESS SCRIPTS MEDICARE- MISSISSIPPI',
  'EXPRESS SCRIPTS MEDICARE- MISSOURI',
  'EXPRESS SCRIPTS MEDICARE- MONTANA',
  'EXPRESS SCRIPTS MEDICARE- NEBRASKA',
  'EXPRESS SCRIPTS MEDICARE- NEVADA',
  'EXPRESS SCRIPTS MEDICARE- NEW HAMPSHIRE',
  'EXPRESS SCRIPTS MEDICARE- NEW JERSEY',
  'EXPRESS SCRIPTS MEDICARE- NEW MEXICO',
  'EXPRESS SCRIPTS MEDICARE- NEW YORK',
  'EXPRESS SCRIPTS MEDICARE- NORTH CAROLINA',
  'EXPRESS SCRIPTS MEDICARE- NORTH DAKOTA',
  'EXPRESS SCRIPTS MEDICARE- OHIO',
  'EXPRESS SCRIPTS MEDICARE- OKLAHOMA',
  'EXPRESS SCRIPTS MEDICARE- OREGON',
  'EXPRESS SCRIPTS MEDICARE- PENNSYLVANIA',
  'EXPRESS SCRIPTS MEDICARE- PUERTO RICO',
  'EXPRESS SCRIPTS MEDICARE- RHODE ISLAND',
  'EXPRESS SCRIPTS MEDICARE- SOUTH CAROLINA',
  'EXPRESS SCRIPTS MEDICARE- SOUTH DAKOTA',
  'EXPRESS SCRIPTS MEDICARE- TENNESSEE',
  'EXPRESS SCRIPTS MEDICARE- TEXAS',
  'EXPRESS SCRIPTS MEDICARE- UTAH',
  'EXPRESS SCRIPTS MEDICARE- VERMONT',
  'EXPRESS SCRIPTS MEDICARE- VIRGINIA',
  'EXPRESS SCRIPTS MEDICARE- WASHINGTON',
  'EXPRESS SCRIPTS MEDICARE- WEST VIRGINIA',
  'EXPRESS SCRIPTS MEDICARE- WISCONSIN',
  'EXPRESS SCRIPTS MEDICARE- WYOMING',
  'Eye Quest',
  'Eye Specialists//Block Vision',
  'EyeMed Vision Care',
  'FAIROSRX',
  'FALLON HEALTH (CORPORATE)',
  'FALLON HEALTH OF MASSACHUSETTS',
  'FALLON HEALTH WEINBERG',
  'Family Health Center',
  'FAMILY HEALTH NETWORK',
  'FAMILY HEALTH NETWORK (COMPANY)',
  'Family Life Insurance Company',
  'FAMILYCARE INC',
  'FARM BUREAU HEALTH PLANS',
  'FCE BENEFIT ADMINISTRATORS',
  'FEDERAL HOME LIFE / GE LIFE & ANNUITY ASSURANCE',
  'FEDERATED INSURANCE',
  'FFVA MUTUAL',
  'FHHS - FLORIDA HOSPITAL HEALTHCARE SYSTEMS',
  'FIDELIS CARE NEW YORK',
  'FIDELIS CARE NEW YORK (COMPANY)',
  'FIDELIS SECURECARE (CORPORATE)',
  'FIDELIS SECURECARE OF MICHIGAN',
  'FIDELIS SECURECARE OF NORTH CAROLINA',
  'FIDELIS SECURECARE OF TEXAS',
  'FIDELITY SECURITY LIFE INS',
  'FIRST CARDINAL',
  'FIRST CHOICE BENEFIT MANAGEMENT',
  'FIRST CHOICE HEALTH ADMINISTRATORS',
  'FIRST CHOICE HEALTH SYSTEMS',
  'FIRST CHOICE VIP CARE',
  'FIRST COAST ADVANTAGE LLC',
  'First Community Healthcare',
  'FIRST HEALTH',
  'First Health Services - PBM',
  'FIRST MEDICAL HEALTH PLAN OF FLORIDA INC',
  'FIRST MEDICAL HEALTH PLAN, INC.',
  'FIRST PLUS',
  'FIRST SERVICE ADMINISTRATORS, INC,',
  'FIRST UNITED AMERICAN LIFE INSURANCE COMPANY - NEW YORK',
  'FIRST UNITED AMERICAN LIFE INSURANCE COMPANY - TEXAS',
  'FIRSTCARE HEALTH PLANS',
  'FIRSTCAROLINACARE INSURANCE COMPANY INC',
  'FirstCommunity Health Plan, Inc',
  'FLEXSCRIPTS',
  'Flores & Associates',
  'FLORIDA BLUE',
  'Florida Community Care',
  'FLORIDA HEALTH ADMINISTRATORS (FHA)',
  'FLORIDA HEALTH CARE PLANS INC',
  'FLORIDA HEALTHCARE PLUS, INC',
  'Florida Hospital Healthcare System',
  'FLORIDA MEDICAL CLINIC',
  'FLORIDA TRUE HEALTH',
  'FLRX PRESCRIPTION BENEFIT MANAGEMENT',
  'FMA - FREE MARKET ADMINISTRATORS',
  'FMH BENEFIT SERVICES',
  'Fond du Lac Human Services',
  'Fore Thought',
  'FOREST COUNTY POTAWATOMI TRIBE HEALTH PLAN',
  'Forethought Life Insurance Company',
  'Formula Benefits',
  'Forward Health',
  'FOUNDATION FOR MEDICAL CARE OF KERN COUNTY',
  'FOUNDATION FOR MEDICAL CARE OF STANISLAUS/TUOLUMNE',
  'Foundation for Medical Care of Tulare and Kings Counties, Inc.',
  'FOUNDATION FOR MEDICAL CARE/MERCED CO',
  'FOUNDATION FOR MEDICAL CARE/PACIFIC',
  'FOUNDATION FOR MEDICAL CARE/RIVERSIDE CO',
  'FOUNDATION FOR MEDICAL CARE/TULARE & KINGS CO\'S',
  'FOX EVERETT',
  'FOX INSURANCE COMPANY - (CORPORATE)',
  'FOX INSURANCE COMPANY - MISSISSIPPI',
  'Fox Valley Laborers Health & Welfare Fund',
  'FRA FLEET RESERVE',
  'Freedom Claims Management Inc (FCMI)',
  'FREEDOM HEALTH INC',
  'FREEDOM LIFE',
  'FRESENIUS HEALTH PARTNERS- (CORPORATE)',
  'FRESENIUS HEALTH PARTNERS- GEORGIA',
  'FRESENIUS HEALTH PARTNERS- NORTH CAROLINA',
  'FRESENIUS HEALTH PARTNERS- TENNESSEE',
  'FRESENIUS HEALTH PARTNERS- TEXAS',
  'FRESENIUS HEALTH PLANS - ILLINOIS',
  'FRESENIUS HEALTH PLANS - NEW YORK',
  'FRESENIUS HEALTH PLANS- (CORPORATE)',
  'FRESENIUS HEALTH PLANS- ARIZONA',
  'FRESENIUS HEALTH PLANS- CALIFORNIA',
  'FRESENIUS HEALTH PLANS- NORTH CAROLINA',
  'Fresenius Medical Plan',
  'FRIDAY HEALTH PLANS',
  'FRINGE BENEFIT COORDINATORS',
  'Fringe Benefit Services Inc',
  'FUTURESCRIPTS LLC',
  'GALLAGHER BASSETT SERVICES, INC',
  'GATEWAY HEALTH PLAN - KENTUCKY',
  'GATEWAY HEALTH PLAN - NORTH CAROLINA',
  'GATEWAY HEALTH PLAN - OHIO',
  'GATEWAY HEALTH PLAN - PENNSYLVANIA',
  'Gateway to Better Health',
  'GBG',
  'GE GROUP ADMINISTRATORS / GENWORTH FINANCIAL',
  'GEHA',
  'GEISINGER HEALTH PLAN (CORPORATE)',
  'GEISINGER HEALTH PLAN - PENNSYLVANIA',
  'GEISINGER HEALTH PLAN - WEST VIRGINIA',
  'GEISINGER HEALTH SYSTEM',
  'GEM GROUP',
  'GEMCARE HEALTH PLAN INC',
  'GENERAL LABORERS FUND',
  'GENERAL PRESCRIPTION PROGRAMS INC (GPP)',
  'Genesee Health Plan',
  'GENWORTH LIFE AND ANNUNITY INSURANCE COMPANY',
  'GeoBlue',
  'GEORGE W HILL CORRECTIONAL FACILITY',
  'GERBER LIFE INSURANCE',
  'GHPP - GENETICALLY HANDICAPPED PT PROGRAM',
  'GILSBAR INSURANCE SERVICES',
  'GLOBAL BENEFITS',
  'Global Life and Accident Insurance Company',
  'GLOBAL MEDICAL MANAGEMENT',
  'Global Pharmaceuticals, LLC',
  'GLOBALEXCEL',
  'GLOBALHEALTH INC',
  'GLOBE LIFE & ACCIDENT INSURANCE CO',
  'GLOBE LIFE AND ACCIDENT INSURANCE COMPANY',
  'GMP EMPLOYERS RETIREE TRUST',
  'GMR HEALTH',
  'GOLD COAST HEALTH PLAN',
  'GOLD COAST JOINT BENEFITS TRUST',
  'GOLDEN HEALTHCARE PHARMACY',
  'GOLDEN RULE INSURANCE',
  'GOLDEN RULE INSURANCE COMPANY',
  'GOLDEN STATE MEDICARE HEALTH PLAN',
  'Good Days',
  'GOOD SAMARITAN',
  'GOODYEAR TIRE & RUBBER MEDICAL BENEFITS',
  'Government Personnel Mutual Life Insurance Company',
  'Governor\'s Access Plan',
  'GPA',
  'GPM Health & Life',
  'GRAND VALLEY HEALTH PLAN',
  'Granite Alliance',
  'GRAPHIC COMMUNICATIONS',
  'GRAPHIC COMMUNICATIONS H&W FUND',
  'Grayson County Indigent Health',
  'GREAT AMERICAN LIFE INSURANCE CO.',
  'Great Lakes PACE',
  'GREAT PLAINS MEDICARE ADVANTAGE - (CORPORATE)',
  'GREAT PLAINS MEDICARE ADVANTAGE - NEBRASKA',
  'GREAT PLAINS MEDICARE ADVANTAGE - NORTH DAKOTA',
  'GREAT PLAINS MEDICARE ADVANTAGE - SOUTH DAKOTA',
  'GREAT-WEST HEALTHCARE - ALASKA',
  'GREAT-WEST HEALTHCARE - ARIZONA',
  'GREAT-WEST HEALTHCARE - CALIFORNIA',
  'GREAT-WEST HEALTHCARE - COLORADO',
  'GREAT-WEST HEALTHCARE - FLORIDA',
  'GREAT-WEST HEALTHCARE - GEORGIA',
  'GREAT-WEST HEALTHCARE - IDAHO',
  'GREAT-WEST HEALTHCARE - ILLINOIS',
  'GREAT-WEST HEALTHCARE - INDIANA',
  'GREAT-WEST HEALTHCARE - KANSAS',
  'GREAT-WEST HEALTHCARE - MISSOURI',
  'GREAT-WEST HEALTHCARE - NEW JERSEY',
  'GREAT-WEST HEALTHCARE - NEW YORK',
  'GREAT-WEST HEALTHCARE - NORTH CAROLINA',
  'GREAT-WEST HEALTHCARE - OHIO',
  'GREAT-WEST HEALTHCARE - OREGON',
  'GREAT-WEST HEALTHCARE - PENNSYLVANIA',
  'GREAT-WEST HEALTHCARE - TENNESSEE',
  'GREAT-WEST HEALTHCARE - TEXAS',
  'GREAT-WEST HEALTHCARE - VIRGINIA',
  'GREAT-WEST HEALTHCARE - WASHINGTON',
  'GREAT-WEST HEALTHCARE - WISCONSIN',
  'GREAT-WEST HEALTHCARE - WYOMING',
  'GREAT-WEST LIFE & ANNUITY INSURANCE COMPANY (CORPORATE)',
  'GREEK CATHOLIC UNION',
  'GREEN COUNTY INDIGENT HEALTH CARE (IHC)',
  'Green Mountain Care',
  'Grenz Benefit Solutions & Administrators',
  'GROUP & PENSION ADMINISTRATORS',
  'GROUP & PENSION ADMINISTRATORS, INC.',
  'GROUP ADMINISTRATORS (TPA)',
  'GROUP ADMINISTRATORS, LTD.',
  'GROUP BENEFIT SERVICES',
  'GROUP HEALTH COOPERATIVE OF EAU CLAIRE',
  'GROUP HEALTH COOPERATIVE OF SOUTH CENTRAL WISCONSIN',
  'GROUP HEALTH INC',
  'GROUP HEALTH INC. PHARMACY SERVICES',
  'GROUP INSURANCE SERVICE CENTER INC.',
  'Group Management Services (GMS)',
  'group marketing services',
  'GROUP RESOURCES',
  'GSPO',
  'GUAM DEPARTMENT OF PUBLIC HEALTH AND SOCIAL SERVICES (DPHSS)',
  'GUARANTEED TRUST LIFE',
  'GUARDIAN HEALTHCARE INC',
  'GUARDIAN LIFE',
  'Guardian Life Insurance Company of America',
  'GUIDANTRX',
  'Guild Times Benefits Fund',
  'Guilford Community Care Network',
  'Gulf Guaranty Employee Benefit Services',
  'GUNDERSEN HEALTH PLAN INC - WISCONSIN',
  'H&A BENEFIT ADMINISTRATORS',
  'HALL COUNTY MEDICAL SOCIETY HEALTH ACCESS',
  'HAP MIDWEST HEALTH PLAN INC',
  'HARBOR HEALTH PLAN',
  'HARKEN HEALTH INSURANCE COMPANY - IL',
  'HARMONY HEALTH PLAN OF ILLINOIS INC',
  'HARMONY HEALTH PLAN OF MISSOURI',
  'HARRINGTON HEALTH',
  'HARTFORD LIFE AND INSURANCE COMPANY',
  'HARVARD PILGRIM HEALTH CARE - MAINE',
  'HARVARD PILGRIM HEALTH CARE - NEW HAMPSHIRE',
  'HARVARD PILGRIM HEALTH CARE INC',
  'HARVARD PILGRIM HEALTH CARE INC (CORPORATE)',
  'HARVARD PILGRIM HEALTH CARE OF CONNECTICUT',
  'HARVARD PILGRIM HEALTH CARE OF MASSACHUSETTS',
  'HARVESTPLAINS HEALTH',
  'Hawaii Mainland Administrators (HMA)',
  'HAWAII MANAGEMENT ALLIANCE ASSOCIATION',
  'HAWAII MEDICAL ASSOCIATION HMA',
  'HAWAII-MAINLAND ADMINISTRATORS',
  'HCC Medical Insurance Services',
  'Health & Welfare Benefit System HWBS',
  'HEALTH ADVANTAGE',
  'HEALTH ALLIANCE MEDICAL PLANS (CORPORATE)',
  'HEALTH ALLIANCE MEDICAL PLANS - ILLINOIS',
  'HEALTH ALLIANCE MEDICAL PLANS - INDIANA',
  'HEALTH ALLIANCE MEDICAL PLANS - IOWA',
  'HEALTH ALLIANCE MEDICAL PLANS - MIDWEST',
  'HEALTH ALLIANCE MEDICAL PLANS - NEBRASKA',
  'HEALTH ALLIANCE MEDICAL PLANS - OHIO',
  'HEALTH ALLIANCE MEDICAL PLANS - WASHINGTON',
  'HEALTH ALLIANCE PLAN',
  'HEALTH ALLIANCE PLAN (COMPANY)',
  'HEALTH CARE DISTRICT OF PALM BEACH COUNTY',
  'Health Care District of Palm Beach County',
  'Health Care District Palm Beach County',
  'HEALTH CARE MANAGEMENT ADMINISTRATORS',
  'HEALTH CARE PAYERS COALITION (HCPC)',
  'HEALTH CARE SERVICE CORPORATION (CORPORATE)',
  'Health Choice (Employees Group Insurance Division)',
  'HEALTH COLORADO INC',
  'HEALTH COMP ADMINISTRATORS',
  'HEALTH COST SOLUTIONS',
  'HEALTH DESIGN PLUS',
  'HEALTH EASY TPA',
  'Health First Health Plans',
  'HEALTH FIRST HEALTH PLANS INC',
  'Health Insurance Innovations',
  'HEALTH INSURANCE PLAN OF GREATER NEW YORK (COMPANY)',
  'Health Management Adminstrators (HMA)',
  'HEALTH MARKETS VALUE RX- (CORPORATE)',
  'HEALTH MARKETS VALUE RX- ALABAMA',
  'HEALTH MARKETS VALUE RX- ALASKA',
  'HEALTH MARKETS VALUE RX- ARIZONA',
  'HEALTH MARKETS VALUE RX- ARKANSAS',
  'HEALTH MARKETS VALUE RX- CALIFORNIA',
  'HEALTH MARKETS VALUE RX- COLORADO',
  'HEALTH MARKETS VALUE RX- CONNECTICUT',
  'HEALTH MARKETS VALUE RX- DELAWARE',
  'HEALTH MARKETS VALUE RX- DISTRICT OF COLUMBIA',
  'HEALTH MARKETS VALUE RX- FLORIDA',
  'HEALTH MARKETS VALUE RX- GEORGIA',
  'HEALTH MARKETS VALUE RX- HAWAII',
  'HEALTH MARKETS VALUE RX- IDAHO',
  'HEALTH MARKETS VALUE RX- ILLINOIS',
  'HEALTH MARKETS VALUE RX- INDIANA',
  'HEALTH MARKETS VALUE RX- IOWA',
  'HEALTH MARKETS VALUE RX- KANSAS',
  'HEALTH MARKETS VALUE RX- KENTUCKY',
  'HEALTH MARKETS VALUE RX- LOUISIANA',
  'HEALTH MARKETS VALUE RX- MAINE',
  'HEALTH MARKETS VALUE RX- MARYLAND',
  'HEALTH MARKETS VALUE RX- MASSACHUSETTS',
  'HEALTH MARKETS VALUE RX- MICHIGAN',
  'HEALTH MARKETS VALUE RX- MINNESOTA',
  'HEALTH MARKETS VALUE RX- MISSISSIPPI',
  'HEALTH MARKETS VALUE RX- MISSOURI',
  'HEALTH MARKETS VALUE RX- MONTANA',
  'HEALTH MARKETS VALUE RX- NEBRASKA',
  'HEALTH MARKETS VALUE RX- NEVADA',
  'HEALTH MARKETS VALUE RX- NEW HAMPSHIRE',
  'HEALTH MARKETS VALUE RX- NEW JERSEY',
  'HEALTH MARKETS VALUE RX- NEW MEXICO',
  'HEALTH MARKETS VALUE RX- NORTH CAROLINA',
  'HEALTH MARKETS VALUE RX- NORTH DAKOTA',
  'HEALTH MARKETS VALUE RX- OHIO',
  'HEALTH MARKETS VALUE RX- OKLAHOMA',
  'HEALTH MARKETS VALUE RX- OREGON',
  'HEALTH MARKETS VALUE RX- PENNSYLVANIA',
  'HEALTH MARKETS VALUE RX- RHODE ISLAND',
  'HEALTH MARKETS VALUE RX- SOUTH CAROLINA',
  'HEALTH MARKETS VALUE RX- SOUTH DAKOTA',
  'HEALTH MARKETS VALUE RX- TENNESSEE',
  'HEALTH MARKETS VALUE RX- TEXAS',
  'HEALTH MARKETS VALUE RX- UTAH',
  'HEALTH MARKETS VALUE RX- VERMONT',
  'HEALTH MARKETS VALUE RX- VIRGINIA',
  'HEALTH MARKETS VALUE RX- WASHINGTON',
  'HEALTH MARKETS VALUE RX- WEST VIRGINIA',
  'HEALTH MARKETS VALUE RX- WISCONSIN',
  'HEALTH MARKETS VALUE RX- WYOMING',
  'HEALTH NET INC (CORPORATE)',
  'HEALTH NET OF ALABAMA',
  'HEALTH NET OF ALASKA',
  'HEALTH NET OF ARIZONA',
  'HEALTH NET OF ARKANSAS',
  'HEALTH NET OF CALIFORNIA',
  'HEALTH NET OF COLORADO',
  'HEALTH NET OF DELAWARE',
  'HEALTH NET OF FLORIDA',
  'HEALTH NET OF GEORGIA',
  'HEALTH NET OF HAWAII',
  'HEALTH NET OF IDAHO',
  'HEALTH NET OF ILLINOIS',
  'HEALTH NET OF INDIANA',
  'HEALTH NET OF IOWA',
  'HEALTH NET OF KANSAS',
  'HEALTH NET OF KENTUCKY',
  'HEALTH NET OF LOUISIANA',
  'HEALTH NET OF MAINE',
  'HEALTH NET OF MARYLAND',
  'HEALTH NET OF MASSACHUSETTS',
  'HEALTH NET OF MICHIGAN',
  'HEALTH NET OF MINNESOTA',
  'HEALTH NET OF MISSISSIPPI',
  'HEALTH NET OF MISSOURI',
  'HEALTH NET OF MONTANA',
  'HEALTH NET OF NEBRASKA',
  'HEALTH NET OF NEVADA',
  'HEALTH NET OF NEW HAMPSHIRE',
  'HEALTH NET OF NEW MEXICO',
  'HEALTH NET OF NORTH CAROLINA',
  'HEALTH NET OF NORTH DAKOTA',
  'HEALTH NET OF OHIO',
  'HEALTH NET OF OKLAHOMA',
  'Health Net of Oregon',
  'HEALTH NET OF OREGON',
  'HEALTH NET OF OREGON/WASHINGTON',
  'HEALTH NET OF PENNSYLVANIA',
  'HEALTH NET OF RHODE ISLAND',
  'HEALTH NET OF SOUTH CAROLINA',
  'HEALTH NET OF SOUTH DAKOTA',
  'HEALTH NET OF TENNESSEE',
  'HEALTH NET OF TEXAS',
  'HEALTH NET OF THE NORTHEAST INC - CONNECTICUT',
  'HEALTH NET OF THE NORTHEAST INC - NEW JERSEY',
  'HEALTH NET OF THE NORTHEAST INC - NEW YORK',
  'HEALTH NET OF UTAH',
  'HEALTH NET OF VERMONT',
  'HEALTH NET OF VIRGINIA',
  'HEALTH NET OF WASHINGTON',
  'HEALTH NET OF WASHINGTON D.C.',
  'HEALTH NET OF WEST VIRGINIA',
  'HEALTH NET OF WISCONSIN',
  'HEALTH NET OF WYOMING',
  'HEALTH NET PHARMACEUTICAL SERVICES',
  'HEALTH NETWORK OF AMERICA',
  'HEALTH NEW ENGLAND INC (CORPORATE)',
  'HEALTH NEW ENGLAND INC - MASSACHUSETTS',
  'HEALTH NEW ENGLAND INC.- CONNECTICUT',
  'Health Option One',
  'Health Partners',
  'HEALTH PARTNERS PLANS',
  'HEALTH PLAN ADMINISTRATORS',
  'HEALTH PLAN OF NEVADA/SIERRA HEALTH AND LIFE INSURANCE COMPANY INC',
  'HEALTH PLAN OF SAN JOAQUIN',
  'HEALTH PLAN OF SAN MATEO',
  'HEALTH PLAN SELECT INC',
  'HEALTH PLANS, INC',
  'HEALTH PLANS, INC.',
  'HEALTH PLUS',
  'HEALTH PLUS AMERIGROUP',
  'HEALTH PLUS OF LOUISIANA INC',
  'HEALTH POINTE OF NEW YORK',
  'Health Program of Alameda County (HealthPAC)',
  'Health Republic of New Jersey',
  'Health Republic of New York',
  'HEALTH RISK MANAGEMENT TPA',
  'Health Safety Net - HSN',
  'Health Services & Benefit Administration',
  'HEALTH SHARE',
  'Health Span',
  'HEALTH SPECTRUM PHARMACY',
  'HEALTH TRADITION HEALTH PLAN',
  'HEALTH WEST ADMINISTRATORS',
  'HEALTHAMERICA PENNSYLVANIA INC',
  'HEALTHCARE ALLIANCE',
  'HEALTHCARE COMPARE ADMINISTRATIVE',
  'Healthcare Highways Health Plan',
  'Healthcare Highways RX',
  'HEALTHCARE MANAGEMENT ADMINISTRATORS HMA',
  'Healthcare Options of El Paso',
  'HEALTHCARE PARTNERS',
  'Healthcare Partners NY',
  'HEALTHCARE SOLUTIONS GROUP INC.',
  'HEALTHCARE SUPPORT',
  'HEALTHCARE USA',
  'HEALTHCHOICE',
  'HEALTHCHOICE ONE (FL)    HMO',
  'HEALTHCOMP ADMINISTRATORS',
  'HEALTHEASE OF FLORIDA INC',
  'HEALTHEDGE ADMINISTRATORS',
  'HEALTHEZ',
  'HEALTHFIRST (CORPORATE)',
  'HEALTHFIRST - NEW JERSEY',
  'HEALTHFIRST - NEW YORK',
  'HEALTHFIRST OF TX',
  'HEALTHGRAM INC.',
  'HEALTHLINK',
  'Healthlink',
  'HEALTHMARKETS CARE ASSURED - OREGON',
  'HealthNow Administrative Services',
  'HEALTHNOW NEW YORK INC',
  'HEALTHNOW NEW YORK INC (COMPANY)',
  'HealthPartners',
  'HEALTHPARTNERS INC',
  'HEALTHPARTNERS INC (CORPORATE)',
  'HEALTHPARTNERS INC - ARIZONA',
  'HEALTHPARTNERS INC - NORTH DAKOTA',
  'HEALTHPARTNERS INC - SOUTH DAKOTA',
  'HEALTHPARTNERS INC - WISCONSIN',
  'HEALTHPARTNERS UNITYPOINT HEALTH - ILLINOIS',
  'HEALTHPARTNERS UNITYPOINT HEALTH - IOWA',
  'HealthPlan Services',
  'HEALTHPLUS OF MICHIGAN',
  'HealthRight Inc',
  'HEALTHSCOPE BENEFITS, INC.',
  'HealthSmart Benefit Solutions',
  'HealthSmart Benefit Solutions, Inc.',
  'HEALTHSMART RX',
  'HEALTHSPAN',
  'HEALTHSUN HEALTH PLANS INC',
  'HEALTHTEAM ADVANTAGE',
  'HEALTHTRANS LLC',
  'HealthWell Foundation',
  'HEALTHY BLUE',
  'HEALTHY OPTIONS',
  'HEALTHY PALM BEACHES INC',
  'HEALTHY SAN FRANCISCO',
  'HealthyCT',
  'HEARTLAND NATIONAL LIFE INSURANCE COMPANY',
  'HEARTLANDPLAINS HEALTH',
  'HENNEPIN HEALTH PLAN',
  'HEREIU WELFARE FUND',
  'HERITAGE CONSULTANTS',
  'HERITAGE HEALTH',
  'HFN Healthcares Finest Network',
  'HHH CHOICES HEALTH PLAN, LLC',
  'Hidalgo County Indigent Health Care Program',
  'High Desert Medical Group',
  'HIGHMARK BCBS DELAWARE',
  'HIGHMARK BCBS WEST VIRGINIA',
  'HIGHMARK BCBS/HIGHMARK CHOICE COMPANY',
  'Highmark BCBS/Keystone Health Plan - West',
  'HIGHMARK HEALTH INSURANCE COMPANY - WEST VIRGINIA',
  'HIGHMARK INC (COMPANY)',
  'Highmark J12 DC, DE, MD, NJ, PA',
  'HIGHMARK MEDICARE SERVICES',
  'HIGHMARK MEDICARE SERVICES INC.',
  'Hill Physicians Medical Group',
  'HILLSBOROUGH COUNTY HEALTH CARE',
  'HILLSBOROUGH HEALTHCARE',
  'HIP HEALTH PLAN OF NEW YORK',
  'HIP PHARMACY SERVICES',
  'HMA ADMINISTRATORS',
  'HMA LLC',
  'HMSA',
  'HOME STATE HEALTH PLAN',
  'HOMELAND HEALTHCARE',
  'HOMETOWN HEALTH',
  'HOMETOWNRX',
  'HOP Administration Unit',
  'Hope Pace',
  'HORIZON BCBS (CORPORATE)',
  'HORIZON BCBS OF NEW JERSEY',
  'HORIZON BCBS OF NEW JERSEY (COMPANY)',
  'HORIZON NJ HEALTH',
  'HOSPITALITY RX',
  'HPS SELECT PPO',
  'HSA HEALTH PLAN',
  'HTH WORLDWIDE INSURANCE SERVICES',
  'HUDSON HEALTH PLAN',
  'Humana',
  'HUMANA CARESOURCE',
  'humana gold choice',
  'HUMANA HEALTH BENEFIT PLAN OF LOUISIANA INC',
  'HUMANA INC - (CORPORATE)',
  'HUMANA INC - ALABAMA',
  'IBEW H&W DIST 11 (IA, MO, NE, ND, SD)',
  'IBEW H&W DIST 2 (CT, ME, MA, NH, RI, VT)',
  'IBEW H&W DIST 3 (DE, NJ, NY, PA)',
  'IBEW H&W DIST 4 (KY, MD, OH, VA, WV, DC)',
  'IBEW H&W DIST 5 (AL, FL, GA, LA, MS, PANAMA)',
  'IBEW H&W DIST 6 (IL, IN, MI, MN, WI)',
  'IBEW H&W DIST 7 (AZ, KS, NM, OK, TX)',
  'IBEW H&W DIST 9 (AK, CA, HI, NV, WA, PACIFIC ISLANDS)',
  'IBEW Local 124',
  'IBT - TEAMSTERS LOCAL 150',
  'IBT Teamsters Local 710',
  'iCore Healthcare',
  'ICW Group',
  'IE SHAFFER & COMPANY',
  'IEC BENEFIT ADMINISTRATORS',
  'IHC',
  'IHC Health Solutions',
  'IHS SEMINOLE TRIBE OF FLORIDA',
  'IIS BENEFITADMINISTRATORS',
  'ILLINICARE HEALTH PLAN',
  'ILLINOIS DEPARTMENT OF PUBLIC HEALTH OFFICE OF HEALTH PROMOTION',
  'ILLINOIS HEALTH SOLUTIONS',
  'ILWU-PMA WELFARE PLAN',
  'IMA  - INSURANCE MANAGEMENT ADMINISTRATORS',
  'IMAGINE HEALTH',
  'IMCARE',
  'IMPERIAL HEALTH PLAN OF CALIFORNIA',
  'INDECS CORPORATION',
  'Indegent Programs',
  'Independence Administrators',
  'INDEPENDENCE BLUE CROSS',
  'INDEPENDENCE BLUE CROSS (CORPORATE)',
  'INDEPENDENCE BLUE CROSS/KEYSTONE HEALTH PLAN EAST',
  'INDEPENDENCE CARE SYSTEM',
  'Independence Medical Group',
  'INDEPENDENT CARE HEALTH PLAN',
  'INDEPENDENT CARE HEALTH PLAN  (CORPORATE)',
  'INDEPENDENT HEALTH',
  'INDIAN HEALTH SERVICES',
  'Indiana Laborers Welfare Fund',
  'INDIANA STATE COUNCIL OF ROOFERS HEALTH AND FUND',
  'indiana university health plan',
  'Indiana/Kentucky Carpenters Welfare Fund',
  'INDIGENT CARE PROGRAM',
  'Indigent Care Program VCU Health Systems',
  'INDIGENT HEALTH CARE PROGRAM PARKER COUNTY',
  'Individual Assurance Company',
  'INGENIORX',
  'INGHAM HEALTH PLAN',
  'INHEALTH MUTUAL',
  'INLAND EMPIRE HEALTH PLAN',
  'Inland Empire Health Plan - IEHP',
  'INNOVACARE HEALTH (COMPANY)',
  'InnovAge Insurance Plan',
  'INNOVATION HEALTH INSURANCE COMPANY',
  'INNOVATIVE ADMINISTRATIVE SERVICES',
  'INNOVATIVE HEALTH PLAN',
  'INNOVIANT - A PRESCRIPTION SOLUTIONS COMPANY',
  'INSERVCO INSURANCE SERVICES',
  'INSULATORS AND ASBESTOS WORKERS LOCAL #14',
  'INSURANCE ADMINISTRATIVE CORPORATION',
  'Insurance Administrative Solutions (IAC)',
  'INSURANCE ADMINISTRATORS OF AMERICA - IAA',
  'INSURANCE DESIGN ADMINISTRATORS (IDA)',
  'Insurance Management Services',
  'INSURANCE MGMT SERVICES',
  'INSURANCE PROGRAM MANAGERS GROUP',
  'INSURANCE SERVICES OF LUBBOCK / ISOL',
  'INSURANCE SYSTEMS, INC.',
  'INSURANCE TRUST (ISTA)',
  'InsuranceTPA.com',
  'INTEGRA ADMINISTRATIVE GROUP',
  'INTEGRA BMS',
  'INTEGRAL QUALITY CARE HEALTH PLAN',
  'Integrated Prescription Management (IPM)',
  'INTEGRITY HEALTH PLAN OF MS',
  'INTER VALLEY HEALTH PLAN',
  'Inter-County Hospitalization Plan Inc.',
  'INTERACTIVE MEDICAL SYSTEMS',
  'INTERCOMMUNITY HEALTH NETWORK COORDINATED CARE ORGANIZATION',
  'INTERMOUNTAIN ADMINISTRATORS',
  'International Benefit Administrators',
  'INTERNATIONAL BROTHERHOOD OF ELECTRICAL WORKERS',
  'INTERNATIONAL BROTHERS OF PAINTERS HEALTH FUND',
  'International Longshore and Warehouse Union',
  'INTERNATIONAL MEDICAL GROUP - IMG',
  'INTERNATIONAL UNION OF BRICKLAYERS AND ALLIED CRAFTWORKERS',
  'International Union of Operating Engineers (OEBF) Local 37',
  'International Union of Operating Engineers Local 132',
  'INTERPLAN HEALTH GROUP',
  'INTOTAL HEALTH',
  'INVESTORS INSURANCE CO. OF AMERICA',
  'INVISION RX PLUS',
  'IOWA HEALTH SOLUTIONS',
  'IOWA TOTAL CARE',
  'IRON WORKERS HEALTH & WELFARE',
  'Iron Workers Health & Welfare',
  'Iron Workers Local Union 40',
  'IRONWORKERS EMPLOYEES BENEFIT CORPORATION',
  'ISLAND GROUP',
  'ISM Insurance',
  'ITPE - INDUSTRIAL TECH & PROF EMPLOYEE NATL MARITIME UNION',
  'IU HEALTH PLANS',
  'IUOE Local 137',
  'IUOE LOCAL 542',
  'J&K Consultants',
  'JacksonHealth',
  'JAI MEDICAL SYSTEMS MANAGED CARE ORGANIZATION INC',
  'JFP BENEFIT MANAGEMENT',
  'JMH HEALTH PLAN',
  'JOHN HOPKINS',
  'JOHN MUIR PHYSICIANS NETWORK',
  'Johns Hopkins Advantage medicare',
  'JOHNS HOPKINS EMPLOYER HEALTH PROGRAMS',
  'Johns Hopkins Employer Health Programs',
  'JOINT BENEFIT TRUST',
  'JOURNEY RX - (CORPORATE)',
  'JP FARLEY CORPORATION (TPA)',
  'JPS Health Network',
  'KAISER FOUNDATION HEALTH PLAN INC (CORPORATE)',
  'KAISER FOUNDATION HEALTH PLAN INC - CALIFORNIA',
  'KAISER FOUNDATION HEALTH PLAN INC - COLORADO',
  'KAISER FOUNDATION HEALTH PLAN INC - DISTRICT OF COLUMBIA',
  'KAISER FOUNDATION HEALTH PLAN INC - GEORGIA',
  'KAISER FOUNDATION HEALTH PLAN INC - HAWAII REGION',
  'KAISER FOUNDATION HEALTH PLAN INC - MARYLAND',
  'KAISER FOUNDATION HEALTH PLAN INC - NORTHERN CALIFORNIA',
  'KAISER FOUNDATION HEALTH PLAN INC - NORTHWEST',
  'KAISER FOUNDATION HEALTH PLAN INC - OREGON',
  'KAISER FOUNDATION HEALTH PLAN INC - SOUTHERN CALIFORNIA',
  'KAISER FOUNDATION HEALTH PLAN INC - VIRGINIA',
  'KAISER FOUNDATION HEALTH PLAN INC - WASHINGTON',
  'KAISER FOUNDATION HEALTH PLAN OF THE MID-ATLANTIC',
  'KAISER PERMANENTE SOUTHERN CALIFORNIA',
  'KALOS HEALTH',
  'KANAWHA HEALTHCARE SOLUTIONS',
  'Kansas Building Trades Open End Health and Welfare Fund',
  'KANSAS HEALTH ADVANTAGE',
  'KEENAN & ASSOCIATES',
  'KELSEYCARE ADVANTAGE',
  'Kemper Benefits',
  'KEMPTON GROUP ADMINISTRATORS',
  'KENTUCKY ACCESS',
  'KENTUCKY EMPLOYERS\' MUTUAL INSURANCE (KEMI)',
  'KENTUCKY HEALTH ADMINISTRATORS',
  'KENTUCKY HEALTH COOPERATIVE, INC',
  'KENTUCKY LABORERS DISTRICT COUNCIL',
  'Kentucky Laborers Health and Welfare Fund',
  'Kentucky Racing Health and Welfare Fund',
  'KENTUCKY SPIRIT HEALTH PLAN',
  'KERN FAMILY HEALTH CARE',
  'KEY BENEFIT ADMINISTRATORS',
  'Key Benefit Concepts',
  'KEY BENEFITS ADMINISTATORS',
  'KEY HEALTH PLAN, INC.',
  'KEY LINK SOLUTIONS INC',
  'KEYLINK SOLUTIONS, INC.',
  'KEYSTONE FIRST',
  'KEYSTONE HEALTH PLAN - WEST',
  'KG ADMINISTRATIVE SERVICES',
  'Kingston Trust Fund',
  'KLAMATH TRIBAL HEALTH & FAMILY SERVICES',
  'KOHLL\'S RX',
  'KPS HEALTH PLANS',
  'KROGER MANAGED PRESCRIPTION DRUG PLAN',
  'KSKJ LIFE',
  'LA CARE HEALTH PLAN',
  'LA COUNTY FIRE FIGHTERS UNION LOCAL 1014',
  'LA SALLE MEDICAL ASSOCIATION',
  'LABORER\'S HEALTH & WELFARE IL',
  'LABORER\'S HEALTH & WELFARE NV',
  'Laborers Health and Welfare Trust Fund for Northern California',
  'Laborers Health and Welfare Trust Fund for Southern CA',
  'LABORERS LOCAL 1298',
  'LABORERS NATIONAL HEALTH AND WELFARE FUND',
  'LABORERS TRUST FUNDS NO. CA',
  'LAKE AREA COMMUNITY HEALTH PLAN',
  'LAND OF LINCOLN HEALTH',
  'LANE OREGON HEALTH PLAN',
  'LBA HEALTHPLANS',
  'LDI INTEGRATED PHARMACY SERVICES',
  'LEGACY HEALTH PLAN',
  'LEGGETT & PLATT',
  'LEON MEDICAL CENTERS HEALTH INC',
  'LIBERTY ADVANTAGE',
  'LIBERTY BANKERS LIFE',
  'Liberty Health Advantage',
  'LIBERTY HEALTH ADVANTAGE INC',
  'LIBERTY MUTUAL INSURANCE GROUP',
  'LIBERTY NATIONAL LIFE INSURANCE COMPANY',
  'LIBERTY UNION LIFE ASSURANCE COMPANY',
  'Life Benefit Plan',
  'LIFE St Francis',
  'Lifemap',
  'LIFEPRINT',
  'LIFESHIELD NATIONAL INSURANCE COMPANY',
  'Lifetime Benefit Solutions',
  'LIFEWELL HEALTH PLAN',
  'LIFEWISE HEALTH PLAN OF ARIZONA',
  'LIFEWISE HEALTH PLAN OF OREGON',
  'LIFEWISE HEALTH PLAN OF WASHINGTON',
  'LIFEWORKS ADVANTAGE',
  'LIGHTHOUSE GUILD',
  'LIGHTHOUSE HEALTH PLAN',
  'LINCOLN FINANCIAL GROUP',
  'Lincoln Heritage Life Insurance',
  'LINCOLN HERITAGE LIFE INSURANCE COMPANY',
  'Line Construction Benefit Fund',
  'LINECO-FIRST HEALTH',
  'LITTLE RIVER BAND OF OTTAWA INDIANS',
  'Little Traverse Bay Band, Contract Health',
  'LOCAL 15 WELFARE FUND',
  'LOCAL 198',
  'Local 25 S.E.I.U Welfare Fund',
  'Local 464A UFCWUnion Welfare Service Benefit Fund',
  'LOCAL 478 OPERATING ENGINEERS',
  'LOCAL 597 PIPEFITTERS UNION',
  'LOCAL 670 WELFARE FUND',
  'LOCAL UNION 25, PLUMBERS & PIPEFITTERS',
  'LOCKARD & WILLIAMS',
  'Logistics Health Inc.',
  'Lone Star',
  'LONGEVITY HEALTH PLAN - (CORPORATE)',
  'LOOMIS COMPANY',
  'LOS ANGELES FIREMAN\'S RELIEF ASSOCIATION',
  'Louisiana HBPA',
  'LOUISIANA HEALTHCARE CONNECTIONS',
  'LOVELACE HEALTH PLAN - NEW MEXICO',
  'LOVELACE HEALTH PLAN - OKLAHOMA',
  'Loyal American Life Insurance Company GASB',
  'LOYAL CHRISTIAN BENEFIT  ASSOCIATION',
  'LUCENT HEALTH',
  'LUMICO',
  'LUTHERAN HEALTH SYSTEM',
  'MACo Montana Association of Counties',
  'MACY\'S BLOOMINGDALES HR SERVICES',
  'Maestro Health',
  'MAGELLAN',
  'MAGELLAN COMPLETE CARE OF ARIZONA',
  'MAGELLAN COMPLETE CARE OF FLORIDA',
  'Magellan Complete Care of Virginia',
  'MAGELLAN MEDICAID ADMINISTRATION INC',
  'MAGELLAN RX MANAGEMENT',
  'MAGELLAN RX MEDICARE - TENNESSEE',
  'MAGELLAN RX MEDICARE- (CORPORATE)',
  'MAGELLAN RX MEDICARE- ALABAMA',
  'MAGELLAN RX MEDICARE- ARIZONA',
  'MAGELLAN RX MEDICARE- ARKANSAS',
  'MAGELLAN RX MEDICARE- COLORADO',
  'MAGELLAN RX MEDICARE- DELAWARE',
  'MAGELLAN RX MEDICARE- DISTRICT OF COLUMBIA',
  'MAGELLAN RX MEDICARE- GEORGIA',
  'MAGELLAN RX MEDICARE- IDAHO',
  'MAGELLAN RX MEDICARE- ILLINOIS',
  'MAGELLAN RX MEDICARE- INDIANA',
  'MAGELLAN RX MEDICARE- IOWA',
  'MAGELLAN RX MEDICARE- KENTUCKY',
  'MAGELLAN RX MEDICARE- LOUISIANA',
  'MAGELLAN RX MEDICARE- MAINE',
  'MAGELLAN RX MEDICARE- MARYLAND',
  'MAGELLAN RX MEDICARE- MICHIGAN',
  'MAGELLAN RX MEDICARE- MINNESOTA',
  'MAGELLAN RX MEDICARE- MISSOURI',
  'MAGELLAN RX MEDICARE- MONTANA',
  'MAGELLAN RX MEDICARE- NEBRASKA',
  'MAGELLAN RX MEDICARE- NEW HAMPSHIRE',
  'MAGELLAN RX MEDICARE- NEW MEXICO',
  'MAGELLAN RX MEDICARE- NEW YORK',
  'MAGELLAN RX MEDICARE- NORTH CAROLINA',
  'MAGELLAN RX MEDICARE- NORTH DAKOTA',
  'MAGELLAN RX MEDICARE- PENNSYLVANIA',
  'MAGELLAN RX MEDICARE- SOUTH DAKOTA',
  'MAGELLAN RX MEDICARE- TEXAS',
  'MAGELLAN RX MEDICARE- UTAH',
  'MAGELLAN RX MEDICARE- VIRGINIA',
  'MAGELLAN RX MEDICARE- WEST VIRGINIA',
  'MAGELLAN RX MEDICARE- WYOMING',
  'MAGNA CARTA COMPANIES',
  'MagnaCare',
  'MagnaCare Rx',
  'MAGNOLIA HEALTH PLAN',
  'MAILHANDLERS BENEFIT PLAN',
  'MAINE COMMUNITY HEALTH OPTIONS',
  'MAINE EMPLOYERS MUTUAL INSURANCE CO',
  'MaineCare',
  'MAINECARE',
  'MAJESTACARE',
  'Makina Health Cooperative',
  'Man-U Service Contract Trust',
  'Managed Care Administrators Inc',
  'MANAGED CARE SYSTEMS',
  'MANAGED HEALTH SERVICES - INDIANA',
  'Manchester Town and Board of Education',
  'Manhattan Insurance Group',
  'MAPFRE LIFE INSURANCE CO',
  'MAPFRE MEDICARE EXCEL',
  'MARICOPA HEALTH PLAN',
  'Maricopa Health Plan',
  'MARION POLK COMMUNITY HEALTH PLAN',
  'Marquette National Life Insurance Company',
  'MARSH ADVANTAGE AMERICA',
  'MARSH AFFINITY GROUP SERVICES',
  'MARTIN`S POINT GENERATIONS ADVANTAGE- (CORPORATE)',
  'MARTIN`S POINT GENERATIONS ADVANTAGE- MAINE',
  'MARTIN`S POINT GENERATIONS ADVANTAGE- NEW HAMPSHIRE',
  'Mary Washington Medicare Advantage',
  'MARYKNOLL FATHERS AND BROTHERS',
  'MARYLAND PHYSICIANS CARE INC',
  'MASS HEALTH',
  'MASTER MATES & PILOTS HEALTH & BENEFITS PLAN',
  'Matrix Healthcare Services',
  'MaxCare',
  'Maxor Administrative Services',
  'MAXORPLUS',
  'MAYO CLINIC',
  'Mayo Health Plan',
  'MAYO MANAGEMENT SVCS, INC - MMSI',
  'MAYO MEDICAL PLAN- MMSI',
  'MB Health',
  'MC-21 CORPORATION',
  'MCA ADMINISTRATORS / MANAGED CARE OF AMERICA',
  'McDonalds Insurance Program',
  'MCKEE FOODS',
  'MCLAREN HEALTH PLAN',
  'MCLAREN HEALTHCARE CORPORATION',
  'MCS LIFE INSURANCE COMPANY',
  'MD CARE HEALTH PLAN',
  'MDWISE',
  'MED PAY',
  'MEDALISTRX',
  'MEDBEN (MEDICAL BENEFITS ADMIN)',
  'MEDBEN - MEDICAL BENEFITS ADMINISTRATION',
  'MEDCO HEALTH SOLUTIONS INC',
  'MEDCOM',
  'MEDCOST BENEFIT ADMINISTRATORS',
  'MEDEX',
  'Medford Leas',
  'MEDI AMERICA',
  'MEDI-CARE FIRST BCBS',
  'MEDICA (CORPORATE)',
  'MEDICA - IOWA',
  'MEDICA - KANSAS',
  'MEDICA - MINNESOTA',
  'MEDICA - NEBRASKA',
  'MEDICA - NORTH DAKOTA',
  'MEDICA - SOUTH DAKOTA',
  'MEDICA - WISCONSIN',
  'MEDICA HEALTH PLANS OF FLORIDA INC',
  'Medicaid IL',
  'Medicaid NC',
  'Medical Assistance Program (MAP)',
  'MEDICAL ASSOCIATES HEALTH PLAN INC - (CORPORATE)',
  'MEDICAL ASSOCIATES HEALTH PLAN INC - ILLINOIS',
  'MEDICAL ASSOCIATES HEALTH PLAN INC - IOWA',
  'MEDICAL ASSOCIATES HEALTH PLAN INC - WISCONSIN',
  'MEDICAL BENEFIT ADMINISTRATORS',
  'MEDICAL CENTER PHARMACY',
  'MEDICAL CLAIMS MANAGEMENT CORP.',
  'Medical Data Exchange (MDX)',
  'Medical Fund Advisors',
  'MEDICAL MUTUAL',
  'MEDICAL MUTUAL (CORPORATE)',
  'MEDICAL MUTUAL OF OHIO',
  'Medicare AK',
  'Medicare AL',
  'Medicare AR',
  'Medicare AZ',
  'Medicare CA',
  'Medicare CO',
  'Medicare CT',
  'Medicare DC',
  'Medicare DE',
  'Medicare FL',
  'Medicare GA',
  'Medicare GenerationRx',
  'Medicare GU',
  'Medicare HI',
  'Medicare IA',
  'Medicare ID',
  'Medicare IL',
  'Medicare IN',
  'Medicare KS',
  'Medicare KY',
  'Medicare LA',
  'Medicare MA',
  'Medicare MD',
  'Medicare ME',
  'Medicare MI',
  'Medicare MN',
  'Medicare MO',
  'Medicare MS',
  'Medicare MT',
  'Medicare NC',
  'Medicare ND',
  'Medicare NE',
  'Medicare NH',
  'Medicare NJ',
  'Medicare NM',
  'Medicare NV',
  'Medicare NY',
  'Medicare of PR',
  'Medicare OH',
  'Medicare OK',
  'Medicare OR',
  'Medicare PA',
  'Medicare PR',
  'Medicare RI',
  'Medicare SC',
  'Medicare SD',
  'Medicare TN',
  'Medicare TX',
  'MEDICARE UPSTATE',
  'Medicare UT',
  'Medicare VA',
  'Medicare VI',
  'Medicare VT',
  'Medicare WA',
  'Medicare WI',
  'Medicare WV',
  'Medicare WY',
  'Medico Group',
  'MEDIGOLD',
  'MEDIGOLD - (CORPORATE)',
  'MediHOP',
  'MEDIMPACT HEALTHCARE SYSTEMS INC',
  'MediView',
  'MEDMETRICS HEALTH PARTNERS INC',
  'MEDONE',
  'MedOne Pharmacy Services',
  'Medova Healthcare Financial Group',
  'MEDPARTNERS',
  'MEDPAY, INC',
  'MedPoint Management',
  'MEDSTAR FAMILY CHOICE (CORPORATE)',
  'MEDSTAR FAMILY CHOICE - DISTRICT OF COLUMBIA',
  'MEDSTAR FAMILY CHOICE - MARYLAND',
  'MEDTIPSTER',
  'MEDTRAK SERVICES',
  'MEDWISE',
  'MEGA LIFE & HEALTH INSURANCE COMPANY',
  'MEMBER INSURANCE',
  'MEMBERHEALTH LLC',
  'MEMBERS HEALTH',
  'MEMBERS HEALTH INSURANCE COMPANY',
  'MEMORIAL CANCER INSTITUTE',
  'Memorial Consumer Health Plan (MCHP)',
  'MEMORIAL HEALTHCARE SYSTEM',
  'MEMORIAL HERMANN HEALTH INSURANCE COMPANY',
  'MEMORIAL HERMANN HEALTHCARE SYSTEMS',
  'MEMORIAL MANAGED CARE PLAN',
  'MENNONITE MUTUAL AID ASSOCIATION (CORPORATE)',
  'MENNONITE MUTUAL AID ASSOCIATION - FLORIDA',
  'MENNONITE MUTUAL AID ASSOCIATION - PENNSYLVANIA',
  'MERCER ADMINISTRATOR (FKA ACEC INSURANCE)',
  'MERCER HUMAN RESOURCE CONSULTING',
  'Merchants Benefit Administration Inc',
  'MERCY BENEFIT ADMINISTRATORS',
  'MERCY CARE PLAN',
  'MERCY HEALTH PLANS (CORPORATE)',
  'NALC Health Benefit Plan',
  'NaphCare',
  'NATIONAL ASBESTOS WORKERS MEDICAL FUND',
  'NATIONAL ASSOCIATION OF LETTER CARRIERS(NALC)',
  'NATIONAL AUTOMATIC SPRINKLER INDUSTRY',
  'NATIONAL BENEFIT ADMINISTRATORS NBA',
  'NATIONAL ELEVATOR INDUSTRY HEALTH PLAN',
  'NATIONAL EMPLOYEE BENEFIT COMPANY - NEBCO',
  'NATIONAL EMPLOYEE BENEFITS ADMINISTRATORS',
  'NATIONAL FOUNDATION LIFE INSURANCE COMPANY',
  'NATIONAL GENERAL BENEFITS SOLUTIONS',
  'NATIONAL GOVERNMENT SERVICES, INC',
  'National Guardian Life insurance',
  'National Health Administrators',
  'NATIONAL HEALTH INSURANCE COMPANY',
  'National Health Plan Corporation',
  'NATIONAL LIFE INSURANCE',
  'NATIONAL PHARMACEUTICAL SERVICES',
  'National Pharmaceutical Services',
  'National Rural Electric Cooperative Association',
  'NATIONAL SCRIPT',
  'National Script',
  'NATIONAL STATES INSURANCE COMPANY',
  'NATIONAL TELEPHONE COOP ASSOC - NTCA',
  'National Union Fire Insurance Company (NUFIC)',
  'NATIONWIDE HEALTH PLANS (CORPORATE)',
  'NATIONWIDE MUTUAL INSURANCE COMPANY',
  'NATIONWIDE PUBLIC EMPLOYEE TRUST',
  'Native Care Health',
  'Nativus',
  'NAVITUS HEALTH SOLUTIONS LLC',
  'NC MEDASSIST',
  'NCAS',
  'NEA GROUP INSURANCE PLAN',
  'NEA MEMBERS INSURANCE',
  'NEBA',
  'NEBRASKA TOTAL CARE',
  'NECA-IBEW LOCAL 176 HEALTH & WELFARE FUND',
  'NECA-IBEW Pension Trust Fund',
  'NEIGHBORHOOD HEALTH PARTNERSHIP',
  'NEIGHBORHOOD HEALTH PLAN',
  'NEIGHBORHOOD HEALTH PLAN OF RHODE ISLAND',
  'NEIGHBORHOOD HEALTH PROVIDERS',
  'NETCARE LIFE AND INSURANCE',
  'Network Health Plan',
  'NETWORK HEALTH PLAN',
  'Network Select',
  'Nevada Health Co-op',
  'New Benefits',
  'New Century Health',
  'New England Electrical Workers Benefit Fund (NEEWBF)',
  'New England Health Care Fund',
  'NEW ERA LIFE INSURANCE',
  'New Hampshire Healthy Families',
  'New Hampshire Healthy Kids',
  'NEW JERSEY CARPENTERS FUND',
  'New Mexico Health Connections',
  'New Mexico Medical Insurance Pool (NMMIP',
  'New River Valley Cancer Center',
  'NEW WEST HEALTH SERVICES',
  'NEW YORK HOTEL TRADE COUNSEL & HOTEL ASSOC',
  'NEW YORK LIFE INSURANCE CO',
  'NEW YORK PRESBYTERIAN COMMUNITY HEALTH PLAN',
  'New York State Insurance Fund',
  'NEW YORK WORK COMPENSATION',
  'NEXCALIBER',
  'NEXT LEVEL HEALTH PLAN',
  'NFP Benefit Alliance',
  'NGS AMERICAN, INC.',
  'NH Healthy Families',
  'NH HEALTHY FAMILIES',
  'NHC ADVANTAGE',
  'NHIC J14 MA, ME, NH, RI, VT',
  'Nippon Life Benefits',
  'NIPPON LIFE INSURANCE COMPANY - NPI',
  'NJ healthcare administrators',
  'NJPLIGA',
  'NONSTOP WELLNESS',
  'NORIDIAN',
  'NORTH AMERICA ADMINISTRATORS, L.P.',
  'NORTH AMERICAN HEALTH PLANS',
  'North American Insurance Co.',
  'NORTH CAROLINA STATE HEALTH PLAN',
  'NORTH CENTRAL STATES REGIONAL COUNCIL OF CARPENTER\'S HEALTH FUND',
  'NORTH CENTRAL STATES REGIONAL COUNCIL OF CARPENTERS HEALTH FUND',
  'NORTH COUNTY SELECT',
  'NORTH DAKOTA WORKFORCE SAFETY & INSURANCE',
  'North Shore LIJ Connect',
  'NORTH SHORE-LIJ HEALTH PLAN INC',
  'NORTHEAST COMMUNITY CARE - MAINE',
  'NORTHEAST COMMUNITY CARE - NEW HAMPSHIRE',
  'NORTHEAST COMMUNITY CARE - NEW YORK',
  'NORTHEAST HEALTH PARTNERS',
  'Northeast Health Plan',
  'NORTHERN CALIFORNIA BAKERY DRIVERS',
  'NORTHERN ILLINOIS HEALTH PLAN',
  'Northern Nevada Operating Engineers Health & Welfare Trust Fund',
  'NORTHWEST ADMINISTRATORS',
  'Northwest Laborers Trust Fund',
  'NORTHWEST OH (NWO) PLUMBERS & PIPEFITTERS HEALTH & WELFARE FOR LOCAL 50',
  'NORTHWEST PHARMACY SERVICES',
  'Northwest Retiree Benefit trust',
  'Northwest Senior Disability Services',
  'NORTHWEST TEXTILE TRUST FUND',
  'NORTHWESTERN COMM HOSPITAL',
  'NOVA',
  'NOVA HEALTHCARE ADMINISTRATORS',
  'Novaitas Solutions',
  'NOVASYS HEALTH',
  'NTCA',
  'NW PLUMBING & PIPE FILTERS',
  'NY HOTEL TRADES COUNCIL & HOTEL ASSOCIATION OF NYC',
  'NYS WORKERS\' COMPENSATION BOARD',
  'OBA MIDWEST, LTD',
  'ODS HEALTH PLAN (CORPORATE)',
  'OFFICE OF GROUP BENEFITS',
  'Ohio Bureau of Workers\' Compensation',
  'OHIO CARPENTERS HEALTH & WELFARE FUND',
  'Ohio Health Choice',
  'OHIO HEALTH GROUP',
  'OhioBWC',
  'OHSU',
  'OHSU HEALTH SERVICES',
  'OKLAHOMA STATE MEDICAL ASSOCIATION - OSMA',
  'OLD REPUBLIC INSURANCE COMPANY',
  'OLD SURETY LIFE INSURANCE COMPANY',
  'OLYMPUS MANAGED HEALTHCARE INC',
  'OMEDARX',
  'OMNICARE HEALTH PLAN',
  'One Call Worker\'s Comp',
  'ONEIDA COMMUNITY HEALTH CENTER',
  'OneNet',
  'ONESHARE HEALTH',
  'ONESOURCE ADMINISTRATORS, LTD',
  'Ontario Health Insurance Plan',
  'OPELOUSAS CANCER CENTER INFUSION',
  'OPERATING ENGINEERS HEALTH&WELFARE FUND',
  'OPERATING ENGINEERS IUOE',
  'Operating Engineers Local 139',
  'OPERATING ENGINEERS LOCAL 501 SECURITY FUND',
  'OPERATING ENGINEERS TRUST FUNDS - LOCAL 3',
  'OptiCare',
  'OPTICARE MANAGED VISION',
  'OPTIMA HEALTH PLAN',
  'OptiMed',
  'OPTIMUM HEALTHCARE INC',
  'OptumCare',
  'OPTUMHEALTH',
  'OptumHealth',
  'OPTUMRX',
  'orange county medical',
  'Orange County Medical Clinic',
  'OrchestraRx',
  'OrchestrateHR*',
  'Oregon Health Management Services',
  'OREGON TEAMSTER EMPLOYERS TRUST (OTET)',
  'Oregon\'s Health CO-OP',
  'Oscar Insurance',
  'OSCAR INSURANCE CORPORATION (CORPORATE)',
  'OSCAR OF ARIZONA',
  'OSCAR OF CALIFORNIA',
  'OSCAR OF COLORADO',
  'OSCAR OF FLORIDA',
  'OSCAR OF GEORGIA',
  'OSCAR OF KANSAS',
  'OSCAR OF MICHIGAN',
  'OSCAR OF MISSOURI',
  'OSCAR OF NEW JERSEY',
  'OSCAR OF OHIO',
  'OSCAR OF PENNSYLVANIA',
  'OSCAR OF TENNESSEE',
  'OSCAR OF TEXAS',
  'OSCAR OF VIRGINIA',
  'OSCHNER HEALTH PLAN',
  'OSF HEALTH PLANS INC',
  'OSU HEALTH PLAN',
  'Oxford health plan',
  'Oxford Health Plans',
  'Oxford Health Plans (A United Healthcare Company)',
  'OXFORD LIFE INSURANCE COMPANY',
  'OZARK HEALTH PLAN',
  'PA HEALTH AND WELLNESS',
  'PACE IOWA',
  'PACE Program',
  'PACE/PACENET',
  'Pacific Care',
  'PACIFIC HEALTH ADMINISTRATORS',
  'PACIFIC HEALTH ALLIANCE',
  'Pacific Southwest Administrators',
  'PACIFICSOURCE HEALTH PLANS (CORPORATE)',
  'PACIFICSOURCE HEALTH PLANS - IDAHO',
  'PACIFICSOURCE HEALTH PLANS - MONTANA',
  'PACIFICSOURCE HEALTH PLANS - OREGON',
  'PACIFICSOURCE HEALTH PLANS - WASHINGTON',
  'PAINTERS UNION PENSION FUND',
  'PALMETTO GBA',
  'Palmetto J11 VA, WV, SC, NC',
  'PAN AMERICAN LIFE (BENEFIT SOLUTIONS)',
  'PAN-AMERICAN LIFE INSURANCE',
  'PARADIGM ADMINISTRATORS',
  'PARAGON BENEFITS',
  'PARAMOUNT HEALTH CARE INC - MICHIGAN',
  'PARAMOUNT HEALTH CARE INC - OHIO',
  'Paramount Healthcare',
  'PARAMOUNTRX',
  'PARKLAND COMMUNITY HEALTH PLAN INC',
  'Parkview Signature Care',
  'PARTNERS HEALTH PLAN',
  'PARTNERS HEALTHCARE CHOICE',
  'PARTNERS NATIONAL HEALTH PLAN OF NORTH CAROLINA INC',
  'PARTNERSHIP HEALTHPLAN OF CALIFORNIA',
  'Partnership HealthPlan of California',
  'PASSPORT HEALTH PLAN',
  'Passport Medicaid',
  'PATH ADMINISTRATORS',
  'PATIENT ADVOCATE LLC',
  'PATIENT SERVICES INC (PSI)',
  'Pawnee Indian Health',
  'Payer Fusion (WEA)',
  'PBA HEALTH',
  'PDC RX',
  'PEACH STATE HEALTH PLAN',
  'Peak Pace Solutions',
  'PEHP/PUBLIC EMPLOYEES HEALTH PROGRAM (UT)',
  'PEKIN INSURANCE CO.',
  'PENN TREATY NETWORK AMERICAN',
  'PENN WESTERN BENEFITS, INC.',
  'PENNSYLVANIA EMPLOYEE BENEFIT TRUST FUND',
  'Pennsylvania Life Insurance Company',
  'Pension Hospital Benefit Plan',
  'PEOPLES HEALTH',
  'PEQUOT PHARMACEUTICAL NETWORK (PRXN)',
  'PERENNIAL CARE',
  'PERFECTHEALTH INSURANCE COMPANY',
  'Perform Specialty LLC',
  'Performance Health',
  'PERFORMANCE HEALTH TECHNOLOGY',
  'PERFORMRX',
  'PERSONAL INSURANCE ADMINISTRATORS - PIA',
  'Pharmaceutical Assistance to the Aged and Disabled (PAAD)',
  'PHARMACEUTICAL HORIZONS INC',
  'PHARMACEUTICAL TECHNOLOGIES INC',
  'Pharmacy Benefit Dimensions',
  'PHARMACY DATA MANAGEMENT INC',
  'PHARMACY FIRST',
  'PHARMACY INSURANCE CORPORATION OF AMERICA (PICA)',
  'PHARMACY PROVIDERS OF OKLAHOMA',
  'PHARMASTAR LLC',
  'Pharmavail',
  'PharmPix',
  'PHARMPIX',
  'PHCS',
  'PHILADELPHIA AMERICAN LIFE CO',
  'Philadelphia American Life Insurance Company',
  'Phoenix Benefits Management, LLC',
  'PHOENIX HEALTH PLAN',
  'PHYSICIANS HEALTH CHOICE - (CORPORATE)',
  'PHYSICIANS HEALTH CHOICE OF ARKANSAS',
  'PHYSICIANS HEALTH CHOICE OF FLORIDA',
  'PHYSICIANS HEALTH CHOICE OF NEW MEXICO',
  'PHYSICIANS HEALTH CHOICE OF TEXAS',
  'PHYSICIANS HEALTH PLAN',
  'PHYSICIANS HEALTH PLAN OF NORTHERN INDIANA INC',
  'PHYSICIANS HEALTH PLAN OF SOUTHWEST MICHIGAN INC',
  'PHYSICIANS MUTUAL INSURANCE COMPANY',
  'PHYSICIANS PLUS INSURANCE CORPORATION',
  'Physicians Reach Out',
  'PHYSICIANS UNITED PLAN INC',
  'PHYSICIANS\' BENEFITS TRUST LIFE INSURANCE COMPANY',
  'PIEDMONT COMMUNITY HEALTHCARE INC',
  'PIEDMONT WELLSTAR HEALTH PLANS',
  'PILLOWPH',
  'PIMA HEALTH SYSTEM',
  'Pinellas County Health Program',
  'PINNACLE CLAIMS MANAGEMENT, INC / PCMI',
  'PINNACLE RX SOLUTIONS',
  'PIONEER LIFE',
  'PIPE FITTERS WELFARE FUND LOCAL 597',
  'PIPE TRADES HEALTH & WELFARE',
  'PIPELINE INSURANCE',
  'PITTMAN & ASSOCIATES',
  'Plan Administrators, Inc. (PAI)',
  'Plan de Salud Hospital General Menonita',
  'PLANNED ADMINISTRATORS',
  'Plumbers & Fitters 110',
  'PLUMBERS & PIPE LOCAL 422',
  'PLUMBERS & PIPEFITTERS LOCAL 344',
  'PLUMBERS & PIPEFITTERS LOCAL 5 MEDICAL FUND',
  'PLUMBERS & PIPEFITTERS LOCAL 562',
  'Plumbers & Pipefitters UA Local 540',
  'PLUMBERS & STEAMFITTERS',
  'Plumbers & steamfitters local 141',
  'Plumbers and Pipefitters Local 344 Health and Welfare Trust Fund',
  'PLUMBERS AND STEAMFITTERS LOCAL 290',
  'PLUMBERS AND STEAMFITTERS LOCAL 33',
  'PLUMBERS LOCAL 125',
  'PLUMBERS LOCAL 98',
  'PLUMBERS LOCAL UNION 68',
  'PLUMBERS LOCAL UNION No. 1 TRUST FUNDS',
  'PLUMBERS WELFARE FUND LOCAL 130',
  'PLUMBERS\' & PIPEFITTERS\' WELFARE EDUCATIONAL FUND',
  'PMC Medicare Choice',
  'POINT COMFORT UNDERWRITERS',
  'Pokagon Band Of Potawatomi Indians',
  'POLISH FALCONS OF AMERICA',
  'POLY AMERICA',
  'POMCO',
  'POPULYTICS',
  'Portico Benefit Services (ELCA Retiree Service Center)',
  'Portico Healthnet',
  'POSITIVE HEALTHCARE - (CORPORATE)',
  'POSITIVE HEALTHCARE - CALIFORNIA',
  'POSITIVE HEALTHCARE - FLORIDA',
  'POSITIVE HEALTHCARE - GEORGIA',
  'POSITIVE HEALTHCARE PARTNERS',
  'PRAIRIE STATES',
  'Precise RxCare',
  'Preferred Administrators',
  'PREFERRED BENEFIT ADMINISTRATORS',
  'PREFERRED CARE INC',
  'PREFERRED CARE PARTNERS',
  'PREFERRED HEALTH CARE',
  'PREFERRED HEALTH SYSTEMS INSURANCE COMPANY',
  'PREFERRED MEDICAL PLAN INC',
  'PREFERRED MEDICARE CHOICE (CORPORATE)',
  'PREFERRED MEDICARE CHOICE INC',
  'Preferred National Pharmacy Solutions',
  'PreferredOne Administrative Services',
  'PREFERREDONE COMMUNITY HEALTH PLAN',
  'PREMERA BCBS OF ALASKA',
  'PREMERA BLUE CROSS (CORPORATE)',
  'PREMERA BLUE CROSS - WASHINGTON',
  'Premier Administrative Solutions',
  'PREMIER EYE CARE',
  'Premier Eyecare',
  'PREMIER HEALTH PLAN',
  'PREMIER PHARMACY PLAN/ SMITH PREMIER SERVICES',
  'PRESBYTERIAN HEALTH PLAN',
  'PRESBYTERIAN HEALTH PLAN (CORPORATE)',
  'PRESBYTERIAN HEALTH SYSTEM',
  'PRESCRIBARX - (CORPORATE)',
  'PRESCRIBARX - ALABAMA',
  'PRESCRIBARX - ALASKA',
  'PRESCRIBARX - ARIZONA',
  'PRESCRIBARX - ARKANSAS',
  'PRESCRIBARX - CALIFORNIA',
  'PRESCRIBARX - COLORADO',
  'PRESCRIBARX - CONNECTICUT',
  'PRESCRIBARX - DELAWARE',
  'PRESCRIBARX - DISTRICT OF COLUMBIA',
  'PRESCRIBARX - FLORIDA',
  'PRESCRIBARX - GEORGIA',
  'PRESCRIBARX - HAWAII',
  'PRESCRIBARX - IDAHO',
  'PRESCRIBARX - ILLINOIS',
  'PRESCRIBARX - INDIANA',
  'PRESCRIBARX - IOWA',
  'PRESCRIBARX - KANSAS',
  'PRESCRIBARX - KENTUCKY',
  'PRESCRIBARX - LOUISIANA',
  'PRESCRIBARX - MAINE',
  'PRESCRIBARX - MARYLAND',
  'PRESCRIBARX - MASSACHUSETTS',
  'PRESCRIBARX - MICHIGAN',
  'PRESCRIBARX - MINNESOTA',
  'PRESCRIBARX - MISSISSIPPI',
  'PRESCRIBARX - MISSOURI',
  'PRESCRIBARX - MONTANA',
  'PRESCRIBARX - NEBRASKA',
  'PRESCRIBARX - NEVADA',
  'PRESCRIBARX - NEW HAMPSHIRE',
  'PRESCRIBARX - NEW JERSEY',
  'PRESCRIBARX - NEW MEXICO',
  'PRESCRIBARX - NEW YORK',
  'PRESCRIBARX - NORTH CAROLINA',
  'PRESCRIBARX - NORTH DAKOTA',
  'PRESCRIBARX - OHIO',
  'PRESCRIBARX - OKLAHOMA',
  'PRESCRIBARX - OREGON',
  'PRESCRIBARX - PENNSYLVANIA',
  'PRESCRIBARX - RHODE ISLAND',
  'PRESCRIBARX - SOUTH CAROLINA',
  'PRESCRIBARX - SOUTH DAKOTA',
  'PRESCRIBARX - TENNESSEE',
  'PRESCRIBARX - TEXAS',
  'PRESCRIBARX - UTAH',
  'PRESCRIBARX - VERMONT',
  'PRESCRIBARX - VIRGINIA',
  'PRESCRIBARX - WASHINGTON',
  'PRESCRIBARX - WEST VIRGINIA',
  'PRESCRIBARX - WISCONSIN',
  'PRESCRIBARX - WYOMING',
  'Prescription Advantage',
  'PRESCRIPTION NETWORK OF KANSAS',
  'PRESTIGE HEALTH CHOICE',
  'PRESTIGE INTERNATIONAL',
  'PREVEA360',
  'PREVEA360 HEALTH PLAN',
  'PRIMARILY CARE, INC',
  'PRIMARY HEALTH',
  'PRIMARY HEALTH OF JOSEPHINE COUNTY',
  'PRIMARY PHYSICIAN CARE',
  'PRIME HEALTH',
  'PRIME THERAPEUTICS LLC',
  'PRIME TIME HEALTH PLAN',
  'PRIMECARE MEDICAL, INC',
  'PRIMEWEST HEALTH',
  'PRINCIPAL FINANCIAL GROUP',
  'PRINCIPAL LIFE INSURANCE COMPANY',
  'PRIORITY HEALTH',
  'PRIORITY PARTNERS',
  'Pro-Claim Plus, Inc.',
  'PROACT INC',
  'PROCARE RX',
  'PRODEGI',
  'PROFESSIONAL ADMINISTRATORS, INC.',
  'PROFESSIONAL BENEFIT ADMINISTRATOR',
  'PROFESSIONAL BENEFIT ADMINISTRATORS, INC',
  'PROFESSIONAL BENEFIT SERVICES (ADMINISTRATOR)',
  'PROFESSIONAL BENEFITS SERVICES - PBS',
  'Professional Claims Management',
  'PROGRESSIVE BENEFIT SERVICES, INC.',
  'PROHEALTH',
  'Project Access',
  'PROMEDICA HEALTH SYSTEM (CORPORATE)',
  'Promedical Plan',
  'PROMINENCE HEALTH (CORPORATE)',
  'PROMINENCE HEALTH PLAN - NEVADA',
  'PROMINENCE HEALTH PLAN- TEXAS',
  'ProScript Pharmacy Management LLC',
  'PROSPERITY LIFE GROUP',
  'PROSSAM',
  'PROVIDENCE HEALTH PLAN - OREGON',
  'PROVIDENCE HEALTH PLANS - ALASKA',
  'PROVIDENCE HEALTH PLANS - WASHINGTON',
  'PROVIDENCE HEALTH SYSTEMS (CORPORATE)',
  'PROVIDENCE INSURANCE & ADMINISTRATIVE SERVICES',
  'Providence Specialty Pharmacy',
  'Provident',
  'PROVIDENT AMERICAN INSURANCE COMPANY',
  'PROVIDER PARTNERS HEALTH PLAN - ILLINOIS',
  'PROVIDER PARTNERS HEALTH PLAN - MARYLAND',
  'PROVIDER PARTNERS HEALTH PLAN - PENNSYLVANIA',
  'PROVIDER SYNERGIES LLC',
  'Prudential Insurance Company of America',
  'PRUITTHEALTH PREMIER',
  'PSERS',
  'Public Employees\' Benefits Program (PEBP)',
  'Pueblo Community Health Center',
  'Puget Sound Energy',
  'Puritan Life Insurance',
  'Puyallup Tribal Health Authority',
  'PYRAMID LIFE INSURANCE COMPANY',
  'QUAD CITY COMMUNITY HEALTHCARE',
  'QUALCARE',
  'QUALCARE INC',
  'QUALCHOICE HEALTH (CORPORATE)',
  'QUALCHOICE OF ARKANSAS',
  'QUALITY BENEFITS, LLC',
  'QUALITY HEALTH PLANS INC',
  'QUALITY HEALTH PLANS OF NEW YORK',
  'QUANTUM HEALTH',
  'QUARTZ - ILLINOIS',
  'QUARTZ - IOWA',
  'QUARTZ - MINNESOTA',
  'QUARTZ - WISCONSIN',
  'QUARTZ HEALTH SOLUTIONS INC (CORPORATE)',
  'QUICK TRIP CORPORATION',
  'QuikTrip Corporation',
  'QVI RISK SOLUTIONS',
  'RAILROAD MAINT & INDUSTRY',
  'RAILROAD MEDICARE',
  'Red Cross Blue Sheild',
  'REDIRECT HEALTH',
  'Regal Life of America Insurance Company',
  'REGENCE BCBS OF OREGON',
  'REGENCE BCBS OF UTAH',
  'REGENCE BLUESHIELD OF IDAHO',
  'REGENCE BLUESHIELD OF WASHINGTON',
  'Regence Group Administrators',
  'REGIONAL CARE TPA',
  'REHN & ASSOCIATES',
  'Reliance Health Plan',
  'Reliance Standard Life Insurance Company',
  'Reliance Standard Specialty Products Administration',
  'Renaissance Life and Health Insurance Company of America',
  'RESERVE NATIONAL',
  'Resolve Health Plan Administrators',
  'RESOURCE ONE ADMINISTRATORS',
  'RESTAT',
  'RETAIL CLERKS WELFARE TRUST',
  'RETIREE MEDICAL INSURANCE',
  'Retiree\'s Welfare Trust',
  'RETIREES WELFARE TRUST #39',
  'RH ADMINISTRATORS, INC.',
  'RIGHT CHOICE BENEFIT ADMINISTRATORS',
  'RISK BENEFIT MANAGEMENT SERVICES - RBMS',
  'RISK MANAGEMENT RESOURCES INC',
  'RITE AID HEALTH SOLUTIONS',
  'RITTER\'S TOWNE PHARMACY',
  'RIVERLINK HEALTH - KENTUCKY',
  'RIVERLINK HEALTH- OHIO',
  'RIVERSPRING STAR',
  'RMSCO, INC',
  'ROCKY MOUNTAIN HEALTH PLANS',
  'ROOFERS LOCAL 149 SECURITY BENEFIT TRUST FUND',
  'ROYAL EMBASSY OF SAUDI ARABIA/CULTURAL MISSION',
  'ROYAL NEIGHBORS OF AMERICA',
  'RURAL CARRIER BENEFIT PLAN',
  'RX AMERICA - (CORPORATE)',
  'RX AMERICA - ALABAMA',
  'RX AMERICA - ALASKA',
  'RX AMERICA - ARIZONA',
  'RX AMERICA - ARKANSAS',
  'RX AMERICA - CALIFORNIA',
  'RX AMERICA - COLORADO',
  'RX AMERICA - CONNECTICUT',
  'RX AMERICA - DELAWARE',
  'RX AMERICA - DISTRICT OF COLUMBIA',
  'RX AMERICA - FLORIDA',
  'RX AMERICA - GEORGIA',
  'RX AMERICA - HAWAII',
  'RX AMERICA - IDAHO',
  'RX AMERICA - ILLINOIS',
  'RX AMERICA - INDIANA',
  'RX AMERICA - IOWA',
  'RX AMERICA - KANSAS',
  'RX AMERICA - KENTUCKY',
  'RX AMERICA - LOUISIANA',
  'RX AMERICA - MAINE',
  'RX AMERICA - MARYLAND',
  'RX AMERICA - MASSACHUSETTS',
  'RX AMERICA - MICHIGAN',
  'RX AMERICA - MINNESOTA',
  'RX AMERICA - MISSISSIPPI',
  'RX AMERICA - MISSOURI',
  'RX AMERICA - MONTANA',
  'RX AMERICA - NEBRASKA',
  'RX AMERICA - NEVADA',
  'RX AMERICA - NEW HAMPSHIRE',
  'RX AMERICA - NEW JERSEY',
  'RX AMERICA - NEW MEXICO',
  'RX AMERICA - NEW YORK',
  'RX AMERICA - NORTH CAROLINA',
  'RX AMERICA - NORTH DAKOTA',
  'RX AMERICA - OHIO',
  'RX AMERICA - OKLAHOMA',
  'RX AMERICA - OREGON',
  'RX AMERICA - PENNSYLVANIA',
  'RX AMERICA - RHODE ISLAND',
  'RX AMERICA - SOUTH CAROLINA',
  'RX AMERICA - SOUTH DAKOTA',
  'RX AMERICA - TENNESSEE',
  'RX AMERICA - TEXAS',
  'RX AMERICA - UTAH',
  'RX AMERICA - VERMONT',
  'RX AMERICA - VIRGINIA',
  'RX AMERICA - WASHINGTON',
  'RX AMERICA - WEST VIRGINIA',
  'RX AMERICA - WISCONSIN',
  'RX AMERICA - WYOMING',
  'Rx preferred Benefits',
  'Rx Savers',
  'Rx Valet',
  'Rx.com Community Pharmacy',
  'RXADVANCE',
  'RxBenefits, Inc.',
  'RXEDO INC',
  'RXPLUS',
  'Ryan White Grant',
  'S&S HEALTHCARE STRATEGIES',
  'Sagamore Health',
  'SAIF CORPORATION',
  'SAMARITAN HEALTH PLANS INC',
  'Samaritan Ministries',
  'SAMBA HEALTH PLAN',
  'SAMBA PLUS',
  'SAN FRANCISCO HEALTH PLAN',
  'SAN JOAQUIN HEALTH ADMINISTRATORS',
  'SANA BENEFITS',
  'SANFORD HEALTH PLAN (CORPORATE)',
  'SANFORD HEALTH PLAN - SOUTH DAKOTA',
  'SANTA CLARA FAMILY HEALTH PLAN',
  'SANTA FE EMPLOYEES HOSPITAL ASSOCIATION',
  'Sante Health System',
  'SANUS HEALTH CORPORATION',
  'SATELLITE HEALTH PLAN',
  'Sav RX',
  'SAV-RX PRESCRIPTION SERVICES',
  'SavNet',
  'SCAN HEALTH PLAN (CORPORATE)',
  'SCAN HEALTH PLAN - ARIZONA',
  'SCAN HEALTH PLAN - CALIFORNIA',
  'SCHALLER ANDERSON INC',
  'SCOTT & WHITE HEALTH PLAN',
  'SCOTT & WHITE HEALTH PLAN (COMPANY)',
  'SCOTT & WHITE PRESCRIPTION SERVICES',
  'SCREEN ACTORS GUILD - SAG',
  'SCRIP WORLD',
  'SCRIPPS HEALTH PLAN',
  'SCRIPT CARE LTD',
  'SCRIPTCLAIM SYSTEMS',
  'SCRIPTGUIDE RX',
  'SCRIPTSAVE',
  'SDM HEALTHCARE MANAGEMENT INC',
  'SEABURY & SMITH',
  'SEAFARERS  #377',
  'SEAFARERS WELFARE PLAN',
  'SEASIDE HEALTH PLAN',
  'SECURE HEALTH PLANS',
  'Secure Health Plans of Georgia',
  'Secure Horizons',
  'SECURE ONE',
  'SECURITY ADMINISTRATIVE SERVICES - INDIANA',
  'SECURITY HEALTH PLAN OF WISCONSIN INC',
  'Sedgewick James',
  'SEDGWICK CLAIMS MANAGEMENT SERVICES, INC',
  'SEE CHANGE',
  'SEIU 1199',
  'SEIU Benefits & Pension Fund',
  'SEIU LOCAL 4',
  'SELECT ADMINISTRATIVE SERVICES',
  'SELECT BENEFIT ADMINISTRATOR',
  'SELECT BENEFIT ADMINISTRATORS',
  'SELECT BENEFITS ADMINISTRATORS',
  'SELECT CARE BENEFIT NETWORKS',
  'SELECT HEALTH OF SOUTH CAROLINA',
  'SELECTCARE OF TEXAS (CORPORATE)',
  'SELECTCARE OF TEXAS LLC',
  'SELECTHEALTH PLANS (CORPORATE)',
  'SELECTHEALTH PLANS - IDAHO',
  'SELECTHEALTH PLANS - UTAH',
  'SELECTHEALTH PLANS UTAH',
  'SELECTHEALTH PRESCRIPTIONS',
  'SELF INSURED BENEFIT ADMINISTRATORS',
  'SELF-FUNDED PLANS, INC.',
  'SELMAN & CO - ALL AMERICA CCN',
  'SELMAN & COMPANY',
  'SEMINOLE TRIBE OF FLORIDA',
  'SENDERO HEALTH PLANS',
  'SENIOR CARE',
  'SENIOR CARE CONNECTION',
  'SENIOR COMMUNITY CARE',
  'SENIOR LIFE',
  'Senior Partners Care',
  'SENIOR WHOLE HEALTH - (CORPORATE)',
  'SENIOR WHOLE HEALTH - MASSACHUSETTS',
  'SENIOR WHOLE HEALTH - NEW YORK',
  'Sentinel Security',
  'SENTINELRX',
  'SENTRY INSURANCE',
  'SERVE YOU - CUSTOM PRESCRIPTION MANAGEMENT',
  'Seton Health Plan',
  'SEVEN CORNERS, INC.',
  'SHARP HEALTH PLAN',
  'SHARP HEALTHCARE (COMPANY)',
  'SHASTA ADMINISTRATORS',
  'Shawnee County Medical Society/Health Access',
  'SHEET METAL WORKER HEALTH NATIONAL FUND',
  'SHEET METAL WORKERS HEALTH&WELFARE LOCAL 91',
  'SHEET METAL WORKERS LOCAL 100',
  'SHEET METAL WORKERS LOCAL 104',
  'SHEET METAL WORKERS LOCAL 105',
  'SHEET METAL WORKERS LOCAL 18',
  'SHEET METAL WORKERS LOCAL 20',
  'SHEET METAL WORKERS LOCAL 28',
  'SHEET METAL WORKERS LOCAL 36',
  'SHEET METAL WORKERS LOCAL 7',
  'SHEET METAL WORKERS LOCAL 73',
  'Sheet Metal Workers Local 73',
  'SHELTER INSURANCE',
  'SHENANDOAH LIFE INSURANCE COMPANY',
  'Sidecar Health',
  'SIEBA (SPECIALISTS IN EMPLOYEE BENEFITS ADMINISTRATION',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - ALABAMA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - ARIZONA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - ARKANSAS',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - CALIFORNIA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - COLORADO',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - DELAWARE',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - DISTRICT OF COLUMBIA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - GEORGIA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - IDAHO',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - ILLINOIS',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - INDIANA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - KANSAS',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - KENTUCKY',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - LOUISIANA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - MARYLAND',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - MISSISSIPPI',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - MISSOURI',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - NEW JERSEY',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - NEW MEXICO',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - OHIO',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - OKLAHOMA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - OREGON',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - PENNSYLVANIA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - SOUTH CAROLINA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - TENNESSEE',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - TEXAS',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - UTAH',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - VIRGINIA',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - WASHINGTON',
  'SIERRA HEALTH AND LIFE INSURANCE COMPANY INC - WEST VIRGINIA',
  'SIERRA PACIFIC INDUSTRIES',
  'Sierra Pacific Industries Health Benefits',
  'Signa Health Care',
  'SIGNATURE ADVANTAGE',
  'SIGNATURESCRIPTS',
  'SIGNIFICA BENEFIT SERVICES',
  'SIHO INSURANCE SERVICES',
  'SILVERBACK RX',
  'SILVERSCRIPT INSURANCE COMPANY (CORPORATE)',
  'SILVERSCRIPT INSURANCE COMPANY - ALABAMA',
  'SILVERSCRIPT INSURANCE COMPANY - ALASKA',
  'SILVERSCRIPT INSURANCE COMPANY - AMERICAN SAMOA',
  'SILVERSCRIPT INSURANCE COMPANY - ARIZONA',
  'SILVERSCRIPT INSURANCE COMPANY - ARKANSAS',
  'SILVERSCRIPT INSURANCE COMPANY - CALIFORNIA',
  'SILVERSCRIPT INSURANCE COMPANY - COLORADO',
  'SILVERSCRIPT INSURANCE COMPANY - CONNECTICUT',
  'SILVERSCRIPT INSURANCE COMPANY - DELAWARE',
  'SILVERSCRIPT INSURANCE COMPANY - DISTRICT OF COLUMBIA',
  'SILVERSCRIPT INSURANCE COMPANY - FLORIDA',
  'SILVERSCRIPT INSURANCE COMPANY - GEORGIA',
  'SILVERSCRIPT INSURANCE COMPANY - GUAM',
  'SILVERSCRIPT INSURANCE COMPANY - HAWAII',
  'SILVERSCRIPT INSURANCE COMPANY - IDAHO',
  'SILVERSCRIPT INSURANCE COMPANY - ILLINOIS',
  'SILVERSCRIPT INSURANCE COMPANY - INDIANA',
  'SILVERSCRIPT INSURANCE COMPANY - IOWA',
  'SILVERSCRIPT INSURANCE COMPANY - KANSAS',
  'SILVERSCRIPT INSURANCE COMPANY - KENTUCKY',
  'SILVERSCRIPT INSURANCE COMPANY - LOUISIANA',
  'SILVERSCRIPT INSURANCE COMPANY - MAINE',
  'SILVERSCRIPT INSURANCE COMPANY - MARYLAND',
  'SILVERSCRIPT INSURANCE COMPANY - MASSACHUSETTS',
  'SILVERSCRIPT INSURANCE COMPANY - MICHIGAN',
  'SILVERSCRIPT INSURANCE COMPANY - MINNESOTA',
  'SILVERSCRIPT INSURANCE COMPANY - MISSISSIPPI',
  'SILVERSCRIPT INSURANCE COMPANY - MISSOURI',
  'SILVERSCRIPT INSURANCE COMPANY - MONTANA',
  'SILVERSCRIPT INSURANCE COMPANY - NEBRASKA',
  'SILVERSCRIPT INSURANCE COMPANY - NEVADA',
  'SILVERSCRIPT INSURANCE COMPANY - NEW HAMPSHIRE',
  'SILVERSCRIPT INSURANCE COMPANY - NEW JERSEY',
  'SILVERSCRIPT INSURANCE COMPANY - NEW MEXICO',
  'SILVERSCRIPT INSURANCE COMPANY - NEW YORK',
  'SILVERSCRIPT INSURANCE COMPANY - NORTH CAROLINA',
  'SILVERSCRIPT INSURANCE COMPANY - NORTH DAKOTA',
  'SILVERSCRIPT INSURANCE COMPANY - OHIO',
  'SILVERSCRIPT INSURANCE COMPANY - OKLAHOMA',
  'SILVERSCRIPT INSURANCE COMPANY - OREGON',
  'SILVERSCRIPT INSURANCE COMPANY - PENNSYLVANIA',
  'SILVERSCRIPT INSURANCE COMPANY - PUERTO RICO',
  'SILVERSCRIPT INSURANCE COMPANY - RHODE ISLAND',
  'SILVERSCRIPT INSURANCE COMPANY - SOUTH CAROLINA',
  'SILVERSCRIPT INSURANCE COMPANY - SOUTH DAKOTA',
  'SILVERSCRIPT INSURANCE COMPANY - TENNESSEE',
  'SILVERSCRIPT INSURANCE COMPANY - TEXAS',
  'SILVERSCRIPT INSURANCE COMPANY - UTAH',
  'SILVERSCRIPT INSURANCE COMPANY - VERMONT',
  'SILVERSCRIPT INSURANCE COMPANY - VIRGIN ISLANDS',
  'SILVERSCRIPT INSURANCE COMPANY - VIRGINIA',
  'SILVERSCRIPT INSURANCE COMPANY - WASHINGTON',
  'SILVERSCRIPT INSURANCE COMPANY - WEST VIRGINIA',
  'SILVERSCRIPT INSURANCE COMPANY - WISCONSIN',
  'SILVERSCRIPT INSURANCE COMPANY - WYOMING',
  'SILVERSUMMIT HEALTH PLAN',
  'SIM MED - USA',
  'SIMPLAN',
  'SIMPLY HEALTHCARE (COMPANY)',
  'SIMPLY HEALTHCARE PLANS',
  'Simply Prescriptions',
  'SIMPRA ADVANTAGE',
  'SInclair Health Services',
  'SISCO (SELF INSURED SERVICES)',
  'sky wang',
  'SKYGEN USA SOLUTIONS',
  'SMARTD RX- (CORPORATE)',
  'SMARTD RX- ALABAMA',
  'SMARTD RX- ALASKA',
  'SMARTD RX- AMERICAN SAMOA',
  'SMARTD RX- ARIZONA',
  'SMARTD RX- ARKANSAS',
  'SMARTD RX- CALIFORNIA',
  'SMARTD RX- COLORADO',
  'SMARTD RX- CONNECTICUT',
  'SMARTD RX- DELAWARE',
  'SMARTD RX- DISTRICT OF COLUMBIA',
  'SMARTD RX- FLORIDA',
  'SMARTD RX- GEORGIA',
  'SMARTD RX- HAWAII',
  'SMARTD RX- IDAHO',
  'SMARTD RX- ILLINOIS',
  'SMARTD RX- INDIANA',
  'SMARTD RX- IOWA',
  'SMARTD RX- KANSAS',
  'SMARTD RX- KENTUCKY',
  'SMARTD RX- LOUISIANA',
  'SMARTD RX- MAINE',
  'SMARTD RX- MARYLAND',
  'SMARTD RX- MASSACHUSETTS',
  'SMARTD RX- MICHIGAN',
  'SMARTD RX- MINNESOTA',
  'SMARTD RX- MISSISSIPPI',
  'SMARTD RX- MISSOURI',
  'SMARTD RX- MONTANA',
  'SMARTD RX- NEBRASKA',
  'SMARTD RX- NEVADA',
  'SMARTD RX- NEW HAMPSHIRE',
  'SMARTD RX- NEW JERSEY',
  'SMARTD RX- NEW MEXICO',
  'SMARTD RX- NEW YORK',
  'SMARTD RX- NORTH CAROLINA',
  'SMARTD RX- NORTH DAKOTA',
  'SMARTD RX- OHIO',
  'SMARTD RX- OKLAHOMA',
  'SMARTD RX- OREGON',
  'SMARTD RX- PENNSYLVANIA',
  'SMARTD RX- PUERTO RICO',
  'SMARTD RX- RHODE ISLAND',
  'SMARTD RX- SOUTH CAROLINA',
  'SMARTD RX- SOUTH DAKOTA',
  'SMARTD RX- TENNESSEE',
  'SMARTD RX- TEXAS',
  'SMARTD RX- UTAH',
  'SMARTD RX- VERMONT',
  'SMARTD RX- VIRGINIA',
  'SMARTD RX- WASHINGTON',
  'SMARTD RX- WEST VIRGINIA',
  'SMARTD RX- WISCONSIN',
  'SMARTD RX- WYOMING',
  'SmartHealth',
  'SMITHFIELD FOODS HEALTH PLAN',
  'SmithRx',
  'SONA BENEFITS',
  'SOONER HEALTH PLAN',
  'Sound Health & Wellness Trust',
  'SOUNDPATH HEALTH',
  'SOUTH CENTRAL BENEFITS OFFICE',
  'SOUTH CENTRAL PREFERRED',
  'SOUTH COUNTRY HEALTH ALLIANCE',
  'SOUTHCOAST HEALTH PLAN',
  'SOUTHEAST COMMUNITY CARE - GEORGIA',
  'SOUTHEAST COMMUNITY CARE - NORTH CAROLINA',
  'SOUTHEAST COMMUNITY CARE - SOUTH CAROLINA',
  'SOUTHEAST COMMUNITY CARE - VIRGINIA',
  'SOUTHERN BENEFIT ADMINISTRATORS',
  'SOUTHERN CALIF. PIPE TRADE TRUST FUND',
  'SOUTHERN CALIFORNIA BAKERY&CONFECTIONARY WELFARE FUND',
  'SOUTHERN CALIFORNIA UNITED FOOD & COMMERCIAL WORKERS UNIONS AND FOOD EMPLOYERS P',
  'Southern Guaranty Insurance Company, Inc',
  'SOUTHERN HEALTH SERVICES INC',
  'SOUTHERN OPERATORS HEALTH FUND',
  'SOUTHERN SCRIPTS',
  'SOUTHWEST ADMINISTRATORS',
  'SOUTHWEST SERVICE ADMINISTRATORS, INC.',
  'SPECIAL INSURANCE SERVICES (TPA)',
  'Special Metals Retiree Veba',
  'SPECIALIZED PHARMACY SOLUTIONS',
  'SPECTRALSOLUTIONS',
  'SPECTRUM PHARMACY SOLUTIONS',
  'TAKECARE INSURANCE COMPANY INC',
  'TALL TREE ADMINISTRATORS',
  'TANIA N MORGAN  MD  MPH  INC',
  'Taylor & Associates, Inc.',
  'TAYLOR BENEFIT RESOURCE, INC - TBR',
  'TCC of SC',
  'TCU-MTA Health & Welfare Trust Fund',
  'TEACHERS HEALTH TRUST',
  'TeamCare',
  'TEAMSTER BENEFIT TRUST',
  'TEAMSTER HEALTH & WELFARE',
  'Teamster Joint Council #53',
  'Teamsters 83 VA',
  'TEAMSTERS LOCAL 527-S',
  'TEAMSTERS LOCAL 618 HEALTH & WELFARE TRUST FUND',
  'TEAMSTERS LOCAL 731',
  'Teamsters Local 856',
  'TEAMSTERS LOCAL 992',
  'Teamsters Rx',
  'TEAMSTERSCARE',
  'Teladoc',
  'TENNESSEE HEALTHCARE',
  'test',
  'test payer Medicare AZ',
  'testpayor',
  'TEXANPLUS',
  'TEXAS CHILDREN`S HEALTH PLAN INC',
  'TEXAS COMMUNITY CARE',
  'TEXAS HEALTH SPRING LLC',
  'Texas Indigent Health Care Association (TIHCA)',
  'TEXAS MUNICIPAL LEAGUE - TML',
  'Texas Mutual Insurance Company',
  'THE ALLIANCE',
  'THE BENEFIT GROUP',
  'The Center for Health Care Strategies (CHCS)',
  'THE HARTFORD WORKERS COMPENSATION CENTER',
  'THE HEALTH PLAN - OHIO',
  'THE HEALTH PLAN - PENNSYLVANIA',
  'THE HEALTH PLAN - WEST VIRGINIA',
  'The Health Plan Insurance Co (THP)',
  'The Health Plan Virginia',
  'THE HEALTH PLANS (CORPORATE)',
  'THE KROGER COMPANY',
  'THE LIFETIME HEALTHCARE COMPANIES (COMPANY)',
  'THE LOOMIS COMPANY',
  'The Memphis Plan',
  'THE RENO PLAN GROUP HEALTH (CDS)',
  'THE UNIVERSITY OF ARIZONA HEALTH PLANS (COMPANY)',
  'THRIVENT FINANCIAL FOR LUTHERANS',
  'TIC INTERNATIONAL',
  'TIME INSURANCE COMPANY',
  'TMESYS',
  'TML Intergovernmental Employee Benefits Pool',
  'TODAYS OPTS CCRX PRMR PLUS (WY)',
  'TODAY`S OPTIONS - (CORPORATE)',
  'TODAY`S OPTIONS - ALABAMA',
  'TODAY`S OPTIONS - ARIZONA',
  'TODAY`S OPTIONS - ARKANSAS',
  'TODAY`S OPTIONS - CALIFORNIA',
  'TODAY`S OPTIONS - COLORADO',
  'TODAY`S OPTIONS - CONNECTICUT',
  'TODAY`S OPTIONS - DELAWARE',
  'TODAY`S OPTIONS - FLORIDA',
  'TODAY`S OPTIONS - GEORGIA',
  'TODAY`S OPTIONS - HAWAII',
  'TODAY`S OPTIONS - IDAHO',
  'TODAY`S OPTIONS - ILLINOIS',
  'TODAY`S OPTIONS - INDIANA',
  'TODAY`S OPTIONS - IOWA',
  'TODAY`S OPTIONS - KANSAS',
  'TODAY`S OPTIONS - KENTUCKY',
  'TODAY`S OPTIONS - LOUISIANA',
  'TODAY`S OPTIONS - MAINE',
  'TODAY`S OPTIONS - MARYLAND',
  'TODAY`S OPTIONS - MASSACHUSETTS',
  'TODAY`S OPTIONS - MICHIGAN',
  'TODAY`S OPTIONS - MINNESOTA',
  'TODAY`S OPTIONS - MISSISSIPPI',
  'TODAY`S OPTIONS - MISSOURI',
  'TODAY`S OPTIONS - MONTANA',
  'TODAY`S OPTIONS - NEBRASKA',
  'TODAY`S OPTIONS - NEVADA',
  'TODAY`S OPTIONS - NEW HAMPSHIRE',
  'TODAY`S OPTIONS - NEW JERSEY',
  'TODAY`S OPTIONS - NEW YORK',
  'TODAY`S OPTIONS - NORTH CAROLINA',
  'TODAY`S OPTIONS - NORTH DAKOTA',
  'TODAY`S OPTIONS - OHIO',
  'TODAY`S OPTIONS - OKLAHOMA',
  'TODAY`S OPTIONS - OREGON',
  'TODAY`S OPTIONS - PENNSYLVANIA',
  'TODAY`S OPTIONS - SOUTH CAROLINA',
  'TODAY`S OPTIONS - SOUTH DAKOTA',
  'TODAY`S OPTIONS - TENNESSEE',
  'TODAY`S OPTIONS - TEXAS',
  'TODAY`S OPTIONS - UTAH',
  'TODAY`S OPTIONS - VERMONT',
  'TODAY`S OPTIONS - VIRGINIA',
  'TODAY`S OPTIONS - WASHINGTON',
  'TODAY`S OPTIONS - WEST VIRGINIA',
  'TODAY`S OPTIONS - WISCONSIN',
  'TODAY`S OPTIONS - WYOMING',
  'TOKIO MARINE MANAGEMENT INC',
  'TOLEDO FIREFIGHTERS HEALTH PLAN',
  'TOTAL CLAIMS ADMINISTRATION',
  'TOTAL CLAIMS SOLUTION / TCS',
  'TOTAL HEALTH CARE INC',
  'TOTAL HEALTH CARE INC (CORPORATE)',
  'Total Plan Concepts',
  'TOTAL PLAN SERVICES',
  'TOTAL SCRIPT',
  'TotaPlan Concepts',
  'TOUCHSTONE HEALTH HMO INC',
  'Town of East Hartford',
  'TOWN OF WEST HARTFORD EMPLOYEE BENEFIT PLAN',
  'TRANSAMERICA',
  'TRANSAMERICA MEDICARERX- (CORPORATE)',
  'TRANSAMERICA MEDICARERX- ALABAMA',
  'TRANSAMERICA MEDICARERX- ALASKA',
  'TRANSAMERICA MEDICARERX- ARIZONA',
  'TRANSAMERICA MEDICARERX- ARKANSAS',
  'TRANSAMERICA MEDICARERX- CALIFORNIA',
  'TRANSAMERICA MEDICARERX- COLORADO',
  'TRANSAMERICA MEDICARERX- CONNECTICUT',
  'TRANSAMERICA MEDICARERX- DELAWARE',
  'TRANSAMERICA MEDICARERX- DISTRICT OF COLUMBIA',
  'TRANSAMERICA MEDICARERX- FLORIDA',
  'TRANSAMERICA MEDICARERX- GEORGIA',
  'TRANSAMERICA MEDICARERX- HAWAII',
  'TRANSAMERICA MEDICARERX- IDAHO',
  'TRANSAMERICA MEDICARERX- ILLINOIS',
  'TRANSAMERICA MEDICARERX- INDIANA',
  'TRANSAMERICA MEDICARERX- IOWA',
  'TRANSAMERICA MEDICARERX- KANSAS',
  'TRANSAMERICA MEDICARERX- KENTUCKY',
  'TRANSAMERICA MEDICARERX- LOUISIANA',
  'TRANSAMERICA MEDICARERX- MAINE',
  'TRANSAMERICA MEDICARERX- MARYLAND',
  'TRANSAMERICA MEDICARERX- MASSACHUSETTS',
  'TRANSAMERICA MEDICARERX- MICHIGAN',
  'TRANSAMERICA MEDICARERX- MINNESOTA',
  'TRANSAMERICA MEDICARERX- MISSISSIPPI',
  'TRANSAMERICA MEDICARERX- MISSOURI',
  'TRANSAMERICA MEDICARERX- MONTANA',
  'TRANSAMERICA MEDICARERX- NEBRASKA',
  'TRANSAMERICA MEDICARERX- NEVADA',
  'TRANSAMERICA MEDICARERX- NEW HAMPSHIRE',
  'TRANSAMERICA MEDICARERX- NEW JERSEY',
  'TRANSAMERICA MEDICARERX- NEW MEXICO',
  'TRANSAMERICA MEDICARERX- NORTH CAROLINA',
  'TRANSAMERICA MEDICARERX- NORTH DAKOTA',
  'TRANSAMERICA MEDICARERX- OHIO',
  'TRANSAMERICA MEDICARERX- OKLAHOMA',
  'TRANSAMERICA MEDICARERX- OREGON',
  'TRANSAMERICA MEDICARERX- PENNSYLVANIA',
  'TRANSAMERICA MEDICARERX- RHODE ISLAND',
  'TRANSAMERICA MEDICARERX- SOUTH CAROLINA',
  'TRANSAMERICA MEDICARERX- SOUTH DAKOTA',
  'TRANSAMERICA MEDICARERX- TENNESSEE',
  'TRANSAMERICA MEDICARERX- TEXAS',
  'TRANSAMERICA MEDICARERX- UTAH',
  'TRANSAMERICA MEDICARERX- VERMONT',
  'TRANSAMERICA MEDICARERX- VIRGINIA',
  'TRANSAMERICA MEDICARERX- WASHINGTON',
  'TRANSAMERICA MEDICARERX- WEST VIRGINIA',
  'TRANSAMERICA MEDICARERX- WISCONSIN',
  'TRANSAMERICA MEDICARERX- WYOMING',
  'TRANSPARENTRX',
  'TRANSWESTERN INSURANCE ADMINISTRATORS',
  'TRAVEL CARE SERVICES',
  'TRAVELERS INSURANCE',
  'Trawick International',
  'TRIBAL HEALTH PLAN',
  'TRIBUTE HEALTH PLAN (CORPORATE)',
  'TRIBUTE HEALTH PLAN OF OKLAHOMA',
  'TRICARE EAST PRIME',
  'Tricare Overseas',
  'Tricare Service Center dba Option Care',
  'TriHealth Benefit Solutions',
  'TRILLIUM COMMUNITY HEALTH PLAN',
  'TRILOGY HEALTH INSURANCE',
  'TRINITY HEALTH PACE',
  'TRIPLE-C INC',
  'TRIPLE-S MANAGEMENT CORPORATION',
  'TRIPLE-S SALUD INC',
  'Tristar Benefit Administrators',
  'TriState Benefits Solutions',
  'TriWest Alliance',
  'TRMC Better Health Partners',
  'TROY MEDICARE',
  'TRUE HEALTH NEW MEXICO',
  'True Rx Management Services',
  'TRUESCRIPTS',
  'TrueScripts',
  'TRUST PLUS PHARMACY BENEFITS INC',
  'TRUSTED HEALTH PLAN',
  'TRUSTEED PLANS SERVICE CORP - TPSC',
  'TRUSTMARK LIFE INSURANCE COMPANY',
  'TUALITY HEALTH ALLIANCE',
  'TUCKER ADMINISTRATORS, INC.',
  'TUFTS HEALTH FREEDOM PLAN',
  'TUFTS HEALTH PLAN (CORPORATE)',
  'TUFTS HEALTH PLAN - CONNECTICUT',
  'TUFTS HEALTH PLAN - MASSACHUSETTS',
  'TUFTS HEALTH PLAN - NETWORK HEALTH',
  'TUFTS HEALTH PLAN - RHODE ISLAND',
  'TWIN CITY PIPE TRADES SERVICE ASSOC - TCPT',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS (CORPORATE)',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - ALABAMA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - ALASKA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - ARIZONA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - ARKANSAS',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - CALIFORNIA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - COLORADO',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - CONNECTICUT',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - DELAWARE',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - DISTRICT OF COLUMBIA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - FLORIDA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - GEORGIA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - HAWAII',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - IDAHO',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - ILLINOIS',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - INDIANA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - IOWA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - KANSAS',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - KENTUCKY',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - LOUISIANA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - MAINE',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - MARYLAND',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - MASSACHUSETTS',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - MICHIGAN',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - MINNESOTA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - MISSISSIPPI',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - MISSOURI',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - MONTANA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - NEBRASKA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - NEVADA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - NEW HAMPSHIRE',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - NEW JERSEY',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - NEW MEXICO',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - NEW YORK',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - NORTH CAROLINA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - NORTH DAKOTA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - OHIO',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - OKLAHOMA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - OREGON',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - PENNSYLVANIA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - RHODE ISLAND',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - SOUTH CAROLINA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - SOUTH DAKOTA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - TENNESSEE',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - TEXAS',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - UTAH',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - VERMONT',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - VIRGINIA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - WASHINGTON',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - WEST VIRGINIA',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - WISCONSIN',
  'U.S. DEPARTMENT OF VETERANS AFFAIRS - WYOMING',
  'UCA Limited',
  'UCARE (CORPORATE)',
  'UCARE MINNESOTA',
  'UCARE WISCONSIN',
  'UCSD HEALTH PLAN',
  'UFCW - UNITED FOOD & COMMERCIAL WORKERS',
  'UFCW Health & Welfare',
  'UFCW LOCAL 655 WELFARE FUND BENEFITS',
  'UFCW Local 880',
  'UFCW NATIONAL HEALTH AND WELFARE FUND',
  'UFCW NO. CALIFORNIA HEALTH & WELFARE',
  'UFCW UNIONS AND EMPLOYERS MIDWEST',
  'UFCW United Food & Commercial Workers Union-Employer Health & Welfare Fund',
  'UFCW- unions and food employer benefit fund',
  'UFW United Furniture Workers Insurance',
  'UHA Health Insurance',
  'UHC Student Services',
  'UK HEALTHCARE',
  'ULTIMATE HEALTH PLANS',
  'UltraBenefits, Inc.',
  'UMC Health System',
  'UMPQUA HEALTH ALLIANCE',
  'UMR',
  'UMWA - United Mine Workers of America',
  'UMWA HEALTH & RETIREMENT FUNDS',
  'UNC INFUSION',
  'UNDERWRITERS SAFETY & CLAIMS',
  'UNICARE',
  'UNICARE HEALTH INSURANCE COMPANY OF THE MIDWEST',
  'UNICARE HEALTH PLANS OF ALABAMA',
  'UNICARE HEALTH PLANS OF ALASKA',
  'UNICARE HEALTH PLANS OF ARIZONA',
  'UNICARE HEALTH PLANS OF ARKANSAS',
  'UNICARE HEALTH PLANS OF CALIFORNIA',
  'UNICARE HEALTH PLANS OF COLORADO',
  'UNICARE HEALTH PLANS OF CONNECTICUT',
  'UNICARE HEALTH PLANS OF DELAWARE',
  'UNICARE HEALTH PLANS OF DISTRICT OF COLUMBIA',
  'UNICARE HEALTH PLANS OF FLORIDA',
  'UNICARE HEALTH PLANS OF GEORGIA',
  'UNICARE HEALTH PLANS OF HAWAII',
  'UNICARE HEALTH PLANS OF IDAHO',
  'UNICARE HEALTH PLANS OF ILLINOIS',
  'UNICARE HEALTH PLANS OF INDIANA',
  'UNICARE HEALTH PLANS OF IOWA',
  'UNICARE HEALTH PLANS OF KANSAS',
  'UNICARE HEALTH PLANS OF KENTUCKY',
  'UNICARE HEALTH PLANS OF LOUISIANA',
  'UNICARE HEALTH PLANS OF MAINE',
  'UNICARE HEALTH PLANS OF MARYLAND',
  'UNICARE HEALTH PLANS OF MASSACHUSETTS',
  'UNICARE HEALTH PLANS OF MICHIGAN',
  'UNICARE HEALTH PLANS OF MINNESOTA',
  'UNICARE HEALTH PLANS OF MISSISSIPPI',
  'UNICARE HEALTH PLANS OF MISSOURI',
  'UNICARE HEALTH PLANS OF MONTANA',
  'UNICARE HEALTH PLANS OF NEBRASKA',
  'UNICARE HEALTH PLANS OF NEVADA',
  'UNICARE HEALTH PLANS OF NEW HAMPSHIRE',
  'UNICARE HEALTH PLANS OF NEW JERSEY',
  'UNICARE HEALTH PLANS OF NEW MEXICO',
  'UNICARE HEALTH PLANS OF NEW YORK',
  'UNICARE HEALTH PLANS OF NORTH CAROLINA',
  'UNICARE HEALTH PLANS OF NORTH DAKOTA',
  'UNICARE HEALTH PLANS OF OHIO',
  'UNICARE HEALTH PLANS OF OKLAHOMA',
  'UNICARE HEALTH PLANS OF OREGON',
  'UNICARE HEALTH PLANS OF PENNSYLVANIA',
  'UNICARE HEALTH PLANS OF RHODE ISLAND',
  'UNICARE HEALTH PLANS OF SOUTH CAROLINA',
  'UNICARE HEALTH PLANS OF SOUTH DAKOTA',
  'UNICARE HEALTH PLANS OF TENNESSEE',
  'UNICARE HEALTH PLANS OF TEXAS',
  'UNICARE HEALTH PLANS OF UTAH',
  'UNICARE HEALTH PLANS OF VERMONT',
  'UNICARE HEALTH PLANS OF VIRGINIA',
  'UNICARE HEALTH PLANS OF WASHINGTON',
  'UNICARE HEALTH PLANS OF WEST VIRGINIA',
  'UNICARE HEALTH PLANS OF WISCONSIN',
  'UNICARE HEALTH PLANS OF WYOMING',
  'Unicare Life & Health Insurance Company',
  'UNIFIED GROUP SERVICES',
  'Unified Life Insurance Company',
  'UNIFORM MEDICAL PLAN',
  'UNION BANKERS  INSURANCE COMPANY',
  'UNION HEALTH SERVICE INC',
  'UNION LABOR LIFE INSURANCE',
  'UNION LOCAL',
  'UNION NATIONAL',
  'UNION PACIFIC RAILROAD EMPLOYEES HEALTH SYSTEM',
  'UNION ROOFERS HEALTH AND WELFARE FUND',
  'Union Security Insurance Company',
  'UNISON HEALTH PLAN ADMINISTRATIVE SERVICES LLC',
  'UNISON HEALTH PLAN OF ARKANSAS',
  'UNISON HEALTH PLAN OF MISSISSIPPI',
  'UNISON HEALTH PLAN OF PENNSYLVANIA',
  'UNISON HEALTH PLAN OF TENNESSEE INC',
  'UNISYS',
  'Unite Here Health',
  'UNITED ADMINISTRATIVE SERVICES',
  'UNITED AGRICULTURAL BENEFIT TRUST',
  'UNITED AMERICAN HEALTH CARE PLAN OF TENNESSEE',
  'UNITED AMERICAN HEALTHCARE CORPORATION',
  'United American Insurance Company',
  'UNITED AMERICAN INSURANCE COMPANY - (CORPORATE)',
  'UNITED AMERICAN INSURANCE COMPANY - ALABAMA',
  'UNITED AMERICAN INSURANCE COMPANY - ALASKA',
  'UNITED AMERICAN INSURANCE COMPANY - ARIZONA',
  'UNITED AMERICAN INSURANCE COMPANY - ARKANSAS',
  'UNITED AMERICAN INSURANCE COMPANY - CALIFORNIA',
  'UNITED AMERICAN INSURANCE COMPANY - COLORADO',
  'UNITED AMERICAN INSURANCE COMPANY - CONNECTICUT',
  'UNITED AMERICAN INSURANCE COMPANY - DELAWARE',
  'UNITED AMERICAN INSURANCE COMPANY - DISTRICT OF COLUMBIA',
  'UNITED AMERICAN INSURANCE COMPANY - FLORIDA',
  'UNITED AMERICAN INSURANCE COMPANY - GEORGIA',
  'UNITED AMERICAN INSURANCE COMPANY - HAWAII',
  'UNITED AMERICAN INSURANCE COMPANY - IDAHO',
  'UNITED AMERICAN INSURANCE COMPANY - ILLINOIS',
  'UNITED AMERICAN INSURANCE COMPANY - INDIANA',
  'UNITED AMERICAN INSURANCE COMPANY - IOWA',
  'UNITED AMERICAN INSURANCE COMPANY - KANSAS',
  'UNITED AMERICAN INSURANCE COMPANY - KENTUCKY',
  'UNITED AMERICAN INSURANCE COMPANY - LOUISIANA',
  'UNITED AMERICAN INSURANCE COMPANY - MAINE',
  'UNITED AMERICAN INSURANCE COMPANY - MARYLAND',
  'UNITED AMERICAN INSURANCE COMPANY - MASSACHUSETTS',
  'UNITED AMERICAN INSURANCE COMPANY - MICHIGAN',
  'UNITED AMERICAN INSURANCE COMPANY - MINNESOTA',
  'UNITED AMERICAN INSURANCE COMPANY - MISSISSIPPI',
  'UNITED AMERICAN INSURANCE COMPANY - MISSOURI',
  'UNITED AMERICAN INSURANCE COMPANY - MONTANA',
  'UNITED AMERICAN INSURANCE COMPANY - NEBRASKA',
  'UNITED AMERICAN INSURANCE COMPANY - NEVADA',
  'UNITED AMERICAN INSURANCE COMPANY - NEW HAMPSHIRE',
  'UNITED AMERICAN INSURANCE COMPANY - NEW JERSEY',
  'UNITED AMERICAN INSURANCE COMPANY - NEW MEXICO',
  'UNITED AMERICAN INSURANCE COMPANY - NORTH CAROLINA',
  'UNITED AMERICAN INSURANCE COMPANY - NORTH DAKOTA',
  'UNITED AMERICAN INSURANCE COMPANY - OHIO',
  'UNITED AMERICAN INSURANCE COMPANY - OKLAHOMA',
  'UNITED AMERICAN INSURANCE COMPANY - OREGON',
  'UNITED AMERICAN INSURANCE COMPANY - PENNSYLVANIA',
  'UNITED AMERICAN INSURANCE COMPANY - RHODE ISLAND',
  'UNITED AMERICAN INSURANCE COMPANY - SOUTH CAROLINA',
  'UNITED AMERICAN INSURANCE COMPANY - SOUTH DAKOTA',
  'UNITED AMERICAN INSURANCE COMPANY - TENNESSEE',
  'UNITED AMERICAN INSURANCE COMPANY - TEXAS',
  'UNITED AMERICAN INSURANCE COMPANY - UTAH',
  'UNITED AMERICAN INSURANCE COMPANY - VERMONT',
  'UNITED AMERICAN INSURANCE COMPANY - VIRGINIA',
  'UNITED AMERICAN INSURANCE COMPANY - WASHINGTON',
  'UNITED AMERICAN INSURANCE COMPANY - WEST VIRGINIA',
  'UNITED AMERICAN INSURANCE COMPANY - WISCONSIN',
  'UNITED AMERICAN INSURANCE COMPANY - WYOMING',
  'UNITED COMMERCIAL TRAVELERS OF AMERICA',
  'UNITED DRUGS',
  'United Federation of Teachers',
  'United Health Care',
  'UNITED HEALTH INTEGRATED SERVICES',
  'United Health Plus',
  'United Healthcare',
  'United Healthcare Community Plan',
  'United Healthcare of California',
  'United Healthcare Plans',
  'UNITED INDIAN HEALTH SERVICES',
  'UNITED INSURANCE COMPANY OF AMERICA',
  'UNITED MEDICAL ALLIANCE',
  'United Medical Resources',
  'UNITED MEDICAL RESOURCES',
  'UNITED MINE WORKERS OF AMERICA',
  'United National Life',
  'UNITED PRODUCTION WORKERS UNION LOCAL 17-18 WELFARE FUND',
  'UNITED STATES LIFE INSURANCE',
  'UNITED TEACHER ASSOCIATES INSURANCE CO',
  'United Teachers Association',
  'United Technologies',
  'United World Life Insurance',
  'UnitedHealth Care - Uniprise',
  'UNITEDHEALTH GROUP',
  'UNITEDHEALTHCARE ARIZONA PHYSICIANS IPA',
  'UNITEDHEALTHCARE COMMUNITY PLAN - NEBRASKA',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF ARIZONA',
  'Unitedhealthcare Community Plan of California',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF CONNECTICUT',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF DELAWARE',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF DISTRICT OF COLUMBIA',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF FLORIDA',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF HAWAII',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF IOWA',
  'Unitedhealthcare Community Plan of Kansas',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF KANSAS',
  'Unitedhealthcare Community Plan of Louisiana',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF MARYLAND',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF MASSACHUSETTS',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF MICHIGAN',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF MISSISSIPPI',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF MISSOURI',
  'Unitedhealthcare Community Plan of Nebraska',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF NEW JERSEY',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF NEW MEXICO',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF NEW YORK',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF NORTH CAROLINA',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF OHIO',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF PENNSYLVANIA',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF RHODE ISLAND',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF SOUTH CAROLINA',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF TENNESSEE',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF TEXAS',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF VIRGINIA',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF WASHINGTON',
  'UNITEDHEALTHCARE COMMUNITY PLAN OF WISCONSIN',
  'UNITEDHEALTHCARE GREAT LAKES HEALTH PLAN',
  'UNITEDHEALTHCARE INC (CORPORATE)',
  'UNITEDHEALTHCARE OF ALABAMA INC',
  'UNITEDHEALTHCARE OF ALASKA INC',
  'UNITEDHEALTHCARE OF AMERICAN SAMOA INC',
  'UNITEDHEALTHCARE OF ARIZONA INC',
  'UNITEDHEALTHCARE OF ARKANSAS INC',
  'UNITEDHEALTHCARE OF CALIFORNIA INC',
  'UNITEDHEALTHCARE OF COLORADO INC',
  'UNITEDHEALTHCARE OF CONNECTICUT INC',
  'UNITEDHEALTHCARE OF DELAWARE INC',
  'UNITEDHEALTHCARE OF DISTRICT OF COLUMBIA INC',
  'UNITEDHEALTHCARE OF FLORIDA INC',
  'UNITEDHEALTHCARE OF GEORGIA INC',
  'UNITEDHEALTHCARE OF GUAM INC',
  'UNITEDHEALTHCARE OF HAWAII INC',
  'UNITEDHEALTHCARE OF IDAHO INC',
  'UNITEDHEALTHCARE OF ILLINOIS INC',
  'UNITEDHEALTHCARE OF INDIANA INC',
  'UNITEDHEALTHCARE OF IOWA INC',
  'UNITEDHEALTHCARE OF KANSAS INC',
  'UNITEDHEALTHCARE OF KENTUCKY INC',
  'UNITEDHEALTHCARE OF LOUISIANA INC',
  'UNITEDHEALTHCARE OF MAINE INC',
  'VA PBM',
  'VALLEY HEALTH PLAN - CALIFORNIA',
  'Valor Health Plans',
  'VANTAGE HEALTH PLAN INC',
  'VENTEGRA LLC',
  'VENTURA COUNTY HEALTH CARE PLAN',
  'VERITY NATIONAL GROUP',
  'Veterans Administration',
  'VETERANS ADMINISTRATION',
  'VHP COMMUNITY CARE',
  'VIBRA HEALTH PLAN',
  'VIBRANT RX',
  'Village Senior Services Corporation',
  'VILLAGECARE MAX',
  'Virgin Islands Medicaid',
  'Virginia Coordinated Care for the Uninsured',
  'VIRGINIA PREMIER HEALTH PLAN INC',
  'Vision Service Plan (VSP)',
  'VISTA HEALTHPLAN INC - FLORIDA',
  'VISTA HEALTHPLAN OF SOUTH FLORIDA',
  'VISTA360 HEALTH',
  'VITALITY HEALTH PLAN OF CA',
  'VIVA HEALTH INC',
  'Vivida Health',
  'VIVIO Health',
  'VIVO HEALTH',
  'VNSNY CHOICE',
  'VOCATIONAL REHAB',
  'VOLUSIA HEALTH NETWORK',
  'VRX PHARMACY SERVICES',
  'WA State Rx Services',
  'WABASH MEMORIAL HOSPITAL PLAN',
  'WALGREENS HEALTH INITIATIVES',
  'Walmart Specialty Pharmacy',
  'WASHINGTON IDAHO (IUOE LOCAL 370)',
  'Washington National',
  'WASHINGTON STATE DEPARTMENT OF LABOR & INDUSTRIES',
  'Washington State Health Care Authority',
  'Washington State Health Insurance Pool',
  'Washington State Rx Services',
  'Washington Teamsters Welfare Trust',
  'WASHINGTON UNIVERSITY SCHOOL OF MEDICINE',
  'WASHOE TRIBAL HEALTH CENTER',
  'Watchtower Healthcare Support',
  'WAUSAU INSURANCE',
  'WC Risk Retention Program',
  'WEA INSURANCE',
  'WEA TRUST',
  'WEB TPA',
  'WELBORN HEALTH PLANS',
  'WELFARE & PENSION ADMINISTRATION SERVICE, INC WPAS',
  'Welforce Administrators',
  'WELL SENSE HEALTH PLAN',
  'WELLCARE - ALABAMA',
  'WELLCARE - ALASKA',
  'WELLCARE - ARIZONA',
  'WELLCARE - ARKANSAS',
  'WELLCARE - CALIFORNIA',
  'WELLCARE - COLORADO',
  'WELLCARE - CONNECTICUT',
  'WELLCARE - DELAWARE',
  'WELLCARE - DISTRICT OF COLUMBIA',
  'WELLCARE - FLORIDA',
  'WELLCARE - FLORIDA (COMPANY)',
  'WELLCARE - FLORIDA (STAYWELL)',
  'WELLCARE - GEORGIA',
  'WELLCARE - HAWAII',
  'WELLCARE - IDAHO',
  'WELLCARE - ILLINOIS',
  'WELLCARE - INDIANA',
  'WELLCARE - IOWA',
  'WELLCARE - KANSAS',
  'WELLCARE - KENTUCKY',
  'WELLCARE - LOUISIANA',
  'WELLCARE - MAINE',
  'WELLCARE - MARYLAND',
  'WELLCARE - MASSACHUSETTS',
  'WELLCARE - MICHIGAN',
  'WELLCARE - MINNESOTA',
  'WELLCARE - MISSISSIPPI',
  'WELLCARE - MISSOURI',
  'WELLCARE - MONTANA',
  'WELLCARE - NEBRASKA',
  'WELLCARE - NEVADA',
  'WELLCARE - NEW HAMPSHIRE',
  'WELLCARE - NEW JERSEY',
  'WELLCARE - NEW MEXICO',
  'WELLCARE - NEW YORK',
  'WELLCARE - NORTH CAROLINA',
  'WELLCARE - NORTH DAKOTA',
  'WELLCARE - OHIO',
  'WELLCARE - OKLAHOMA',
  'WELLCARE - OREGON',
  'WELLCARE - PENNSYLVANIA',
  'WELLCARE - RHODE ISLAND',
  'WELLCARE - SOUTH CAROLINA',
  'WELLCARE - SOUTH DAKOTA',
  'WELLCARE - TENNESSEE',
  'WELLCARE - TEXAS',
  'WELLCARE - UTAH',
  'WELLCARE - VERMONT',
  'WELLCARE - VIRGINIA',
  'WELLCARE - WASHINGTON',
  'WELLCARE - WEST VIRGINIA',
  'WELLCARE - WISCONSIN',
  'WELLCARE - WYOMING',
  'WELLCARE HEALTH PLANS INC',
  'WELLCARE HEALTH PLANS INC (CORPORATE)',
  'WELLDYNE RX',
  'WellFirst Health',
  'WELLFLEET',
  'WELLMARK BCBS OF IOWA',
  'WELLMARK BCBS OF SOUTH DAKOTA',
  'WELLMARK INC (CORPORATE)',
  'WELLNET HEALTHCARE',
  'WELLPOINT INC',
  'WELLPOINT NEXTRX',
  'WELLS FARGO INSURANCE SERVICES',
  'WELLS FARGO TPA ( FKA ACORDIA NATIONAL)',
  'WELLSPAN POPULATION HEALTH SERVICES',
  'WEST VIRGINIA FAMILY HEALTH',
  'WEST VIRGINIA PUBLIC EMPLOYEES INSURANCE AGENCY (WVPEIA)',
  'WEST VIRGINIA SENIOR ADVANTAGE',
  'West Volusia Hospital Authority',
  'Western & Southern Financial Group',
  'Western Catholic Union',
  'WESTERN GROWERS ASSOCIATION - WGA',
  'WESTERN HEALTH ADVANTAGE',
  'WESTERN MUTUAL INSURANCE COMPANY',
  'WESTERN OREGON ADVANCED HEALTH',
  'WESTERN PA ELECTRICAL EMPLOYEE INS TRUST FUND',
  'WESTERN SKY COMMUNITY CARE',
  'WESTERN SOUTHERN LIFE',
  'WESTERN UNITED LIFE',
  'Wexford Health Services',
  'WH Administrators, INC.',
  'WHITE MOTOR RETIREE TRUST',
  'WI Mason\'s Healthcare Fund, Local No. 19',
  'Will County Local 174 Carpenters Welfare Fund',
  'WILLAMETTE VALLEY COMMUNITY HEALTH',
  'WILLIAM C EARHART COMPANY, INC',
  'WILSON-MCSHANE CORPORATION',
  'WINDSOR HEALTH PLAN INC - (CORPORATE)',
  'WINDSOR HEALTH PLAN INC - ALABAMA',
  'WINDSOR HEALTH PLAN INC - ARKANSAS',
  'WINDSOR HEALTH PLAN INC - COLORADO',
  'WINDSOR HEALTH PLAN INC - GEORGIA',
  'WINDSOR HEALTH PLAN INC - IDAHO',
  'WINDSOR HEALTH PLAN INC - ILLINOIS',
  'WINDSOR HEALTH PLAN INC - IOWA',
  'WINDSOR HEALTH PLAN INC - KANSAS',
  'WINDSOR HEALTH PLAN INC - KENTUCKY',
  'WINDSOR HEALTH PLAN INC - LOUISIANA',
  'WINDSOR HEALTH PLAN INC - MINNESOTA',
  'WINDSOR HEALTH PLAN INC - MISSISSIPPI',
  'WINDSOR HEALTH PLAN INC - MISSOURI',
  'WINDSOR HEALTH PLAN INC - MONTANA',
  'WINDSOR HEALTH PLAN INC - NEBRASKA',
  'WINDSOR HEALTH PLAN INC - NORTH CAROLINA',
  'WINDSOR HEALTH PLAN INC - NORTH DAKOTA',
  'WINDSOR HEALTH PLAN INC - OHIO',
  'WINDSOR HEALTH PLAN INC - OKLAHOMA',
  'WINDSOR HEALTH PLAN INC - OREGON',
  'WINDSOR HEALTH PLAN INC - SOUTH CAROLINA',
  'WINDSOR HEALTH PLAN INC - SOUTH DAKOTA',
  'WINDSOR HEALTH PLAN INC - TENNESSEE',
  'WINDSOR HEALTH PLAN INC - TEXAS',
  'WINDSOR HEALTH PLAN INC - UTAH',
  'WINDSOR HEALTH PLAN INC - VIRGINIA',
  'WINDSOR HEALTH PLAN INC - WASHINGTON',
  'WINDSOR HEALTH PLAN INC- WYOMING',
  'Windsor Sterling',
  'WINE LIQUOR & DISTILLERY UNION',
  'Wine, Liquor and Distillery Union Local 1D',
  'WINHEALTH PARTNERS',
  'WINNEBAGO COUNTY HEALTH DEPARTMENT',
  'WISCONSIN ADVENTIST',
  'WISCONSIN CARPENTERS HEALTH FUND',
  'WISCONSIN ELECTRICAL EMPLOYEES HEALTH&WELFARE PLAN',
  'WISCONSIN HEALTH FUND',
  'WISCONSIN PHYSICIAN SERVICE INSURANCE CORPORATION (CORPORATE)',
  'WISCONSIN PHYSICIANS HEALTH INSURANCE CORPORATION',
  'WISCONSIN PHYSICIANS SERVICE INSURANCE CORPORATION/WPS',
  'WMS PRESCRIPTION DRUG PLANS',
  'Wood Specialty Group',
  'Woodmen Insurance Agency, Inc.',
  'WORKERS\' COMPENSATION FUND OF UTAH',
  'WORLD INSURANCE COMPANY',
  'World Trade Center Health Program',
  'WRITERS GUILD INDUSTRY HEALTH FUND',
  'Wyoming School Boards Association Insurance Trust',
  'XEROX STATE HEALTHCARE LLC',
  'YAMHILL COMMUNITY CARE ORGANIZATION',
  'Yamhill County Care Organization',
  'York Risk Services Group, Inc.',
  'YourCare Health Plan',
  'YOURCARE HEALTH PLAN',
  'ZENITH ADMINISTRATORS INC',
  'ZENITH ADMINISTRATORS, INC',
  'ZENITH AMERICAN ADMINISTRATORS',
  'Zurich American Insurance Company',
  'AMERIHEALTH CARITAS (COMPANY)',
  'AMERIHEALTH CARITAS - DELAWARE',
  'AMERIHEALTH CARITAS - DISTRICT OF COLUMBIA',
  'AMERIHEALTH CARITAS - IOWA',
  'AMERIHEALTH CARITAS - LOUISIANA',
  'AMERIHEALTH CARITAS - MICHIGAN',
  'AMERIHEALTH CARITAS - NEW HAMPSHIRE',
  'AMERIHEALTH CARITAS - NORTHEAST',
  'AMERIHEALTH CARITAS - PENNSYLVANIA',
  'Amerihealth Casualty',
  'AMERIHEALTH INC (COMPANY)',
  'AmeriHealth Mercy Health Plan/Keystone Mercy Health Plan',
  'AMFIRST INSURANCE COMPANY',
  'Amgen',
  'AMIDA CARE',
  'AMITA HEALTH',
  'AMO (AMERICAN MARITIME OFFICERS) BENEFIT PLANS',
  'AMWINS Group Benefit',
  'AMWINS RX',
  'ANCHOR BENEFIT CONSULTING, INC.',
  'ANTHEM BCBS - COLORADO',
  'ANTHEM BCBS - CONNECTICUT',
  'ANTHEM BCBS - INDIANA',
  'ANTHEM BCBS - KENTUCKY',
  'ANTHEM BCBS - MAINE',
  'ANTHEM BCBS - MISSOURI',
  'ANTHEM BCBS - NEVADA',
  'ANTHEM BCBS - NEW HAMPSHIRE',
  'ANTHEM BCBS - OHIO',
  'ANTHEM BCBS - VIRGINIA',
  'ANTHEM BCBS - WISCONSIN',
  'Anthem BCBS MO',
  'Anthem BCBS- MINNESOTA',
  'Anthem BCBS-North Carolina',
  'ANTHEM BLUE CROSS - CALIFORNIA',
  'Anthem Health & Life Insurance Company',
  'Anthem Health Keeper Plus',
  'ANTHEM INC (CORPORATE)',
  'ANTHEM PAYER NY',
  'APEX BENEFITS SERVICES',
  'APEXHEALTH - (CORPORATE)',
  'APEXHEALTH - NORTH CAROLINA',
  'APEXHEALTH - SOUTH CAROLINA',
  'APEXHEALTH - TENNESSEE',
  'APEXHEALTH - VIRGINIA',
  'Apostrophe',
  'ApproRX',
  'APRIL INTERNATIONAL BENEFITS',
  'APWU AMERICAN POSTAL WORKER\'S HEALTH PLAN',
  'APWU HEALTH PLAN',
  'ARAYA',
  'ARAYA RX',
  'ARBOR HEALTH PLAN',
  'ARC',
  'ARCADIAN COMMUNITY CARE - CALIFORNIA',
  'ARCADIAN COMMUNITY CARE - LOUISIANA',
  'ARCADIAN COMMUNITY CARE - MISSOURI',
  'ARCADIAN HEALTH PLAN - OKLAHOMA',
  'Archbold Medical',
  'ARCHCARE ADVANTAGE',
  'ARCHES HEALTH PLAN',
  'ARCHIMEDES',
  'ARDENT HEALTH SERVICES',
  'ARISE HEALTH PLAN',
  'ARIZONA BENEFIT OPTIONS',
  'ARIZONA COMPLETE HEALTH',
  'Arizona Foundation for Medical Care',
  'ARIZONA PIPE & METAL TRADES HEALTH & WELFARE TRUST FUND',
  'ARKANSAS BCBS',
  'ARKANSAS COMMUNITY CARE INC - ARKANSAS',
  'ARKANSAS COMMUNITY CARE INC - TEXAS',
  'ARKANSAS HEALTH AND WELLNESS',
  'ARMADACARE',
  'Arrowpoint Capital',
  'ARTA MEDICARE HEALTHPLAN',
  'ASCENSION COMPLETE - FLORIDA',
  'ASCENSION COMPLETE - ILLINOIS',
  'ASCENSION COMPLETE - KANSAS',
  'ASEA/AFSCME LOCAL 52',
  'ASPIRE HEALTH PLAN',
  'ASPIRUS ARISE HEALTH PLAN OF WISCONSIN, INC',
  'ASR HEALTH BENEFITS',
  'ASRM LLC',
  'ASSOCIATED ADMINISTRATORS INC',
  'ASSOCIATED THIRD PARTY ADMINISTRATORS',
  'ASSOCIATION & SOCIETY INSURANCE CORPORATION',
  'ASSURANT HEALTH INC',
  'ASSURED BENEFITS ADMINISTRATORS',
  'Assured Life Association',
  'AssuredRX',
  'ASURIS NORTHWEST HEALTH - OREGON',
  'ASURIS NORTHWEST HEALTH - OREGON/WASHINGTON',
  'ASURIS NORTHWEST HEALTH - WASHINGTON',
  'ATLANTIC ADMINISTRATORS',
  'ATLANTIC COAST LIFE INSURANCE COMPANY',
  'Atlantic Health System',
  'ATLANTIC PRESCRIPTION SERVICES (APS)',
  'ATLAS ADMINISTRATORS',
  'ATRIO HEALTH PLANS',
  'AULTCARE',
  'AULTCARE (COMPANY)',
  'Aultman Health Foundation',
  'AULTRA ADMINISTRATIVE GROUP',
  'AUTO MECHANICS HEALTH & WELFARE',
  'AUTO-OWNERS INSURANCE',
  'AUTOMATED BENEFIT SERVICES, INC',
  'AUTOMATED GROUP ADMINISTRATION - AGA',
  'AUTOMATIC SPRINKLER FITTERS H&W LOCAL 281',
  'Automobile Mechanics Local 701 Welfare Fund',
  'AUXIANT',
  'AUXILIO PLAN DE SOCIOS',
  'AVALON BENEFIT SERVICES, INC',
  'AVALON INSURANCE COMPANY - PENNSYLVANIA',
  'AVALON INSURANCE COMPANY - WEST VIRGINIA',
  'Avant Ministries',
  'AVERA HEALTH PLANS INC',
  'Avesis',
  'AVIA PARTNERS INC',
  'AVMED HEALTH PLAN',
  'AWP Rx',
  'AXA Assistance USA',
  'AXA Equitable',
  'CIGNA-HEALTHSPRING - OHIO',
  'CIGNA-HEALTHSPRING - OKLAHOMA',
  'CIGNA-HEALTHSPRING - OREGON',
  'CIGNA-HEALTHSPRING - PENNSYLVANIA',
  'CIGNA-HEALTHSPRING - RHODE ISLAND',
  'CIGNA-HEALTHSPRING - SOUTH CAROLINA',
  'CIGNA-HEALTHSPRING - SOUTH DAKOTA',
  'CIGNA-HEALTHSPRING - TENNESSEE',
  'CIGNA-HEALTHSPRING - TEXAS',
  'CIGNA-HEALTHSPRING - UTAH',
  'CIGNA-HEALTHSPRING - VERMONT',
  'CIGNA-HEALTHSPRING - VIRGINIA',
  'CIGNA-HEALTHSPRING - WASHINGTON',
  'CIGNA-HEALTHSPRING - WASHINGTON D.C.',
  'CIGNA-HEALTHSPRING - WEST VIRGINIA',
  'CIGNA-HEALTHSPRING - WISCONSIN',
  'CIGNA-HEALTHSPRING - WYOMING',
  'CINERGY HEALTH',
  'CITIZEN POTAWATOMI NATION',
  'CITIZENS RX',
  'CITRUS HEALTH CARE INC',
  'CITY OF MESA EMPLOYEE BENEFITS',
  'CLAIM MANAGEMENT SERVICES',
  'ClaimChoice Admistrators',
  'ClaimLinx',
  'CLEAR HEALTH ALLIANCE',
  'CLEAR ONE HEALTH PLANS',
  'CLEAR ONE HEALTH PLANS - MONTANA',
  'CLEAR SPRING HEALTH - (CORPORATE)',
  'CLEARRIVER HEALTH',
  'CLEARSCRIPT',
  'ClearStone Solutions',
  'Cleveland Clinic Employee Health Plan',
  'CLOVER HEALTH',
  'Clover Health',
  'CLOVER HEALTH - (CORPORATE)',
  'CLOVER HEALTH - GEORGIA',
  'CLOVER HEALTH - PENNSYLVANIA',
  'CLOVER HEALTH - TEXAS',
  'CMS DAYTONA BEACH',
  'CMS Georgia',
  'CMS MARATHON',
  'CMS PANAMA CITY',
  'CMS ST. PETERSBURG',
  'CMS TAMPA',
  'CNIC HEALTH SOLUTIONS - CHOICE NET/INTERCARE HEALTH PLANS',
  'Coastal Administrative Services (CAS)',
  'Coastal Communities Physician Network',
  'COASTAL HEALTHCARE ADMINISTRATORS',
  'COFINITY',
  'Collective Health',
  'COLONIAL HEALTHCARE',
  'COLONIAL LIFE & ACCIDENT INSURANCE',
  'Colonial Medical Insurance, LTD',
  'COLONIAL-PENN LIFE INSURANCE',
  'COLORADO ACCESS',
  'COLORADO COMMUNITY HEALTH ALLIANCE',
  'COLORADO FUEL & IRON (CF&I)',
  'Colorado Health Insurance Cooperative, Inc.',
  'Colorado Laborers Health and Welfare',
  'Columbia Pacific CCO',
  'COLUMBIA UNITED PROVIDERS',
  'COLUMBIAN MUTUAL LIFE INSURANCE',
  'COMBINED BENEFITS ADMINISTRATORS',
  'COMBINED INSURANCE COMPANY',
  'COMM-Insure',
  'Commercial Risk Management, Inc.',
  'Commercial Travelers Mutual Insurance Company',
  'COMMISSION FOR THE BLIND AND VISUALLY IMPAIRED',
  'Common Ground Healthcare',
  'COMMONWEALTH CARE ALLIANCE INC',
  'COMMONWEALTH INDEMNITY PLAN',
  'COMMUNITY AND MIGRANT HEALTH CENTERS OF WASHINGTON',
  'COMMUNITY CARE ALLIANCE OF ILLINOIS',
  'Community Care Associates',
  'COMMUNITY CARE COOPERATIVE(C3)',
  'COMMUNITY CARE HEALTH PLAN',
  'COMMUNITY CARE INC- WISCONSIN',
  'Community Care of North Carolina',
  'COMMUNITY CARE PLAN',
  'COMMUNITY CARE SENIOR HEALTH PLAN',
  'COMMUNITY CCRX - (CORPORATE)',
  'COMMUNITY CCRX - ALABAMA',
  'COMMUNITY CCRX - ALASKA',
  'COMMUNITY CCRX - ARIZONA',
  'COMMUNITY CCRX - ARKANSAS',
  'COMMUNITY CCRX - CALIFORNIA',
  'COMMUNITY CCRX - COLORADO',
  'COMMUNITY CCRX - CONNECTICUT',
  'COMMUNITY CCRX - DELAWARE',
  'COMMUNITY CCRX - DISTRICT OF COLUMBIA',
  'COMMUNITY CCRX - FLORIDA',
  'COMMUNITY CCRX - GEORGIA',
  'COMMUNITY CCRX - HAWAII',
  'COMMUNITY CCRX - IDAHO',
  'COMMUNITY CCRX - ILLINOIS',
  'COMMUNITY CCRX - INDIANA',
  'COMMUNITY CCRX - IOWA',
  'COMMUNITY CCRX - KANSAS',
  'COMMUNITY CCRX - KENTUCKY',
  'COMMUNITY CCRX - LOUISIANA',
  'COMMUNITY CCRX - MAINE',
  'COMMUNITY CCRX - MARYLAND',
  'COMMUNITY CCRX - MASSACHUSETTS',
  'COMMUNITY CCRX - MICHIGAN',
  'COMMUNITY CCRX - MINNESOTA',
  'COMMUNITY CCRX - MISSISSIPPI',
  'COMMUNITY CCRX - MISSOURI',
  'COMMUNITY CCRX - MONTANA',
  'COMMUNITY CCRX - NEBRASKA',
  'COMMUNITY CCRX - NEVADA',
  'COMMUNITY CCRX - NEW HAMPSHIRE',
  'COMMUNITY CCRX - NEW JERSEY',
  'COMMUNITY CCRX - NEW MEXICO',
  'COMMUNITY CCRX - NEW YORK',
  'COMMUNITY CCRX - NORTH CAROLINA',
  'COMMUNITY CCRX - NORTH DAKOTA',
  'COMMUNITY CCRX - OHIO',
  'COMMUNITY CCRX - OKLAHOMA',
  'COMMUNITY CCRX - OREGON',
  'COMMUNITY CCRX - PENNSYLVANIA',
  'COMMUNITY CCRX - PUERTO RICO',
  'COMMUNITY CCRX - RHODE ISLAND',
  'COMMUNITY CCRX - SOUTH CAROLINA',
  'COMMUNITY CCRX - SOUTH DAKOTA',
  'COMMUNITY CCRX - TENNESSEE',
  'COMMUNITY CCRX - TEXAS',
  'COMMUNITY CCRX - UTAH',
  'COMMUNITY CCRX - VERMONT',
  'COMMUNITY CCRX - VIRGINIA',
  'COMMUNITY CCRX - WASHINGTON',
  'COMMUNITY CCRX - WEST VIRGINIA',
  'COMMUNITY CCRX - WISCONSIN',
  'COMMUNITY CCRX - WYOMING',
  'Community First',
  'COMMUNITY FIRST HEALTH PLANS INC',
  'Community Health Care Network',
  'COMMUNITY HEALTH CHOICE INC',
  'COMMUNITY HEALTH GROUP',
  'COMMUNITY HEALTH IMPROVEMENT SOLUTIONS',
  'COMMUNITY HEALTH NETWORK',
  'COMMUNITY HEALTH NETWORK OF CONNECTICUT INC',
  'COMMUNITY HEALTH PARTNERSHIP',
  'COMMUNITY HEALTH PLAN - CALIFORNIA',
  'COMMUNITY HEALTH PLAN OF WASHINGTON',
  'COMMUNITY HEALTH SOLUTIONS LLC',
  'Community Healthcenter Network',
  'COMMUNITY LIVING ALLIANCE',
  'COMMUNITYCARE - OKLAHOMA',
  'COMMUNITYCONNECT HEALTHPLAN',
  'Companion Life',
  'Companion Life Insurance Company',
  'COMPASS HEALTH ADMINISTRATORS',
  'Compass Rose Benefits Group',
  'Compassionate Family Care',
  'COMPCARE',
  'COMPCARE HEALTH SERVICES CORPORATION (COMPANY)',
  'Comprehensive Benefit Consultants',
  'COMPREHENSIVE BENEFITS ADMINISTRATOR, INC',
  'COMPREHENSIVE CARE SERVICES',
  'COMPUSYS OF UTAH INC.',
  'CONCERT HEALTH PLAN',
  'CONDUENT',
  'Conduent State Healthcare, LLC',
  'Conifer Health Solutions',
  'ConnectCare',
  'CONNECTED CARE ARIZONA',
  'CONNECTED CARE CALIFORNIA',
  'CONNECTICARE INC - CONNECTICUT',
  'CONNECTICARE INC - MASSACHUSETTS',
  'CONNECTICUT CARPENTERS BENEFIT FUND',
  'Connecticut Pire Traders Health Fund',
  'CONSECO INSURANCE COMPANY',
  'CONSECO LIFE INSURANCE',
  'CONSOCIATE GROUP',
  'CONSOLIDATED ASSOCIATION OF RAILROAD EMPLOYEES HEALTH CARE (CARE)',
  'CONSOLIDATED ASSOCIATIONS OF RAILROAD EMPLOYEES / CARE',
  'CONSOLIDATED HEALTH PLAN',
  'CONSTELLATION HEALTH',
  'CONSTITUTION LIFE',
  'CONSTRUCTION INDUSTRY & WELFARE FUND',
  'CONSTRUCTION LABORERS HEALTH & WELFARE',
  'Consumers Mutual Insurance of Michigan',
  'CONTINENTAL AMERICAN INSURANCE COMPANY - CAIC',
  'CONTINENTAL BENEFITS',
  'CONTINENTAL GENERAL INSURANCE CO',
  'CONTINENTAL LIFE INSURANCE',
  'CONTRA COSTA HEALTH PLAN',
  'CONTRACT HEALTH',
  'COOK CHILDREN`S HEALTH PLAN',
  'COOK GROUP HEALTH PLAN TRUST',
  'COOPERATIVE',
  'COOPERATIVE BENEFIT ADMINISTRATORS - CBA',
  'COOPORTUNITY HEALTH - IOWA',
  'Coordinated Benefits',
  'COORDINATED CARE',
  'COPPERPOINT',
  'CORE BENEFITS, INC',
  'Core Health',
  'CORESOURCE',
  'CORNERSTONE BENEFITS ADMINISTRATOR',
  'CORPORATE BENEFIT SERVICES OF AMERICA - CBSA',
  'Corporate Benefits Service, Inc.',
  'CORPORATE PLANNING MANAGEMENT',
  'CORVEL',
  'COSTCO HEALTH SOLUTIONS',
  'COUNTRY LIFE INSURANCE COMPANY',
  'COUNTY CARE',
  'COUNTY CLAIMS ADMINISTRATION',
  'County Medically Indigent Services Program (CMISP)',
  'COUNTY OF KERN POINT OF SERVICE PLAN',
  'COUNTY OF LOS ANGELES',
  'COUNTYCARE HEALTH PLAN',
  'COVENANT ADMINISTRATORS',
  'COVENTRY HEALTH CARE INC',
  'COVENTRY HEALTH CARE INC (CORPORATE)',
  'COVENTRY HEALTH CARE OF ALABAMA INC',
  'COVENTRY HEALTH CARE OF ALASKA INC',
  'COVENTRY HEALTH CARE OF ARIZONA INC',
  'COVENTRY HEALTH CARE OF ARKANSAS INC',
  'COVENTRY HEALTH CARE OF CALIFORNIA INC',
  'COVENTRY HEALTH CARE OF COLORADO INC',
  'COVENTRY HEALTH CARE OF CONNECTICUT INC',
  'COVENTRY HEALTH CARE OF DELAWARE INC',
  'COVENTRY HEALTH CARE OF DISTRICT OF COLUMBIA INC',
  'COVENTRY HEALTH CARE OF FLORIDA INC',
  'COVENTRY HEALTH CARE OF FLORIDA INC MEDICARE',
  'Coventry Health Care of GA',
  'COVENTRY HEALTH CARE OF GEORGIA INC',
  'COVENTRY HEALTH CARE OF HAWAII INC',
  'COVENTRY HEALTH CARE OF IDAHO INC',
  'COVENTRY HEALTH CARE OF ILLINOIS INC',
  'COVENTRY HEALTH CARE OF ILLINOIS INC (STAND-ALONE)',
  'COVENTRY HEALTH CARE OF INDIANA INC',
  'COVENTRY HEALTH CARE OF IOWA INC',
  'COVENTRY HEALTH CARE OF KANSAS INC',
  'COVENTRY HEALTH CARE OF KENTUCKY INC',
  'COVENTRY HEALTH CARE OF LOUISIANA INC',
  'COVENTRY HEALTH CARE OF MAINE INC',
  'COVENTRY HEALTH CARE OF MARYLAND INC',
  'COVENTRY HEALTH CARE OF MASSACHUSETTS INC',
  'COVENTRY HEALTH CARE OF MICHIGAN INC',
  'COVENTRY HEALTH CARE OF MINNESOTA INC',
  'COVENTRY HEALTH CARE OF MISSISSIPPI INC',
  'COVENTRY HEALTH CARE OF MISSOURI INC',
  'COVENTRY HEALTH CARE OF MISSOURI INC (STAND-ALONE)',
  'COVENTRY HEALTH CARE OF MONTANA INC',
  'COVENTRY HEALTH CARE OF NEBRASKA INC',
  'COVENTRY HEALTH CARE OF NEVADA INC',
  'COVENTRY HEALTH CARE OF NEW HAMPSHIRE INC',
  'COVENTRY HEALTH CARE OF NEW JERSEY INC',
  'COVENTRY HEALTH CARE OF NEW MEXICO INC',
  'COVENTRY HEALTH CARE OF NEW YORK INC',
  'COVENTRY HEALTH CARE OF NORTH CAROLINA INC',
  'COVENTRY HEALTH CARE OF NORTH DAKOTA INC',
  'COVENTRY HEALTH CARE OF OHIO INC',
  'COVENTRY HEALTH CARE OF OKLAHOMA INC',
  'COVENTRY HEALTH CARE OF OREGON INC',
  'COVENTRY HEALTH CARE OF PENNSYLVANIA INC',
  'COVENTRY HEALTH CARE OF RHODE ISLAND INC',
  'COVENTRY HEALTH CARE OF SOUTH CAROLINA INC',
  'COVENTRY HEALTH CARE OF SOUTH DAKOTA INC',
  'COVENTRY HEALTH CARE OF TENNESSEE INC',
  'COVENTRY HEALTH CARE OF TEXAS INC',
  'COVENTRY HEALTH CARE OF THE CAROLINAS INC',
  'COVENTRY HEALTH CARE OF UTAH INC',
  'COVENTRY HEALTH CARE OF VERMONT INC',
  'COVENTRY HEALTH CARE OF VIRGINIA INC',
  'COVENTRY HEALTH CARE OF WASHINGTON INC',
  'COVENTRY HEALTH CARE OF WEST VIRGINIA INC',
  'COVENTRY HEALTH CARE OF WISCONSIN INC',
  'COVENTRY HEALTH CARE OF WYOMING INC',
  'COVERCOLORADO',
  'COX HEALTHPLANS',
  'CRESTPOINT HEALTH',
  'CROSSROADS HEALTHCARE MANAGEMENT',
  'CRUM AND FOSTER',
  'Crystal Run Health Plan',
  'CRYSTAL RUN HEALTH PLAN',
  'CSHCS IN',
  'CSHCS MI',
  'CULINARY HEALTH FUND',
  'CURRENT HEALTH SOLUTIONS',
  'CUSTOM DESIGN BENEFITS',
  'CT Pipe Trades Health Fund',
  'CVS CAREMARK',
  'HUMANA INC - ALASKA',
  'HUMANA INC - ARIZONA',
  'HUMANA INC - ARKANSAS',
  'HUMANA INC - CALIFORNIA',
  'HUMANA INC - COLORADO',
  'HUMANA INC - CONNECTICUT',
  'HUMANA INC - DELAWARE',
  'HUMANA INC - DISTRICT OF COLUMBIA',
  'HUMANA INC - FLORIDA',
  'HUMANA INC - GEORGIA',
  'HUMANA INC - HAWAII',
  'HUMANA INC - IDAHO',
  'HUMANA INC - ILLINOIS',
  'HUMANA INC - INDIANA',
  'HUMANA INC - IOWA',
  'HUMANA INC - KANSAS',
  'HUMANA INC - KANSAS/MISSOURI',
  'HUMANA INC - KENTUCKY',
  'HUMANA INC - LOUISIANA',
  'HUMANA INC - MAINE',
  'HUMANA INC - MARYLAND',
  'HUMANA INC - MASSACHUSETTS',
  'HUMANA INC - MICHIGAN',
  'HUMANA INC - MINNESOTA',
  'HUMANA INC - MISSISSIPPI',
  'HUMANA INC - MISSOURI',
  'HUMANA INC - MONTANA',
  'HUMANA INC - NEBRASKA',
  'HUMANA INC - NEVADA',
  'HUMANA INC - NEW HAMPSHIRE',
  'HUMANA INC - NEW JERSEY',
  'HUMANA INC - NEW MEXICO',
  'HUMANA INC - NEW YORK',
  'HUMANA INC - NORTH CAROLINA',
  'HUMANA INC - NORTH DAKOTA',
  'HUMANA INC - OHIO',
  'HUMANA INC - OKLAHOMA',
  'HUMANA INC - OREGON',
  'HUMANA INC - PENNSYLVANIA',
  'HUMANA INC - PUERTO RICO',
  'HUMANA INC - RHODE ISLAND',
  'HUMANA INC - SOUTH CAROLINA',
  'HUMANA INC - SOUTH DAKOTA',
  'HUMANA INC - TENNESSEE',
  'HUMANA INC - TEXAS',
  'HUMANA INC - UTAH',
  'HUMANA INC - VERMONT',
  'HUMANA INC - VIRGINIA',
  'HUMANA INC - WASHINGTON',
  'HUMANA INC - WEST VIRGINIA',
  'HUMANA INC - WISCONSIN',
  'HUMANA INC - WYOMING',
  'HUMANA PHARMACY SOLUTIONS',
  'Husky Health of Connecticut',
  'HYDE RX SERVICES CORPORATION',
  'URX',
  'UNITEDHEALTHCARE OF MARYLAND INC',
  'UNITEDHEALTHCARE OF MASSACHUSETTS INC',
  'UNITEDHEALTHCARE OF MICHIGAN INC',
  'UNITEDHEALTHCARE OF MINNESOTA INC',
  'UNITEDHEALTHCARE OF MISSISSIPPI INC',
  'UNITEDHEALTHCARE OF MISSOURI INC',
  'UNITEDHEALTHCARE OF MONTANA INC',
  'UNITEDHEALTHCARE OF NEBRASKA INC',
  'UNITEDHEALTHCARE OF NEVADA INC',
  'UNITEDHEALTHCARE OF NEW ENGLAND INC',
  'UNITEDHEALTHCARE OF NEW HAMPSHIRE INC',
  'UNITEDHEALTHCARE OF NEW JERSEY INC',
  'UNITEDHEALTHCARE OF NEW MEXICO INC',
  'UNITEDHEALTHCARE OF NEW YORK INC',
  'UNITEDHEALTHCARE OF NORTH CAROLINA INC',
  'UNITEDHEALTHCARE OF NORTH DAKOTA INC',
  'UNITEDHEALTHCARE OF NORTHERN MARIANA ISLANDS INC',
  'UNITEDHEALTHCARE OF OHIO INC',
  'UNITEDHEALTHCARE OF OKLAHOMA INC',
  'UNITEDHEALTHCARE OF OREGON INC',
  'UNITEDHEALTHCARE OF PENNSYLVANIA INC',
  'UNITEDHEALTHCARE OF PUERTO RICO INC',
  'UNITEDHEALTHCARE OF RHODE ISLAND INC',
  'UNITEDHEALTHCARE OF SOUTH CAROLINA INC',
  'UNITEDHEALTHCARE OF SOUTH DAKOTA INC',
  'UNITEDHEALTHCARE OF TENNESSEE INC',
  'UNITEDHEALTHCARE OF TEXAS INC',
  'UNITEDHEALTHCARE OF THE MID-ATLANTIC INC',
  'UNITEDHEALTHCARE OF THE MIDLANDS INC',
  'UNITEDHEALTHCARE OF THE MIDWEST INC',
  'UNITEDHEALTHCARE OF THE VIRGIN ISLANDS INC',
  'UNITEDHEALTHCARE OF UTAH INC',
  'UNITEDHEALTHCARE OF VERMONT INC',
  'UNITEDHEALTHCARE OF VIRGINIA INC',
  'UNITEDHEALTHCARE OF WASHINGTON INC',
  'UNITEDHEALTHCARE OF WEST VIRGINIA INC',
  'UNITEDHEALTHCARE OF WISCONSIN INC',
  'UNITEDHEALTHCARE OF WYOMING INC',
  'UNITEDHEALTHCARE OXFORD HEALTH PLAN OF CONNECTICUT INC',
  'UNITEDHEALTHCARE OXFORD HEALTH PLAN OF NEW JERSEY INC',
  'UNITEDHEALTHCARE OXFORD HEALTH PLAN OF NEW YORK INC',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - ARKANSAS',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - GEORGIA',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - ILLINOIS',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - IOWA',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - NORTH CAROLINA',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - OHIO',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - SOUTH CAROLINA',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - TENNESSEE',
  'UNITEDHEALTHCARE SERVICES COMPANY OF THE RIVER VALLEY INC - VIRGINIA',
  'UNITEDHEARTLAND',
  'UNIVERA HEALTHCARE',
  'Universal American',
  'UNIVERSAL AMERICAN, A WELLCARE COMPANY - (CORPORATE)',
  'Universal Benefits Corporation',
  'UNIVERSAL FIDELITY LIFE INSURANCE COMPANY',
  'UNIVERSAL HEALTH CARE INC - (CORPORATE)',
  'UNIVERSAL HEALTH CARE INC - ARIZONA',
  'UNIVERSAL HEALTH CARE INC - FLORIDA',
  'UNIVERSAL HEALTH CARE INC - GEORGIA',
  'UNIVERSAL HEALTH CARE INC - ILLINOIS',
  'UNIVERSAL HEALTH CARE INC - LOUISIANA',
  'UNIVERSAL HEALTH CARE INC - MARYLAND',
  'UNIVERSAL HEALTH CARE INC - MISSISSIPPI',
  'UNIVERSAL HEALTH CARE INC - MISSOURI',
  'UNIVERSAL HEALTH CARE INC - NEVADA',
  'UNIVERSAL HEALTH CARE INC - NEW YORK',
  'UNIVERSAL HEALTH CARE INC - NORTH CAROLINA',
  'UNIVERSAL HEALTH CARE INC - OHIO',
  'UNIVERSAL HEALTH CARE INC - OKLAHOMA',
  'UNIVERSAL HEALTH CARE INC - PENNSYLVANIA',
  'UNIVERSAL HEALTH CARE INC - SOUTH CAROLINA',
  'UNIVERSAL HEALTH CARE INC - TEXAS',
  'UNIVERSAL HEALTH CARE INC - UTAH',
  'UNIVERSAL HEALTH CARE INC - VIRGINIA',
  'UNIVERSAL HEALTH CARE INC - WASHINGTON D.C.',
  'Universal Rx',
  'UNIVERSITY CARE ADVANTAGE',
  'UNIVERSITY HEALTH ALLIANCE',
  'UPMC HEALTH PLAN (CORPORATE)',
  'UPMC HEALTH PLAN - OHIO',
  'UPMC HEALTH PLAN - PENNSYLVANIA',
  'UPMC HEALTH PLAN - WEST VIRGINIA',
  'UPMC HEALTH SYSTEM',
  'UPPER PENINSULA HEALTH PLAN',
  'US Benefits',
  'US BENEFITS',
  'US DEPT OF LABOR, OWCP',
  'US Family Health Plan',
  'US FAMILY HEALTH PLANS',
  'US HEALTH & LIFE INSURANCE COMPANY',
  'US RX CARE',
  'USAA LIFE INSURANCE COMPANY',
  'USABLE LIFE INSURANCE',
  'USABLE MUTUAL INSURANCE COMPANY',
  'Utah Laborors Trust Funds',
  'UTAH PIPE TRADE',
  'Utah-Idaho Teamsters Security Fund',
  'UVA Health (University of Virginia Health System)',
  'Aetna Better Health - New York',
  'MERCY HEALTH PLANS - ARKANSAS',
  'MERCY HEALTH PLANS - ILLINOIS',
  'MERCY HEALTH PLANS - MISSOURI',
  'Spectrum Solutions',
];

export default _.map(payerList, name => ({ label: name, value: name }));
