import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import _ from 'lodash';
import { graphql, compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import RequiredFieldModal from 'AuthorizationSharedComponents/RequiredFieldModal';
import ContextPane from 'AuthorizationSharedComponents/ContextPane';
import Prescriber from 'AuthorizationSharedSteps/PrescriberSection';
import ResponseSection from 'AuthorizationSharedSteps/ResponseSection';
import EnrollmentInitializeSection from './EnrollmentInitializeSection';
import EnrollmentRequestDetails from './EnrollmentRequestDetails';
import EnrollmentSubmit from './EnrollmentSubmit';

import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { resetForm, setCurrentStep, setFormFields, syncAuthorizationDetails, toggleHighlightRequiredFields } from '../../reducers/formReducer';
import ROUTE_PATHS from '../ROUTE_PATHS';
import { withFormById } from '../../graphql/AuthorizationForm';
import {
  withUpdateAuthorizationProgress,
  withSubmitAuthorization,
  withUpdateFormDetails,
  withAuthorizationQuery,
  withUpdateAuthorization,
} from '../../graphql/Authorization';
import { withCurrentAccount } from '../../graphql/Account';
import NewAuthorizationHOC from '../../components/NewAuthorizationHOC';
import getEnrollmentDescStringFromConfig from '../../util/getEnrollmentDescStringFromConfig';

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: row;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  margin: 50px 0 50px 75px;
  flex-direction: column;
  width: 900px;
  @media (min-width: 1300px) {
    width: 1100px;
  }
`;

export const FormTitle = styled.div`
  font-size: 32px;
  font-weight: 500;
`;

export const FormSubTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.theme.darkGray};
  margin-bottom: 10px;
`;

const OverviewRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
`;

const STEPS = CONFIG.CONSTANTS.PATIENT_ENROLLMENT_STEPS.ALL;
export class NewPatientEnrollment extends PureComponent {
  state = {
    loading: false,
    requiredFieldModalOpen: false,
  }

  componentDidMount() {
    const { syncStepToUrl } = this.props;
    syncStepToUrl();
    this.syncLocalState();
  }

  componentDidUpdate(prevProps) {
    const { onComponentUpdate, authorization, results } = this.props;
    onComponentUpdate(prevProps, authorization, results, this.syncLocalState.bind(this));
  }

  syncLocalState() {
    const { authorization, syncLocalState } = this.props;
    if (authorization) {
      syncLocalState(authorization.config, authorization.status, authorization.attachments);
    }
  }

  updateAndStep = async (isForward) => {
    const { disabled, hasInvalidResults, takeStep, saveAuthorizationChanges } = this.props;

    if (disabled || !isForward) {
      await takeStep(isForward);
    } else if (!hasInvalidResults()) {
      await saveAuthorizationChanges();
      await takeStep(isForward);
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  triggerRequiredModalOnInvalid = (manuallyTrigger) => {
    const { hasInvalidResults } = this.props;
    if (hasInvalidResults(manuallyTrigger)) {
      this.setState({ requiredFieldModalOpen: true });
      return true;
    }
    return false;
  }

  getContent = (step, activeStepConfig) => {
    const { authorization, account, goToLocation, saveAuthorizationChanges } = this.props;
    let content;
    switch (step) {
      case STEPS.INITIATE.step:
        content = (
          <EnrollmentInitializeSection
            authorization={authorization}
            isInvalid={this.triggerRequiredModalOnInvalid}
            nextStep={() => { this.updateAndStep(true); }}
          />
        );
        break;
      case STEPS.REQUEST_DETAILS.step:
        content = (
          <EnrollmentRequestDetails
            authorization={authorization}
            triggerRequiredModalOnInvalid={() => { this.triggerRequiredModalOnInvalid(true); }}
            back={() => { this.updateAndStep(false); }}
            nextStep={() => { this.updateAndStep(true); }}
          />
        );
        break;
      case STEPS.PRESCRIBER_AND_LOCATION_DETAILS.step:
        content = (
          <Prescriber authorization={authorization} back={() => { this.updateAndStep(false); }} nextStep={() => { this.updateAndStep(true); }}>
            <EnrollmentSubmit
              authorization={authorization}
              account={account}
              back={() => { this.updateAndStep(false); }}
              nextStep={() => { this.updateAndStep(true); }}
              backToList={() => { goToLocation(ROUTE_PATHS.PATIENT_ENROLLMENT_LIST); }}
              saveChanges={saveAuthorizationChanges}
            />
          </Prescriber>
        );
        break;
      case STEPS.RESPONSE.step:
        content = (
          <ResponseSection
            back={() => { this.updateAndStep(false); }}
            authorization={authorization}
          />
        );
        break;
      default:
        content = (<FormContainer />);
        break;
    }

    return (
      <FormContainer>
        <FormTitle>Patient Enrollment</FormTitle>
        <FormSubTitle>{activeStepConfig.title}</FormSubTitle>
        { content }
      </FormContainer>
    );
  }

  render() {
    const { authorization, account, goToLocation, disabled, step, setStepAndUpdateURL, saveAuthorizationChanges } = this.props;
    const { requiredFieldModalOpen, loading } = this.state;

    const currentStep = _.min([step, CONFIG.CONSTANTS.PATIENT_ENROLLMENT_STEPS.ALL.RESPONSE.step]);
    const activeStepConfig = _.find(
      CONFIG.CONSTANTS.PATIENT_ENROLLMENT_STEPS.ALL,
      { step: currentStep },
    );
    if (authorization) {
      const content = this.getContent(currentStep, activeStepConfig, authorization.isPortalSubmission);

      return (
        <Container>
          { content }
          <ContextPane
            isSamaUser={_.get(account, 'isSamaUser')}
            sections={CONFIG.CONSTANTS.PATIENT_ENROLLMENT_STEPS}
            authorizationId={authorization.id}
            backToAuthorizations={() => { goToLocation(ROUTE_PATHS.PATIENT_ENROLLMENT_LIST); }}
            saveChanges={saveAuthorizationChanges}
            setStep={(stepTo) => {
              if (stepTo === STEPS.RESPONSE.step || disabled) {
                setStepAndUpdateURL(stepTo);
              } else {
                saveAuthorizationChanges().then(() => { setStepAndUpdateURL(stepTo); });
              }
            }}
            isPatientEnrollment
          >
            <OverviewRow>
              <div style={{ flex: 1 }}>Submitting to</div>
              <div>{ getEnrollmentDescStringFromConfig(authorization.config) }</div>
            </OverviewRow>
          </ContextPane>
          <RequiredFieldModal
            closeModal={() => { this.setState({ requiredFieldModalOpen: false }); }}
            open={requiredFieldModalOpen}
            content="Required fields are highlighted in red."
          />
          <LoadingSpinner open={loading} />
        </Container>
      );
    }
    return (<div />);
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    attachments: state.form.attachments,
    step: state.form.currentStep,
    disabled: state.form.disabled,
    requiredFieldKeys: state.form.requiredFieldKeys,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    syncLocalState: (config, status, attachments) => { dispatch(syncAuthorizationDetails(config, status, attachments)); },
    goToLocation: (location) => { dispatch(push(location)); },
    setToForm: toSet => dispatch(setFormFields(toSet)),
    setStep(step) { dispatch(setCurrentStep(step)); },
    reset() { dispatch(resetForm()); },
    toggleHighlightRequiredFields(shouldBeHighlighted) { dispatch(toggleHighlightRequiredFields(shouldBeHighlighted)); },
  };
}

export const withAuthorization = graphql(
  withAuthorizationQuery,
  {
    options: (props) => {
      const params = new URLSearchParams(props.location.search);
      return { variables: { id: _.toNumber(params.get('id')) }, fetchPolicy: 'network-only' };
    },
    props: ({ data }) => ({
      authorization: data.authorizationById,
      authorizationRefetch: _.get(data, 'refetch'),
    }),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(
    withUpdateFormDetails,
    withSubmitAuthorization,
    withUpdateAuthorizationProgress,
    withAuthorization,
    withFormById, // withFormById must be after withAuthorization
    withUpdateAuthorization,
    withCurrentAccount,
  )(
    withAlert(NewAuthorizationHOC(NewPatientEnrollment))
  )
);
