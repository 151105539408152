import React from 'react';
import BaseButton from '../../../components/BaseButton';

const MissingFormButton = ({ onClick }) => (
  <BaseButton
    color="#c02d4c"
    onClick={onClick}
    style={{ marginLeft: 'auto' }}
  >
    The Form I Need Is Missing
  </BaseButton>
);

export default MissingFormButton;
