import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { BaseCleave } from './StyledComponents';

class SegmentNumber extends PureComponent {
  render() {
    const { item, handleChange, result, disabled, highlightIfMissing } = this.props;

    return (
      <BaseCleave
        disabled={disabled}
        onChange={(event) => { handleChange({ [item.key]: event.target.value }); }}
        options={{ numericOnly: true }}
        value={result || ''}
        highlight={highlightIfMissing && !result}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { key } = props.item;
  return key ? { result: state.form.results[key] } : { result: null };
}

export default connect(mapStateToProps)(SegmentNumber);
