import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';

import { withUpdateAuthorizationStatus } from '../../graphql/Authorization';
import RequestResendButton from '../RequestResendButton';
import ActionRequiredRowContainer from './ActionRequiredRowContainer';
import BaseButton from '../BaseButton';
import { ActionRequiredBanner } from './FollowUpRow';

const FollowUpButtons = styled(BaseButton)`
  margin-left: 10px;
  width: 200px;
`;

class SendingFailedRow extends PureComponent {
  markPended = async () => {
    const { updateAuthorizationStatus, alert, row } = this.props;
    const authorization = row.model;
    try {
      await updateAuthorizationStatus({
        variables: {
          id: authorization.id,
          status: CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING,
        },
      });

      alert.info('Authorization status updated to pending');
    } catch (e) {
      alert.error('Authorization update failed');
    }
  }

  render() {
    const { i, keyOff, row } = this.props;
    const authorization = row.model;

    return (
      <ActionRequiredRowContainer keyOff={keyOff} i={i} row={row}>
        <ActionRequiredBanner>
          {
            'This authorization failed to be sent. This can happen if the fax is too long or if the fax number is invalid.'
            + ' Please verify the fax number and resend to resolve this issue.'
          }
          <RequestResendButton authorizationId={authorization.id}>
            <FollowUpButtons>Resend</FollowUpButtons>
          </RequestResendButton>
          <FollowUpButtons onClick={this.markPended}>Mark Pending</FollowUpButtons>
        </ActionRequiredBanner>
      </ActionRequiredRowContainer>
    );
  }
}

export default compose(withUpdateAuthorizationStatus)(withAlert(SendingFailedRow));
