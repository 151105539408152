import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import UnexpectedErrorIcon from 'react-icons/lib/md/error-outline';
import ServiceDownIcon from 'react-icons/lib/md/watch-later';
import strings from 'Resources/strings';
import Section from 'AuthorizationSharedComponents/Section';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';
import * as FullStory from '@fullstory/browser';

import PortalTimeout from './PortalTimeout';
import PortalHCPCS from './PortalHCPCS';
import PortalICD from './PortalICD';
import PortalError from './PortalError';
import PortalTable from './PortalTable';
import PortalFillTable from './PortalFillTable';
import PortalImagePrompt from './PortalImagePrompt';
import PortalText from './PortalText';
import PortalComplete from './PortalComplete';
import PortalSubmitted from './PortalSubmitted';
import PortalUpload from './PortalUpload';
import LoadingSpinner from '../../components/LoadingSpinner';
import PortalTutorialModal from '../../components/PortalTutorialModal';
import LargeLoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import BaseButton from '../../components/BaseButton';
import { withUpdateAuthorization } from '../../graphql/Authorization';
import LivePortalConnection from './LivePortalConnection';
import PortalLogin from './PortalLogin';
import PortalErrorModal from './PortalErrorModal';
import PortalPriorSteps from './PortalPriorSteps';
import Modal from '../../components/Modal';
import { ModalBody, ModalBodyLarge, ModalFooter, ModalContent } from '../../components/ModalStyledComponents';
import { withUpdateShowTutorial } from '../../graphql/Account';
import ReopenLivePortalConnection from './ReopenLivePortalConnection';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LoadingSpinnerStyled = styled(LoadingSpinner)`
  border: 4px solid rgba(114, 113, 243, 0.2);
  border-top-color: rgb(114, 113, 243);
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid ${({ theme, color }) => color || theme.purple};
  border-radius: 3px;
  ${BaseButton} {
    margin-left: auto;
  }
`;

const StepContainer = styled.div`
  padding: 10px;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 20px 0;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  justify-content: center;
  ${BaseButton} {
    width: 300px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;

const MESSAGE_TYPES = CONFIG.CONSTANTS.INTEGRATION_MESSAGE_TYPES;
const COMPONENT_TYPES = CONFIG.CONSTANTS.INTEGRATION_COMPONENT_TYPES;

export class PortalSpecificSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openUserCredentialModal: false,
      showStepBackWarning: false,
      closeUserSubmissionModal: false,
      openLiveIntegrationModal: false,
      liveConnectionStarted: false,
      isLoading: false,
      portalTutorialModal: false,
    };

    props.setTriggerReinputLogin(() => { this.setState({ openUserCredentialModal: true }); });
  }

  componentDidMount = async () => {
    const { authorization, isMissingPortalCredentials, hasStartedIntegration, hasStartedLiveConnection, hidePortalTutorial } = this.props;

    if (CONFIG.NODE_ENV === 'production' && authorization && !_.get(authorization, 'submittedAt')) {
      FullStory.event('Live Viewer Started', {
        authorizationId: authorization.id,
        createdById: authorization.createdById,
      });
    }

    if (isMissingPortalCredentials) {
      this.setState({ openUserCredentialModal: true });
    }

    if (hasStartedIntegration && hasStartedLiveConnection) {
      this.setState({ openLiveIntegrationModal: true, liveConnectionStarted: true });
    }

    if (!hidePortalTutorial) {
      this.setState({ portalTutorialModal: true });
    }
  }

  componentWillUnmount() {
    const { setTriggerReinputLogin, authorization } = this.props;

    setTriggerReinputLogin(null);
    if (CONFIG.NODE_ENV === 'production' && authorization) {
      FullStory.event('Live Viewer Ended', {
        authorizationId: authorization.id,
        createdById: authorization.createdById,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { latestIntegrationMessage } = this.props;
    const currentMessageType = _.get(latestIntegrationMessage, 'type');
    const previousMessageType = _.get(prevProps, 'latestIntegrationMessage.type');
    // If we JUST received live connection message then auto open modal
    if (
      (previousMessageType !== currentMessageType && currentMessageType === MESSAGE_TYPES.TRIGGER_LIVE_CONNECTION)
    ) {
      // eslint-disable-next-line
      this.setState({ openLiveIntegrationModal: true, liveConnectionStarted: true });
    }

    // After submit, we send loading, need to close the live modal at that time
    if (previousMessageType !== currentMessageType && currentMessageType === MESSAGE_TYPES.LOADING) {
      // eslint-disable-next-line
      this.setState({ openLiveIntegrationModal: false, isLoading: true });
    }
  }

  restartIntegration = async () => {
    const { _restartIntegration } = this.props;
    this.setState({ openLiveIntegrationModal: false, liveConnectionStarted: false });
    await _restartIntegration(true);
  }

  sendResponse = async (response) => {
    const { authorization, sendIntegrationMessage, updateAuthorization, alert, latestIntegrationMessage } = this.props;
    const previousStep = _.find(authorization.customPortalSteps, { id: response.id });

    if (!previousStep || previousStep.isDraft) {
      const formattedResponse = {
        ..._.omit(response, 'data'),
        requiresHardReset: latestIntegrationMessage.requiresHardReset,
      };

      try {
        if (!latestIntegrationMessage.skipSaving) {
          await updateAuthorization(
            { variables: {
              id: authorization.id,
              patch: { customPortalSteps: _.reject(authorization.customPortalSteps, { isDraft: true }).concat(formattedResponse) } },
            }
          );
        }

        if (!formattedResponse.isDraft) {
          await sendIntegrationMessage(_.omit(formattedResponse, 'priorValue'));
        }
      } catch (e) {
        console.error(e);
        alert.error('There was an error sending your response to the server, please refresh and try again');
      }
    }
  };

  removeTerminalAndRetry = () => {
    const { authorization } = this.props;
    if (_.get(_.last(authorization.customPortalSteps), 'isTerminal')) {
      this.resetToStep(_.last(authorization.customPortalSteps).id);
    } else {
      // In this case, the authorization is out of date in the front-end.
      // Therefore, we should just restart with 'past steps'
      this.resetToStep(null, true);
    }
  };

  retryFromLastStep = () => {
    const { authorization } = this.props;
    this.resetToStep(_.last(authorization.customPortalSteps).id);
  };

  resetToStep = (stepId, resetWithSameSteps) => {
    const { authorization } = this.props;

    let portalStepsAfterReset = authorization.customPortalSteps.slice(0, _.findIndex(authorization.customPortalSteps, { id: stepId }));
    if (resetWithSameSteps) {
      portalStepsAfterReset = authorization.customPortalSteps;
    }

    this.updatePortalStepsAndRestart(portalStepsAfterReset);
  };

  updatePortalStepsAndRestart = async (newSteps) => {
    const { authorization, updateAuthorization, alert } = this.props;

    try {
      await updateAuthorization({ variables: { id: authorization.id, patch: { customPortalSteps: newSteps } } });
      this.restartIntegration();
    } catch {
      alert.error('There was an error updating your authorization, please refresh and try again');
    }
  }

  unsetStep = (stepId) => {
    const { authorization } = this.props;

    const newSteps = _.reject(authorization.customPortalSteps, step => (step.id === stepId || step.isTerminal));

    this.updatePortalStepsAndRestart(newSteps);
  }

  resetAuthorization = () => {
    const { authorization, updateAuthorization, alert, goToInitialStep } = this.props;

    updateAuthorization({ variables: { id: authorization.id, patch: { customPortalSteps: [], formDetails: { step: 1 }, PatientId: null } } })
      .then(() => {
        this.restartIntegration();
        goToInitialStep();
      })
      .catch((e) => {
        console.error(e);
        alert.error('There was an error resetting your authorization, please refresh and try again');
      });
  };

  getErrorContent = (authorization, message, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials) => {
    const { takeStepsBack } = this.props;
    let icon;
    let title;
    let isReenterCredentials = false;
    let isDefaultCase = false;

    switch (message.subType) {
      case MESSAGE_TYPES.ERROR_SUB_TYPE.SERVICE_DOWN:
        icon = ServiceDownIcon;
        title = strings.INTEGRATED_AUTHORIZATIONS.ERROR_TITLES.SERVICE_DOWN;
        break;
      case MESSAGE_TYPES.ERROR_SUB_TYPE.WRONG_CREDENTIALS:
        icon = UnexpectedErrorIcon;
        title = 'Invalid login credentials';
        isReenterCredentials = true;
        isDefaultCase = true;
        break;
      case MESSAGE_TYPES.ERROR_SUB_TYPE.MULTIPLE_SESSIONS:
        icon = UnexpectedErrorIcon;
        title = 'Multiple sessions error';
        break;
      default:
        icon = UnexpectedErrorIcon;
        title = strings.INTEGRATED_AUTHORIZATIONS.ERROR_TITLES.UNEXPECTED;
        isDefaultCase = true;
    }
    return isDefaultCase ? (
      <PortalErrorModal
        authorization={authorization}
        message={message}
        title={title}
        retryFromLastStep={retryFromLastStep}
        removeTerminalAndRetry={removeTerminalAndRetry}
        restartIntegration={this.restartIntegration}
        updatePortalStepsAndRestart={this.updatePortalStepsAndRestart}
        unsetStep={this.unsetStep}
        resetToStep={this.resetToStep}
        reenterCredentials={reenterCredentials}
        hideStandardActions={isReenterCredentials}
        takeStepsBack={takeStepsBack}
        getPriorSteps={this.getPriorSteps}
        retry={() => { this.resetToStep(null, true); }}
      />
    ) : (
      <PortalError
        authorization={authorization}
        title={title}
        message={message}
        hideStandardActions={isReenterCredentials}
        reenterCredentials={
          isReenterCredentials
            ? reenterCredentials
            : null
        }
        removeTerminalAndRetry={removeTerminalAndRetry}
        retryFromLastStep={retryFromLastStep}
        back={back}
        icon={icon}
      />
    );
  };

  getTerminalStepContent = (authorization, message, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials) => {
    let content = null;

    switch (message.type) {
      case MESSAGE_TYPES.ERROR:
        content = this.getErrorContent(authorization, message, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials);
        break;
      default:
        console.error('Unrecognized type on integration message', message);
    }
    return content;
  };

  getIntegrationMessageContent = (authorization, message, sendResponse, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials) => {
    let content = null;
    // Order here matters
    switch (message.type) {
      case MESSAGE_TYPES.ERROR:
        content = this.getErrorContent(authorization, message, removeTerminalAndRetry, retryFromLastStep, back, reenterCredentials);
        break;
      case MESSAGE_TYPES.LOADING:
        content = (<LargeLoadingSpinner open />);
        break;
      case MESSAGE_TYPES.INTEGRATION_UPDATE:
        content = (
          <LoadingContainer>
            <LoadingSpinnerStyled height={40} width={40} />
            { _.get(message, 'message') || 'Building your authorization...' }
          </LoadingContainer>
        );
        break;
      case MESSAGE_TYPES.SUBMISSION_SUCCESS:
        content = (<PortalSubmitted />);
        break;
      case MESSAGE_TYPES.TRIGGER_LIVE_CONNECTION:
        content = (<ReopenLivePortalConnection onClick={() => { this.setState({ openLiveIntegrationModal: true, portalTutorialModal: true }); }} />);
        break;
      case MESSAGE_TYPES.NO_LIVE_CONNECTION:
        break;
      default:
        switch (message.componentType) {
          case COMPONENT_TYPES.TABLE:
            content = (<PortalTable message={message} onSelect={sendResponse} />);
            break;
          case COMPONENT_TYPES.TEXT:
            content = (<PortalText message={message} onSelect={sendResponse} />);
            break;
          case COMPONENT_TYPES.ICD:
            content = (<PortalICD message={message} onSelect={sendResponse} authorization={authorization} />);
            break;
          case COMPONENT_TYPES.HCPCS:
            content = (<PortalHCPCS message={message} onSelect={sendResponse} authorization={authorization} />);
            break;
          case COMPONENT_TYPES.UPLOAD:
            content = (<PortalUpload message={message} onSelect={sendResponse} authorization={authorization} />);
            break;
          case COMPONENT_TYPES.COMPLETE:
            content = (<PortalComplete message={message} onSubmit={sendResponse} />);
            break;
          case COMPONENT_TYPES.FILL_TABLE:
            content = (<PortalFillTable message={message} onSubmit={sendResponse} />);
            break;
          case COMPONENT_TYPES.IMAGE_PROMPT:
            content = (<PortalImagePrompt message={message} onSelect={sendResponse} />);
            break;
          default:
            console.error('Unrecognized type on integration message', message);
            content = (
              <LoadingContainer>
                <LoadingSpinnerStyled height={40} width={40} />
                { _.get(message, 'message') || 'Loading...' }
              </LoadingContainer>
            );
        }
    }
    return content;
  };

  render() {
    const {
      alert,
      latestIntegrationMessage,
      back,
      authorization,
      nextStep,
      navigateToMainList,
      navigateToResponse,
      runnerId,
      ioClient,
      sendIntegrationMessage,
      hidePortalTutorial,
      updateShowTutorial,
      unsetMissingPortalCredentials,
      isMissingPortalCredentials,
    } = this.props;
    const {
      showStepBackWarning,
      openUserCredentialModal,
      closeUserSubmissionModal,
      openLiveIntegrationModal,
      liveConnectionStarted,
      isLoading,
      portalTutorialModal,
    } = this.state;

    let content = null;
    let openUserSubmissionModal;
    const showPortalTutorial = !hidePortalTutorial && portalTutorialModal && !(openUserCredentialModal || isMissingPortalCredentials);
    if (!closeUserSubmissionModal) {
      openUserSubmissionModal = _.get(latestIntegrationMessage, 'type') === MESSAGE_TYPES.SUBMISSION_SUCCESS;
    }

    if (_.get(latestIntegrationMessage, 'type') === MESSAGE_TYPES.SUCCESS_AND_IMMEDIATE_RESPONSE) {
      navigateToResponse();
    }

    if (_.get(_.last(authorization.customPortalSteps), 'isTerminal')) {
      content = this.getTerminalStepContent(
        authorization,
        _.last(authorization.customPortalSteps),
        this.removeTerminalAndRetry,
        this.retryFromLastStep,
        back,
        () => { this.setState({ openUserCredentialModal: true }); }
      );
    } else if (latestIntegrationMessage) {
      content = this.getIntegrationMessageContent(
        authorization,
        latestIntegrationMessage,
        this.sendResponse,
        this.removeTerminalAndRetry,
        this.retryFromLastStep,
        back,
        () => { this.setState({ openUserCredentialModal: true }); }
      );
    } else if (!latestIntegrationMessage) {
      if (liveConnectionStarted) {
        if (isLoading) {
          content = (<LargeLoadingSpinner open />);
        } else {
          content = (<ReopenLivePortalConnection onClick={() => { this.setState({ openLiveIntegrationModal: true, portalTutorialModal: true }); }} />);
        }
      } else {
        content = (
          <LoadingContainer>
            <LoadingSpinnerStyled height={40} width={40} />
            Proceeding to next step...
          </LoadingContainer>
        );
      }
    }

    return (
      <FormContainer>
        <PortalTimeout
          message={latestIntegrationMessage}
          runnerId={runnerId}
          restartIntegration={this.restartIntegration}
          onTriggerOpen={() => { this.setState({ openLiveIntegrationModal: false }); }}
          isLiveConnectionPortal={liveConnectionStarted}
        />
        { authorization.customPortalSteps.length > 0 && (
          <PortalPriorSteps
            authorization={authorization}
            resetToStep={this.resetToStep}
            unsetStep={this.unsetStep}
          />
        ) }
        { !authorization.submittedAt && (
          <Section section={{ title: 'Current Step' }}>
            <StepContainer>
              { content }
            </StepContainer>
          </Section>
        ) }
        { authorization.submittedAt && (
          <Section section={{ title: 'Authorization Submitted' }}>
            <PortalSubmitted />
          </Section>
        ) }
        <FormSubmitButtons back={authorization.submittedAt ? back : () => { this.setState({ showStepBackWarning: true }); }} submit={nextStep} />
        <PortalLogin
          open={openUserCredentialModal || isMissingPortalCredentials}
          portalKey={authorization.portalKey}
          onSubmit={() => {
            this.setState({ openUserCredentialModal: false });
            unsetMissingPortalCredentials();
            this.restartIntegration();
          }}
          closeModal={() => { this.setState({ openUserCredentialModal: false }, () => { unsetMissingPortalCredentials(); }); }}
        />
        { showPortalTutorial && (
          <PortalTutorialModal
            open
            back={() => { this.setState({ portalTutorialModal: false }); }}
            onComplete={() => {
              updateShowTutorial().catch(() => { alert.error('There was an error setting portal tutorial as complete'); });
              this.setState({ portalTutorialModal: false });
            }}
          />
        ) }
        { openLiveIntegrationModal && ioClient && !showPortalTutorial && (
          <LivePortalConnection
            backToAuthorizations={navigateToMainList}
            sendIntegrationMessage={sendIntegrationMessage}
            ioClient={ioClient}
            close={() => { this.setState({ openLiveIntegrationModal: false }); }}
            authorization={authorization}
            restartIntegration={this.restartIntegration}
            open
            hasLostConnection={_.get(latestIntegrationMessage, 'type') === MESSAGE_TYPES.NO_LIVE_CONNECTION}
          />
        ) }
        { openUserSubmissionModal && (
          <Modal header="Submission Success!" open={openUserSubmissionModal} onClick={() => { this.setState({ closeUserSubmissionModal: true }); }}>
            <ModalBody>
              <CustomModalContent>
                Your portal authorization has been successfully submitted!
                SamaCare will keep track of the authorization and automatically look for a response from the payer every morning.
              </CustomModalContent>
            </ModalBody>
            <CustomModalFooter>
              <BaseButton onClick={() => { navigateToMainList(); }}>Return to Authorizations List</BaseButton>
            </CustomModalFooter>
          </Modal>
        ) }
        { showStepBackWarning && (
          <Modal header="Return to Prescriber Details" open={showStepBackWarning} onClick={() => { this.setState({ showStepBackWarning: false }); }}>
            <ModalBodyLarge>
              <CustomModalContent>
                <div style={{ margin: '10px 0' }}>{`You are currently connected to the ${authorization.portalTitle} web portal.`}</div>
                <div style={{ margin: '10px 0' }}>After returning, this web integration will need to be restarted.</div>
                <div style={{ margin: '10px 0' }}>
                  If you want to change portal specific details click the re-input information button next to the relevant section.
                </div>
              </CustomModalContent>
            </ModalBodyLarge>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '16px' }}>
              <BaseButton onClick={() => { this.setState({ showStepBackWarning: false }); }}>No, I want to stay on this page</BaseButton>
              <BaseButton onClick={back}>Yes, I want to go back</BaseButton>
            </div>
          </Modal>
        ) }
      </FormContainer>
    );
  }
}

export default compose(withUpdateAuthorization, withUpdateShowTutorial)(withAlert(PortalSpecificSteps));
