import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import Patient from 'AuthorizationSharedComponents/Patient';
import Section from 'AuthorizationSharedComponents/Section';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';
import SegmentSelector from 'Segment/SegmentSelector';

import { withCurrentAccount } from '../../graphql/Account';
import { withInsuranceCompanies } from '../../graphql/InsuranceCompany';
import { withCreatePatientOnAuthorization } from '../../graphql/Patient';
import { setFormFields } from '../../reducers/formReducer';
import genentechPayers from './genentechPayers';
import RequiredTag from '../../components/RequiredTag';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const SingleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const relationshipOptions = [
  { label: 'Parent', value: 'parent' },
  { label: 'Spouse', value: 'spouse' },
  { label: 'Dependent Child', value: 'dependentChild' },
  { label: 'Dependent Adult', value: 'dependentAdult' },
  { label: 'Unknown', value: 'unknown' },
  { label: 'Other', value: 'other' },
];


const fields = CONFIG.DEFAULT_FIELDS;
export class EnrollmentInitializeSection extends PureComponent {
  state = { filteredPayers: genentechPayers.slice(0, 20) }

  selectorSetter = (selection, key) => {
    const { set } = this.props;
    if (selection) {
      set({ [key]: selection.value });
    } else {
      set({ [key]: null });
    }
  }

  submit = async () => {
    const { isInvalid, results } = this.props;
    let requiredFieldKeys = [fields.INSURANCE_STATE.key, fields.INSURANCE_COMPANY.key];
    if (results[fields.PATIENT_NOT_POLICY_HOLDER.key]) {
      requiredFieldKeys = requiredFieldKeys.concat([fields.RELATIONSHIP_TO_PATIENT.key]);
    }

    if (!isInvalid(_.some(requiredFieldKeys, key => (!results[key])))) {
      await this.success();
    }
  }

  success = async () => {
    const { nextStep, authorization, dob, firstName, lastName, createPatientOnAuthorization } = this.props;

    if (!authorization.patient) {
      await createPatientOnAuthorization(
        { variables: { authorizationId: authorization.id, patientProps: { firstName: firstName.trim(), lastName: lastName.trim(), dob } } }
      );
    }
    nextStep();
  }

  setFilteredPayers = (input) => {
    if (input.length) {
      this.setState({ filteredPayers: _.filter(genentechPayers, ({ label }) => label.toLowerCase().includes(input.toLowerCase())).slice(0, 20) });
    } else {
      this.setState({ filteredPayers: genentechPayers.slice(0, 20) });
    }
  }

  render() {
    const { filteredPayers } = this.state;
    const {
      authorization, lastName, firstName, dob, disabled, set, results, account,
    } = this.props;
    const selectedCompany = _.find(genentechPayers, { value: results[fields.INSURANCE_COMPANY.key] });
    const selectedSecondary = _.find(
      genentechPayers,
      { value: results[fields.SECONDARY_INSURANCE_COMPANY.key] },
    );
    const selectedRelationship = _.find(relationshipOptions, { value: results[fields.RELATIONSHIP_TO_PATIENT.key] });

    if (!account) { return (<div />); }
    const insuranceSection = [
      fields.PATIENT_MEMBER_ID,
      fields.INSURANCE_STATE,
      fields.PATIENT_GROUP_NUMBER,
      { ...fields.INSURANCE_PHONE, required: !!results[fields.GOODDAYS_ENROLLMENT.key] },
      ...(results[fields.PATIENT_NOT_POLICY_HOLDER.key] ? [fields.POLICY_HOLDER_FIRST_NAME, fields.POLICY_HOLDER_LAST_NAME] : []),
      fields.ADD_SECONDARY_PAYER,
    ];

    const secondaryInsuranceSection = [
      fields.SECONDARY_PATIENT_MEMBER_ID,
      fields.SECONDARY_PATIENT_GROUP_NUMBER,
      { ...fields.SECONDARY_INSURANCE_PHONE, required: !!results[fields.GOODDAYS_ENROLLMENT.key] },
    ];
    return (
      <FormContainer>
        <Patient
          authorizationId={authorization.id}
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          patientId={_.get(authorization, 'patient.id')}
          currentStep={authorization.formDetails.currentStep}
          showGenderField
        />
        <Section
          section={{
            items: [
              [{ ...fields.PATIENT_PHONE, required: true }, ...(results[fields.GENENTECH_ENROLLMENT.key] ? [fields.PATIENT_DO_NOT_CONTACT] : [])],
              { ...fields.PATIENT_ADDRESS_LINE, required: true },
              [{ ...fields.PATIENT_CITY, required: true }, { ...fields.PATIENT_STATE, required: true }, { ...fields.PATIENT_ZIP, required: true }],
            ],
            title: 'Patient Contact Information',
          }}
        />
        <Section
          section={{
            items: [fields.EMAIL_NOTIFICATION_TYPE],
            title: 'Send notification for major status updates?',
          }}
        />
        <Section
          section={{
            items: _.compact([
              (results[fields.GOODDAYS_ENROLLMENT.key] ? {
                title: 'Insurance Type (Retina Patient Assistance for Good Days only applies to Medicare and Military. If you would like'
                + ' to only submit to Access Solutions, please go back and create a new enrollment)',
                key: 'insuranceTypeSelector',
                type: CONFIG.CONSTANTS.FORM_TYPES.OPTION,
                options: [fields.PATIENT_IS_MEDICARE, fields.PATIENT_IS_MILITARY],
                required: true,
              } : fields.PATIENT_NOT_POLICY_HOLDER),
            ]),
            title: 'Patient Insurance Overview',
          }}
        />
        {!results[fields.PATIENT_IS_UNINSURED.key] && (
          <Section
            section={{
              items: insuranceSection,
              title: 'Patient Insurance Details',
            }}
          >
            {results[fields.PATIENT_NOT_POLICY_HOLDER.key] && (
              <SegmentSelector
                disabled={disabled}
                onChange={(selection) => { this.selectorSetter(selection, fields.RELATIONSHIP_TO_PATIENT.key); }}
                options={relationshipOptions}
                title={(
                  <TitleContainer>
                    Relationship to Patient
                    <RequiredTag />
                  </TitleContainer>
                )}
                value={selectedRelationship}
                sorted
              />
            )}
            <SingleLine>
              <SegmentSelector
                disabled={disabled}
                onChange={(company) => {
                  if (company) {
                    set({ [fields.INSURANCE_COMPANY.key]: company.value });
                  } else {
                    set({ [fields.INSURANCE_COMPANY.key]: null });
                  }
                }}
                onInputChange={(input) => { this.setFilteredPayers(input); }}
                options={filteredPayers}
                title={(
                  <TitleContainer>
                    Insurance Organization
                    <RequiredTag />
                  </TitleContainer>
                )}
                value={selectedCompany}
                createable={results[fields.GOODDAYS_ENROLLMENT.key] && !results[fields.GENENTECH_ENROLLMENT.key]}
              />
            </SingleLine>
          </Section>
        )}
        {!results[fields.PATIENT_IS_UNINSURED.key] && results[fields.ADD_SECONDARY_PAYER.key] && (
          <Section
            section={{
              items: secondaryInsuranceSection,
              title: 'Patient Secondary Insurance Details',
            }}
          >
            <SingleLine>
              <SegmentSelector
                disabled={disabled}
                onChange={(company) => {
                  if (company) {
                    set({ [fields.SECONDARY_INSURANCE_COMPANY.key]: company.value });
                  } else {
                    set({ [fields.SECONDARY_INSURANCE_COMPANY.key]: null });
                  }
                }}
                onInputChange={(input) => { this.setFilteredPayers(input); }}
                options={filteredPayers}
                title={(
                  <TitleContainer>
                    Insurance Organization
                    <RequiredTag />
                  </TitleContainer>
                )}
                value={selectedSecondary}
                createable={results[fields.GOODDAYS_ENROLLMENT.key] && !results[fields.GENENTECH_ENROLLMENT.key]}
              />
            </SingleLine>
          </Section>
        )}
        <FormSubmitButtons submit={this.submit} />
      </FormContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.form.results[fields.PATIENT_FIRST_NAME.key],
    lastName: state.form.results[fields.PATIENT_LAST_NAME.key],
    dob: state.form.results[fields.PATIENT_DOB.key],
    state: state.form.results[fields.INSURANCE_STATE.key],
    disabled: state.form.disabled,
    results: state.form.results,
  };
}

const mapDispatchToProps = dispatch => ({
  set(toSet) { dispatch(setFormFields(toSet)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  compose(withCreatePatientOnAuthorization, withInsuranceCompanies, withCurrentAccount)(withApollo(EnrollmentInitializeSection))
);
