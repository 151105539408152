import React, { PureComponent } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { BaseText } from 'Segment/StyledComponents';

import { WhiteOpaqueOverlay, SelectionOverOverlay } from './Overlay';
import BaseButton from '../../../../components/BaseButton';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectionOverlay = styled(SelectionOverOverlay)`
  width: 400px;
  padding: 20px 10px;
  margin-top: 100px;
`;

const SelectionScroll = styled.div`
  overflow-y: scroll;
  width: 100%;
  max-height: 200px;
`;

const Option = styled.div`
  cursor: pointer;
  padding: 10px;
  margin: 3px 0;
  border: 1px solid ${props => props.theme.lightGray};
  &:hover {
    background: ${props => props.theme.lightGray};
  }
`;

const SelectionHeader = styled.div`
  text-align: center;
  font-size: 20px;
  color: ${props => props.theme.purple};
  margin-bottom: 20px;
`;

class SelectOverlay extends PureComponent {
  state = { filterText: '' }

  render() {
    const { options, onSelect, clear } = this.props;
    const { filterText } = this.state;

    const filteredOptions = filterText ? _.filter(options, ({ label }) => ((label || '').toLowerCase().includes(filterText.toLowerCase()))) : options;
    return (
      <Container>
        <WhiteOpaqueOverlay />
        <SelectionOverlay>
          <SelectionHeader>Please Select an Option</SelectionHeader>
          { options.length > 10 && (
            <div style={{ fontWeight: 'bold', marginBottom: '10px' }}>
              Search
              <BaseText
                value={filterText || ''}
                onChange={(e) => { this.setState({ filterText: e.target.value }); }}
                placeholder="Search"
                style={{ marginLeft: 0 }}
              />
            </div>
          ) }
          <SelectionScroll>
            { _.isEmpty(options) && (<div>No options found for dropdown</div>) }
            {
              _.map(
                filteredOptions,
                ({ label, value, rawText }) => (<Option onClick={() => { onSelect(value, rawText); }}>{ label || 'CLEAR'}</Option>),
              )
            }
          </SelectionScroll>
          <BaseButton onClick={clear} style={{ width: '100%', marginTop: '10px' }}>Close with no change</BaseButton>
        </SelectionOverlay>
      </Container>
    );
  }
}

export default SelectOverlay;
