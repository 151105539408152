import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ZipCleave } from './BaseComponents';

export class SegmentZip extends PureComponent {
  state = { cleave: null };

  onInit = (zipCleave) => {
    this.setState({ cleave: zipCleave });
  }

  onBlur = () => {
    const { item, result, handleChange } = this.props;
    const { cleave } = this.state;
    const numbericZipValue = (result || '').replace(/[^0-9]/g, '');

    // 5 digit and 9 digit zips. Cleave adds "-" as a delimiter, so we need to replace it above
    if (numbericZipValue.length !== 5 && numbericZipValue.length !== 9) {
      handleChange({ [item.key]: '' });
      cleave.setRawValue('');
    }
  }

  render() {
    const { item, handleChange, result, disabled, highlightIfMissing } = this.props;

    return (
      <ZipCleave
        disabled={disabled}
        onChange={(event) => {
          let val = event.target.value;
          if (item.maxLength) {
            val = event.target.value.slice(0, item.maxLength);
          }
          handleChange({ [item.key]: val });
        }}
        number={result || ''}
        highlight={highlightIfMissing && !result}
        onBlur={this.onBlur}
        onInit={this.onInit}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { key } = props.item;
  return key ? { result: state.form.results[key] } : { result: null };
}

export default connect(mapStateToProps)(SegmentZip);
