import { CHECK_AUTHORIZATION_STATUS_ROUTE, PORTAL_AUTH_ROUTE } from '../shared-code';

const RESET_PASSWORD_BASE = '/reset-password';

const ROUTE_PATHS = {
  PORTAL_AUTHORIZATION: PORTAL_AUTH_ROUTE,
  AUTHORIZATION_LIST: '/',
  LOGIN: '/login',
  FORM_AUTHORIZATION: '/new-authorization',
  REFERRAL: '/referral',
  PATIENT_ENROLLMENT: '/new-patient-enrollment',
  PATIENT_ENROLLMENT_LIST: '/patient-enrollment-list',
  TAGGER: '/pdfTagger',
  RESET_PASSWORD_BASE,
  RESET_PASSWORD: `${RESET_PASSWORD_BASE}/:nonce`,
  ADMIN_ROUTE: '/admin',
  SUPPORT_ROUTE: '/support',
  SECURE_FILE: '/secure-file',
  CHECK_AUTHORIZATION_STATUS: CHECK_AUTHORIZATION_STATUS_ROUTE,
};

export default ROUTE_PATHS;
