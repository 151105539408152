import React, { PureComponent } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import moment from 'moment';
import Select from 'react-select';
import { withAlert } from 'react-alert';
import { BaseCleave, BaseMultiline, BaseText } from 'Segment/StyledComponents';
import { PhoneCleave } from 'Segment/BaseComponents';

import AlertModal from '../../components/AlertModal';
import BaseButton from '../../components/BaseButton';
import SectionHeader from './SectionHeader';
import ICDSelector from '../../components/ICDSelector';
import HcpcsCodeSelector from '../../components/HcpcsCodeSelector';
import CustomCheckbox from '../../components/CustomCheckbox';
import CustomRadio from '../../components/CustomRadio';

const TableContainer = styled.div`
  padding: 10px;

  ${BaseButton} {
    margin-left: auto;
    margin-top: 10px;
  }
`;

const Cell = styled.td`
  padding: 10px 15px;
`;

const FillImage = styled.img`
  max-width: 750px;
`;

const FILL_TYPES = CONFIG.CONSTANTS.PORTAL_FILL_TABLE_TYPES;
export class PortalFillTable extends PureComponent {
  constructor(props) {
    super(props);
    const { message } = this.props;
    const defaultState = { validationModalOpen: false };

    if (_.get(message, 'priorValue')) {
      this.state = { ...defaultState, results: message.priorValue };
    } else {
      this.state = {
        ...defaultState,
        results: _.reduce(
          _.flatten(props.message.data),
          (aggregatedResults, dataRow) => {
            if (dataRow.key) {
              return { ...aggregatedResults, [dataRow.key]: _.isNil(dataRow.defaultValue) ? '' : dataRow.defaultValue };
            }

            return aggregatedResults;
          },
          {},
        ),
      };
    }

    this.saveDraft = _.throttle(this._saveDraft, 7500, { leading: true, trailing: false });
  }

  componentWillUnmount() {
    this.saveDraft.cancel();
  }

  componentDidUpdate(prevProps, prevState) {
    const { results } = this.state;
    const prevResults = prevState.results;

    if (!_.isEqual(results, prevResults)) {
      this.saveDraft();
    }
  }

  updateValue = (key, newValue, callback = null) => {
    const { results } = this.state;
    this.setState({ results: { ...results, [key]: newValue } }, callback);
  }

  isHiddenCell = (cell) => {
    const { results } = this.state;

    if (_.isBoolean(cell.isVisible)) {
      if (!cell.isVisible) return true;
    } else if (cell.isVisible) {
      try {
        // eslint-disable-next-line
        if (!eval(cell.isVisible)(results)) { return true; }
      } catch (e) {
        console.error(`invalid isVisible method [${cell.isVisible}]. ${e}`);
      }
    }

    return false;
  }

  getCellContent = (cell, cellIndex) => {
    const { results } = this.state;
    let content;

    if (this.isHiddenCell(cell)) { return null; }

    switch (cell.type) {
      case FILL_TYPES.TEXT:
        content = (<div key={`portalText_${cell.text}_${cellIndex}`} style={{ maxWidth: 350 }}>{ cell.text }</div>);
        break;
      case FILL_TYPES.HEADER:
        content = (<b>{ cell.text }</b>);
        break;
      case FILL_TYPES.NUMBER:
        content = (
          <BaseCleave
            key={`portalFillNumber_${cell.key}`}
            options={{ numericOnly: true }}
            onChange={(e) => { this.updateValue(cell.key, _.replace(e.target.value || '', /,/g, '')); }}
            value={results[cell.key] || ''}
          />
        );
        break;
      case FILL_TYPES.FLOAT:
        content = (
          <BaseCleave
            key={`portalFillFloat_${cell.key}`}
            options={{ numeral: true }}
            onChange={(e) => { this.updateValue(cell.key, _.replace(e.target.value || '', /,/g, '')); }}
            value={results[cell.key] || ''}
          />
        );
        break;
      case FILL_TYPES.DATE:
        content = (
          <BaseCleave
            placeholder="12/23/1988"
            key={`portalFillDate_${cell.key}`}
            options={{ date: true, datePattern: ['m', 'd', 'Y'], delimiter: '/' }}
            onChange={(e) => { this.updateValue(cell.key, e.target.value); }}
            value={results[cell.key] || ''}
          />
        );
        break;
      case FILL_TYPES.DATE_SHORT:
        content = (
          <BaseCleave
            placeholder="12/1988"
            key={`portalFillDate_${cell.key}`}
            options={{ date: true, datePattern: ['m', 'Y'], delimiter: '/' }}
            onChange={(e) => { this.updateValue(cell.key, e.target.value); }}
            value={results[cell.key] || ''}
          />
        );
        break;
      case FILL_TYPES.PHONE:
        content = (
          <PhoneCleave
            key={`portalFillPhone_${cell.key}`}
            onChange={(e) => { this.updateValue(cell.key, e.target.value); }}
            number={results[cell.key] || ''}
          />
        );
        break;
      case FILL_TYPES.SELECT:
        content = (
          <Select
            style={{ minWidth: 225 }}
            key={`portalFillSelect_${cell.key}`}
            value={_.find(cell.options, { value: results[cell.key] }) || {}}
            // Selected can be undefined if we're clearing the select
            onChange={(selected) => { this.updateValue(cell.key, _.get(selected, 'value')); }}
            options={cell.options}
          />
        );
        break;
      case FILL_TYPES.MULTILINE:
        content = (
          <BaseMultiline
            style={{ minWidth: 600 }}
            key={`portalMultiline_${cell.key}`}
            onChange={(e) => {
              this.updateValue(cell.key, e.target.value);
            }}
            // Needs to stop the enter to submit functionality of the parent
            onKeyDown={(e) => { e.stopPropagation(); }}
            value={results[cell.key]}
          />
        );
        break;
      case FILL_TYPES.TEXT_INPUT:
        content = (
          <BaseText
            style={{ minWidth: 300 }}
            key={`portalTextInput_${cell.key}`}
            onChange={(e) => { this.updateValue(cell.key, e.target.value); }}
            value={results[cell.key]}
          />
        );
        break;
      case FILL_TYPES.CHECKBOX:
        content = (
          <CustomCheckbox
            checked={_.isNil(results[cell.key]) ? cell.defaultChecked : results[cell.key]}
            onChange={(e) => { this.updateValue(cell.key, e.target.checked); }}
            defaultChecked={_.isNil(results[cell.key]) ? cell.defaultChecked : results[cell.key]}
          />
        );
        break;
      case FILL_TYPES.RADIO:
        content = (
          <CustomRadio
            radioButtons={cell.options}
            onChange={(val) => { this.updateValue(cell.key, val); }}
          />
        );
        break;
      case FILL_TYPES.IMAGE:
        if (cell.base64Image) {
          content = (
            <FillImage src={`data:image/png;base64, ${cell.base64Image}`} />
          );
        } else {
          content = (<FillImage src={cell.imageUrl} />);
        }
        break;
      case FILL_TYPES.ICD:
        content = (
          <ICDSelector
            max={cell.max}
            // purposefully using ._saveDraft because it fires instantly, unlike .saveDraft, which avoids the error of saving
            // too late and clearing out what the user might have been typing
            onChange={(newIcds) => { this.updateValue(cell.key, newIcds, () => { this._saveDraft(); }); }}
            initializedICDs={results[cell.key]}
          />
        );
        break;
      case FILL_TYPES.HCPCS:
        content = (
          <HcpcsCodeSelector
            onChange={(newHcpcs) => {
              // Only trigger changes when actual j code is selected
              if (_.get(newHcpcs, 'value', '').length > 4) {
                this.updateValue(cell.key, _.get(newHcpcs, 'value'));
              } else {
                this.updateValue(cell.key, '');
              }
            }}
            value={{ label: results[cell.key], value: results[cell.key] }}
          />
        );
        break;
      default:
        break;
    }

    return (<Cell key={`PortalFillTableCell_${cell.key || cell.text}_${cellIndex}`}>{content}</Cell>);
  }

  isValidSubmission = () => {
    const { message } = this.props;
    const { results } = this.state;
    const requiredCells = _.flatten(_.map(message.data, row => _.filter(row, { required: true })));
    return _.every(requiredCells, cell => ((!_.isNil(results[cell.key]) && results[cell.key] !== '') || this.isHiddenCell(cell)));
  }

  getFillTableDisplay = (messageData) => {
    const { results } = this.state;

    // filtering out rows where any cells are hidden
    const visibleMessageData = _.filter(_.map(messageData, subArr => _.filter(subArr, cell => !this.isHiddenCell(cell))), arr => !_.isEmpty(arr));

    return _.map(visibleMessageData, row => _.map(row, (cell) => {
      switch (cell.type) {
        case FILL_TYPES.TEXT:
        case FILL_TYPES.HEADER:
          return cell.text;
        case FILL_TYPES.SELECT:
          return _.get(_.find(cell.options, { value: results[cell.key] }), 'label', '');
        case FILL_TYPES.ICD:
          return _.keys(results[cell.key]).join(', ');
        case FILL_TYPES.DATE:
        case FILL_TYPES.DATE_SHORT:
        case FILL_TYPES.NUMBER: // leading zeros should not be removed
        case FILL_TYPES.FLOAT:
        case FILL_TYPES.TEXT_INPUT:
        case FILL_TYPES.PHONE:
        case FILL_TYPES.MULTILINE:
        case FILL_TYPES.HCPCS:
          return results[cell.key];
        case FILL_TYPES.IMAGE:
          return 'Image not shown';
        case FILL_TYPES.CHECKBOX:
          return results[cell.key] ? 'Yes' : 'No';
        case FILL_TYPES.RADIO:
          return results[cell.key] ? _.find(cell.options, option => option.value === results[cell.key]).label : '';
        default:
          console.error('Unexpected type');
          return '';
      }
    }));
  }

  cleanFillTableDisplay = (headers, display) => {
    const cleanDisplay = _.reject(display, selectionArr => (selectionArr.length === 2 && _.some(selectionArr, _.isNil)));
    return [headers, cleanDisplay];
  }

  onKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.submit();
    }
  }

  submit = async (isDraft = false) => {
    const { onSubmit, message } = this.props;
    const { results } = this.state;

    const display = this.getFillTableDisplay(message.data);

    if (!this.getValidationError() || isDraft) {
      if (isDraft) {
        await onSubmit({ key: message.key, value: results, isDraft });
      } else {
        onSubmit({
          ...message,
          value: results,
          display: this.cleanFillTableDisplay(message.headers, display),
        });
      }
    } else {
      this.setState({ validationModalOpen: true });
    }
  }

  getValidationError = () => {
    const { message } = this.props;
    const { results } = this.state;

    if (!this.isValidSubmission()) {
      return 'Please ensure all required fields are filled in.';
    }

    const numbersWithRanges = _.flatten(
      _.map(
        message.data,
        row => _.filter(row, ({ type, range }) => ((type === 'number' || type === 'float') && _.isArray(range))),
      )
    );

    const invalidNumberValue = _.find(numbersWithRanges, ({ key, range }) => {
      const value = parseInt(results[key], 10);

      if (!_.isNaN(value)) {
        return value > range[1] || value < range[0];
      }

      return false;
    });

    if (invalidNumberValue) {
      return 'Please ensure all numbers are within their required ranges.';
    }

    const dateSubmissions = _.flatten(
      _.map(
        message.data,
        row => _.filter(row, ({ type }) => (type === 'date'))
      )
    );

    const invalidDateValue = _.find(dateSubmissions, ({ key }) => !moment(results[key], CONFIG.CONSTANTS.DATE_FORMAT, true).isValid());

    if (invalidDateValue) {
      return 'Please ensure all dates are formatted like MM/DD/YYYY';
    }

    return null;
  }

  _saveDraft = async () => {
    const { alert } = this.props;

    try {
      await this.submit(true);
      alert.success('Your progress has been saved!');
    } catch (e) {
      alert.error('There was an error saving your draft');
    }
  }

  render() {
    const { message } = this.props;
    const { validationModalOpen } = this.state;

    return (
      <TableContainer onKeyDown={this.onKeyPress}>
        <SectionHeader>{ message.title }</SectionHeader>
        <table>
          <thead>
            { message.headers && (
              <tr>
                { _.map(message.headers, header => (<Cell key={`${message.id}_header_${header}`}><b>{ header }</b></Cell>)) }
              </tr>
            )}
          </thead>
          <tbody>
            { _.map(message.data, row => (<tr>{_.map(row, this.getCellContent)}</tr>)) }
          </tbody>
        </table>
        <AlertModal
          header="Validation Errors"
          closeModal={() => { this.setState({ validationModalOpen: false }); }}
          open={validationModalOpen}
          content={this.getValidationError()}
        />
        <BaseButton onClick={() => { this.submit(); }}>Submit</BaseButton>
      </TableContainer>
    );
  }
}

export default withAlert(PortalFillTable);
