import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import AutomatedRow from './AutomatedRow';
import FollowUpRow from './FollowUpRow';
import SendingFailedRow from './SendingFailedRow';
import AutoAssociatedModal from '../AutoAssociatedModal';
import hasActionableFollowUp from '../../util/hasActionableFollowUp';

const TableContainer = styled.table`
  width: 100%;
  min-width: ${props => (props.minWidth || '900px')};
  max-width: 1450px;
  border-spacing: 0 10px;
`;

const TableHeader = styled.tr`
  font-weight: 500;
  color: ${props => props.theme.lightGray};
`;

const Row = styled.tr``;

const DataRow = styled(Row)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;

  &:hover {
    background-color: ${props => props.theme.lightestGray};
    cursor: pointer;
  }
`;

const Data = styled.td`
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
`;

const LineBreak = styled.div`
  border-top: 1px solid ${props => props.theme.lightGray};
  margin: 15px 0;
`;

class ListTable extends PureComponent {
  state = { autoAssociationReviewAuth: null };

  handleAutomatedRowClick = async row => this.setState({ autoAssociationReviewAuth: row.model });

  render() {
    const { dataRows, headers, keyOff, onClick, minWidth, ActionRow, showActionBanner, isSupport } = this.props;
    const { autoAssociationReviewAuth } = this.state;

    return (
      <>
        <TableContainer minWidth={minWidth}>
          <tbody>
            <TableHeader>
              { _.map(headers, (header, i) => (
                <Data key={`ListTableHeader${i}-${keyOff}`}><div style={{ margin: '20px 0' }}>{ header }</div></Data>
              )) }
            </TableHeader>
            { _.map(dataRows, (row, i) => {
              let content;
              if (row.requiresAssociationReview && showActionBanner) {
                content = (
                  <AutomatedRow i={i} keyOff={keyOff} row={row} handleAutomatedRowClick={this.handleAutomatedRowClick} />
                );
              } else if (hasActionableFollowUp(row.model) && showActionBanner) {
                content = (<FollowUpRow i={i} keyOff={keyOff} row={row} />);
              } else if (_.get(row.model, 'status') === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED && showActionBanner) {
                content = (<SendingFailedRow i={i} keyOff={keyOff} row={row} />);
              } else {
                const indexOrId = _.get(row, 'model.id', i);
                const supportedOrDefaultStyle = isSupport && row.model.isSupported ? { border: 'solid 3px #ffcc00', backgroundColor: '#f7e98e' } : {};

                content = (
                  <>
                    <DataRow
                      key={`ListTableRow${indexOrId}-${keyOff}`}
                      onClick={({ metaKey }) => { if (onClick) { onClick(row.model, metaKey); } }}
                      style={supportedOrDefaultStyle}
                    >
                      {
                        _.map(row.cells, (cell, ind) => (
                          <Data
                            key={`ListTableCell${indexOrId}-${ind}-${keyOff}`}
                            isFirst={ind === 0}
                          >
                            { cell }
                          </Data>
                        ))
                      }
                    </DataRow>
                    {
                      ActionRow && (
                        <tr key={`ListActionRow${_.get(row, 'model.id', i)}-${keyOff}`}>
                          <td colSpan={`${row.cells.length}`}>
                            <ActionRow key={`ActionRow${_.get(row, 'model.id', i)}-${keyOff}`} authorization={row.model} />
                          </td>
                        </tr>
                      )
                    }
                  </>
                );
              }
              return (
                <>
                  { content }
                  { ActionRow && (i !== dataRows.length - 1) && (
                    <tr>
                      <td colSpan={`${row.cells.length}`}>
                        <LineBreak />
                      </td>
                    </tr>
                  ) }
                </>
              );
            })}
          </tbody>
        </TableContainer>
        { autoAssociationReviewAuth && (
          <AutoAssociatedModal authorization={autoAssociationReviewAuth} closeModal={() => this.setState({ autoAssociationReviewAuth: null })} />
        )}
      </>
    );
  }
}

export default ListTable;
