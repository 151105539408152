import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { withApollo } from 'react-apollo';
import styled from 'styled-components';
import strings from 'Resources/strings';
import { BaseMultiline } from 'Segment/StyledComponents';

import { WhiteOpaqueOverlay, SelectionOverOverlay } from './Overlay';
import BaseButton from '../../../../components/BaseButton';
import { reportError } from '../../../../graphql/RequestHelp';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectionOverlay = styled(SelectionOverOverlay)`
  width: 600px;
  padding: 20px 10px;
  margin-top: 100px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectionHeader = styled.div`
  text-align: center;
  font-size: 20px;
  color: ${props => props.theme.purple};
  margin-bottom: 20px;
`;

const ActionButton = styled(BaseButton)`
  width: 200px;
  margin: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

class ErrorOverlay extends PureComponent {
  state = { renderReportError: false, renderConfirmation: false, userReportedDetails: '' };

  handleReportError = () => {
    const { alert, client, authorization } = this.props;
    const { userReportedDetails } = this.state;

    client.mutate({
      mutation: reportError,
      variables: { title: `${authorization.portalKey} Service Error`, message: `Authorization ID ${authorization.id}<br /><br />${userReportedDetails}` },
    }).then(() => this.setState({ renderConfirmation: true, renderReportError: false }))
      .catch(() => { alert.error(strings.MISSING_INFORMATION.ERROR); });
  };

  render() {
    const { restartIntegration } = this.props;
    const { renderReportError, renderConfirmation, userReportedDetails } = this.state;

    return (
      <Container>
        <WhiteOpaqueOverlay />
        <SelectionOverlay>
          { !renderConfirmation && !renderReportError && (
            <>
              <SelectionHeader>Service error</SelectionHeader>
              <TextContainer>
                Our services have encountered an interuption while running your portal authorization.
                Please either restart the authorization or report the error to the SamaCare team using the buttons below.
              </TextContainer>
              <ButtonContainer>
                <ActionButton onClick={restartIntegration}>Restart authorization</ActionButton>
                <ActionButton onClick={() => { this.setState({ renderReportError: true }); }}>Report error</ActionButton>
              </ButtonContainer>
            </>
          ) }
          { renderReportError && (
            <>
              <SelectionHeader>{strings.REPORT_ERROR.MODAL_HEADER}</SelectionHeader>
              <div style={{ display: 'flex', flexDirection: 'column', width: '500px', marginBottom: '20px' }}>
                <BaseMultiline onChange={(e) => { this.setState({ userReportedDetails: e.target.value }); }} value={userReportedDetails} />
              </div>
              <ButtonContainer>
                <ActionButton
                  key="reportError-close"
                  onClick={() => { this.setState({ renderReportError: false }); }}
                >
                  Back
                </ActionButton>
                <ActionButton key="reportError-submit" onClick={this.handleReportError}>{strings.REPORT_ERROR.SUBMIT}</ActionButton>
              </ButtonContainer>
            </>
          ) }
          { renderConfirmation && (
            <>
              <SelectionHeader>Error reported</SelectionHeader>
              <TextContainer>The error has been reported to the SamaCare team - you can close this window and return to SamaCare in a new tab</TextContainer>
            </>
          ) }
        </SelectionOverlay>
      </Container>
    );
  }
}

export default withAlert(withApollo(ErrorOverlay));
