import gql from 'graphql-tag';

export default gql`
  fragment insuranceCompanyInfo on InsuranceCompany {
    id
    name
    searchTags
    planParentCompanyId
  }
`;
