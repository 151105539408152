import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import ArrowBackIcon from 'react-icons/lib/md/arrow-back';
import ArrowForwardIcon from 'react-icons/lib/md/arrow-forward';

const ImageContainer = styled.div`
  margin: 0 10px;
  width: 100%;
`;
const arrowWidth = 30;

const sharedStyles = css`
  height: 40px;
  width: ${arrowWidth}px;
  color: ${props => props.theme.gray};
  cursor: pointer;
  margin: auto;
  &:hover {
    color: ${props => props.theme.purple};
    opacity: .7;
  }
`;


const PreviewImage = styled.img`
  width: ${props => props.width}px;
  border: 1px solid ${({ theme }) => theme.darkGray};
`;

const Forward = styled(ArrowForwardIcon)`
  ${sharedStyles}
`;

const Back = styled(ArrowBackIcon)`
  ${sharedStyles}
`;

const Carousel = ({ imageLinks = [], baseWidth = 700 }) => {
  const [viewIndex, setViewIndex] = useState(0);
  const showArrows = imageLinks.length > 1;
  const backIndex = (viewIndex - 1) < 0 ? (imageLinks.length - 1) : (viewIndex - 1); // wrap to length - 1 if at 0
  const forwardIndex = (viewIndex + 1) % imageLinks.length; // never exceed length - 1

  if (!imageLinks.length) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
      {showArrows && <Back onClick={() => { setViewIndex(backIndex); }} />}
      <ImageContainer width={baseWidth}>
        <PreviewImage width={baseWidth - (arrowWidth * 2)} src={imageLinks[viewIndex]} />
      </ImageContainer>
      {showArrows && <Forward onClick={() => { setViewIndex(forwardIndex); }} />}
    </div>
  );
};

export default Carousel;
