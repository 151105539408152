import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import * as FullStory from '@fullstory/browser';

import ROUTE_PATHS from './routes/ROUTE_PATHS';
import NewAuthorization from './routes/NewAuthorization';
import NewReferral from './routes/NewReferral';
import NewPatientEnrollment from './routes/NewPatientEnrollment';
import Authorizations from './routes/Authorizations';
import PatientEnrollmentsList from './routes/PatientEnrollmentsList';
import Admin from './routes/Admin';
import Support from './routes/Support';
import Login from './routes/Login';
import PortalAuthorization from './routes/PortalAuthorization';
import PDFTagger from './routes/PDFTagger';
import ResetPassword from './routes/ResetPassword';
import SecureFileViewer from './routes/SecureFileViewer';
import CheckAuthorizationStatus from './routes/CheckAuthorizationStatus';
import Header from './components/Header';

if (CONFIG.NODE_ENV === 'production') {
  Sentry.init({
    dsn: CONFIG.SENTRY_DSN,
    debug: CONFIG.NODE_ENV !== 'production',
    environment: CONFIG.NODE_ENV,
    release: VERSION,
    attachStacktrace: true,
  });

  FullStory.init({ orgId: CONFIG.CONSTANTS.FULL_STORY_ORG_ID });
}

const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  padding: 0;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const App = () => (
  <AppWrapper>
    <ContentWrapper>
      { !_.includes(['tagger'], CONFIG.NODE_ENV) && (<Header />) }
      { _.includes(['tagger'], CONFIG.NODE_ENV)
        ? (
          <Switch>
            <Route component={Login} path={ROUTE_PATHS.LOGIN} />
            <Route component={PDFTagger} path="/" />
          </Switch>
        ) : (
          <Switch>
            { CONFIG.NODE_ENV !== 'production' && (
              <Route component={PDFTagger} path={ROUTE_PATHS.TAGGER} />
            ) }
            <Route component={Login} path={ROUTE_PATHS.LOGIN} />
            <Route component={ResetPassword} path={ROUTE_PATHS.RESET_PASSWORD} />
            <Route component={NewAuthorization} path={ROUTE_PATHS.FORM_AUTHORIZATION} />
            <Route component={Admin} path={ROUTE_PATHS.ADMIN_ROUTE} />
            <Route component={NewPatientEnrollment} path={ROUTE_PATHS.PATIENT_ENROLLMENT} />
            <Route component={PatientEnrollmentsList} path={ROUTE_PATHS.PATIENT_ENROLLMENT_LIST} />
            <Route component={Support} path={ROUTE_PATHS.SUPPORT_ROUTE} />
            <Route component={PortalAuthorization} path={ROUTE_PATHS.PORTAL_AUTHORIZATION} />
            <Route component={NewReferral} path={ROUTE_PATHS.REFERRAL} />
            <Route component={SecureFileViewer} path={ROUTE_PATHS.SECURE_FILE} />
            <Route component={CheckAuthorizationStatus} path={ROUTE_PATHS.CHECK_AUTHORIZATION_STATUS} />
            { CONFIG.NODE_ENV !== 'production' && (<Route component={PDFTagger} path={ROUTE_PATHS.TAGGER} />) }
            {
              // Must be last - anything after this route won't show up
            }
            <Route component={Authorizations} path={ROUTE_PATHS.AUTHORIZATION_LIST} />
          </Switch>
        )
      }
    </ContentWrapper>
  </AppWrapper>
);

export default App;
