import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { compose, withApollo } from 'react-apollo';
import _ from 'lodash';
import { withAlert } from 'react-alert';
import Select from 'react-select';
import { PhoneCleave } from 'Segment/BaseComponents';
import { BaseMultiline } from 'Segment/StyledComponents';
import colors from 'Resources/colors';

import RequiredTag from '../../../components/RequiredTag';
import {
  withCreateFormNumber,
  withPossibleFormNumbers,
  searchFormNumbersQuery,
  getForbiddenFormNumberQuery,
  withDeleteFormNumber,
} from '../../../graphql/FormNumber';
import BaseButton from '../../../components/BaseButton';
import Favorite from '../../../components/Favorite';
import { withSetFavorite, withDeleteFavorite } from '../../../graphql/Favorite';

const Container = styled.div`
  padding: 20px 0;
`;

const TitleContainer = styled.div`
  font-weight: 500;
  padding: 10px 0 5px 0;
  font-size: 16px;
  border-bottom: 2px solid ${props => props.theme.darkGray};
`;

const ScrollContainer = styled.div`
  max-height: 250px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

const FormDetails = styled.div`
  flex: 1;
  font-weight: ${({ isHighlighted }) => (isHighlighted ? 500 : 300)}
`;

const FormDescription = styled.div`
  font-size: 14px;
  color: ${props => props.theme.gray};
`;

const SelectFormButton = styled(BaseButton)`
  padding: 5px;
  font-size: 14px;
  margin-left: 20px;
`;

const SelectorTitle = styled.div`
  font-weight: 500;
  margin: 9px 4px 7px 4px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  ${BaseButton} {
    margin-left: 10px;
  }
`;

const FormSubmitButton = styled(BaseButton)`
  margin-left: 10px;
`;

const EmptyState = styled.div`
  font-size: 16px;
  margin: 20px;
  text-align: center;
`;

export class SelectPhoneList extends PureComponent {
  state = { showCreateNumber: false, number: '', description: '', id: '', selectedNumber: '' };

  formatNumber = (number) => {
    const onlyNumeric = number.replace(/\D/g, '');
    return _.compact([
      onlyNumeric.slice(0, 3),
      onlyNumeric.slice(3, 6),
      onlyNumeric.slice(6, 10),
    ]).join('-');
  }

  setNewNumber = async () => {
    const { createFormNumber, onSelect, insuranceCompanyId, alert, client } = this.props;
    const { number, description, id, selectedNumber } = this.state;

    if (!number) {
      alert.error('Please create a phone number selection');
    } else if (id && number === selectedNumber) {
      await onSelect(id);
    } else {
      try {
        const blacklisted = await client.query({
          query: getForbiddenFormNumberQuery,
          variables: { number },
        });
        const blacklistedResp = blacklisted.data.getForbiddenFormNumber;
        if (blacklistedResp) {
          this.setState({
            description: blacklistedResp.description,
            forbiddenReason: blacklistedResp.forbiddenReason,
            selectedNumber: blacklistedResp.number,
            id: blacklistedResp.id,
          });
        } else {
          const created = await createFormNumber({ variables: { number, description, insuranceCompanyId } });
          await onSelect(created.data.createFormNumber.id);
        }
      } catch (e) {
        alert.error('Error creating number, are you sure that is a valid phone number?');
      }
    }
  }

  queryNumbers = async (partialNumber) => {
    const { client } = this.props;
    const onlyNumeric = partialNumber.replace(/\D/g, '');
    const formatted = _.compact([onlyNumeric.slice(0, 3), onlyNumeric.slice(3, 6), onlyNumeric.slice(6)]).join('-');
    const queriedNumbersRes = await client.query({
      query: searchFormNumbersQuery,
      variables: { partialNumber: formatted },
    });
    return _.get(queriedNumbersRes, 'data.searchFormNumbers');
  }

  generateNumbers = (list, title, selectText = 'Select Number', isFavorite = false) => {
    const { onSelect, deleteFormNumber, deleteFavorite, setFavorite } = this.props;
    if (list.length) {
      return (
        <div key={`generatedNumberList_${title}`}>
          <TitleContainer>
            { title }
          </TitleContainer>
          <ScrollContainer>
            {_.map(list, number => (
              <FormContainer key={`numberSelectContainer-numberId-${number.id}`}>
                <FormDetails>
                  <Favorite
                    isFavorite={isFavorite}
                    setFavorite={setFavorite}
                    deleteFavorite={deleteFavorite}
                    numberId={_.parseInt(number.id)}
                  />
                  {number.number}
                  <FormDescription>{number.description}</FormDescription>
                </FormDetails>
                { number.canBeDeletedByEndUser && (
                  <SelectFormButton color="#ff4242" onClick={async () => { await deleteFormNumber({ variables: { id: number.id } }); }}>
                    Delete
                  </SelectFormButton>
                ) }
                <SelectFormButton onClick={() => { onSelect(number.id); }}>{ selectText }</SelectFormButton>
              </FormContainer>
            ))}
          </ScrollContainer>
        </div>
      );
    }
    return false;
  };

  render() {
    const { numbers, onBack, parentStyleOverrides = {} } = this.props;
    const { number, showCreateNumber, description, forbiddenReason } = this.state;

    let content;
    if (!showCreateNumber) {
      content = (
        <Container>
          { (_.get(numbers, 'hasNumbers'))
            ? (
              <div>
                { this.generateNumbers(numbers.favoriteNumbers, 'Favorited Numbers', 'Select Number', true) }
                { this.generateNumbers(numbers.suggestedNumbers, 'Suggested Numbers') }
                { this.generateNumbers(numbers.remainingNumbers, 'Numbers') }
              </div>
            )
            : (
              <EmptyState>
                No fax numbers were found matching the specified insurance, plan, and form information.  Please add a number using the button below.
              </EmptyState>
            )
          }
        </Container>
      );
    } else {
      content = (
        <Container>
          <SelectorTitle>Fax Number</SelectorTitle>
          { !number && (
            <Select.AsyncCreatable
              labelKey="number"
              valueKey="number"
              loadOptions={async (input) => {
                const options = await this.queryNumbers(input);
                return { options };
              }}
              onChange={(selected) => {
                if (selected) {
                  const formatted = this.formatNumber(selected.number);
                  this.setState({ number: formatted, description: selected.description, id: selected.id, selectedNumber: formatted });
                }
              }}
            />
          )}
          { number && (
            <div>
              <PhoneCleave
                number={number}
                onChange={(e) => { this.setState({ number: e.target.value }); }}
              />
              {forbiddenReason
                ? (
                  <div>
                    <SelectorTitle style={{ color: 'red' }}>
                      WARNING - This number has been blacklisted by SamaCare
                    </SelectorTitle>
                    <div>
                      <b>Description:</b>
                      {` ${description}`}
                    </div>
                    <div>
                      <b>Reason for Blacklisting:</b>
                      {` ${forbiddenReason}`}
                    </div>
                    <SelectorTitle>
                      Are you sure you want to proceed?
                    </SelectorTitle>
                  </div>
                )
                : (
                  <>
                    <SelectorTitle>
                      Description
                      <RequiredTag />
                    </SelectorTitle>
                    <BaseMultiline onChange={(e) => { this.setState({ description: e.target.value }); }} value={description} />
                  </>
                )}
            </div>
          )}
        </Container>
      );
    }

    return (
      <div style={parentStyleOverrides}>
        { content }
        <ButtonContainer>
          { !showCreateNumber && (<BaseButton color={colors.gray} onClick={onBack}>Back</BaseButton>) }
          <BaseButton
            color={showCreateNumber ? '#757575' : '#c02d4c'}
            onClick={() => { this.setState({ showCreateNumber: !showCreateNumber, forbiddenReason: '', number: '', id: '' }); }}
          >
            { showCreateNumber ? 'Back' : 'Use Unlisted Number' }
          </BaseButton>
          { showCreateNumber && (<FormSubmitButton disabled={!number} onClick={this.setNewNumber}>Use this number</FormSubmitButton>) }
        </ButtonContainer>
      </div>
    );
  }
}

export default compose(
  withPossibleFormNumbers,
  withCreateFormNumber,
  withDeleteFormNumber,
  withSetFavorite,
  withDeleteFavorite,
)(withAlert(withApollo(SelectPhoneList)));
