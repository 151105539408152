import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const uploadDocumentsToOncoEMR = gql`
  mutation uploadDocumentsToOncoEMR($patientMRN: String!, $authorizationId: Int!, $documents: [JSON]!, $emrCreds: JSON) {
    uploadDocumentsToOncoEMR(patientMRN: $patientMRN, authorizationId: $authorizationId, documents: $documents, emrCreds: $emrCreds)
  }
`;

export const withUploadDocumentsToOncoEMR = graphql(
  uploadDocumentsToOncoEMR,
  { name: 'uploadDocumentsToOncoEMR' },
);
