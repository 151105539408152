import React, { PureComponent } from 'react';
import styled from 'styled-components';

const scrollbarWidth = 17;
const length = 98;
const ScrollBarContainer = styled.div`
  width: ${props => props.width ? `${length}%` : `${scrollbarWidth}px`};
  height: ${props => props.height ? `${length}%` : `${scrollbarWidth}px`};
  background-color: ${props => props.theme.lightPurple};
  position: absolute;
  border-radius: ${scrollbarWidth}px;
  top: ${props => props.width ? `calc(100% - ${scrollbarWidth / 2}px)` : 0};
  left: ${props => props.height ? `calc(100% - ${scrollbarWidth / 2}px)` : 0};
  margin: ${props => props.width ? '0 auto' : 'auto 0'};
  z-index: ${props => props.theme.zTopPlus3};
`;

const Bar = styled.div`
  border-radius: ${scrollbarWidth}px;
  width: ${props => props.width || `${scrollbarWidth}px`};
  height: ${props => props.height || `${scrollbarWidth}px`};
  cursor: pointer;
  background-color: ${props => props.theme.purple};
  position: absolute;
  left: ${props => props.width ? props.position : 0};
  top: ${props => props.height ? props.position : 0};
`;

class ScrollBar extends PureComponent {
  ref;

  onContainerClick = (e) => {
    const { width, height, onClickScroll } = this.props;
    const { bottom, top, left, right } = this.ref.getBoundingClientRect();
    if (width) {
      const clickX = e.clientX;
      if (clickX < left) {
        onClickScroll('left');
      } else if (clickX > right) {
        onClickScroll('right');
      }
    } else if (height) {
      const clickY = e.clientY;
      if (clickY < top) {
        onClickScroll('bottom');
      } else if (clickY > bottom) {
        onClickScroll('top');
      }
    }
  }

  render() {
    const { width, height, position, onScrollStart, isZoomed, stopScroll } = this.props;

    // When we're zoomed in, we acutally emulate a zoom using Emulation.setPageScaleFactor
    // when scaled in this way, chrome does not seem to update the scroll or page bounds, and the scrollbar is inaccurate
    // By scaling the scrollbar when zoomed we're attempting to give the user a feeling that they can keep scrolling even if they've reached the computed 'end'
    const scaleBackAmt = isZoomed ? 0.05 : 0;
    const widthPercent = (width ? `${width * 100 * (1 - scaleBackAmt)}%` : 0);
    const heightPercent = (height ? `${height * 100 * (1 - scaleBackAmt)}%` : 0);
    const positionPercent = `${Math.max(scaleBackAmt / 2, position) * 100}%`;
    return (
      <ScrollBarContainer
        width={widthPercent}
        height={heightPercent}
        onClick={this.onContainerClick}
      >
        <Bar
          ref={ref => this.ref = ref}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onScrollStart(e.clientX, e.clientY, !!height);
          }}
          onMouseUp={(e) => {
            e.stopPropagation();
            e.preventDefault();
            stopScroll();
          }}
          width={widthPercent}
          height={heightPercent}
          position={positionPercent}
        />
      </ScrollBarContainer>
    );
  }
}

export default ScrollBar;
