import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import { withDefaultProps } from './utils';
import insuranceCompanyInfo from './fragments/insuranceCompanyInfo';

const INSURANCE_COMPANIES_QUERY = 'insuranceCompanies';
export const withInsuranceCompanies = graphql(
  gql`
    query ${INSURANCE_COMPANIES_QUERY} {
      insuranceCompaniesFindAll {
        ...insuranceCompanyInfo
      }
    }
    ${insuranceCompanyInfo}
  `,
  {
    props: withDefaultProps(({ data }) => ({ insuranceCompanies: data.insuranceCompaniesFindAll })),
  }
);

export const withUpsertInsuranceCompany = graphql(
  gql`
    mutation upsertInsuranceCompany($id: Int, $name: String!, $searchTags: [String!], $parentCompanyName: String, $parentId: Int) {
      upsertInsuranceCompany(id: $id, name: $name, searchTags: $searchTags, parentCompanyName: $parentCompanyName, parentId: $parentId) {
        ...insuranceCompanyInfo
      }
    }
    ${insuranceCompanyInfo}
  `,
  {
    name: 'upsertInsuranceCompany',
    options: () => ({ refetchQueries: () => [INSURANCE_COMPANIES_QUERY], awaitRefetchQueries: true }),
  }
);
