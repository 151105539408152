import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import colors from 'Resources/colors';
import TouchIcon from 'react-icons/lib/md/touch-app';
import MdWarning from 'react-icons/lib/md/warning';
import ReactTooltip from 'react-tooltip';

import strings from 'Resources/strings';
import hasActionableFollowUp from '../../util/hasActionableFollowUp';
import UpdateAuthorizationStatusButton from '../UpdateAuthorizationStatusButton';
import CancelAuthorizationSendButton from '../CancelAuthorizationSendButton';
import RequestResendButton from '../RequestResendButton';
import EditAndResubmitButton from '../EditAndResubmitButton';
import FollowUpButton from '../FollowUpButton';
import BaseButton from '../BaseButton';
import PayerResponseRange from './PayerResponseRange';

const Status = styled.div`
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 8px;
  border-radius: 5px;
  background: ${props => props.statusColor};
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const ReviewStatus = styled.div`
  margin-top: 3px;
  font-size: 12px;
  color: ${props => props.theme.warningRed};
  font-weight: bold;
  width: 225px;
  white-space: normal;
`;

const StatusButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 2px 10px;
`;

const StyledErrorIcon = styled(TouchIcon)`
  margin-right: 4px;
`;

const StyledWarningIcon = styled(MdWarning)`
  margin-right: 4px;
`;

const StatusActionButton = styled(BaseButton)`
  border: 0;
  background: transparent;
  border-style: dotted;
  border-radius: 3px;
  font-size: 14px;
  padding: 3px;
  color: ${props => props.theme.darkPurple};

  &:hover {
    color: white;
    background: ${({ theme }) => theme.purple};
  }
`;

const StatusActionText = styled.div`
  padding: 5px;
  font-size: 14px;
  color: ${props => props.theme.darkGray};
`;


const statusColorMap = {
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION]: colors.presubmissionPink,
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENT]: '#ffa747',
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENDING]: '#ffa747',
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED]: '#ff4242',
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PENDING]: '#00f2d4',
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.ACTION_REQUIRED]: '#0099ff',
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.MODIFIED_APPROVAL]: '#4fcbe0',
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.APPROVED]: '#2BBF96',
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.DENIED]: '#ff4242',
  [CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.DUPLICATE]: colors.orange,
};

const editableStatuses = [...CONFIG.CONSTANTS.AUTHORIZATION_STATUSES_ALLOWED_ON_UPDATES, CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENT];
export class RequestStatus extends PureComponent {
  getStatusTextAndColor = () => {
    const { authorization } = this.props;
    const statusColor = statusColorMap[authorization.status] || colors.dullGray;

    return (
      <StatusButtonWrapper>
        <Status statusColor={statusColor} />
        <div>
          { _.upperFirst(authorization.status.replace(/_/g, ' ')) }
        </div>
      </StatusButtonWrapper>
    );
  }

  getStatusActionButton = () => {
    const { authorization } = this.props;

    if (authorization.followUp) {
      // Denial next steps should superceed all other actions
      if (authorization.followUp.type === CONFIG.CONSTANTS.FOLLOW_UP_TYPES.EDIT_AND_RESUBMIT) {
        return (
          <EditAndResubmitButton
            key={`editAndResubmitButton-id-${authorization.id}`}
            authorizationId={authorization.id}
          >
            <StatusActionButton>
              <StyledErrorIcon />
              Edit and Resubmit
            </StatusActionButton>
          </EditAndResubmitButton>
        );
      }
      if (authorization.followUp.type === CONFIG.CONSTANTS.FOLLOW_UP_TYPES.RESUBMIT) {
        return (
          <RequestResendButton
            key={`authRequestResendButton-id-${authorization.id}`}
            authorizationId={authorization.id}
          >
            <StatusActionButton>
              <StyledErrorIcon />
              Resend
            </StatusActionButton>
          </RequestResendButton>
        );
      }
      const tooltipId = `followUp_${authorization.id}`;
      const tooltip = (<ReactTooltip id={tooltipId}>{strings.FOLLOW_UPS[authorization.followUp.type]}</ReactTooltip>);
      return hasActionableFollowUp(authorization) ? (
        <FollowUpButton
          key={`respondToPayerButton-id-${authorization.id}`}
          authorization={authorization}
          authorizationId={authorization.id}
          followUp={authorization.followUp}
          correspondence={authorization.latestCorrespondence}
        >
          <StatusActionButton data-for={tooltipId} data-tip="">
            { tooltip }
            <StyledErrorIcon />
            Follow Up Required
          </StatusActionButton>
        </FollowUpButton>
      ) : (
        <StatusActionText data-for={tooltipId} data-tip="">
          { tooltip }
          <StyledWarningIcon
            data-for={`followUp_${authorization.id}`}
            data-tip=""
          />
          Follow Up Required
        </StatusActionText>
      );
    }

    switch (authorization.status) {
      case CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED:
        return (
          <RequestResendButton
            key={`authRequestResendButton-id-${authorization.id}`}
            authorizationId={authorization.id}
          >
            <StatusActionButton>
              <StyledErrorIcon />
              Resend
            </StatusActionButton>
          </RequestResendButton>
        );
      case CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENDING:
        return (
          <CancelAuthorizationSendButton
            key={`authCancelAuthorizationSendButtonButton-id-${authorization.id}`}
            authorization={authorization}
          >
            <StatusActionButton>{strings.AUTHORIZATIONS.CANCEL_AUTH_SEND_BUTTON}</StatusActionButton>
          </CancelAuthorizationSendButton>
        );
      default:
        break;
    }
    return null;
  }

  isEditable = () => {
    const { authorization, disabled } = this.props;

    if (disabled) {
      return false;
    }

    return CONFIG.NODE_ENV !== 'production' || _.includes(editableStatuses, authorization.status);
  }

  render() {
    const { authorization } = this.props;
    const showReviewStatus = _.includes(CONFIG.CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES, authorization.status) && authorization.displayReviewWarning;
    const showPayerResponseTimes = !_.includes(
      [
        ...CONFIG.CONSTANTS.TERMINAL_AUTHORIZATION_STATUSES,
        CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.PRESUBMISSION,
        CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.SENDING_FAILED,
        CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT,
      ],
      authorization.status
    ) && authorization.insuranceCompany;

    return (
      <StatusWrapper key={`authStatusIndicator-id-${authorization.id}`}>
        <div style={{ display: 'flex' }}>
          {
            this.isEditable()
              ? (
                <UpdateAuthorizationStatusButton authorization={authorization}>
                  { this.getStatusTextAndColor() }
                </UpdateAuthorizationStatusButton>
              )
              : (
                <StatusWrapper>
                  { this.getStatusTextAndColor() }
                </StatusWrapper>
              )
          }
          { this.getStatusActionButton() }
        </div>
        {showReviewStatus && <ReviewStatus>Please review payer response to ensure accuracy of this authorization status</ReviewStatus>}
        {showPayerResponseTimes && (
          <PayerResponseRange
            max={authorization.insuranceCompany.responseRangeMax}
            min={authorization.insuranceCompany.responseRangeMin}
          />
        )}
      </StatusWrapper>
    );
  }
}

export default RequestStatus;
