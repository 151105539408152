import React, { PureComponent } from 'react';
import { withAlert } from 'react-alert';
import { graphql, compose } from 'react-apollo';
import _ from 'lodash';
import styled from 'styled-components';
import { IntegratedDownloadedFilesSuggestionModalNoRedux } from 'AuthorizationSharedComponents/IntegratedDownloadedFilesSuggestionModal';
import TiDelete from 'react-icons/lib/ti/delete';

import Upload from '../../../../components/Upload';
import BaseButton from '../../../../components/BaseButton';
import { WhiteOpaqueOverlay, SelectionOverOverlay } from './Overlay';
import { withAuthorizationQuery } from '../../../../graphql/Authorization';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectionOverlay = styled(SelectionOverOverlay)`
  width: 600px;
  padding: 20px 10px;
  margin-top: 100px;
  overflow: scroll;
  max-height: 500px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SelectionHeader = styled.div`
  text-align: center;
  font-size: 20px;
  color: ${props => props.theme.purple};
  margin-bottom: 20px;
`;

const File = styled.div`
  border: 1px dashed ${props => props.theme.purple};
  padding: 10px;
  width: 400px;
  margin: 10px 0;
  text-align: center;
  color: ${props => props.theme.purple};
`;

const DeleteIcon = styled(TiDelete)`
  width: 22px;
  height: 22px;
  float: right;
  cursor: pointer;
`;

const MaxFilesText = styled.div`
  text-align: center;
  margin: 5px;
`;

class UploadOverlay extends PureComponent {
  state = { uploads: [], loading: false }

  onDrop = () => {
    this.setState({ loading: true });
  }

  remove(file) {
    const { uploads } = this.state;
    this.setState({ uploads: _.reject(uploads, { awsKey: file.awsKey }) });
  }

  isAtMaxFiles = () => {
    const { maxFiles } = this.props;
    const { uploads } = this.state;

    return _.isNumber(maxFiles) && uploads.length >= maxFiles;
  }

  set = (file) => {
    const { maxFiles } = this.props;
    const { uploads } = this.state;

    if (_.isNumber(maxFiles) && uploads.length >= maxFiles) {
      this.setState({ uploads: [...uploads.slice(1), file] });
    } else {
      this.setState({ uploads: [...uploads, file] });
    }

    this.setState({ loading: false });
  }

  onDropSuccess = (file, res) => {
    const { data } = res;

    const formattedFile = { title: file.name, id: data.id, awsKey: res.data.fields.key };
    this.set(formattedFile);
  }

  onDropError = () => {
    const { alert } = this.props;
    alert.error('There was an error uploading your file');
    this.setState({ loading: false });
  }

  render() {
    const { authorization, maxMB, sendUploads, maxFiles } = this.props;
    const { loading, uploads } = this.state;

    return (
      <Container>
        <WhiteOpaqueOverlay />
        <SelectionOverlay>
          <SelectionHeader>{ `Max ${maxMB} MB | ${maxFiles} file(s) at a time` }</SelectionHeader>
          { !_.isEmpty(_.get(authorization, 'integratedEMRFilesDownloaded', [])) && !this.isAtMaxFiles() && (
            <IntegratedDownloadedFilesSuggestionModalNoRedux
              authorizationId={authorization.id}
              maxFileSizeMB={maxMB}
              set={this.set}
              attachments={uploads}
            />
          )}
          {
            !this.isAtMaxFiles() && (
              <Upload
                disabled={loading || this.isAtMaxFiles()}
                onDrop={this.onDrop}
                onDropSuccess={this.onDropSuccess}
                onDropError={this.onDropError}
                customMaxSize={maxMB * 1000000}
                multiple={!_.isNumber(maxFiles) || maxFiles > 1}
              />
            )
          }
          { uploads && !_.isEmpty(uploads) && !this.isAtMaxFiles() && `Uploaded attachments (${maxFiles} at a time)` }
          { uploads
            && this.isAtMaxFiles()
            && (
              <MaxFilesText>
                Max files reached. Please ensure the files below are successfully uploaded to the website prior to selecting more
              </MaxFilesText>
            )
          }
          { _.map(uploads, file => (
            <File key={`uploaded-file-id-${file.id}`}>
              { file.title }
              { !loading && (<DeleteIcon onClick={() => { this.remove(file); }} />) }
            </File>
          )) }
          <BaseButton
            disabled={loading}
            style={{ width: 400 }}
            onClick={() => { sendUploads(uploads); }}
          >
            { _.isEmpty(uploads) ? 'Close' : 'Upload Selected' }
          </BaseButton>
        </SelectionOverlay>
      </Container>
    );
  }
}

export const withAuthorization = graphql(
  withAuthorizationQuery,
  {
    options: props => ({ variables: { id: props.authorizationId }, fetchPolicy: 'network-only' }),
    props: ({ data }) => ({
      authorization: data.authorizationById,
    }),
  }
);

export default compose(withAuthorization)(withAlert(UploadOverlay));
