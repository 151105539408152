import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import _ from 'lodash';
import moment from 'moment';

import Section from '../Section';
import PatientSearch from '../PatientSearch';
import { withSetPatient } from '../../../graphql/Authorization';
import SelectPatientModal from '../SelectPatientModal';

const fields = CONFIG.DEFAULT_FIELDS;

export class PatientInitial extends PureComponent {
  state = { showPatientSelectionModal: false, matchingPatients: [], patientResults: [] };

  handleBlur = () => {
    const { firstName, lastName, dob } = this.props;
    const { patientResults } = this.state;
    const matchingPatients = _.filter(patientResults, patient => (
      moment(dob).isSame(patient.dob, 'day') && firstName && lastName
    ));
    this.setState({ matchingPatients, showPatientSelectionModal: !_.isEmpty(matchingPatients) });
  };

  render() {
    const { patientId, firstName, lastName, setPatient, authorizationId, showGenderField } = this.props;
    const { showPatientSelectionModal, matchingPatients } = this.state;

    return (
      <Section
        childrenAfterSection
        section={{
          items: [[
            { ...fields.PATIENT_FIRST_NAME, onBlur: this.handleBlur },
            { ...fields.PATIENT_LAST_NAME, onBlur: this.handleBlur },
            { ...fields.PATIENT_DOB, onBlur: this.handleBlur },
            ...(showGenderField ? [{
              key: 'genderSelector',
              title: 'Patient Gender',
              required: true,
              type: CONFIG.CONSTANTS.FORM_TYPES.OPTION,
              options: [
                fields.PATIENT_GENDER_MALE,
                fields.PATIENT_GENDER_FEMALE,
              ],
            }] : []),
          ]],
          title: 'Patient',
        }}
      >
        { !patientId && (
          <PatientSearch
            firstName={firstName}
            lastName={lastName}
            setPatient={(patient) => { setPatient({ variables: { patientId: patient.id, authorizationId } }); }}
            onResult={patients => this.setState({ patientResults: patients })}
          />
        ) }
        { !_.isEmpty(matchingPatients) && (
          <SelectPatientModal
            open={showPatientSelectionModal}
            patients={matchingPatients}
            setPatient={(patient) => {
              setPatient({ variables: { patientId: patient.id, authorizationId } });
              this.setState({ showPatientSelectionModal: false });
            }}
            onCloseModal={() => this.setState({ matchingPatients: [] })}
          />
        )}
      </Section>
    );
  }
}

export default compose(withSetPatient)(PatientInitial);
