import _ from 'lodash';
import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import PatientSearchList from './PatientSearchList';

const PatientSearch = ({ setPatient, firstName = '', lastName = '', onResult = () => {} }) => (
  <Query
    query={gql`
        query PatientSearchQuery($firstName: String, $lastName: String) {
          patientsByName(
            firstName: $firstName,
            lastName: $lastName,
          ) {
            id
            dob
            firstName
            lastName
            gender
          }
        }
      `}
    skip={(firstName.length + lastName.length) < 1}
    variables={{ firstName, lastName }}
    onCompleted={(data) => { if (_.get(data, 'patientsByName.length') > 0) { onResult(data.patientsByName); } }}
  >
    {({ data }) => {
      if (_.get(data, 'patientsByName.length') > 0) {
        return (
          <PatientSearchList
            patients={data.patientsByName}
            setPatient={setPatient}
          />
        );
      }
      return (<div />);
    }}
  </Query>
);

export default PatientSearch;
