import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import { withRunStatusChecker } from '../../graphql/StatusChecker';
import Modal from '../../components/Modal';
import LoadingButton from '../../components/LoadingButton';
import UserInput from './UserInput';

const UserInputBody = styled.div`
  margin: 10px 0;
`;

const TextBody = styled.div`
  margin: 10px 0;
  text-align: center;
`;

const STATUSES = CONFIG.CONSTANTS.STATUS_CHECKER_STATUSES;
export class CheckAuthorizationStatus extends PureComponent {
  state = { isNew: true, currentMessage: null, responses: {}, loading: false }

  sendStatusCheckerMessage = async () => {
    const { runStatusChecker, location, alert } = this.props;
    const { responses, isNew, currentMessage } = this.state;

    const params = new URLSearchParams(location.search);

    this.setState({ loading: true });

    const required = _.filter(_.get(currentMessage, 'userInputs', []), { required: true });
    if (_.some(required, ({ key }) => !responses[key])) {
      alert.error('Missing required response');
      this.setState({ loading: false });
    } else {
      try {
        const response = await runStatusChecker({
          variables: {
            accountId: params.get('accountId'),
            authId: params.get('authId'),
            key: params.get('key'),
            responses,
            isNewRunner: isNew,
          },
        });
        this.setState({ currentMessage: _.get(response, 'data.runStatusChecker') });
      } catch (e) {
        alert.error('There was an error checking your authorization status, please reach out to SamaCare via the chat button at the bottom right');
      } finally {
        this.setState({ loading: false, isNew: false });
      }
    }
  }

  setResponse = (key, val) => {
    const { responses } = this.state;
    this.setState({ responses: { ...responses, [key]: val } });
  }

  getModalContent = () => {
    const { currentMessage, responses, loading } = this.state;

    if (_.get(currentMessage, 'status') === STATUSES.SUCCESS) {
      return (
        <div>
          <TextBody>{ currentMessage.text }</TextBody>
        </div>
      );
    }

    if (_.get(currentMessage, 'status') === STATUSES.ERROR) {
      return (
        <div>
          <TextBody>There is an error</TextBody>
          <div>{ currentMessage.text }</div>
        </div>
      );
    }

    if (_.get(currentMessage, 'status') === STATUSES.PROCESSING) {
      return (
        <div>
          <TextBody>{ currentMessage.text }</TextBody>
          <UserInputBody>
            {
              _.map(currentMessage.userInputs, input => (
                <UserInput input={input} onChange={this.setResponse} value={responses[input.key]} />
              ))
            }
          </UserInputBody>
          <LoadingButton style={{ width: '100%' }} loading={loading} onClick={this.sendStatusCheckerMessage}>
            Submit
          </LoadingButton>
        </div>
      );
    }

    return (
      <div>
        <TextBody>
          SamaCare can automatically check on the status and documents of your outstanding
 authorizations here. When this completes, you will receive an email with a report of the authorizations
 we have successfully updated.
        </TextBody>
        <TextBody>
          Press below to initiate the checks on your authorizations for this integration.
        </TextBody>
        <LoadingButton style={{ width: '100%' }} loading={loading} onClick={this.sendStatusCheckerMessage}>
          Begin Checking on Outstanding Authorizations
        </LoadingButton>
      </div>
    );
  }

  render() {
    return (
      <Modal
        open
        header="Check on Authorizations"
      >
        {this.getModalContent()}
      </Modal>
    );
  }
}

export default compose(withRunStatusChecker)(withAlert(CheckAuthorizationStatus));
