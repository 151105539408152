import React from 'react';
import styled from 'styled-components';

import LoadingSpinner from '../../../../components/LoadingSpinner';
import { WhiteOpaqueOverlay, SelectionOverOverlay } from './Overlay';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SelectionOverlay = styled(SelectionOverOverlay)`
  width: 400px;
  padding: 20px 10px;
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  ${LoadingSpinner} {
    border-color: ${props => props.theme.purple};
    border-top-color: white;
    height: 50px;
    width: 50px;
  }
`;

const PageLoadOverlay = ({ message }) => (
  <Container>
    <WhiteOpaqueOverlay />
    <SelectionOverlay>
      <LoadingSpinner />
      <div style={{ textAlign: 'center', marginTop: '10px' }}>{ message }</div>
    </SelectionOverlay>
  </Container>
);

export default PageLoadOverlay;
