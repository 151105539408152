import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';

import { AuthorizationDetails, TitleContainer } from './ContainerComponents';

const OverviewRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 5px 0;
  align-items: center;
`;

const OverviewDetails = styled.div`
  flex: 1;
`;

const OverviewTitle = styled.div`
  width: 35%;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ScrollContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 0 10px;
`;

class AuthorizationActions extends PureComponent {
  state = {};

  render() {
    const { authorization } = this.props;

    const rows = [
      { title: 'Patient', description: `${authorization.patient.lastName}, ${authorization.patient.firstName}` },
      { title: 'Details', description: `${authorization.config[CONFIG.DEFAULT_FIELDS.REQUEST_DESCRIPTION.key]}` },
      { title: 'Member ID', description: `${authorization.config[CONFIG.DEFAULT_FIELDS.PATIENT_MEMBER_ID.key]}` },
      { title: 'DoS', description: `${authorization.config[CONFIG.DEFAULT_FIELDS.DATE_OF_SERVICE.key]}` },
    ];

    return (
      <AuthorizationDetails>
        <TitleContainer>Authorization Details</TitleContainer>
        <ScrollContainer>
          {
            _.map(rows, ({ title, description }, i) => (
              <OverviewRow key={`live_details_${i}`}>
                <OverviewTitle>{ title }</OverviewTitle>
                <OverviewDetails>{ description }</OverviewDetails>
              </OverviewRow>
            ))
          }
        </ScrollContainer>
      </AuthorizationDetails>
    );
  }
}

export default AuthorizationActions;
