import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';

import FormList from './FormList';
import SelectPhoneList from './SelectPhoneList';
import FormConfirmationView from './FormConfirmationView';
import { withUpdateAuthorization } from '../../../graphql/Authorization';
import Modal from '../../../components/Modal';
import { ModalBodyLarge } from '../../../components/ModalStyledComponents';

const TwoPane = styled.div`
  display: flex;
  flex-direction: row;
  width: 1100px;
`;

class SelectFormModal extends PureComponent {
  state = { authorizationFormId: null };

  setFormAndNumber = async (formNumberId) => {
    const { authorization, updateAuthorization, onSubmit, alert } = this.props;
    const { authorizationFormId } = this.state;
    try {
      await updateAuthorization({ variables: { id: authorization.id, patch: { formId: authorizationFormId, FormNumberId: formNumberId } } });
      onSubmit();
      this.setState({ authorizationFormId: null });
    } catch {
      alert.error('There was an error setting the form or submission number');
    }
  }

  render() {
    const { open, close, authorization, insuranceCompanyId, insuranceType, insuranceName, state, jCode } = this.props;
    const { authorizationFormId } = this.state;

    return (
      <Modal
        header={authorizationFormId ? 'Select fax number & confirm form' : 'Select payer form'}
        onClick={() => { this.setState({ authorizationFormId: null }); close(); }}
        open={open}
      >
        { !authorizationFormId && (
          <ModalBodyLarge>
            <FormList
              jCode={jCode}
              insuranceType={insuranceType}
              authorizationId={authorization.id}
              insuranceName={insuranceName}
              insuranceCompanyId={insuranceCompanyId}
              onSelect={(selectedFormId) => { this.setState({ authorizationFormId: selectedFormId }); }}
              state={state}
            />
          </ModalBodyLarge>
        )}
        { authorizationFormId && (
          <TwoPane>
            <FormConfirmationView formId={authorizationFormId} />
            <SelectPhoneList
              parentStyleOverrides={{ margin: '0 auto', width: '400px' }}
              authorizationFormId={authorizationFormId}
              insuranceCompanyId={insuranceCompanyId}
              state={state}
              onSelect={this.setFormAndNumber}
              onBack={() => { this.setState({ authorizationFormId: null }); }}
            />
          </TwoPane>
        ) }
      </Modal>
    );
  }
}

export default compose(withUpdateAuthorization)(withAlert(SelectFormModal));
