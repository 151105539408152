import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import Select from 'react-select';

import { withResolveCareMetxFollowUp } from '../../../graphql/SupportedAuthorizationFollowUp';
import BaseButton from '../../../components/BaseButton';
import Modal from '../../../components/Modal';
import { ModalFooter } from '../../../components/ModalStyledComponents';
import { withDetailedForms } from '../../../graphql/AuthorizationForm';
import { withInsuranceCompanies } from '../../../graphql/InsuranceCompany';
import filterInsuranceCompanyOptions from '../../../util/filterInsuranceCompanyOptions';

const StyledSelect = styled(Select)`
  width: 100%;
`;

const ModalBody = styled.div`
  width: 750px;
  display: flex;
  flex-direction: column;
  margin: 20px 0;
`;

const CustomModalFooter = styled(ModalFooter)`
  justify-content: center;
  ${BaseButton} {
    width: 100%;
    height: 40px;
    margin: 0 50px;
  }
`;

const Button = styled(BaseButton)`
  color: ${props => props.isSelected ? 'white' : props.theme.purple};
  background-color: ${props => props.isSelected ? props.theme.purple : 'white'};
  text-align: center;
  margin: 3px 10px;
  flex: 1;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  margin-top: 10px;
`;

const RequestedFormDetailRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const { SITE_DOMAIN, SITE_PROTOCOL, DEFAULT_FIELDS } = CONFIG;
const fieldsAndDescriptions = [
  { key: DEFAULT_FIELDS.NEW_INSURANCE_COMPANY.key, description: 'NEW PAYER REQUESTED: ' },
  { key: DEFAULT_FIELDS.NEW_FORM_WEB_LINK.key, description: 'User provided link for form: ' },
  { key: DEFAULT_FIELDS.NEW_FORM_USER_COMMENTS.key, description: 'User added comments: ' },
  { key: DEFAULT_FIELDS.NEW_FORM_AWS_KEY.key, description: `${SITE_PROTOCOL}${SITE_DOMAIN}/#/secure-file?awsKey=` },
];
const homogenizeString = (string = '') => (string.replace(/[^A-Za-z]/g, '').toLowerCase());
class SupportedFollowUpsDashboard extends PureComponent {
  state = { status: null, selectedFormId: null, selectedInsuranceCompanyId: null }

  resolve = async () => {
    const { resolveCareMetxFollowUp, alert, followUpId, onClose } = this.props;
    const { status, selectedFormId, selectedInsuranceCompanyId } = this.state;

    if (!status) {
      alert.error('Expected a resolution status');
      return;
    }

    if (status === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.CAREMETX_FORM_READY && !selectedFormId) {
      alert.error('Expected a selected form');
      return;
    }

    try {
      await resolveCareMetxFollowUp({
        variables: { id: followUpId, status, formId: selectedFormId, InsuranceCompanyId: selectedInsuranceCompanyId },
      });
      this.setState({ status: null, selectedFormId: null }, onClose);
      alert.info('Success');
    } catch {
      alert.error('There was an error');
    }
  }

  render() {
    const { forms, onClose, authorization, insuranceCompanies } = this.props;
    const { status, selectedFormId, selectedInsuranceCompanyId } = this.state;

    const augmentedForms = _.map(forms, form => ({ ...form, displayValue: `${form.title} - ${form.description}` }));
    const selectedForm = _.find(augmentedForms, { id: selectedFormId });

    return (
      <Modal
        header="Select Resolution Status"
        onClick={() => { this.setState({ status: '' }, onClose); }}
        open
      >
        <ModalBody>
          <b style={{ margin: '5px 0' }}>
            This is a request from a CareMetx user, saying their form is not on our site
          </b>
          <div>
            1. If no payer: add new payer + form to Sama and press &apos;Form Found&apos; to associate them
          </div>
          <div>
            2. If there exists a payer
              a. Form is in our system: press &apos;Form Found&apos; and associate
              b. If form not in system: find form, upload it to our system, press &apos;Form Found&apos; and associate
          </div>
          <div>
            3. If form cannot be found, either due to bad directions or if the form does not exist, please select: &apos;Form not found&apos;
          </div>
          <b style={{ margin: '5px 0' }}>User provided Details</b>
          {
            _.map(fieldsAndDescriptions, ({ key, description }) => {
              if (authorization.config[key]) {
                return (
                  <RequestedFormDetailRow>
                    <div>{ description }</div>
                    <div style={{ marginLeft: '5px' }}>{ authorization.config[key] }</div>
                  </RequestedFormDetailRow>
                );
              }

              return null;
            })
          }
          <ButtonContainer>
            <Button
              onClick={() => { this.setState({ status: CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.CAREMETX_FORM_READY }); }}
              isSelected={status === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.CAREMETX_FORM_READY}
            >
              Form Found!
            </Button>
            <Button
              onClick={() => { this.setState({ status: CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.CAREMETX_FORM_NOT_FOUND }); }}
              isSelected={status === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.CAREMETX_FORM_NOT_FOUND}
            >
              Form not Found
            </Button>
          </ButtonContainer>
          { status === CONFIG.CONSTANTS.AUTHORIZATION_STATUSES.CAREMETX_FORM_READY && (
            <>
              { authorization.config[DEFAULT_FIELDS.NEW_INSURANCE_COMPANY.key] && (
                <>
                  <div>Choose new payer</div>
                  <StyledSelect
                    labelKey="name"
                    onChange={(company) => {
                      if (company) {
                        this.setState({ selectedInsuranceCompanyId: company.id });
                      } else {
                        this.setState({ selectedInsuranceCompanyId: null });
                      }
                    }}
                    options={_.sortBy(insuranceCompanies, 'name')}
                    value={_.find(insuranceCompanies, { id: selectedInsuranceCompanyId })}
                    valueKey="id"
                    sorted
                    filterOption={filterInsuranceCompanyOptions}
                  />
                </>
              ) }
              <div>Choose new form</div>
              <StyledSelect
                onChange={(selected) => {
                  if (selected) {
                    this.setState({ selectedFormId: selected.id });
                  } else {
                    this.setState({ selectedFormId: null });
                  }
                }}
                labelKey="displayValue"
                valueKey="id"
                options={augmentedForms}
                filterOptions={(options, string) => (
                  _.filter(options, option => (homogenizeString(option.displayValue).includes(homogenizeString(string))))
                )}
                value={selectedForm}
              />
            </>
          ) }
        </ModalBody>
        <CustomModalFooter>
          <BaseButton disabled={!status} onClick={this.resolve}>Resolve</BaseButton>
        </CustomModalFooter>
      </Modal>
    );
  }
}

export default compose(withResolveCareMetxFollowUp, withDetailedForms, withInsuranceCompanies)(withAlert(SupportedFollowUpsDashboard));
