import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import Select from 'react-select';
import Creatable from 'react-select/lib/Creatable';
import { BaseText } from 'Segment/StyledComponents';

import CreateInsuranceCompanyModal from './CreateInsuranceCompanyModal';
import FiltersContainer from './FiltersContainer';
import DeleteFax from './DeleteFax';
import BaseButton from '../../../../components/BaseButton';
import { withInsuranceCompanies } from '../../../../graphql/InsuranceCompany';
import { withUpdateFormNumber } from '../../../../graphql/FormNumber';
import { withUpsertFaxFilter } from '../../../../graphql/FaxFilter';
import Modal from '../../../../components/Modal';
import filterInsuranceCompanyOptions from '../../../../util/filterInsuranceCompanyOptions';
import { ModalBody, ModalFooter, ModalContent } from '../../../../components/ModalStyledComponents';
import { Pill } from '../../SharedStyles';

const InputLabel = styled.div`
  width: 100%;
  font-weight: 500;
  margin: 10px 0;
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  background-color: ${props => props.selected && props.theme.lightPurple};

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.lightPurple};
  }
`;

const EllipsisText = styled.div`
  color: ${props => props.faded ? props.theme.darkGray : ''};
  width: 225px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const StyledButton = styled(BaseButton)`
  width: 100%;
  margin-bottom: 8px;
`;

const StyledInput = styled(BaseText)`
  width: 100%;
  margin: 0 16px 8px 0;
`;

const CustomModalContent = styled(ModalContent)`
  padding: 10px 0 20px;
  font-size: 16px;
`;

const CustomModalFooter = styled(ModalFooter)`
  font-size: 16px;
  justify-content: center;
  ${BaseButton} {
    width: 300px;
    margin-left: 10px;
    padding: 5px 10px;
  }
`;


const EditContainer = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  margin-bottom: 50px;
  flex-direction: row;
`;

const EditColumn = styled.div`
  width: 33%;
  margin: 0 8px;

  ${BaseButton} {
    margin-left: auto;
  }
`;

const Scrollable = styled.div`
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

export class FaxEditContainer extends PureComponent {
  state = {
    updateForms: [],
    updateInsuranceCompanies: [],
    updateNumber: '',
    updateDescription: '',
    updateForbiddenReason: '',
    unassociateInstitution: false,
    showAssociationWarning: false,
  };

  componentDidMount() {
    const { selectedNumber } = this.props;
    this.updateSelectedNumber(selectedNumber);
  }

  componentDidUpdate(prevProps) {
    const { selectedNumber } = this.props;
    if (selectedNumber.id !== prevProps.selectedNumber.id) {
      this.updateSelectedNumber(selectedNumber);
    }
  }

  updateSelectedNumber = ({ associatedForms, associatedCompanies, number, description, forbiddenReason }) => {
    this.setState({
      updateForms: associatedForms,
      updateInsuranceCompanies: associatedCompanies,
      updateNumber: number,
      updateDescription: description,
      updateForbiddenReason: forbiddenReason,
      unassociateInstitution: false,
    });
  }

  checkForUnsavedChanges = () => {
    const { selectedNumber } = this.props;
    const { updateForms, updateNumber, updateDescription, updateForbiddenReason, unassociateInstitution, updateInsuranceCompanies } = this.state;
    return (
      updateDescription !== selectedNumber.description
      || updateNumber !== selectedNumber.number
      || unassociateInstitution
      || updateForbiddenReason !== selectedNumber.forbiddenReason
      || !_.isEqual(_.sortBy(_.map(selectedNumber.associatedForms, 'id')), _.sortBy(_.map(updateForms, 'id')))
      || !_.isEqual(_.sortBy(_.map(selectedNumber.associatedCompanies, 'id')), _.sortBy(_.map(updateInsuranceCompanies, 'id')))
    );
  }

  saveChanges = async () => {
    const { updateFormNumber, selectedNumber, alert } = this.props;
    const { updateForms, updateNumber, updateDescription, updateForbiddenReason, unassociateInstitution, updateInsuranceCompanies } = this.state;
    if (!updateDescription || !updateNumber) {
      alert.error('Please enter both a description and a correct number');
    } else {
      try {
        const patch = {
          description: updateDescription,
          number: updateNumber,
          forbiddenReason: updateForbiddenReason,
          ...(unassociateInstitution ? { InstitutionId: null } : {}),
          formIds: _.map(updateForms, 'id'),
          companyIds: _.map(updateInsuranceCompanies, 'id'),
        };
        await updateFormNumber({ variables: { id: selectedNumber.id, patch } });
        alert.info('Successfully updated form number');
      } catch (e) {
        alert.error(`Failed to update ${e}`);
      }
    }
  }

  render() {
    const { insuranceCompanies, upsertFaxFilter, selectedNumber, clearSelectedNumber, forms } = this.props;
    const {
      updateForms,
      updateNumber,
      updateDescription,
      updateForbiddenReason,
      unassociateInstitution,
      showAssociationWarning,
      showDeleteModal,
      updateInsuranceCompanies,
      newPayer,
    } = this.state;
    const unsavedChanges = this.checkForUnsavedChanges();

    return (
      <div>
        <EditContainer>
          <EditColumn>
            <InputLabel>Change Number or Description</InputLabel>
            <StyledInput
              onChange={(e) => { this.setState({ updateDescription: e.target.value }); }}
              value={updateDescription}
              placeholder="Update Description"
            />
            <StyledInput
              onChange={(e) => { this.setState({ updateNumber: e.target.value }); }}
              value={updateNumber}
              placeholder="Update Fax"
            />
            {updateForbiddenReason && (
              <StyledInput
                onChange={(e) => { this.setState({ updateForbiddenReason: e.target.value }); }}
                value={updateForbiddenReason}
              />
            )}
            <div style={{ display: 'flex' }}>
              <StyledButton
                style={{ marginRight: '8px' }}
                disabled={!selectedNumber.InstitutionId || updateForbiddenReason || unassociateInstitution}
                onClick={() => { this.setState({ unassociateInstitution: true }); }}
              >
                Remove Institution
              </StyledButton>
              <StyledButton
                style={{ width: '55%' }}
                onClick={() => { this.setState({ updateForbiddenReason: updateForbiddenReason ? '' : 'Forbidden number reason' }); }}
              >
                {updateForbiddenReason ? 'Unblacklist' : 'Blacklist'}
              </StyledButton>
            </div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'space-between', margin: '0 8px' }}>
              {unsavedChanges && (
                <>
                  <InputLabel style={{ color: 'red' }}>
                    <div style={{ width: '100px', display: 'flex', flexDirection: 'column' }}>
                      {selectedNumber.InstitutionId && (unassociateInstitution || updateForbiddenReason) && <Pill warning>Unassociate</Pill>}
                      {updateForbiddenReason && <Pill warning>Blacklist</Pill>}
                    </div>
                  </InputLabel>
                  <BaseButton
                    style={{ width: '100px', marginRight: '8px' }}
                    disabled={!unsavedChanges}
                    onClick={() => {
                      if (
                        !_.isEqual(_.sortBy(_.map(selectedNumber.associatedCompanies, 'id')), _.sortBy(_.map(updateInsuranceCompanies, 'id')))
                        && updateInsuranceCompanies.length
                      ) {
                        this.setState({ showAssociationWarning: true });
                      } else {
                        this.saveChanges();
                      }
                    }}
                  >
                    Save
                  </BaseButton>
                </>
              )}
              <BaseButton color="red" style={{ width: '125px' }} onClick={() => { this.setState({ showDeleteModal: true }); }}>Delete</BaseButton>
            </div>
          </EditColumn>
          <EditColumn>
            <div style={{ height: '200px' }}>
              {updateForbiddenReason ? <InputLabel>Blacklisted numbers are not form associated</InputLabel> : (
                <div>
                  <InputLabel>Associate forms for number</InputLabel>
                  <Select
                    onChange={(form) => { this.setState(prevState => ({ updateForms: _.uniqBy(_.compact([...prevState.updateForms, form]), 'id') })); }}
                    labelKey="title"
                    valueKey="id"
                    placeholder="Change associated forms"
                    options={forms}
                  />
                  <Scrollable style={{ height: '200px' }}>
                    {_.map(updateForms, ({ title, description, id }) => (
                      <ItemContainer style={{ display: 'flex', margin: '0 8px' }} key={`associatedform-id-${id}`}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <EllipsisText>{title}</EllipsisText>
                          <EllipsisText faded>{description}</EllipsisText>
                        </div>
                        <BaseButton
                          onClick={() => { this.setState(prevState => ({ updateForms: _.reject(prevState.updateForms, { id }) })); }}
                        >
                          X
                        </BaseButton>
                      </ItemContainer>
                    ))}
                  </Scrollable>
                </div>
              )}
            </div>
          </EditColumn>
          <EditColumn>
            <div style={{ height: '200px' }}>
              <div>
                <InputLabel>Associate payers for number</InputLabel>
                <Creatable
                  sorted
                  onChange={(payer) => {
                    this.setState(prevState => ({ updateInsuranceCompanies: _.uniqBy(_.compact([...prevState.updateInsuranceCompanies, payer]), 'id') }));
                  }}
                  labelKey="name"
                  valueKey="id"
                  placeholder="Change associated insurance companies"
                  options={_.sortBy(insuranceCompanies, 'name')}
                  filterOption={filterInsuranceCompanyOptions}
                  onNewOptionClick={({ name }) => { this.setState({ newPayer: name }); }}
                />
                <Scrollable style={{ height: '200px' }}>
                  {_.map(updateInsuranceCompanies, ({ name, id }) => (
                    <ItemContainer style={{ display: 'flex', margin: '0 8px' }} key={`associatedcompany-id-${id}`}>
                      <EllipsisText>{name}</EllipsisText>
                      <BaseButton
                        onClick={() => { this.setState(prevState => ({ updateInsuranceCompanies: _.reject(prevState.updateInsuranceCompanies, { id }) })); }}
                      >
                        X
                      </BaseButton>
                    </ItemContainer>
                  ))}
                </Scrollable>
              </div>
            </div>
          </EditColumn>
        </EditContainer>
        <FiltersContainer
          filter={selectedNumber.faxFilter}
          associationId={selectedNumber.id}
          upsertFunction={upsertFaxFilter}
          associationKey="formNumberId"
        />
        {showDeleteModal && (
          <DeleteFax
            id={selectedNumber.id}
            description={selectedNumber.description}
            number={selectedNumber.description}
            clear={() => { clearSelectedNumber(); }}
          />
        )}
        {showAssociationWarning && (
          <Modal header="Create Insurance Association" open={showAssociationWarning} onClick={() => { this.setState({ showAssociationWarning: false }); }}>
            <ModalBody>
              <CustomModalContent>
                Associating an Insurance Company to a fax number will show this number for ALL authorizations using this payer
                Please only associate a number to a payer if it is NOT form specific and should be visible for all users selecting this Insurance Company
              </CustomModalContent>
            </ModalBody>
            <CustomModalFooter>
              <BaseButton onClick={() => { this.setState({ showAssociationWarning: false }, this.saveChanges); }}>Associate this Number to Payer</BaseButton>
            </CustomModalFooter>
          </Modal>
        )}
        {newPayer && <CreateInsuranceCompanyModal onClose={() => { this.setState({ newPayer: null }); }} name={newPayer} />}
      </div>
    );
  }
}

export default compose(withInsuranceCompanies, withUpsertFaxFilter, withUpdateFormNumber)(withAlert(FaxEditContainer));
