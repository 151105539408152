import styled from 'styled-components';

export const Pill = styled.span`
  border-radius: 3px;
  background: white;
  padding: 10px;
  font-size: 12px;
  margin-right: 10px;
  border: 1px solid ${({ disabled, warning, theme }) => (warning ? theme.red : (disabled ? theme.darkGray : theme.purple))};
  color: ${({ disabled, warning, theme }) => (warning ? theme.red : (disabled ? theme.darkGray : theme.purple))};
  &:hover {
    cursor: ${props => props.onClick ? 'pointer' : ''};
    color: ${props => props.onClick ? 'white' : ''};
    background-color: ${({ disabled, warning, theme, onClick }) => onClick ? (warning ? theme.red : (disabled ? theme.darkGray : theme.purple)) : ''};
  }
`;

export const SupportScroll = styled.div`
  max-height: 500px;
  overflow: scroll;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }
`;

export const CreateNew = styled.div`
  display: flex;
  width: 600px;
  height: 300px;
  margin: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.lightPurple};
  &:hover {
    border: 1px solid ${props => props.theme.purple};
    cursor: pointer;
    background-color: white;
  }
`;

export const SupportTwoPane = styled.div`
  width: 1350px;
  display: flex;
  flex-direction: row;
  margin: 10px 0;
`;

export const SupportListContainer = styled.div`
  width: 750px;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;


export const SupportTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 16px;
`;

export const SupportTableHeader = styled.th`
  position: sticky;
  top: 0;
  text-align: left;
  font-weight: 500;
  color: ${props => props.theme.lightGray};
  background-color: white;
`;

export const SupportTableRow = styled.tr`
  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  height: 50px;
  background-color: ${props => props.selected && props.theme.lightPurple};
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.lightPurple};
  }
`;

export const SupportCell = styled.td`
  font-weight: 300;
`;

export const SupportEditContainer = styled.div`
  display: flex;
  width: 600px;
  height: 100%;
  margin: 50px;
  flex-direction: column;
`;
