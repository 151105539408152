import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import BaseButton from './BaseButton';
import LoadingButton from './LoadingButton';

const dropzoneClass = 'dropzone';
const MAX_FILE_SIZE_B = 50000000;

export const DROPZONE_WIDTH = 400;
const DropzoneContainer = styled.div`
  .${dropzoneClass} {
    padding: 30px 20px;
    cursor: ${props => (props.disabled ? '' : 'pointer')};
    width: ${DROPZONE_WIDTH}px;
    border: 1px dashed ${props => props.theme.purple};
    border-radius: 3px;
    text-align: center;
    display: flex;
    flex-direction: column;

    ${BaseButton} {
      margin: 0 auto 10px auto;
      width: 200px;
    }
  }
  margin-bottom: 10px;
`;

const HelperText = styled.div`
  font-size: 12px;
  color: ${props => props.theme.darkGray};
`;

export class SamaDropzone extends PureComponent {
  render() {
    const {
      multiple = false,
      maxSize,
      dropzoneStyle = {},
      accept = [CONFIG.CONSTANTS.CONTENT_TYPES.PDF],
      disabled,
      loading,
      onDrop,
      onDropRejected,
      className,
      helperText = '(files must be in PDF format)',
      buttonText = 'Browse PDFs to upload',
    } = this.props;

    return (
      <DropzoneContainer disabled={disabled || loading}>
        <Dropzone
          style={dropzoneStyle}
          accept={accept}
          className={className}
          disabled={disabled || loading}
          maxSize={maxSize || MAX_FILE_SIZE_B}
          multiple={multiple}
          onDrop={onDrop}
          onDropRejected={onDropRejected}
        >
          <LoadingButton disabled={disabled || loading} loading={loading}>{buttonText}</LoadingButton>
          <span>or drag and drop them here</span>
          <HelperText>{helperText}</HelperText>
        </Dropzone>
      </DropzoneContainer>
    );
  }
}

export default SamaDropzone;
