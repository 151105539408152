import _ from 'lodash';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withAlert } from 'react-alert';

import Modal from '../../components/Modal';
import { ModalContent } from '../../components/ModalStyledComponents';
import { getUserStatusCheckerFormattedURL } from '../../shared-code';

const { SITE_DOMAIN, SITE_PROTOCOL, NODE_ENV } = CONFIG;
const checkStatusRoot = `${SITE_PROTOCOL}${SITE_DOMAIN}${NODE_ENV === 'dev' ? ':8080' : ''}`;

const CheckerLink = styled.a`
  width: 100%;
  padding: 10px;
  display: block;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.lightGray};
  }
`;

export class CheckStatusButton extends PureComponent {
  state = { open: false }

  render() {
    const { children, accountId, supportedPortals } = this.props;
    const { open } = this.state;

    return (
      <div>
        { open && (
          <Modal
            onClick={() => { this.setState({ open: false }); }}
            open={open}
            header="Check Statuses and Download Documents for Integrated Portals"
          >
            <ModalContent style={{ margin: '10px 0' }}>
              Click below to check the statuses of all non-finalized authorizations for a SamaCare integrated portal
            </ModalContent>
            <div>
              {
                _.map(supportedPortals, ({ key, title }) => (
                  <CheckerLink
                    key={`statusCheckerRow_${key}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={getUserStatusCheckerFormattedURL(checkStatusRoot, accountId, key)}
                  >
                    { title }
                  </CheckerLink>
                ))
              }
            </div>
          </Modal>
        ) }
        <div onClick={() => { this.setState({ open: true }); }}>
          {children}
        </div>
      </div>
    );
  }
}

export default withAlert(CheckStatusButton);
