import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { withAlert } from 'react-alert';
import { compose } from 'react-apollo';
import FormSubmitButtons from 'AuthorizationSharedComponents/FormSubmitButtons';

import { withSubmitPatientEnrollment } from '../../graphql/PatientEnrollment';
import UpdateCredentialsModal from '../../components/UpdateCredentialsModal';
import AlertModal from '../../components/AlertModal';
import BaseButton from '../../components/BaseButton';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const ENROLLMENT_TEXT = 'Your enrollment has been successfully submitted. SamaCare will automatically check'
+ ' for updates to your responses and upload them here. You may return to the main list now';
export class EnrollmentSubmit extends PureComponent {
  state = { loading: false, success: false, erroredPortal: null, userInput: [] };

  submit = async () => {
    const { submitPatientEnrollment, authorization, alert, saveChanges } = this.props;

    this.setState({ loading: true, erroredPortal: null });

    try {
      await saveChanges();
      const response = await submitPatientEnrollment({ variables: { id: authorization.id } });
      const { errorCredentialConfig, erroredPortal } = response.data.submitPatientEnrollment;

      if (errorCredentialConfig) {
        this.setState({ erroredPortal, userInput: errorCredentialConfig.userInput });
      } else {
        this.setState({ success: true, loading: false, erroredPortal: null });
      }
    } catch {
      alert.error('There was an error submitting this enrollment');
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, success, userInput, erroredPortal } = this.state;
    const { back, authorization, account, backToList } = this.props;
    const sent = success || authorization.submittedAt;

    return (
      <Container>
        <LoadingSpinner open={loading} />
        { erroredPortal && (
          <UpdateCredentialsModal
            requiredFields={userInput}
            close={() => { this.setState({ erroredPortal: null }); }}
            onSubmit={this.submit}
            portalKey={erroredPortal}
            account={account}
          />
        ) }
        { sent && (
          <AlertModal
            content={ENROLLMENT_TEXT}
            header="Enrollment Submission Success"
            buttons={(
              <BaseButton style={{ width: '100%' }} onClick={backToList}>Back to Main List</BaseButton>
            )}
            open
          />
        ) }
        <FormSubmitButtons back={back} submit={this.submit} submitText="Submit" />
      </Container>
    );
  }
}

export default compose(withSubmitPatientEnrollment)(withAlert(EnrollmentSubmit));
