export default {
  font_size: '16px',
  pdfWidth: `${CONFIG.CONSTANTS.PDF_STANDARD_DIMENSIONS.width * CONFIG.CONSTANTS.SCALE_PDF_DISPLAY_MULTIPLIER}px`,
  zDefault: 0,
  zLow: 1,
  zMiddle: 2,
  zUpper: 3,
  zTop: 4,
  zTopPlus1: 5,
  zTopPlus2: 6,
  zTopPlus3: 7,
};
