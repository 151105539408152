import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import styled from 'styled-components';
import { BaseText } from 'Segment/StyledComponents';
import { SegmentDate } from 'Segment/SegmentDate';

import Modal from '../../components/Modal';
import BaseButton from '../../components/BaseButton';
import { SocialCleave } from '../../components/Segment/BaseComponents';
import { withSubmitPatientEnrollment } from '../../graphql/PatientEnrollment';
import { withUpdateAuthorization } from '../../graphql/Authorization';

const ModalBody = styled.div`
  width: 450px;
`;

const InputContainer = styled.div`
  margin: 5px 0;
`;

const fields = CONFIG.DEFAULT_FIELDS;
const SSN_KEY = 'patientSSN';
const CSN_KEY = 'patientCSN';
const LAST_NAME_KEY = 'patientLastName';
const DOB_KEY = 'dob';
const inputs = [
  { label: 'Patient Last Name', key: LAST_NAME_KEY, type: 'text' },
  { label: 'Patient DOB', key: DOB_KEY, type: 'date' },
  { label: 'Patient SSN', key: SSN_KEY, type: 'ssn' },
  { label: 'Patient CSN', key: CSN_KEY, type: 'text' },
];

export class CheckBalanceButton extends PureComponent {
  constructor(props) {
    super(props);

    const { config } = props.authorization;
    this.state = {
      modalOpen: false,
      [LAST_NAME_KEY]: config[fields.PATIENT_LAST_NAME.key] || '',
      [SSN_KEY]: config[fields.SOCIAL_SECURITY_NUMBER.key] || '',
      [CSN_KEY]: config[fields.PATIENT_CSN.key] || '',
      [DOB_KEY]: config[fields.PATIENT_DOB.key] || '',
    };
  }

  checkBalance = async () => {
    const { alert, updateAuthorization, authorization, submitPatientEnrollment } = this.props;
    const currentState = this.state;

    if (!_.every([LAST_NAME_KEY, DOB_KEY], key => !!currentState[key])) {
      alert.error('Must provide valid patient DOB and Last Name');
      return;
    }

    if (!_.some([SSN_KEY, CSN_KEY], key => !!currentState[key])) {
      alert.error('Must provide valid SSN or GoodDays CSN');
      return;
    }

    try {
      await updateAuthorization({
        variables: {
          id: authorization.id,
          patch: {
            config: {
              ...authorization.config,
              [fields.PATIENT_LAST_NAME.key]: currentState[LAST_NAME_KEY],
              [fields.SOCIAL_SECURITY_NUMBER.key]: currentState[SSN_KEY],
              [fields.PATIENT_CSN.key]: currentState[CSN_KEY],
              [fields.PATIENT_DOB.key]: currentState[DOB_KEY],
            },
          },
        },
      });
      await submitPatientEnrollment({ variables: { id: authorization.id, isBalanceCheck: true } });
      alert.info('We are checking on this patient\'s CDF balance');
      this.setState({ modalOpen: false });
    } catch {
      alert.error('There was an error checking the balance for this patient');
    }
  }

  render() {
    const { modalOpen } = this.state;
    const currentState = this.state;

    return (
      <div>
        <BaseButton onClick={() => { this.setState({ modalOpen: true }); }}>
          Check CDF Balance
        </BaseButton>
        { modalOpen && (
          <Modal header="Check CDF Balance" onClick={() => { this.setState({ modalOpen: false }); }} open>
            <ModalBody>
              {
                _.map(inputs, ({ label, key, type }) => {
                  let input;
                  if (type === 'text') {
                    input = (
                      <BaseText
                        onChange={(e) => { this.setState({ [key]: e.target.value }); }}
                        value={currentState[key] || ''}
                      />
                    );
                  }
                  if (type === 'ssn') {
                    input = (
                      <SocialCleave
                        onChange={(e) => { this.setState({ [key]: e.target.value }); }}
                        number={currentState[key] || ''}
                      />
                    );
                  }
                  if (type === 'date') {
                    input = (
                      <SegmentDate
                        handleChange={(newDate) => { this.setState(newDate); }}
                        item={{ key }}
                        result={currentState[key] || ''}
                      />
                    );
                  }

                  return (
                    <>
                      <InputContainer>
                        <div>{ label }</div>
                        { input }
                      </InputContainer>
                    </>
                  );
                })
              }
              <BaseButton style={{ marginLeft: 'auto' }} onClick={this.checkBalance}>Check Balance</BaseButton>
            </ModalBody>
          </Modal>
        ) }
      </div>
    );
  }
}

export default compose(withSubmitPatientEnrollment, withUpdateAuthorization)(withAlert(CheckBalanceButton));
