import styled from 'styled-components';

export const TransparentOverlay = styled.div`
  position: absolute;
  z-index: ${props => props.zIndexOverride || props.theme.zTopPlus1};
  width: ${({ width }) => width || '100%'};
  height: 100%;
`;

export const WhiteOpaqueOverlay = styled(TransparentOverlay)`
  background-color: white;
  opacity: 0.7;
`;

export const SelectionOverOverlay = styled.div`
  z-index: ${props => props.theme.zTopPlus2};
  position: absolute;
  background: white;
  border: 1px solid ${props => props.theme.purple};
`;
