import _ from 'lodash';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withAlert } from 'react-alert';
import SegmentUpload from 'Segment/SegmentUpload';
import IntegratedDownloadedFilesSuggestionModal from 'AuthorizationSharedComponents/IntegratedDownloadedFilesSuggestionModal';

import { setAttachment } from '../../reducers/formReducer';
import RequiredTag from '../../components/RequiredTag';
import BaseButton from '../../components/BaseButton';
import SectionHeader from './SectionHeader';

export class PortalUpload extends PureComponent {
  constructor(props) {
    super(props);
    const { message, set } = this.props;

    if (_.get(message, 'priorValue')) {
      set(message.priorValue);
    }

    this.saveDraft = _.throttle(this._saveDraft, 5000, { trailing: true });
  }

  componentDidUpdate(prevProps) {
    const { attachments } = this.props;

    if (!_.isEqual(attachments, prevProps.attachments)) {
      this.saveDraft();
    }
  }

  _saveDraft = async () => {
    const { onSelect, message, attachments, alert } = this.props;

    try {
      await onSelect({ key: message.key, value: attachments, isDraft: true });
      alert.success('Your progress has been saved!');
    } catch (e) {
      alert.error('There was an error saving your draft');
    }
  }

  render() {
    const { attachments, message, onSelect, segmentLoading, authorization } = this.props;
    const { title, required, maxMB } = message;

    return (
      <div>
        <SectionHeader>
          { title }
          { maxMB && (` (Max ${maxMB} mb per file)`) }
          { required && (<RequiredTag />) }
        </SectionHeader>
        { !_.isEmpty(authorization.integratedEMRFilesDownloaded) && (
          <IntegratedDownloadedFilesSuggestionModal
            authorizationId={authorization.id}
            maxFileSizeMB={maxMB}
          />
        )}
        <SegmentUpload customMaxSize={maxMB * 1000000} />
        <BaseButton
          disabled={segmentLoading || (required && _.isEmpty(attachments))}
          onClick={() => { onSelect({ ...message, value: attachments, display: _.map(attachments, 'title').join(', ') }); }}
        >
          I am finished uploading
        </BaseButton>
      </div>
    );
  }
}


const mapStateToProps = state => ({
  attachments: state.form.attachments,
  segmentLoading: state.form.segmentLoading,
});

const mapDispatchToProps = dispatch => ({
  set(file) { dispatch(setAttachment(file)); },
});

export default connect(mapStateToProps, mapDispatchToProps)(withAlert(PortalUpload));
