import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import Select from 'react-select';
import styled from 'styled-components';

import colors from 'Resources/colors';
import { BaseText } from 'Segment/StyledComponents';
import BaseButton from '../../../components/BaseButton';

import filterInsuranceCompanyOptions from '../../../util/filterInsuranceCompanyOptions';
import { withUpsertInsuranceCompany } from '../../../graphql/InsuranceCompany';
import { Pill, CreateNew, SupportEditContainer } from '../SharedStyles';

const StyledInput = styled(BaseText)`
  width: 100%;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const FieldLabel = styled.div`
  width: 30%;
  margin-right: 10px;
  font-weight: 500;
`;

const baseState = {
  createNewPayer: false,
  updateName: '',
  updateParentPlanId: null,
  updateSearchTags: [],
};

export class PayerEdit extends PureComponent {
  state = baseState;

  componentDidMount() {
    const { selectedCompany } = this.props;
    this.updateSelectedPayer(selectedCompany);
  }

  componentDidUpdate(prevProps) {
    const { selectedCompany } = this.props;
    if (selectedCompany.id !== prevProps.selectedCompany.id) {
      this.updateSelectedPayer(selectedCompany);
    }
  }

  updateSelectedPayer = (selectedCompany) => {
    if (!_.isEmpty(selectedCompany)) {
      this.setState({
        createNewPayer: false,
        updateName: selectedCompany.name,
        updateParentPlanId: selectedCompany.planParentCompanyId,
        updateSearchTags: [...selectedCompany.searchTags],
      });
    }
  }

  checkForUnsavedChanges = () => {
    const { selectedCompany } = this.props;
    const { createNewPayer, updateName, updateParentPlanId, updateSearchTags } = this.state;
    if (createNewPayer) {
      return updateName || updateParentPlanId || updateSearchTags.length;
    }
    return (
      updateName !== selectedCompany.name
      || updateParentPlanId !== selectedCompany.planParentCompanyId
      || !_.isEqual(_.sortBy(updateSearchTags), _.sortBy(selectedCompany.searchTags))
    );
  }

  saveChanges = async () => {
    const { upsertInsuranceCompany, selectedCompany, resetSelectedCompany, alert } = this.props;
    const { updateName, updateSearchTags, updateParentPlanId, createNewPayer } = this.state;
    if (!updateName) {
      alert.error('Please enter a name for this payer');
    } else {
      try {
        const args = {
          ...(createNewPayer ? {} : { id: selectedCompany.id }),
          name: updateName,
          searchTags: updateSearchTags,
          parentId: updateParentPlanId,
        };
        await upsertInsuranceCompany({ variables: args });
        if (!createNewPayer) {
          resetSelectedCompany();
          alert.info('Successfully updated insurance company');
        } else {
          this.setState(baseState);
          alert.info('Successfully created insurance company');
        }
      } catch (e) {
        alert.error(`Failed to upsert ${e}`);
      }
    }
  }

  render() {
    const { createNewPayer, updateName, updateSearchTags, addTag, updateParentPlanId } = this.state;
    const { selectedCompany, insuranceCompanies, clearSelectedCompany } = this.props;

    const hasPayer = !_.isEmpty(selectedCompany) || createNewPayer;

    return !hasPayer ? (
      <CreateNew onClick={() => { this.setState({ ...baseState, createNewPayer: true }); }}>
        Click to Create a New Insurance Company
      </CreateNew>
    ) : (
      <SupportEditContainer>
        {createNewPayer && (
          <RowWrapper>
            <div style={{ marginLeft: '10px', fontStyle: 'italic' }}>Edit New Company Details and Click Save To Create New Record</div>
          </RowWrapper>
        )}
        <RowWrapper>
          <FieldLabel>Name</FieldLabel>
          <StyledInput
            onChange={(e) => { this.setState({ updateName: e.target.value }); }}
            value={updateName}
            placeholder="Update Insurance Name"
          />
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>Parent Company</FieldLabel>
          <StyledSelect
            valueKey="id"
            labelKey="name"
            clearable
            options={insuranceCompanies}
            filterOption={filterInsuranceCompanyOptions}
            onChange={(selected = {}) => { this.setState({ updateParentPlanId: selected.id }); }}
            placeholder="Add or remove a parent insurance company"
            value={_.find(insuranceCompanies, { id: updateParentPlanId })}
          />
        </RowWrapper>
        <RowWrapper>
          <FieldLabel>Search Tags</FieldLabel>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <div style={{ display: 'flex', marginBottom: '10px' }}>
              <StyledInput
                onChange={(e) => { this.setState({ addTag: e.target.value }); }}
                value={addTag}
                placeholder="Add a New Search Tag"
              />
              <BaseButton
                style={{ width: '50%', marginLeft: '10px' }}
                onClick={() => { if (addTag) this.setState(prevState => ({ updateSearchTags: _.uniq([...prevState.updateSearchTags, addTag]), addTag: '' })); }}
              >
                Add Tag
              </BaseButton>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: '10px' }}>
              {_.map(updateSearchTags, searchTag => (
                <Pill
                  onClick={() => { this.setState(prevState => ({ updateSearchTags: _.without(prevState.updateSearchTags, searchTag) })); }}
                  key={`pill-${searchTag}`}
                >
                  {`${searchTag} X`}
                </Pill>
              ))}
            </div>
          </div>
        </RowWrapper>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '35%', marginLeft: '10px', color: colors.red, textAlign: 'center' }}>
            {this.checkForUnsavedChanges() ? 'Unsaved Changes' : ''}
          </div>
          <BaseButton
            style={{ width: '20%', marginLeft: '10px' }}
            onClick={() => { this.setState({ createNewPayer: false }, clearSelectedCompany); }}
          >
            Cancel
          </BaseButton>
          <BaseButton
            style={{ width: '20%', marginLeft: '10px' }}
            onClick={this.saveChanges}
          >
            Save
          </BaseButton>
        </div>
      </SupportEditContainer>
    );
  }
}

export default compose(withUpsertInsuranceCompany)(withAlert(PayerEdit));
