import _ from 'lodash';
import styled, { css } from 'styled-components';
import React, { PureComponent } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PatientRow from './PatientRow';
import { BaseText } from '../../components/Segment/StyledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const EmptyState = styled.div`
  font-size: 16px;
  color: ${props => props.theme.lightGray};
  font-weight: bold;
  margin: 50px 20px 0 20px;
  text-align: center;
`;

export const OverflowAndEllipsis = css`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  font-size: 14px;
  font-weight: bold;

  ${BaseText} {
    width: 200px;
    margin: 0 10px;
  }
`;

const HeaderRow = styled.tr`
  td {
    padding: 5px;
    font-weight: bold;
    color: ${props => props.theme.lightGray};
  }
`;

export class PatientEnrollmentListManager extends PureComponent {
  state = { lastNameFilter: '' };

  render() {
    const { patientEnrollments } = this.props;
    const { lastNameFilter } = this.state;

    const grouped = _.groupBy(patientEnrollments, 'patient.id');
    const tabularData = _.sortBy(_.reduce(_.values(grouped), (aggregated, enrollments) => {
      if (lastNameFilter) {
        if (
          _.get(enrollments[0], 'patient.lastName', '').toLowerCase().includes(lastNameFilter.toLowerCase())
        ) {
          return aggregated.concat({
            patient: enrollments[0].patient,
            enrollments,
            sortBy: _.maxBy(enrollments, 'sortByTimestamp').sortByTimestamp,
          });
        }

        return aggregated;
      }

      return aggregated.concat({
        patient: enrollments[0].patient,
        enrollments,
        sortBy: _.maxBy(enrollments, 'sortByTimestamp').sortByTimestamp,
      });
    }, []), 'sortBy').reverse();

    return (
      <Container>
        <SearchContainer>
          Filter by last name
          <BaseText
            onChange={(e) => { this.setState({ lastNameFilter: e.target.value || '' }); }}
            value={lastNameFilter}
            placeholder="Last Name"
          />
        </SearchContainer>
        <table style={{ width: '100%' }}>
          {
            !_.isEmpty(tabularData)
              ? (
                <tbody>
                  <HeaderRow>
                    <td />
                    <td>Patient Name</td>
                    <td>Good Days Status</td>
                    <td>Access Solutions Status</td>
                  </HeaderRow>
                  {
                    _.map(
                      tabularData,
                      ({ patient, enrollments }) => (
                        <PatientRow key={`${_.get(patient, 'id', 'undefPatient')}_row`} enrollments={enrollments} patient={patient} />
                      )
                    )
                  }
                </tbody>
              )
              : (<EmptyState>No enrollments were found.</EmptyState>)
          }
        </table>
      </Container>
    );
  }
}

const mapDispatchProps = dispatch => ({
  toEnrollment(id, step, routePrefix, shouldOpenInBlank) {
    const route = `${routePrefix}?step=${step || 1}&id=${id}`;
    if (shouldOpenInBlank) {
      window.open(`#${route}`, '_blank');
    } else {
      dispatch(push(route));
    }
  },
});

export default connect(() => ({}), mapDispatchProps)(PatientEnrollmentListManager);
