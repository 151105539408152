import _ from 'lodash';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';

import { withInsuranceCompanies } from '../../graphql/InsuranceCompany';
import BaseButton from '../../components/BaseButton';
import SupportResponses from './SupportResponses';
import SupportOnboarding from './SupportOnboarding';
import SupportFormsNumbers from './SupportFormsNumbers';
import SupportPayers from './SupportPayers';
import SupportDrugOptions from './SupportDrugOptions';
import SendSecureFile from './SendSecureFile';
import SupportPortalDetails from './SupportPortalDetails';
import AuthorizationErrorDashboard from './AuthorizationErrorDashboard';
import IntegrationErrorDashboard from './IntegrationErrorDashboard';
import SupportPatientSyncers from './SupportPatientSyncers';
import SupportedFollowUps from './SupportedAuthorizationFollowUps';

const ButtonContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;

  ${BaseButton} {
    margin: 0 8px;
  }
`;

const ToggleButton = styled(BaseButton)`
  background-color: ${props => props.selected ? props.theme.purple : 'white'};
  color: ${props => props.selected ? 'white' : props.theme.purple};
`;

const RESPONSE_VIEW = 'responses';
const ONBOARDING_VIEW = 'onboarding';
const PAYERS_VIEW = 'payers';
const FORMS_NUMBERS_VIEW = 'formsAndNumbers';
const DRUGS_OPTIONS_VIEW = 'drugOptions';
const SEND_SECURE_FILE = 'secureFile';
const PORTAL_METRICS = 'portalMetrics';
const AUTHORIZATION_ERROR_DASHBOARD = 'authorizationErrorDashboard';
const INTEGRATION_ERROR_DASHBOARD = 'integrationErrorDashboard';
const PATIENT_SYNCER = 'patientSyncer';
const SUPPORTED_FOLLOW_UPS = 'supportedFollowUps';

class Support extends PureComponent {
  state = { sendFileProps: {} }

  switchSupportView = (view, correspondence = {}, institution = {}) => {
    const { location, goToLocation } = this.props;

    this.setState({ sendFileProps: { correspondence, institution, institutionId: institution.id } });

    const params = new URLSearchParams(location.search);
    params.set('view', view);

    goToLocation(`${location.pathname}?${params.toString()}`);
  }

  render() {
    const { insuranceCompanies, location } = this.props;
    const { sendFileProps } = this.state;

    const params = new URLSearchParams(location.search);
    const currentView = params.get('view');

    const supportViews = [
      { isDefault: true, key: SUPPORTED_FOLLOW_UPS, title: 'Supported Auths' },
      { key: RESPONSE_VIEW, title: 'Responses' },
      { key: ONBOARDING_VIEW, title: 'Onboarding' },
      { key: PAYERS_VIEW, title: 'Payers' },
      { key: FORMS_NUMBERS_VIEW, title: 'Forms & Numbers' },
      { key: DRUGS_OPTIONS_VIEW, title: 'Drugs' },
      { key: SEND_SECURE_FILE, title: 'Send File' },
      { key: PORTAL_METRICS, title: 'Portal Metrics' },
      { key: AUTHORIZATION_ERROR_DASHBOARD, title: 'Deleted Auths' },
      { key: INTEGRATION_ERROR_DASHBOARD, title: 'Script Errors' },
      { key: PATIENT_SYNCER, title: 'Patient Syncers' },
    ];

    return (
      <div>
        <ButtonContainer>
          { _.map(supportViews, view => (
            <ToggleButton
              key={view.key}
              onClick={() => { this.switchSupportView(view.key); }}
              selected={view.isDefault ? (!currentView || currentView === view.key) : currentView === view.key}
            >
              { view.title }
            </ToggleButton>
          )) }
        </ButtonContainer>
        { (!currentView || currentView === SUPPORTED_FOLLOW_UPS) && (<SupportedFollowUps insuranceCompanies={insuranceCompanies} />) }
        { currentView === RESPONSE_VIEW && (
          <SupportResponses
            insuranceCompanies={insuranceCompanies}
            toSendFile={(correspondence, institution) => { this.switchSupportView(SEND_SECURE_FILE, correspondence, institution); }}
          />
        ) }
        { (currentView === ONBOARDING_VIEW) && (<SupportOnboarding />) }
        { (currentView === FORMS_NUMBERS_VIEW) && (<SupportFormsNumbers />) }
        { (currentView === PAYERS_VIEW) && (<SupportPayers />) }
        { (currentView === DRUGS_OPTIONS_VIEW) && (<SupportDrugOptions />) }
        { (currentView === SEND_SECURE_FILE) && (<SendSecureFile {...sendFileProps} />) }
        { (currentView === PORTAL_METRICS) && (<SupportPortalDetails />) }
        { (currentView === AUTHORIZATION_ERROR_DASHBOARD) && (<AuthorizationErrorDashboard />) }
        { (currentView === INTEGRATION_ERROR_DASHBOARD) && (<IntegrationErrorDashboard />) }
        { (currentView === PATIENT_SYNCER) && (<SupportPatientSyncers />) }
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    goToLocation: (location) => { dispatch(push(location)); },
  };
}

export default connect(() => ({}), mapDispatchToProps)(compose(withInsuranceCompanies)(Support));
export const FULL_ROUTE = '/support';
