import _ from 'lodash';
import styled from 'styled-components';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { withAlert } from 'react-alert';
import { withFormAssociatedAuthorizations } from '../../../../graphql/Authorization';
import { withDeleteAuthorizationForm } from '../../../../graphql/AuthorizationForm';
import BaseButton from '../../../../components/BaseButton';
import Modal from '../../../../components/Modal';
import { ModalContent } from '../../../../components/ModalStyledComponents';
import ROUTE_PATHS from '../../../ROUTE_PATHS';

const CustomModalContent = styled(ModalContent)`
  max-height: 500px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

const ModalHeader = styled.div`
  margin: 20px 0;
  display: block;
`;

const Link = styled.a`
  display: block;
`;

class DeleteForm extends PureComponent {
  deleteForm = async () => {
    const { id, deleteAuthorizationForm, alert, clearForm } = this.props;
    try {
      await deleteAuthorizationForm({
        variables: {
          id,
        },
      });
      clearForm();
      alert.info('Successfully deleted record');
    } catch (e) {
      alert.error(`Failed to update ${e}`);
    }
  }

  generateModal = (allowDelete, authorizations) => {
    const { id, title, description, back } = this.props;
    return (
      <Modal header="Delete Authorization Form" onClick={() => { back(); }} open>
        <CustomModalContent>
          {allowDelete && (
            <ModalHeader>
              {`Are you sure want to delete form "${title} - ${description}"`}
            </ModalHeader>
          )}
          <ModalHeader>
            {allowDelete && _.isEmpty(authorizations.length) ? null
              : allowDelete
                ? 'WARNING - The following draft authorizations attached this form will be deleted:'
                : 'Cannot delete form because it is associated with following Edit and Resubmit Authorizations:'}
          </ModalHeader>
          {this.generateList(authorizations)}
          {allowDelete && (
            <BaseButton style={{ width: '500px' }} onClick={() => { this.deleteForm(id); }}>
              Yes
            </BaseButton>
          )}
        </CustomModalContent>
      </Modal>
    );
  }

  generateList = authorizations => authorizations.map(auth => (
    <Link
      target="_blank"
      key={`deleteForm-auth-${auth.id}`}
      href={`${window.location.origin}/#${ROUTE_PATHS.FORM_AUTHORIZATION}?step=1&id=${auth.id}`}
    >
      {`Authorization ID ${auth.id}`}
    </Link>
  ));

  render() {
    const { authorizations } = this.props;
    const { PRESUBMISSION, EDIT_AND_RESUBMIT } = CONFIG.CONSTANTS.AUTHORIZATION_STATUSES;
    if (authorizations) {
      const draftAuths = _.filter(authorizations, { status: PRESUBMISSION });
      const resubmitAuths = _.filter(authorizations, { status: EDIT_AND_RESUBMIT });
      if (!resubmitAuths.length) {
        return this.generateModal(true, draftAuths);
      }
      return this.generateModal(false, resubmitAuths);
    }
    return (<div />);
  }
}

export default compose(withFormAssociatedAuthorizations, withDeleteAuthorizationForm)(withAlert(DeleteForm));
