import React, { PureComponent } from 'react';
import styled from 'styled-components';
import CloseIcon from 'react-icons/lib/md/close';

import FrozenBackdropHOC from './FrozenBackdropHOC';

const ModalContainer = styled.div`
  z-index: ${props => props.theme.zTop};
  background-color: white;
  padding: 15px 15px 10px 15px;
  border-radius: 3px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const ModalHeader = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  color: ${props => props.theme.purple};
`;

const ModalBody = styled.div`
  width: 100%;
`;

const CloseModal = styled(CloseIcon)`
  position: absolute;
  height: 25px;
  width: 20px;
  right: 15px;
  top: 15px;
  color: ${props => props.theme.purple};
  cursor: pointer;
`;

class Modal extends PureComponent {
  render() {
    const { children, header, onClick, styleOverride } = this.props;

    return (
      <ModalContainer style={styleOverride}>
        { onClick && (<CloseModal onClick={(e) => { e.stopPropagation(); onClick(e); }} />) }
        <ModalBody>
          <ModalHeader>{header}</ModalHeader>
          { children }
        </ModalBody>
      </ModalContainer>
    );
  }
}

export default FrozenBackdropHOC(Modal);
