const { GENENTECH_ENROLLMENT, GOODDAYS_ENROLLMENT } = CONFIG.DEFAULT_FIELDS;

export default (config) => {
  let enrollmentString = [];
  if (config[GENENTECH_ENROLLMENT.key]) {
    enrollmentString = enrollmentString.concat('Access Solutions');
  }

  if (config[GOODDAYS_ENROLLMENT.key]) {
    enrollmentString = enrollmentString.concat('Gooddays');
  }
  if (enrollmentString.length === 1) {
    return enrollmentString[0];
  }
  return enrollmentString.join(' + ');
};
