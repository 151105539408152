import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'react-apollo';

import ListFilter from '../../components/MainList/ListFilter';
import PatientEnrollmentsListManager from './PatientEnrollmentListManager';
import ROUTE_PATHS from '../ROUTE_PATHS';
import filterTabs from './filterTabs';
import { withPatientEnrollments } from '../../graphql/PatientEnrollment';
import { withCurrentAccount } from '../../graphql/Account';
import CreateEnrollmentButton from './CreateEnrollmentButton';

const FormTitle = styled.div`
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
`;

const Container = styled.div`
  display: flex;
  height: calc(100% - 50px);
  margin: 50px 75px;
  flex-direction: column;
`;

export class PatientEnrollmentsList extends PureComponent {
  state = { selected: filterTabs[1], sortBy: 'sortByTimestamp' };

  refetchPatientEnrollmentsList = async (selected) => {
    const { patientEnrollmentsRefetch, alert } = this.props;
    const { sortBy } = this.state;

    try {
      await patientEnrollmentsRefetch({
        filterByAccount: selected.onlyMe,
        isArchived: selected.isArchived,
        sortBy,
      });
      this.setState({ selected });
    } catch {
      alert.error('Error updating Patient Enrollment List');
    }
  };

  render() {
    const { selected, sortBy } = this.state;
    const { patientEnrollments, toNewEnrollment, account } = this.props;
    return (
      <Container>
        <FormTitle>
          <div style={{ flex: 1 }}>{ selected.title }</div>
          <CreateEnrollmentButton navigateMethod={toNewEnrollment} account={account} />
        </FormTitle>
        <ListFilter
          onSelect={this.refetchPatientEnrollmentsList}
          selected={selected}
          tabs={filterTabs}
          hideFilters
        />
        <PatientEnrollmentsListManager
          patientEnrollments={patientEnrollments}
          sortBy={sortBy} setSort={(newSort) => { this.setState({ sortBy: newSort }, this.refetchPatientEnrollmentsList); }}
        />
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    toNewEnrollment(id) { dispatch(push(`${ROUTE_PATHS.PATIENT_ENROLLMENT}?step=1&id=${id}`)); },
  };
}
export default connect(() => ({}), mapDispatchToProps)(compose(withCurrentAccount, withPatientEnrollments)(PatientEnrollmentsList));
