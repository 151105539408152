import _ from 'lodash';
import sharedCode from '../../shared-code/index';

export const { flattenInputs } = sharedCode;

export const traverseInputs = (inputs, cb) => (
  _.compact(_.map(inputs, (input) => {
    const newItems = traverseInputs((input.items || []), cb);
    return cb({ ...input, items: newItems });
  }))
);

export const updateFlatInput = (inputs, updatedInput, updatedInputId) => (
  traverseInputs(inputs, (input) => {
    if (input.id === updatedInputId) { return { ...updatedInput, items: input.items }; }
    return input;
  })
);

export const addChild = (inputs, rootNodeId, child) => (
  traverseInputs(inputs, (input) => {
    if (input.id === rootNodeId) {
      const childOpts = {};
      if (input.type === CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.RADIO_GROUP.key) {
        childOpts.type = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.RADIO.key;
      }
      return { ...input, items: (input.items || []).concat({ ...child, ...childOpts, parentId: rootNodeId }) };
    }
    return input;
  })
);

export const moveNode = (nodes, toMoveId, newParentId) => {
  let toMove = null;

  const withMoverRemoved = traverseInputs(nodes, (input) => {
    if (input.id !== toMoveId) {
      return input;
    }

    toMove = input;
    return null;
  });

  if (newParentId) {
    return addChild(withMoverRemoved, newParentId, toMove);
  }

  return withMoverRemoved.concat(toMove);
};

export const deleteNode = (nodes, toDeleteId) => traverseInputs(nodes, (input) => {
  if (input.id === toDeleteId) {
    return null;
  }

  return input;
});

export const deleteBox = (nodes, nodeId, boxIndex) => traverseInputs(nodes, (input) => {
  if (input.id === nodeId) {
    return { ...input, boxes: _.filter(input.boxes, (box, i) => (i !== boxIndex)) };
  }

  return input;
});

export const defaultOnlyFocusChildrenIfTrue = type => (!_.includes([
  CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.RADIO_GROUP.key,
  CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.DATE3.key,
  CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.PHONE2.key,
  CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.PHONE3.key,
  CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.ZIP2.key,
], type));

export const generateInput = (overrides) => {
  const type = _.get(overrides, 'type') || CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.TEXT.key;
  // Default to onlyFocusChildrenIfTrue unless we expect certain types of children
  const onlyFocusChildrenIfTrue = defaultOnlyFocusChildrenIfTrue(type);

  return {
    id: `${Math.round(Math.random() * 10000000)}`,
    items: [],
    boxes: [],
    importance: CONFIG.CONSTANTS.INPUT_IMPORTANCE.DEFAULT.key,
    samaTypes: [],
    onlyFocusChildrenIfTrue,
    ...overrides,
    type,
  };
};

export const moveBox = (inputs, selectedId, boxIndex, { x, y }) => (
  traverseInputs(inputs, (input) => {
    if (input.id === selectedId) {
      const newBoxes = _.map(input.boxes, (box, i) => {
        if (i === boxIndex) {
          return { ...box, x: box.x + x, y: box.y + y };
        }
        return box;
      });

      return { ...input, boxes: newBoxes };
    }
    return input;
  })
);

export const moveAllBoxes = (inputs, currentPage, { x, y }) => (
  traverseInputs(inputs, (input) => {
    const newBoxes = _.map(input.boxes, (box) => {
      if (box.page === currentPage) {
        return { ...box, x: box.x + x, y: box.y + y };
      }
      return box;
    });
    return { ...input, boxes: newBoxes };
  })
);

export const cloneNode = (inputs, rootNodeId) => {
  const target = _.find(inputs, { id: rootNodeId });
  const newBoxes = _.map(target.boxes, box => ({ ...box, x: box.x - 10, y: box.y + 10 }));
  const clone = { ...target, id: `${Math.round(Math.random() * 10000000)}`, boxes: newBoxes, items: [], samaTypes: [...target.samaTypes] };
  return clone;
};


export const setTabIndexOnBoxes = (inputs) => {
  let boxes = [];
  traverseInputs(inputs, (input) => {
    boxes = boxes.concat(_.map(input.boxes, (box, i) => ({
      ...box,
      // Round to lowest 10 pixel placement, ensure inputs that are roughly grouped are in the right place
      tabIndexY: Math.floor(box.y / 10) * 10,
      inputId: input.id,
      boxIndex: i,
    })));
  });

  const withProperIndices = _.map(_.sortBy(boxes, ['page', 'tabIndexY', 'x']), (box, index) => (_.omit({ ...box, tabIndex: index + 1 })));
  return traverseInputs(inputs, (input) => {
    const newBoxes = _.sortBy(_.filter(withProperIndices, { inputId: input.id }), 'boxIndex');
    return { ...input, boxes: _.map(newBoxes, box => (_.omit(box, ['tabIndexY', 'boxIndex', 'inputId']))) };
  });
};

export const removeInvalidRadioGroups = inputs => (
  traverseInputs(inputs, (input) => {
    if (input.type === CONFIG.CONSTANTS.TAGGER_INPUT_TYPES.RADIO_GROUP.key && _.isEmpty(input.items)) {
      return null;
    }
    return input;
  })
);

const types = CONFIG.CONSTANTS.TAGGER_INPUT_TYPES;
export const trimBoxesByMaxPageLength = (inputs, maxPages) => {
  const prunedInputs = traverseInputs(inputs, (input) => {
    // 0 indexed pages
    const newBoxes = _.filter(input.boxes, ({ page }) => page <= maxPages - 1);
    if (_.isEmpty(newBoxes) && input.type !== types.RADIO_GROUP.key) {
      return null;
    }

    return { ...input, boxes: newBoxes };
  });

  // Still need to clean up all radio groups without children
  return traverseInputs(prunedInputs, (input) => {
    if (input.type === types.RADIO_GROUP.key && _.isEmpty(input.items)) {
      return null;
    }

    return input;
  });
};
