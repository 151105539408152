import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import styled from 'styled-components';
import Selector from './SegmentSelector';

const { STATES } = CONFIG.CONSTANTS;

const StyledSelector = styled(Selector)`
  width: ${props => props.width || '600px'};
`;

class SegmentState extends PureComponent {
  set = (state) => {
    const { handleChange, item } = this.props;
    handleChange({ [item.key]: state });
  }

  render() {
    const { result, disabled, width, highlightIfMissing } = this.props;
    const selected = STATES[result];
    return (
      <StyledSelector
        disabled={disabled}
        onChange={(e) => { if (e) this.set(e.value); }}
        options={_.map(STATES, (state, abbr) => ({ value: abbr, label: state }))}
        value={result ? { label: selected, value: result } : ''}
        width={width}
        sorted
        highlight={highlightIfMissing && !result}
      />
    );
  }
}

function mapStateToProps(state, props) {
  return { result: state.form.results[props.item.key] };
}

export default connect(mapStateToProps)(SegmentState);
